import React from "react"
import { Statistic as StatisticAntd } from "antd"
import { StatisticProps } from "antd/lib/statistic/Statistic"

type Props = StatisticProps & {
  titlePrefix?: React.ReactNode
}

const Statistics: React.FC<Props> = props => {
  const config = { ...props }

  if (config.titlePrefix) {
    config.title = (
      <>
        {config.title} {config.titlePrefix}
      </>
    )
  }

  return <StatisticAntd {...config} />
}

export default Statistics
