import {
  AddFilterHeadingReportAction,
  CancelFilterHeadingReportAction,
  ClearFilterHeadingReportAction,
  ClearSortHeadingReportAction,
  ExportHeadingReportAction,
  HeadingReportActionType,
  LoadHeadingReportAction,
  SetPaginationHeadingReportAction,
  SetSortHeadingReportAction,
} from "../../../../@types/actions/remote/cabinet/report/heading"

const loadHeadingReport = (
  payload: LoadHeadingReportAction["payload"],
): LoadHeadingReportAction => ({
  type: HeadingReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: HeadingReportActionType.LoadPending,
    }),
    success: payload => ({
      type: HeadingReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: HeadingReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterHeadingReport = (): ClearFilterHeadingReportAction => ({
  type: HeadingReportActionType.ClearFilter,
})

const clearSortHeadingReport = (): ClearSortHeadingReportAction => ({
  type: HeadingReportActionType.ClearSort,
})

const exportHeadingReport = (
  payload: ExportHeadingReportAction["payload"],
): ExportHeadingReportAction => ({
  type: HeadingReportActionType.Export,
  payload,
})

const addFilterHeadingReport = (
  payload: AddFilterHeadingReportAction["payload"],
): AddFilterHeadingReportAction => ({
  type: HeadingReportActionType.AddFilter,
  payload,
})

const cancelFilterHeadingReport = (
  payload: CancelFilterHeadingReportAction["payload"],
): CancelFilterHeadingReportAction => ({
  type: HeadingReportActionType.CancelFilter,
  payload,
})

const setPaginationHeadingReport = (
  payload: SetPaginationHeadingReportAction["payload"],
): SetPaginationHeadingReportAction => ({
  type: HeadingReportActionType.SetPagination,
  payload,
})

const setSortHeadingReport = (
  payload: SetSortHeadingReportAction["payload"],
): SetSortHeadingReportAction => ({
  type: HeadingReportActionType.SetSort,
  payload,
})

export {
  loadHeadingReport,
  clearFilterHeadingReport,
  clearSortHeadingReport,
  exportHeadingReport,
  addFilterHeadingReport,
  cancelFilterHeadingReport,
  setPaginationHeadingReport,
  setSortHeadingReport,
}
