import React from "react"
import { Tooltip, Typography } from "antd"
import { QuestionCircleOutlined } from "@ant-design/icons"
import { TextProps } from "antd/lib/typography/Text"

export type IconWithTooltipProps = {
  description: React.ReactNode
  prefix?: React.ReactNode
  icon?: React.ReactNode
  textProps?: TextProps
}

type Props = IconWithTooltipProps

const IconWithTooltip: React.FC<Props> = React.memo(props => {
  const {
    description,
    prefix,
    textProps,
    icon = <QuestionCircleOutlined />,
  } = props

  return (
    <Tooltip title={description}>
      {prefix}
      <Typography.Text
        style={{ marginLeft: 5 }}
        type="secondary"
        {...textProps}
      >
        {icon}
      </Typography.Text>
    </Tooltip>
  )
})

export default IconWithTooltip
