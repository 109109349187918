import {
  AddFilterStatusCodeReportAction,
  CancelFilterStatusCodeReportAction,
  ClearFilterStatusCodeReportAction,
  ClearSortStatusCodeReportAction,
  ExportStatusCodeReportAction,
  LoadStatusCodeReportAction,
  SetPaginationStatusCodeReportAction,
  SetSortStatusCodeReportAction,
  StatusCodeReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/statusCode"

const loadStatusCodeReport = (
  payload: LoadStatusCodeReportAction["payload"],
): LoadStatusCodeReportAction => ({
  type: StatusCodeReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: StatusCodeReportActionType.LoadPending,
    }),
    success: payload => ({
      type: StatusCodeReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: StatusCodeReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterStatusCodeReport = (): ClearFilterStatusCodeReportAction => ({
  type: StatusCodeReportActionType.ClearFilter,
})

const clearSortStatusCodeReport = (): ClearSortStatusCodeReportAction => ({
  type: StatusCodeReportActionType.ClearSort,
})

const exportStatusCodeReport = (
  payload: ExportStatusCodeReportAction["payload"],
): ExportStatusCodeReportAction => ({
  type: StatusCodeReportActionType.Export,
  payload,
})

const addFilterStatusCodeReport = (
  payload: AddFilterStatusCodeReportAction["payload"],
): AddFilterStatusCodeReportAction => ({
  type: StatusCodeReportActionType.AddFilter,
  payload,
})

const cancelFilterStatusCodeReport = (
  payload: CancelFilterStatusCodeReportAction["payload"],
): CancelFilterStatusCodeReportAction => ({
  type: StatusCodeReportActionType.CancelFilter,
  payload,
})

const setPaginationStatusCodeReport = (
  payload: SetPaginationStatusCodeReportAction["payload"],
): SetPaginationStatusCodeReportAction => ({
  type: StatusCodeReportActionType.SetPagination,
  payload,
})

const setSortStatusCodeReport = (
  payload: SetSortStatusCodeReportAction["payload"],
): SetSortStatusCodeReportAction => ({
  type: StatusCodeReportActionType.SetSort,
  payload,
})

export {
  loadStatusCodeReport,
  clearFilterStatusCodeReport,
  clearSortStatusCodeReport,
  exportStatusCodeReport,
  addFilterStatusCodeReport,
  cancelFilterStatusCodeReport,
  setPaginationStatusCodeReport,
  setSortStatusCodeReport,
}
