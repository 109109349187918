import { Action } from "redux"
import {
  CreateReportPayload,
  CreateReportResponse,
  DeleteReportPayload,
  GetReportListPayload,
  GetReportListResponse,
  GetReportPayload,
  GetReportResponse,
  StopCrawlingPayload,
} from "../../../../../../services/apiClient"
import { RemoteActionWithPayload } from "../../../action"

export enum ReportActionType {
  Delete = "@@REPORT/DELETE",
  DeleteSuccess = "@@REPORT/DELETE_SUCCESS",
  DeleteFailure = "@@REPORT/DELETE_FAILURE",
  DeletePending = "@@REPORT/DELETE_PENDING",

  CurrentClear = "@@REPORT/CLEAR",

  CreatedClear = "@@REPORT/CREATED/CLEAR",

  Create = "@@REPORT/CREATE",
  CreateSuccess = "@@REPORT/CREATE_SUCCESS",
  CreatePending = "@@REPORT/CREATE_PENDING",
  CreateFailure = "@@REPORT/CREATE_FAILURE",

  Load = "@@REPORT/LOAD",
  LoadSuccess = "@@REPORT/LOAD_SUCCESS",
  LoadPending = "@@REPORT/LOAD_PENDING",
  LoadFailure = "@@REPORT/LOAD_FAILURE",

  ClearList = "@@REPORT/CLEAR_LIST",

  LoadList = "@@REPORT/LOAD_LIST",
  LoadListSuccess = "@@REPORT/LOAD_LIST_SUCCESS",
  LoadListFailure = "@@REPORT/LOAD_LIST_FAILURE",
  LoadListPending = "@@REPORT/LOAD_LIST_PENDING",

  LoadByAlias = "@@REPORT/LOAD_BY_ALIAS",
  LoadByAliasSuccess = "@@REPORT/LOAD_BY_ALIAS_SUCCESS",
  LoadByAliasPending = "@@REPORT/LOAD_BY_ALIAS_PENDING",
  LoadByAliasFailure = "@@REPORT/LOAD_BY_ALIAS_FAILURE",

  StopCrawling = "@@REPORT/STOP_CRAWLING",
  StopCrawlingSuccess = "@@REPORT/STOP_CRAWLING_SUCCESS",
  StopCrawlingPending = "@@REPORT/STOP_CRAWLING_PENDING",
  StopCrawlingFailure = "@@REPORT/STOP_CRAWLING_FAILURE",
  StopCrawlingReset = "@@REPORT/STOP_CRAWLING_RESET",
}

export type DeleteReportAction = RemoteActionWithPayload<
  DeleteReportPayload,
  DeleteReportPayload,
  ReportActionType.Delete,
  ReportActionType.DeletePending,
  ReportActionType.DeleteSuccess,
  ReportActionType.DeleteFailure
>
export type DeleteReportPendingAction = ReturnType<
  DeleteReportAction["meta"]["pending"]
>
export type DeleteReportSuccessAction = ReturnType<
  DeleteReportAction["meta"]["success"]
>
export type DeleteReportFailureAction = ReturnType<
  DeleteReportAction["meta"]["failure"]
>

export type LoadReportAction = RemoteActionWithPayload<
  GetReportPayload,
  GetReportResponse,
  ReportActionType.Load,
  ReportActionType.LoadPending,
  ReportActionType.LoadSuccess,
  ReportActionType.LoadFailure
>
export type LoadReportPendingAction = ReturnType<
  LoadReportAction["meta"]["pending"]
>
export type LoadReportSuccessAction = ReturnType<
  LoadReportAction["meta"]["success"]
>
export type LoadReportFailureAction = ReturnType<
  LoadReportAction["meta"]["failure"]
>

export type StopCrawlingAction = RemoteActionWithPayload<
  StopCrawlingPayload,
  boolean,
  ReportActionType.StopCrawling,
  ReportActionType.StopCrawlingPending,
  ReportActionType.StopCrawlingSuccess,
  ReportActionType.StopCrawlingFailure
>
export type StopCrawlingPendingAction = ReturnType<
  StopCrawlingAction["meta"]["pending"]
>
export type StopCrawlingSuccessAction = ReturnType<
  StopCrawlingAction["meta"]["success"]
>
export type StopCrawlingFailureAction = ReturnType<
  StopCrawlingAction["meta"]["failure"]
>

export type StopCrawlingResetAction = Action<ReportActionType.StopCrawlingReset>

export type ClearReportAction = Action<ReportActionType.CurrentClear>

export type ClearReportCreatedAction = Action<ReportActionType.CreatedClear>

export type CreateReportAction = RemoteActionWithPayload<
  CreateReportPayload,
  CreateReportResponse,
  ReportActionType.Create,
  ReportActionType.CreatePending,
  ReportActionType.CreateSuccess,
  ReportActionType.CreateFailure
>
export type CreateReportPendingAction = ReturnType<
  CreateReportAction["meta"]["pending"]
>
export type CreateReportSuccessAction = ReturnType<
  CreateReportAction["meta"]["success"]
>
export type CreateReportFailureAction = ReturnType<
  CreateReportAction["meta"]["failure"]
>

export type LoadReportListAction = RemoteActionWithPayload<
  Pick<GetReportListPayload, "projectId">,
  GetReportListResponse,
  ReportActionType.LoadList,
  ReportActionType.LoadListPending,
  ReportActionType.LoadListSuccess,
  ReportActionType.LoadListFailure
>
export type LoadReportListPendingAction = ReturnType<
  LoadReportListAction["meta"]["pending"]
>
export type LoadReportListSuccessAction = ReturnType<
  LoadReportListAction["meta"]["success"]
>
export type LoadReportListFailureAction = ReturnType<
  LoadReportListAction["meta"]["failure"]
>

export type LoadReportByAliasAction = RemoteActionWithPayload<
  GetReportListPayload,
  GetReportListResponse,
  ReportActionType.LoadByAlias,
  ReportActionType.LoadByAliasPending,
  ReportActionType.LoadByAliasSuccess,
  ReportActionType.LoadByAliasFailure
>
export type LoadReportByAliasPendingAction = ReturnType<
  LoadReportByAliasAction["meta"]["pending"]
>
export type LoadReportByAliasSuccessAction = ReturnType<
  LoadReportByAliasAction["meta"]["success"]
>
export type LoadReportByAliasFailureAction = ReturnType<
  LoadReportByAliasAction["meta"]["failure"]
>

export type ClearReportListAction = Action<ReportActionType.ClearList>
