import { SharedLinkActionType } from "../../../../@types/actions/remote/cabinet/report/share"
import {
  SharedLinkActions,
  SharedLinkState,
} from "../../../../@types/reducers/remote/cabinet/report/shareReducer"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: SharedLinkState = {
  current: {
    isLoading: false,
  },
  created: {
    isLoading: false,
  },
}

const sharedLinkReducer = (
  state: SharedLinkState = initState,
  action: SharedLinkActions,
): SharedLinkState => {
  switch (action.type) {
    case SharedLinkActionType.LoadByTokenPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case SharedLinkActionType.LoadByTokenSuccess:
      return {
        ...state,
        current: {
          data: action.payload.data,
          isLoading: false,
        },
      }

    case SharedLinkActionType.LoadByTokenFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case SharedLinkActionType.LoadPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case SharedLinkActionType.LoadSuccess:
      return {
        ...state,
        current: {
          data: action.payload.data,
          isLoading: false,
        },
      }

    case SharedLinkActionType.LoadFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case SharedLinkActionType.CreatePending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case SharedLinkActionType.CreateSuccess:
      return {
        ...state,
        current: {
          data: action.payload.data,
          isLoading: false,
        },
      }

    case SharedLinkActionType.CreateFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case SharedLinkActionType.CreatedClear:
      return {
        ...state,
        created: JSON.parse(JSON.stringify(initState.created)),
      }

    case SharedLinkActionType.CurrentClear:
      return {
        ...state,
        current: JSON.parse(JSON.stringify(initState.current)),
      }

    case SharedLinkActionType.DeleteSuccess:
      return {
        ...state,
        current: JSON.parse(JSON.stringify(initState.current)),
        created: JSON.parse(JSON.stringify(initState.current)),
      }

    default:
      return state
  }
}

export default sharedLinkReducer
