import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { RobotstxtReportActionType } from "../../../../@types/actions/remote/cabinet/report/robotstxt"
import {
  RobotstxtReportActions,
  RobotstxtReportState,
} from "../../../../@types/reducers/remote/cabinet/report/robotstxtReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: RobotstxtReportState = {
  isLoading: false,
  fields: [
    "resource_id",
    "page_title",
    "page_dfi",
    "resource_url",
    "resource_url_no_scheme",
    "resource_status_code",
    "resource_robotstxt_ignore",
    "resource_redirect_url",
    "resource_redirect_status_code",
    "resource_redirects",
    "resource_robotstxt_line",
    "resource_is_external",
    "page_canonical_url",
    "page_is_canonical",
    "page_internal_only",
  ],
  defaultFilter: {
    page_internal_only: [[ApiClientFilterType.Eq, true]],
    resource_is_external: [[ApiClientFilterType.Eq, false]],
  },
  defaultSort: {
    page_dfi: ApiClientSortOrder.Asc,
  },
  alwaysSort: {
    resource_id: ApiClientSortOrder.Asc,
  },
}

const robotstxtReducer = (
  state: RobotstxtReportState = initState,
  action: RobotstxtReportActions,
): RobotstxtReportState => {
  switch (action.type) {
    case RobotstxtReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case RobotstxtReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case RobotstxtReportActionType.LoadPending:
      return updateStateSetPending(state)

    case RobotstxtReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case RobotstxtReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case RobotstxtReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case RobotstxtReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case RobotstxtReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case RobotstxtReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default robotstxtReducer
