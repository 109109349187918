import { ProjectTypes } from "../../../@types/actions/remote/cabinet/project"
import { ReportActionType } from "../../../@types/actions/remote/cabinet/report/report"
import {
  ProjectActions,
  ProjectState,
} from "../../../@types/reducers/remote/cabinet/project"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../../utils/reducer"

const initState: ProjectState = {
  current: {
    isLoading: false,
  },
  created: {
    isLoading: false,
  },
  list: {
    isLoading: false,
  },
}

const projectReducer = (
  state: ProjectState = initState,
  action: ProjectActions,
): ProjectState => {
  switch (action.type) {
    case ProjectTypes.LoadListSuccess: {
      return {
        ...state,
        list: {
          data: action.payload.data.sort((projectA, projectB): number => {
            if (
              !projectB.reports ||
              !projectB.reports[0] ||
              !projectB.reports[0].created_at ||
              !projectA.reports ||
              !projectA.reports[0] ||
              !projectA.reports[0].created_at
            ) {
              return -1
            }

            return (
              new Date(projectB.reports[0].created_at).getTime() -
              new Date(projectA.reports[0].created_at).getTime()
            )
          }),
          isLoading: false,
        },
      }
    }
    case ProjectTypes.LoadListFailure: {
      return {
        ...state,
        list: { ...state.list, error: action.payload, isLoading: false },
      }
    }
    case ProjectTypes.LoadListPending: {
      return { ...state, list: { ...state.list, isLoading: true } }
    }

    case ProjectTypes.DeleteSuccess: {
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data?.filter(
            project => project.id !== action.payload.projectId,
          ),
        },
      }
    }

    case ReportActionType.DeleteSuccess: {
      return {
        ...state,
        list: {
          ...state.list,
          data: state.list.data?.map(project => {
            if (project.reports) {
              project.reports = project.reports.filter(
                report => report.id !== action.payload.reportId,
              )
            }

            return project
          }),
        },
      }
    }

    case ProjectTypes.CreateSuccess: {
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload,
        },
        created: {
          data: action.payload,
          isLoading: false,
        },
        list: {
          ...state.list,
          data: [action.payload, ...(state.list.data ?? [])],
        },
      }
    }
    case ProjectTypes.CreateFailure:
      return {
        ...state,
        created: updateStateLoadFailure(state.created, action),
      }

    case ProjectTypes.CreatePending:
      return {
        ...state,
        created: updateStateSetPending(state.created),
      }

    case ProjectTypes.CreatedClear: {
      return {
        ...state,
        created: JSON.parse(JSON.stringify(initState.created)),
      }
    }

    case ReportActionType.LoadSuccess: {
      const data = state.list.data
        ?.map(project => {
          if (project.id !== action.payload.project_id) {
            return project
          }

          if (!project.reports) {
            project.reports = [action.payload]
          } else if (
            project.reports.find(report => report.id === action.payload.id)
          ) {
            project.reports = project.reports.map(report => {
              if (report.id === action.payload.id) {
                return { ...action.payload }
              }

              return report
            })
          } else {
            project.reports.unshift(action.payload)
          }

          return project
        })
        .sort((projectA, projectB): number => {
          if (
            !projectB.reports ||
            !projectB.reports[0] ||
            !projectB.reports[0].created_at ||
            !projectA.reports ||
            !projectA.reports[0] ||
            !projectA.reports[0].created_at
          ) {
            return -1
          }

          return (
            new Date(projectB.reports[0].created_at).getTime() -
            new Date(projectA.reports[0].created_at).getTime()
          )
        })

      return {
        ...state,
        list: {
          ...state.list,
          data,
        },
      }
    }

    case ProjectTypes.LoadSuccess: {
      return { ...state, current: { data: action.payload, isLoading: false } }
    }
    case ProjectTypes.LoadFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case ProjectTypes.LoadPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case ProjectTypes.LoadByAliasSuccess: {
      return {
        ...state,
        current: { data: action.payload.data, isLoading: false },
      }
    }
    case ProjectTypes.LoadByAliasFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case ProjectTypes.LoadByAliasPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case ProjectTypes.LoadByHostSuccess: {
      if (action.payload.data.length) {
        return {
          ...state,
          current: { data: action.payload.data[0], isLoading: false },
        }
      }

      return { ...state, current: { data: null, isLoading: false } }
    }
    case ProjectTypes.LoadByHostFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case ProjectTypes.LoadByHostPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case ProjectTypes.Clear: {
      return {
        ...state,
        current: JSON.parse(JSON.stringify(initState.current)),
      }
    }

    default:
      return state
  }
}

export default projectReducer
