import {
  AddFilterInternalReportAction,
  CancelFilterInternalReportAction,
  ClearFilterInternalReportAction,
  ClearSortInternalReportAction,
  ExportInternalReportAction,
  InternalReportActionType,
  LoadInternalReportAction,
  SetPaginationInternalReportAction,
  SetSortInternalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/internal"

const loadInternalReport = (
  payload: LoadInternalReportAction["payload"],
): LoadInternalReportAction => ({
  type: InternalReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: InternalReportActionType.LoadPending,
    }),
    success: payload => ({
      type: InternalReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: InternalReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterInternalReport = (): ClearFilterInternalReportAction => ({
  type: InternalReportActionType.ClearFilter,
})

const clearSortInternalReport = (): ClearSortInternalReportAction => ({
  type: InternalReportActionType.ClearSort,
})

const exportInternalReport = (
  payload: ExportInternalReportAction["payload"],
): ExportInternalReportAction => ({
  type: InternalReportActionType.Export,
  payload,
})

const addFilterInternalReport = (
  payload: AddFilterInternalReportAction["payload"],
): AddFilterInternalReportAction => ({
  type: InternalReportActionType.AddFilter,
  payload,
})

const cancelFilterInternalReport = (
  payload: CancelFilterInternalReportAction["payload"],
): CancelFilterInternalReportAction => ({
  type: InternalReportActionType.CancelFilter,
  payload,
})

const setPaginationInternalReport = (
  payload: SetPaginationInternalReportAction["payload"],
): SetPaginationInternalReportAction => ({
  type: InternalReportActionType.SetPagination,
  payload,
})

const setSortInternalReport = (
  payload: SetSortInternalReportAction["payload"],
): SetSortInternalReportAction => ({
  type: InternalReportActionType.SetSort,
  payload,
})

export {
  loadInternalReport,
  clearFilterInternalReport,
  clearSortInternalReport,
  exportInternalReport,
  addFilterInternalReport,
  cancelFilterInternalReport,
  setPaginationInternalReport,
  setSortInternalReport,
}
