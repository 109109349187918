import { TitleReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/titleReducer"
import {
  addFilterTitleReport,
  setSortTitleReport,
} from "../../../state/actions/remote/cabinet/report/title"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { ApiClientSortOrder } from "../../apiClient/@types/sort"
import { Filter, FilterActionType } from "../types/filter"

const filter: Filter<TitleReportRecord>[] = [
  {
    name: "дубликат",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterTitleReport({
          page_title_not_unique: [[ApiClientFilterType.Eq, true]],
        }),
      },
      {
        type: FilterActionType.Sort,
        action: setSortTitleReport({
          page_title: ApiClientSortOrder.Desc,
        }),
      },
    ],
  },
  {
    name: "пустые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterTitleReport({
          page_title: [[ApiClientFilterType.Eq, ""]],
        }),
      },
    ],
  },
]

export default filter
