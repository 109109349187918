import { all, fork } from "redux-saga/effects"
import exportListPageResourcesSaga from "./exportListPageResourcesSaga"
import exportListPagesSaga from "./exportListPagesSaga"
import exportListResourcesSaga from "./exportListResourcesSaga"
import exportListSitemapUrlsSaga from "./exportListSitemapUrlsSaga"
import getListPageResourcesSaga from "./getListPageResourcesSaga"
import getListPagesSaga from "./getListPagesSaga"
import getListResourcesSaga from "./getListResourcesSaga"
import getListSitemapUrlsSaga from "./getListSitemapUrlsSaga"
import getPageUrlsSaga from "./getPageUrlsSaga"
import getReportSaga from "./getReportSaga"
import projectReportsGraphSagas from "./graph"
import shareSagas from "./share"
import deleteReportSaga from "./deleteReportSaga"
import stopCrawlingSaga from "./stopCrawlingSaga"

export default function* projectReportsSagas(): Generator {
  yield all([
    fork(deleteReportSaga),
    fork(projectReportsGraphSagas),
    fork(shareSagas),
    fork(exportListPageResourcesSaga),
    fork(exportListPagesSaga),
    fork(exportListResourcesSaga),
    fork(exportListSitemapUrlsSaga),
    fork(getListPageResourcesSaga),
    fork(getListPagesSaga),
    fork(getListResourcesSaga),
    fork(getListSitemapUrlsSaga),
    fork(getPageUrlsSaga),
    fork(getReportSaga),
    fork(stopCrawlingSaga),
  ])
}
