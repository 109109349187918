import { toUnicode } from "punycode"

export default function decodeHostnameInUrl(url: string): string {
  try {
    const { hostname } = new URL(url)
    return url.replace(hostname, toUnicode(hostname))
  } catch (e) {
    return url
  }
}
