import { all, fork } from "redux-saga/effects"
import getEntryPointsSaga from "./getEntryPointsSaga"
import checkRobotstxtAndSitemapsSaga from "./checkRobotstxtAndSitemapsSaga"
import validateSitemapSaga from "./validateSitemapSaga"

export default function* hostSagas(): Generator {
  yield all([
    fork(getEntryPointsSaga),
    fork(checkRobotstxtAndSitemapsSaga),
    fork(validateSitemapSaga),
  ])
}
