export enum ReportPageResourceType {
  Other = 0,
  Image = 1,
  Script = 2,
  Style = 3,
  Canonical = 4,
  Anchor = 5,
}

export enum ReportPageResourceTag {
  Unknown = 0,
  Link = 1,
  Script = 2,
  Img = 3,
  Style = 4,
  Rel = 5,
  A = 6,
}
