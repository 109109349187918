import { useSelector } from "react-redux"
import { SitemapUrlsGraphAlias } from "../services/apiClient"
import { getSitemapUrlsGraph } from "../state/selectors/remote/cabinet/report"
import { GraphRecordInfo } from "../types/graph"

type UseSitemapUrlsGraphResult = {
  state: ReturnType<typeof getSitemapUrlsGraph>
  info: GraphRecordInfo
}

export function useSitemapUrlsGraph(): UseSitemapUrlsGraphResult {
  const state = useSelector(getSitemapUrlsGraph)
  const info = {
    [SitemapUrlsGraphAlias.InSitemap]: {
      label: "Всего страниц в sitemap",
      description:
        "Общее количество страниц, найденных при сканировании sitemap.xml",
    },
    [SitemapUrlsGraphAlias.NotInCrawl]: {
      label: "Осиротевшие страницы",
      description:
        "Страницы из sitemap.xml, не обнаруженные при сканировании сайта (страницы без внутренних связей/ссылок)",
    },
    [SitemapUrlsGraphAlias.OnlyInCrawl]: {
      label: "Отсутствуют в sitemap",
      description:
        "Страницы, найденные при сканировании сайта и отсутствующие в sitemap.xml",
    },
  }

  return { state, info }
}
