import React from "react"
import { Pie as PieAnt } from "@ant-design/charts/lib"
import { TooltipAttr } from "@antv/g2plot/src/types/attr"
import { Datum } from "@antv/g2plot/src/types/common"
import formatters from "../services/formatters"
import { StateGraph } from "../state/@types/state"
import { GraphRecordInfo } from "../types/graph"

type Props = {
  graph: StateGraph
  info?: GraphRecordInfo
  legend?: boolean
}

const Pie: React.FC<Props> = props => {
  const { graph, info, legend = true } = props

  const [colors, setColors] = React.useState([])

  const label = (key: string): string =>
    info && info[key] ? info[key].label : key

  return (
    <>
      <div style={{ height: 185 }}>
        <PieAnt
          chartRef={(chart): void => {
            if (!colors.length) {
              setColors(chart.chart.themeObject.colors10)
            }
          }}
          loading={graph.isLoading}
          height={185}
          data={graph.data?.values ?? []}
          angleField="value"
          colorField="key"
          legend={false}
          tooltip={{
            formatter: (datum: Datum): ReturnType<TooltipAttr> => ({
              value: formatters.number.format(datum.value),
              name: label(datum.key),
            }),
          }}
          label={{
            type: "inner",
            offset: -40,
            autoRotate: false,
            style: {
              textAlign: "center",
              fontSize: 14,
            },
            formatter: (datum: Datum): string =>
              formatters.number.format(datum.value),
          }}
        />
      </div>
      {legend && graph.data?.values && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginTop: "auto",
          }}
        >
          {graph.data.values.map((item, index) => (
            <div
              className="custom-legend"
              key={item.key}
              style={{ display: "flex" }}
            >
              <div
                className="custom-legend__mark"
                style={{
                  display: "block",
                  borderRadius: "50%",
                  height: 10,
                  width: 10,
                  backgroundColor: colors[index],
                }}
              />
              <div
                className="custom-legend__container"
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: 5,
                  lineHeight: 1,
                  marginBottom: 10,
                }}
              >
                <span className="custom-legend__label" style={{ fontSize: 13 }}>
                  {label(item.key)}
                </span>
                <span
                  className="custom-legend__count"
                  style={{ marginLeft: "auto" }}
                >
                  {formatters.number.format(item.value)}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default Pie
