import { Action } from "redux"
import {
  CreateProjectPayload,
  CreateProjectResponse,
  DeleteProjectPayload,
  GetProjectByAliasPayload,
  GetProjectByAliasResponse,
  GetProjectListPayload,
  GetProjectListResponse,
  GetProjectPayload,
  GetProjectResponse,
} from "../../../../../services/apiClient"
import { RemoteAction, RemoteActionWithPayload } from "../../action"

export enum ProjectTypes {
  Delete = "@@PROJECT/DELETE",
  DeleteSuccess = "@@PROJECT/DELETE_SUCCESS",
  DeleteFailure = "@@PROJECT/DELETE_FAILURE",
  DeletePending = "@@PROJECT/DELETE_PENDING",

  Clear = "@@PROJECT/CLEAR",
  CreatedClear = "@@PROJECT/CREATED/CLEAR",

  Create = "@@PROJECT/CREATE",
  CreateSuccess = "@@PROJECT/CREATE_SUCCESS",
  CreateFailure = "@@PROJECT/CREATE_FAILURE",
  CreatePending = "@@PROJECT/CREATE_PENDING",

  Load = "@@PROJECT/LOAD",
  LoadSuccess = "@@PROJECT/LOAD_SUCCESS",
  LoadPending = "@@PROJECT/LOAD_PENDING",
  LoadFailure = "@@PROJECT/LOAD_FAILURE",

  LoadList = "@@PROJECT/LOAD_LIST",
  LoadListSuccess = "@@PROJECT/LOAD_LIST_SUCCESS",
  LoadListPending = "@@PROJECT/LOAD_LIST_PENDING",
  LoadListFailure = "@@PROJECT/LOAD_LIST_FAILURE",

  LoadByAlias = "@@PROJECT/LOAD_BY_ALIAS",
  LoadByAliasSuccess = "@@PROJECT/LOAD_BY_ALIAS_SUCCESS",
  LoadByAliasPending = "@@PROJECT/LOAD_BY_ALIAS_PENDING",
  LoadByAliasFailure = "@@PROJECT/LOAD_BY_ALIAS_FAILURE",

  LoadByHost = "@@PROJECT/LOAD_BY_HOST",
  LoadByHostSuccess = "@@PROJECT/LOAD_BY_HOST_SUCCESS",
  LoadByHostPending = "@@PROJECT/LOAD_BY_HOST_PENDING",
  LoadByHostFailure = "@@PROJECT/LOAD_BY_HOST_FAILURE",
}

export type CreateProjectAction = RemoteActionWithPayload<
  CreateProjectPayload,
  CreateProjectResponse,
  ProjectTypes.Create,
  ProjectTypes.CreatePending,
  ProjectTypes.CreateSuccess,
  ProjectTypes.CreateFailure
>
export type CreateProjectPendingAction = ReturnType<
  CreateProjectAction["meta"]["pending"]
>
export type CreateProjectSuccessAction = ReturnType<
  CreateProjectAction["meta"]["success"]
>
export type CreateProjectFailureAction = ReturnType<
  CreateProjectAction["meta"]["failure"]
>

export type DeleteProjectAction = RemoteActionWithPayload<
  DeleteProjectPayload,
  DeleteProjectPayload,
  ProjectTypes.Delete,
  ProjectTypes.DeletePending,
  ProjectTypes.DeleteSuccess,
  ProjectTypes.DeleteFailure
>
export type DeleteProjectPendingAction = ReturnType<
  DeleteProjectAction["meta"]["pending"]
>
export type DeleteProjectSuccessAction = ReturnType<
  DeleteProjectAction["meta"]["success"]
>
export type DeleteProjectFailureAction = ReturnType<
  DeleteProjectAction["meta"]["failure"]
>

export type LoadProjectByAliasAction = RemoteActionWithPayload<
  GetProjectByAliasPayload,
  GetProjectByAliasResponse,
  ProjectTypes.LoadByAlias,
  ProjectTypes.LoadByAliasPending,
  ProjectTypes.LoadByAliasSuccess,
  ProjectTypes.LoadByAliasFailure
>
export type LoadProjectByAliasPendingAction = ReturnType<
  LoadProjectByAliasAction["meta"]["pending"]
>
export type LoadProjectByAliasSuccessAction = ReturnType<
  LoadProjectByAliasAction["meta"]["success"]
>
export type LoadProjectByAliasFailureAction = ReturnType<
  LoadProjectByAliasAction["meta"]["failure"]
>

export type LoadProjectListAction = RemoteAction<
  GetProjectListResponse,
  ProjectTypes.LoadList,
  ProjectTypes.LoadListPending,
  ProjectTypes.LoadListSuccess,
  ProjectTypes.LoadListFailure
>
export type LoadProjectListPendingAction = ReturnType<
  LoadProjectListAction["meta"]["pending"]
>
export type LoadProjectListSuccessAction = ReturnType<
  LoadProjectListAction["meta"]["success"]
>
export type LoadProjectListFailureAction = ReturnType<
  LoadProjectListAction["meta"]["failure"]
>

export type LoadProjectByHostAction = RemoteActionWithPayload<
  GetProjectListPayload,
  GetProjectListResponse,
  ProjectTypes.LoadByHost,
  ProjectTypes.LoadByHostPending,
  ProjectTypes.LoadByHostSuccess,
  ProjectTypes.LoadByHostFailure
>
export type LoadProjectByHostPendingAction = ReturnType<
  LoadProjectByHostAction["meta"]["pending"]
>
export type LoadProjectByHostSuccessAction = ReturnType<
  LoadProjectByHostAction["meta"]["success"]
>
export type LoadProjectByHostFailureAction = ReturnType<
  LoadProjectByHostAction["meta"]["failure"]
>

export type LoadProjectAction = RemoteActionWithPayload<
  GetProjectPayload,
  GetProjectResponse,
  ProjectTypes.Load,
  ProjectTypes.LoadPending,
  ProjectTypes.LoadSuccess,
  ProjectTypes.LoadFailure
>
export type LoadProjectPendingAction = ReturnType<
  LoadProjectAction["meta"]["pending"]
>
export type LoadProjectSuccessAction = ReturnType<
  LoadProjectAction["meta"]["success"]
>
export type LoadProjectFailureAction = ReturnType<
  LoadProjectAction["meta"]["failure"]
>

export type ClearProjectAction = Action<ProjectTypes.Clear>

export type ClearProjectCreatedAction = Action<ProjectTypes.CreatedClear>
