import { takeEvery } from "redux-saga/effects"
import apiClient from "../../../../../../services/apiClient"
import {
  DeleteSharedLinkAction,
  SharedLinkActionType,
} from "../../../../../@types/actions/remote/cabinet/report/share"
import { remoteSaga } from "../../../../common/remoteSaga"

function* fetchDeleteShare(action: DeleteSharedLinkAction) {
  const request = function* () {
    yield apiClient.cabinet.project.reports.sharedLinks.deleteShare(
      action.payload,
    )
  }

  yield remoteSaga(request, action)
}

export default function* deleteShareSaga(): Generator {
  yield takeEvery(SharedLinkActionType.Delete, fetchDeleteShare)
}
