import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetProjectResponse,
} from "../../../../../services/apiClient"
import {
  LoadProjectAction,
  ProjectTypes,
} from "../../../../@types/actions/remote/cabinet/project"

function* fetchLoadProject(action: LoadProjectAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetProjectResponse = yield call(
      apiClient.cabinet.project.projects.getProject,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getProjectSaga(): Generator {
  yield takeEvery(ProjectTypes.Load, fetchLoadProject)
}
