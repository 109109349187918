import { UrlReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/urlReducer"
import { Filter } from "../types/filter"

const filters: Filter<UrlReportRecord>[] = [
  {
    name: "С параметрами",
    disabled: true,
    actions: [],
    tooltip: {
      title: "В процессе разработки", // TODO: implement this
    },
  },
  {
    name: "Нестандартные символы",
    disabled: true,
    actions: [],
    tooltip: {
      title: "В процессе разработки", // TODO: implement this
    },
  },
]

export default filters
