import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom"
import CabinetLayout from "../../components/CabinetLayout"
import history from "../../lib/history"
import { NotFoundError } from "../../services/apiClient"
import { loadSharedLinkByToken } from "../../state/actions/remote/cabinet/report/share"
import { getShare } from "../../state/selectors/remote/cabinet/report"
import routes, { reportRoutes, RoutesAlias } from "../routes"
import ContentRouter from "./content/ContentRouter"
import DashboardPage from "./DashboardPage"
import IndexingRouter from "./indexing/IndexingRouter"
import LinksRouter from "./links/LinksRouter"
import StructureRouter from "./structure/StructureRouter"

const SRouter: React.FC = () => {
  const { url } = useRouteMatch()
  const { token } = useParams<{ [RoutesAlias.Token]: string }>()

  const shareState = useSelector(getShare)
  const shareStateData = shareState.data

  const dispatch = useDispatch()

  const path = reportRoutes(url)

  React.useEffect(() => {
    dispatch(
      loadSharedLinkByToken({
        token,
      }),
    )
  }, [])

  React.useEffect(() => {
    if (shareStateData) {
      history.push(
        `${routes.s(shareStateData.token).index()}/${shareStateData.path}`,
      )
    }
  }, [shareStateData])

  React.useEffect(() => {
    if (shareState.error instanceof NotFoundError) {
      history.push(routes.login())
    }
  }, [shareState.error])

  if (!shareStateData) {
    return null
  }

  const shareTokenRoutes = routes.s(shareStateData.token)

  return (
    <CabinetLayout>
      <Switch>
        <Route
          path={shareTokenRoutes.dashboard()}
          render={(): JSX.Element => (
            <DashboardPage
              reportId={shareStateData.report_id}
              shareToken={shareStateData.token}
            />
          )}
        />
        <Route
          path={shareTokenRoutes.content().index()}
          render={(): JSX.Element => (
            <ContentRouter
              reportId={shareStateData.report_id}
              shareToken={shareStateData.token}
            />
          )}
        />
        <Route
          path={shareTokenRoutes.structure().index()}
          render={(): JSX.Element => (
            <StructureRouter
              reportId={shareStateData.report_id}
              shareToken={shareStateData.token}
            />
          )}
        />
        <Route
          path={shareTokenRoutes.links().index()}
          render={(): JSX.Element => (
            <LinksRouter
              reportId={shareStateData.report_id}
              shareToken={shareStateData.token}
            />
          )}
        />
        <Route
          path={path.indexing().index()}
          render={(): JSX.Element => (
            <IndexingRouter
              shareToken={shareStateData.token}
              reportId={shareStateData.report_id}
            />
          )}
        />
      </Switch>
    </CabinetLayout>
  )
}

export default SRouter
