import { CanonicalReportState } from "../../../@types/reducers/remote/cabinet/report/canonicalReducer"
import { DescriptionReportState } from "../../../@types/reducers/remote/cabinet/report/descriptionReducer"
import { ExternalReportState } from "../../../@types/reducers/remote/cabinet/report/externalReducer"
import { HeadingReportState } from "../../../@types/reducers/remote/cabinet/report/headingReducer"
import { ImageGroupReportState } from "../../../@types/reducers/remote/cabinet/report/imageGroupReducer"
import { ImageReportState } from "../../../@types/reducers/remote/cabinet/report/imageReducer"
import { IndexStatusGraphState } from "../../../@types/reducers/remote/cabinet/report/indexStatusGraphReducer"
import { InternalReportState } from "../../../@types/reducers/remote/cabinet/report/internalReducer"
import { MetaRobotsReportState } from "../../../@types/reducers/remote/cabinet/report/metaRobotsReducer"
import { NotHtmlGroupReportState } from "../../../@types/reducers/remote/cabinet/report/notHtmlGroupReducer"
import { NotHtmlReportState } from "../../../@types/reducers/remote/cabinet/report/notHtmlReducer"
import { RedirectReportState } from "../../../@types/reducers/remote/cabinet/report/redirectReducer"
import { RedirectsGraphState } from "../../../@types/reducers/remote/cabinet/report/redirectsGraphReducer"
import {
  ReportCreatedState,
  ReportCurrentState,
  ReportListState,
  StopCrawlingState,
} from "../../../@types/reducers/remote/cabinet/report/reportReducer"
import { RobotsGraphState } from "../../../@types/reducers/remote/cabinet/report/robotsGraphReducer"
import { RobotstxtReportState } from "../../../@types/reducers/remote/cabinet/report/robotstxtReducer"
import { SelectedPageState } from "../../../@types/reducers/remote/cabinet/report/selectedPageReducer"
import {
  SharedLinkCreatedState,
  SharedLinkCurrentState,
} from "../../../@types/reducers/remote/cabinet/report/shareReducer"
import { SitemapUrlReportState } from "../../../@types/reducers/remote/cabinet/report/sitemapUrlReducer"
import { SitemapUrlsGraphState } from "../../../@types/reducers/remote/cabinet/report/sitemapUrlsGraphReducer"
import { SourcePageReportState } from "../../../@types/reducers/remote/cabinet/report/sourcePageReducer"
import { StatusCodeReportState } from "../../../@types/reducers/remote/cabinet/report/statusCodeReducer"
import { StatusCodesGraphState } from "../../../@types/reducers/remote/cabinet/report/statusCodesGraphReducer"
import { TitleReportState } from "../../../@types/reducers/remote/cabinet/report/titleReducer"
import { UrlReportState } from "../../../@types/reducers/remote/cabinet/report/urlReducer"
import { UrlTreeState } from "../../../@types/reducers/remote/cabinet/report/urlTreeReducer"
import { Selector } from "../../../@types/selectors/selectors"

const getReport: Selector<ReportCurrentState> = state =>
  state.remote.cabinet.report.main.current

const getReportCreated: Selector<ReportCreatedState> = state =>
  state.remote.cabinet.report.main.created

const getReportList: Selector<ReportListState> = state =>
  state.remote.cabinet.report.main.list

const getStopCrawling: Selector<StopCrawlingState> = state =>
  state.remote.cabinet.report.main.stopCrawling

const getTitles: Selector<TitleReportState> = state =>
  state.remote.cabinet.report.title

const getDescriptions: Selector<DescriptionReportState> = state =>
  state.remote.cabinet.report.description

const getHeadings: Selector<HeadingReportState> = state =>
  state.remote.cabinet.report.heading

const getImages: Selector<ImageReportState> = state =>
  state.remote.cabinet.report.image

const getImageGroupReport: Selector<ImageGroupReportState> = state =>
  state.remote.cabinet.report.imageGroup

const getNotHtml: Selector<NotHtmlReportState> = state =>
  state.remote.cabinet.report.notHtml

const getNotHtmlGroups: Selector<NotHtmlGroupReportState> = state =>
  state.remote.cabinet.report.notHtmlGroup

const getRobotstxt: Selector<RobotstxtReportState> = state =>
  state.remote.cabinet.report.robotstxt

const getRobotsGraph: Selector<RobotsGraphState> = state =>
  state.remote.cabinet.report.robotsGraph

const getStatusCodes: Selector<StatusCodeReportState> = state =>
  state.remote.cabinet.report.statusCode

const getStatusCodesGraph: Selector<StatusCodesGraphState> = state =>
  state.remote.cabinet.report.statusCodesGraph

const getRedirects: Selector<RedirectReportState> = state =>
  state.remote.cabinet.report.redirect

const getRedirectsGraph: Selector<RedirectsGraphState> = state =>
  state.remote.cabinet.report.redirectsGraph

const getCanonicals: Selector<CanonicalReportState> = state =>
  state.remote.cabinet.report.canonical

const getMetaRobots: Selector<MetaRobotsReportState> = state =>
  state.remote.cabinet.report.metaRobots

const getSitemapUrls: Selector<SitemapUrlReportState> = state =>
  state.remote.cabinet.report.sitemapUrl

const getSitemapUrlsGraph: Selector<SitemapUrlsGraphState> = state =>
  state.remote.cabinet.report.sitemapUrlsGraph

const getIndexStatusGraph: Selector<IndexStatusGraphState> = state =>
  state.remote.cabinet.report.indexStatusGraph

const getInternal: Selector<InternalReportState> = state =>
  state.remote.cabinet.report.internal

const getExternal: Selector<ExternalReportState> = state =>
  state.remote.cabinet.report.external

const getSelectedPage: Selector<SelectedPageState> = state =>
  state.remote.cabinet.report.selectedPage

const getSourcePage: Selector<SourcePageReportState> = state =>
  state.remote.cabinet.report.sourcePage

const getUrls: Selector<UrlReportState> = state =>
  state.remote.cabinet.report.url

const getUrlTree: Selector<UrlTreeState> = state =>
  state.remote.cabinet.report.urlTree

const getCreatedShare: Selector<SharedLinkCreatedState> = state =>
  state.remote.cabinet.report.sharedLink.created

const getShare: Selector<SharedLinkCurrentState> = state =>
  state.remote.cabinet.report.sharedLink.current

export {
  getReport,
  getReportCreated,
  getReportList,
  getStopCrawling,
  getTitles,
  getDescriptions,
  getHeadings,
  getImages,
  getImageGroupReport,
  getNotHtml,
  getNotHtmlGroups,
  getRobotstxt,
  getRobotsGraph,
  getStatusCodes,
  getStatusCodesGraph,
  getRedirects,
  getRedirectsGraph,
  getCanonicals,
  getMetaRobots,
  getSitemapUrls,
  getSitemapUrlsGraph,
  getIndexStatusGraph,
  getInternal,
  getExternal,
  getSelectedPage,
  getSourcePage,
  getUrls,
  getUrlTree,
  getCreatedShare,
  getShare,
}
