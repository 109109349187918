import { ReportProblemAlias, ReportProblemType } from "./problems"
import { ReportSettingAlias } from "./settings"

export enum ReportStatus {
  New = 1,
  Pending = 2,
  Processing = 5,
  Processed = 3,
  Error = 6,
  Deleted = 4,
}

export type Report = {
  id: string
  created_by: string
  project_id: string
  entry_point: string
  budget: number
  budget_used: number
  thread_count: number
  status: ReportStatus
  payload: ReportPayload
  created_at: string
  updated_at: string
  deleted_at: string | null
  alias: string
}

export type ReportPayload = {
  crawl_task_id?: string
  settings: ReportPayloadSettings
  result?: ReportPayloadResult
  status?: ReportPayloadStatus
}

export type ReportPayloadSettings = {
  [ReportSettingAlias.ThreadCount]: number
  [ReportSettingAlias.ThreadSleepMs]: number
  [ReportSettingAlias.Budget]: number
  [ReportSettingAlias.MaxDfi]: number
  [ReportSettingAlias.TimeoutMs]: number
  [ReportSettingAlias.Sitemaps]: string[]
  [ReportSettingAlias.UseRobotstxt]: boolean
  [ReportSettingAlias.UseMetarobots]: boolean
  [ReportSettingAlias.UseCanonical]: boolean
  [ReportSettingAlias.FollowSubdomains]: boolean
  [ReportSettingAlias.TreatWwwAsSameHost]: boolean
  [ReportSettingAlias.FollowNofollowLinks]: boolean
  [ReportSettingAlias.FetchExternalLinks]: boolean
  [ReportSettingAlias.UserAgent]: string
  [ReportSettingAlias.RobotsUserAgent]: string
}

export type ReportPayloadResult = {
  robotstxt_lines?: string[]
  stats?: Partial<Record<ReportProblemType | "urls" | "pages", number>>
  problems?: ReportPayloadResultProblems
}

export type ReportPayloadResultProblems = Partial<
  Record<ReportProblemAlias, number | boolean>
>

export enum CrawlStatus {
  Success = 0,
  BudgedExceeded = 1,
  ForceStopped = 2,
}

export type ReportPayloadStatus = {
  budget_used: number
  resources_enqueued: number
  speed: number
  crawl_done: boolean
  done: boolean
  started_at: string
  crawl_finished_at: string
  crawl_status?: CrawlStatus
  finished_at: string
  error: string | null
}
