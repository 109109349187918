import { IndexStatusGraphActionType } from "../../../../@types/actions/remote/cabinet/report/indexStatusGraph"
import {
  IndexStatusGraphActions,
  IndexStatusGraphState,
} from "../../../../@types/reducers/remote/cabinet/report/indexStatusGraphReducer"
import {
  updateStateLoadFailure,
  updateStateLoadGraphSuccess,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: IndexStatusGraphState = {
  isLoading: false,
}

const indexStatusGraphReducer = (
  state: IndexStatusGraphState = initState,
  action: IndexStatusGraphActions,
): IndexStatusGraphState => {
  switch (action.type) {
    case IndexStatusGraphActionType.LoadSuccess:
      return updateStateLoadGraphSuccess(state, action)

    case IndexStatusGraphActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case IndexStatusGraphActionType.LoadPending:
      return updateStateSetPending(state)

    default:
      return state
  }
}

export default indexStatusGraphReducer
