import { call, put } from "redux-saga/effects"
import { RemoteActionWithPayload } from "../../@types/actions/action"

export function* remoteSaga<
  Resp,
  Payload extends object,
  Type,
  PendingType,
  SuccessType,
  FailureType
>(
  request: () => Generator,
  action: RemoteActionWithPayload<
    Payload,
    Resp,
    Type,
    PendingType,
    SuccessType,
    FailureType
  >,
) {
  yield put(action.meta.pending())
  try {
    const response: Resp = yield call(request)
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}
