import { MakeRequestPayload } from "../../../../@types/makeRequest"
import {
  GetIndexStatusGraphPayload,
  GetIndexStatusGraphResponse,
  GetRedirectsGraphPayload,
  GetRedirectsGraphResponse,
  GetRobotsGraphPayload,
  GetRobotsGraphResponse,
  GetSitemapUrlsGraphPayload,
  GetSitemapUrlsGraphResponse,
  GetStatusCodesGraphPayload,
  GetStatusCodesGraphResponse,
} from "../../../../@types/rest/cabinet/project/reports/graph"
import makeRequest from "../../../../makeRequest"

export const getIndexStatusGraph = async (
  payload: GetIndexStatusGraphPayload,
): Promise<GetIndexStatusGraphResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}/graph/index-status-ch`,
  }
  if (payload.shareToken) {
    reqPayload.headers = {
      "share-token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return await res.json()
}

export const getRedirectsGraph = async (
  payload: GetRedirectsGraphPayload,
): Promise<GetRedirectsGraphResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}/graph/redirects-ch`,
  }
  if (payload.shareToken) {
    reqPayload.headers = {
      "share-token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return await res.json()
}

export const getRobotsGraph = async (
  payload: GetRobotsGraphPayload,
): Promise<GetRobotsGraphResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}/graph/robots-ch`,
  }
  if (payload.shareToken) {
    reqPayload.headers = {
      "share-token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return await res.json()
}

export const getSitemapUrlsGraph = async (
  payload: GetSitemapUrlsGraphPayload,
): Promise<GetSitemapUrlsGraphResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}/graph/sitemap-urls-ch`,
  }
  if (payload.shareToken) {
    reqPayload.headers = {
      "share-token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return await res.json()
}

export const getStatusCodesGraph = async (
  payload: GetStatusCodesGraphPayload,
): Promise<GetStatusCodesGraphResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}/graph/status-codes-ch`,
  }
  if (payload.shareToken) {
    reqPayload.headers = {
      "share-token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return await res.json()
}
