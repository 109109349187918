import { ApiResponseWithData } from "../../../../response"

export type CreateReportSharedLinkResponse = ApiResponseWithData<
  CreateReportSharedLinkData
>
export type CreateReportSharedLinkData = {
  id: string
  created_by: string
  report_id: string
  path: string
  token: string
  created_at: string
  expires_at: string
}
export enum CreateReportSharedLinkDuration {
  Day = 0,
  Week = 1,
  Month = 2,
}
export type CreateReportSharedLinkPayload = {
  reportId: string
  body: {
    path: string
    duration: CreateReportSharedLinkDuration
  }
}

export type GetReportSharedLinkResponse = ApiResponseWithData<
  GetReportSharedLinkData
>
export type GetReportSharedLinkData = {
  id: string
  created_by: string
  report_id: string
  path: string
  token: string
  created_at: string
  expires_at: string
}
export type GetReportSharedLinkPayload = {
  reportId: string
  params: {
    path: string
  }
}

export type GetReportSharedLinkByTokenResponse = ApiResponseWithData<
  GetReportSharedLinkByTokenData
>
export type GetReportSharedLinkByTokenData = {
  id: string
  created_by: string
  report_id: string
  path: string
  token: string
  created_at: string
  expires_at: string
}
export type GetReportSharedLinkByTokenPayload = {
  token: string
}

export type DeleteReportSharedLinkPayload = {
  id: string
  reportId: string
}
export type DeleteReportSharedLinkResponse = {
  success: boolean
}
