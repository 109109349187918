import {
  AddFilterExternalReportAction,
  CancelFilterExternalReportAction,
  ClearFilterExternalReportAction,
  ClearSortExternalReportAction,
  ExportExternalReportAction,
  ExternalReportActionType,
  LoadExternalReportAction,
  SetPaginationExternalReportAction,
  SetSortExternalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/external"

const loadExternalReport = (
  payload: LoadExternalReportAction["payload"],
): LoadExternalReportAction => ({
  type: ExternalReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: ExternalReportActionType.LoadPending,
    }),
    success: payload => ({
      type: ExternalReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: ExternalReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterExternalReport = (): ClearFilterExternalReportAction => ({
  type: ExternalReportActionType.ClearFilter,
})

const clearSortExternalReport = (): ClearSortExternalReportAction => ({
  type: ExternalReportActionType.ClearSort,
})

const exportExternalReport = (
  payload: ExportExternalReportAction["payload"],
): ExportExternalReportAction => ({
  type: ExternalReportActionType.Export,
  payload,
})

const addFilterExternalReport = (
  payload: AddFilterExternalReportAction["payload"],
): AddFilterExternalReportAction => ({
  type: ExternalReportActionType.AddFilter,
  payload,
})

const cancelFilterExternalReport = (
  payload: CancelFilterExternalReportAction["payload"],
): CancelFilterExternalReportAction => ({
  type: ExternalReportActionType.CancelFilter,
  payload,
})

const setPaginationExternalReport = (
  payload: SetPaginationExternalReportAction["payload"],
): SetPaginationExternalReportAction => ({
  type: ExternalReportActionType.SetPagination,
  payload,
})

const setSortExternalReport = (
  payload: SetSortExternalReportAction["payload"],
): SetSortExternalReportAction => ({
  type: ExternalReportActionType.SetSort,
  payload,
})

export {
  loadExternalReport,
  clearFilterExternalReport,
  clearSortExternalReport,
  exportExternalReport,
  addFilterExternalReport,
  cancelFilterExternalReport,
  setPaginationExternalReport,
  setSortExternalReport,
}
