import { RedirectsGraphActionType } from "../../../../@types/actions/remote/cabinet/report/redirectsGraph"
import {
  RedirectsGraphActions,
  RedirectsGraphState,
} from "../../../../@types/reducers/remote/cabinet/report/redirectsGraphReducer"
import {
  updateStateLoadFailure,
  updateStateLoadGraphSuccess,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: RedirectsGraphState = {
  isLoading: false,
}

const redirectsGraphReducer = (
  state: RedirectsGraphState = initState,
  action: RedirectsGraphActions,
): RedirectsGraphState => {
  switch (action.type) {
    case RedirectsGraphActionType.LoadSuccess:
      return updateStateLoadGraphSuccess(state, action)

    case RedirectsGraphActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case RedirectsGraphActionType.LoadPending:
      return updateStateSetPending(state)

    default:
      return state
  }
}

export default redirectsGraphReducer
