import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  DeleteProjectAction,
  ProjectTypes,
} from "../../../../@types/actions/remote/cabinet/project"

function* fetchDeleteProject(action: DeleteProjectAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    yield call(apiClient.cabinet.project.projects.deleteProject, action.payload)
    yield put(action.meta.success(action.payload))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* deleteProjectSaga(): Generator {
  yield takeEvery(ProjectTypes.Delete, fetchDeleteProject)
}
