import descriptions from "./descriptions"
import canonicals from "./canonicals"
import external from "./external"
import headings from "./headings"
import imageGroups from "./imageGroups"
import images from "./images"
import internal from "./internal"
import metaRobots from "./metaRobots"
import notHtml from "./notHtml"
import notHtmlGroups from "./notHtmlGroups"
import redirects from "./redirects"
import robotstxt from "./robotstxt"
import sitemapUrls from "./sitemapUrls"
import statusCodes from "./statusCodes"
import urls from "./urls"
import titles from "./titles"

export default {
  descriptions,
  canonicals,
  external,
  headings,
  imageGroups,
  images,
  internal,
  metaRobots,
  notHtml,
  notHtmlGroups,
  redirects,
  robotstxt,
  sitemapUrls,
  statusCodes,
  urls,
  titles,
}
