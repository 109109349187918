import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  PurchaseResponse,
  PurchaseResponseData,
} from "../../../services/apiClient"
import {
  BillingTypes,
  PurchaseAction,
} from "../../@types/actions/remote/billing"

function* fetchPurchase(action: PurchaseAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: PurchaseResponse = yield call(
      apiClient.billing.purchase,
      action.payload,
    )
    yield put(action.meta.success(response))
    yield call(purchaseForm, response.data)
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* purchaseSaga(): Generator {
  yield takeEvery(BillingTypes.Purchase, fetchPurchase)
}

function purchaseForm(formParams: PurchaseResponseData): void {
  const { method, url, fields } = formParams

  const form = document.createElement("form")

  form.setAttribute("method", method)
  form.setAttribute("action", url)

  for (const [field, value] of Object.entries(fields)) {
    const input = document.createElement("input")
    input.setAttribute("type", "hidden")
    input.setAttribute("name", field)
    input.setAttribute("value", value)
    form.appendChild(input)
  }

  document.body.appendChild(form)
  form.submit()
}
