import React from "react"
import MetaRobotsReport from "../../../components/Report/MetaRobotsReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportMetaRobotsReport } from "../../../state/actions/remote/cabinet/report/metaRobots"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.MetaRobots)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const MetaRobotsPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportMetaRobotsReport,
  })

  return <MetaRobotsReport reportId={reportId} shareToken={shareToken} />
}

export default MetaRobotsPage
