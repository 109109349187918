import React from "react"
import { useDispatch } from "react-redux"
import { useRedirectsGraph } from "../../hooks/useRedirectsGraph"
import { loadRedirectsGraph } from "../../state/actions/remote/cabinet/report/redirectsGraph"
import Pie from "../Pie"

type Props = {
  reportId: string
  shareToken?: string
  legend?: boolean
}

const RedirectsGraph: React.FC<Props> = props => {
  const { reportId, shareToken, legend } = props
  const { state, info } = useRedirectsGraph()

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!state.data && !state.isLoading) {
      dispatch(
        loadRedirectsGraph({
          reportId,
          shareToken,
        }),
      )
    }
  }, [dispatch])

  return <Pie graph={state} legend={legend} info={info} />
}

export default RedirectsGraph
