import { all, fork } from "redux-saga/effects"
import createShareSaga from "./createShareSaga"
import getShareByTokenSaga from "./getShareByTokenSaga"
import getShareSaga from "./getShareSaga"
import deleteShareSaga from "./deleteShareSaga"

export default function* projectReportsShareSagas(): Generator {
  yield all([
    fork(createShareSaga),
    fork(getShareByTokenSaga),
    fork(getShareSaga),
    fork(deleteShareSaga),
  ])
}
