import { useSelector } from "react-redux"
import { RobotsGraphAlias } from "../services/apiClient"
import { getRobotsGraph } from "../state/selectors/remote/cabinet/report"
import { GraphRecordInfo } from "../types/graph"

export type UseRobotsGraphResult = {
  state: ReturnType<typeof getRobotsGraph>
  info: GraphRecordInfo
}

export function useRobotsGraph(): UseRobotsGraphResult {
  const state = useSelector(getRobotsGraph)
  const info = {
    [RobotsGraphAlias.Allow]: {
      label: "Индексирование разрешено (Allow)",
      description:
        "Количество страниц разрешенных к индексированию в robots.txt",
    },
    [RobotsGraphAlias.Disallow]: {
      label: "Индексирование запрещено (Disallow)",
      description:
        "Количество страниц, запрещенных к индексированию директивой Disallow",
    },
  }

  return { state, info }
}
