import history from "../../lib/history"

export default function persistFilteredResourceParamsToUrl(params: object) {
  try {
    if (!Object.keys(params).length) {
      history.replace({
        search: "",
      })
      return
    }

    const paramsStringify: string = JSON.stringify(params)
    const base64: string = window.btoa(paramsStringify)
    const search: string = new URLSearchParams({ c: base64 }).toString()
    history.push({
      search,
    })
  } catch (error) {
    // TODO: add logs
  }
}
