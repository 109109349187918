import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { CanonicalReportRecord } from "../../../../reducers/remote/cabinet/report/canonicalReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum CanonicalReportActionType {
  Load = "@@REPORTS/CANONICALS/LOAD",
  LoadSuccess = "@@REPORTS/CANONICALS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/CANONICALS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/CANONICALS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/CANONICALS/SET_PAGINATION",
  SetSort = "@@REPORTS/CANONICALS/SET_SORT",
  AddFilter = "@@REPORTS/CANONICALS/ADD_FILTER",
  CancelFilter = "@@REPORTS/CANONICALS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/CANONICALS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/CANONICALS/CLEAR_SORT",
  Export = "@@REPORTS/CANONICALS/EXPORT",
}

export type LoadCanonicalReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<CanonicalReportRecord>,
  CanonicalReportActionType.Load,
  CanonicalReportActionType.LoadPending,
  CanonicalReportActionType.LoadSuccess,
  CanonicalReportActionType.LoadFailure
>

export type LoadPendingCanonicalReportAction = ReturnType<
  LoadCanonicalReportAction["meta"]["pending"]
>
export type LoadSuccessCanonicalReportAction = ReturnType<
  LoadCanonicalReportAction["meta"]["success"]
>
export type LoadFailureCanonicalReportAction = ReturnType<
  LoadCanonicalReportAction["meta"]["failure"]
>

export type ClearFilterCanonicalReportAction = Action<
  CanonicalReportActionType.ClearFilter
>
export type ClearSortCanonicalReportAction = Action<
  CanonicalReportActionType.ClearSort
>
export type ExportCanonicalReportAction = ExportAction<
  CanonicalReportRecord,
  ExportListPagesPayload<CanonicalReportRecord>,
  CanonicalReportActionType.Export
>

export type AddFilterCanonicalReportAction = AddFilterAction<
  CanonicalReportRecord,
  CanonicalReportActionType.AddFilter
>

export type CancelFilterCanonicalReportAction = CancelFilterAction<
  CanonicalReportRecord,
  CanonicalReportActionType.CancelFilter
>

export type SetSortCanonicalReportAction = SetSortAction<
  CanonicalReportRecord,
  CanonicalReportActionType.SetSort
>

export type SetPaginationCanonicalReportAction = SetPaginationAction<
  CanonicalReportActionType.SetPagination
>
