import React from "react"
import UrlTreeReport from "../../../components/Report/UrlTreeReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.UrlTree)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const UrlTreePage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({ reportId, title, shareToken })

  return <UrlTreeReport reportId={reportId} shareToken={shareToken} />
}

export default UrlTreePage
