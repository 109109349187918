import React from "react"
import { Spin } from "antd"

const PageLoader: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        width: "100%",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Spin style={{ position: "relative", zIndex: 1 }} />
    </div>
  )
}

export default PageLoader
