import {
  Col,
  Empty,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { UnorderedListOutlined } from "@ant-design/icons"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import {
  ApiClientExportFormat,
  ApiClientFilterType,
} from "../../services/apiClient"
import filters from "../../services/filters"
import { NotHtmlGroupReportRecord } from "../../state/@types/reducers/remote/cabinet/report/notHtmlGroupReducer"
import { SourcePageReportRecord } from "../../state/@types/reducers/remote/cabinet/report/sourcePageReducer"
import {
  addFilterNotHtmlGroupReport,
  cancelFilterNotHtmlGroupReport,
  clearFilterNotHtmlGroupReport,
  clearSortNotHtmlGroupReport,
  loadNotHtmlGroupReport,
  setPaginationNotHtmlGroupReport,
  setSortNotHtmlGroupReport,
} from "../../state/actions/remote/cabinet/report/notHtmlGroup"
import {
  addFilterSourcePageReport,
  clearSortSourcePageReport,
  clearSourcePageReport,
  exportSourcePageReport,
  loadSourcePageReport,
  setPaginationSourcePageReport,
  setSortSourcePageReport,
} from "../../state/actions/remote/cabinet/report/sourcePage"
import {
  getNotHtmlGroups,
  getSourcePage,
} from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import columnsType from "../ui/Table/columnsType"
import Url from "../Url"
import ExportButtons from "../ExportButtons"

type Props = {
  reportId: string
  shareToken?: string
}

const NotHtmlGroupReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props
  const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false)

  const dispatch = useDispatch()

  const selectResource = () => {
    setIsModalVisible(true)
  }

  const onCancel = () => {
    setIsModalVisible(false)
    dispatch(clearSourcePageReport())
  }

  return (
    <>
      <NotHtml
        reportId={reportId}
        shareToken={shareToken}
        selectResource={selectResource}
      />
      <Modal
        title="Источники"
        width={1000}
        visible={isModalVisible}
        onCancel={onCancel}
        footer={null}
      >
        <Pages shareToken={shareToken} reportId={reportId} />
      </Modal>
    </>
  )
}

export default NotHtmlGroupReport

type NotHtmlProps = {
  reportId: string
  shareToken?: string
  selectResource: () => void
}

const NotHtml: React.FC<NotHtmlProps> = props => {
  const { reportId, shareToken, selectResource } = props

  const notHtmlGroupState = useSelector(getNotHtmlGroups)

  const dispatch = useDispatch()

  const columns: ColumnsType<NotHtmlGroupReportRecord> = [
    {
      key: "resource_url_no_scheme",
      title: "URL",
      dataIndex: "resource_url_no_scheme",
      sorter: true,
      render: (value: string, record: NotHtmlGroupReportRecord) => (
        <Url value={record.resource_url} />
      ),
    },
    columnsType.resourceCount({
      key: "page_resource_count",
      dataIndex: "page_resource_count",
      render: (value: number, record): React.ReactNode => {
        const showMore = value > 0

        const button = (
          <Tag
            style={showMore ? { cursor: "pointer" } : {}}
            onClick={(): void => {
              if (!showMore) {
                return
              }

              dispatch(
                addFilterSourcePageReport({
                  target_resource_id: [
                    [ApiClientFilterType.Eq, record.resource_id],
                  ],
                }),
              )
              selectResource()
            }}
          >
            <Typography.Text type={showMore ? undefined : "secondary"}>
              <UnorderedListOutlined style={{ marginRight: 10 }} />
              {value}
            </Typography.Text>
          </Tag>
        )

        if (!showMore) {
          return <>{button}</>
        }

        return <Tooltip title="Посмотреть страницы">{button}</Tooltip>
      },
    }),
    columnsType.statusCode({
      key: "resource_status_code",
      dataIndex: "resource_status_code",
    }),
    columnsType.resourceType("resource_content_type", {
      key: "resource_type",
      dataIndex: "resource_type",
    }),
    columnsType.robotstxtIgnore(
      record => ({
        value: record.resource_robotstxt_ignore,
        lineNumber: record.resource_robotstxt_line,
        isExternal: record.resource_is_external,
      }),
      {
        key: "resource_robotstxt_ignore",
        dataIndex: "resource_robotstxt_ignore",
        sorter: true,
      },
    ),
  ]

  const { load, onChangeTable } = useTable(
    loadNotHtmlGroupReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationNotHtmlGroupReport,
    setSortNotHtmlGroupReport,
    clearSortNotHtmlGroupReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterNotHtmlGroupReport,
    cancelFilterNotHtmlGroupReport,
    clearFilterNotHtmlGroupReport,
    setSortNotHtmlGroupReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterNotHtmlGroupReport,
    cancelFilterNotHtmlGroupReport,
  )

  if (notHtmlGroupState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        notHtmlGroupState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.notHtmlGroups}
                activeFilter={notHtmlGroupState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={notHtmlGroupState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        columns={columns}
        onChangeTable={onChangeTable}
        dataSource={notHtmlGroupState.data}
        loading={notHtmlGroupState.isLoading}
        pagination={notHtmlGroupState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

const inputOptions: InputSearchByFieldsValueType<NotHtmlGroupReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
]

type PagesProps = {
  reportId: string
  shareToken?: string
}

const Pages: React.FC<PagesProps> = props => {
  const { reportId, shareToken } = props

  const sourcePageState = useSelector(getSourcePage)

  const dispatch = useDispatch()

  const { load, onChangeTable } = useTable(
    loadSourcePageReport.bind(null, {
      reportId,
      shareToken,
      saveParamsToUrl: false,
    }),
    setPaginationSourcePageReport,
    setSortSourcePageReport,
    clearSortSourcePageReport,
  )

  const onSelectExport = React.useCallback(
    (format: ApiClientExportFormat) =>
      dispatch(
        exportSourcePageReport({
          reportId,
          fileName: "Источники",
          shareToken,
          params: {
            "export-format": format,
          },
        }),
      ),
    [],
  )

  React.useEffect(() => {
    if (sourcePageState.filter) {
      load()
    }
  }, [sourcePageState.filter])

  React.useEffect(() => {
    return () => {
      dispatch(clearSourcePageReport())
    }
  }, [])

  return (
    <Space direction="vertical" align="end">
      <ExportButtons onSelect={onSelectExport} />
      <Table<SourcePageReportRecord>
        rowKey="page_resource_id"
        dataSource={sourcePageState.data}
        pagination={sourcePageState.pagination}
        loading={sourcePageState.isLoading}
        onChangeTable={onChangeTable}
        columns={pageListNotHtmlColumns}
      />
    </Space>
  )
}

const pageListNotHtmlColumns: ColumnsType<SourcePageReportRecord> = [
  columnsType.pageInformation(
    record => ({
      resourceId: record.src_resource_id,
      url: {
        value: record.src_resource_url,
      },
      title: record.src_page_title,
    }),
    {
      key: "src_resource_url_no_scheme",
      dataIndex: "src_resource_url_no_scheme",
    },
  ),
  columnsType.indexStatus(
    record => ({
      canonicalUrl: record.src_page_canonical_url,
      robotstxtIgnore: record.src_resource_robotstxt_ignore,
      robotstxtLine: record.src_resource_robotstxt_line,
    }),
    {
      key: "src_resource_index_status",
      dataIndex: "src_resource_index_status",
    },
  ),
]
