import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../../routes"
import CanonicalsPage from "./CanonicalsPage"
import MetaRobotsPage from "./MetaRobotsPage"
import RedirectsPage from "./RedirectsPage"
import RobotsPage from "./RobotsPage"
import SitemapUrlsPage from "./SitemapUrlsPage"
import StatusCodesPage from "./StatusCodesPage"

type Props = {
  reportId: string
  shareToken: string
}

const IndexingRouter: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const path = routes.s(shareToken).indexing()

  return (
    <Switch>
      <Route
        path={path.robots()}
        render={(): JSX.Element => (
          <RobotsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.statusCodes()}
        render={(): JSX.Element => (
          <StatusCodesPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.redirects()}
        render={(): JSX.Element => (
          <RedirectsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.canonicals()}
        render={(): JSX.Element => (
          <CanonicalsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.metaRobots()}
        render={(): JSX.Element => (
          <MetaRobotsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.sitemapUrls()}
        render={(): JSX.Element => (
          <SitemapUrlsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
    </Switch>
  )
}

export default React.memo(IndexingRouter) as typeof IndexingRouter
