export enum RoutesAlias {
  Plan = "planAlias",
  Site = "alias",
  Report = "reportAlias",
  ResourceId = "resourceId",
  Token = "token",
}

const routes = {
  index: () => "/",
  login: () => "/login",
  register: () => "/register",
  s: (token: string = `:${RoutesAlias.Token}`) => reportRoutes(`/s/${token}`),
  settings: () => {
    const settings = "/settings"

    return {
      index: () => settings,
      billing: () => {
        const billing = `${settings}/billing`

        return {
          index: () => billing,
          tariffs: () => `${billing}/tariffs`,
        }
      },
    }
  },
  site: () => {
    const site = "/site"

    return {
      index: () => site,
      create: () => `${site}/create`,
      alias(alias: string = `:${RoutesAlias.Site}`) {
        const siteAlias = `${site}/${alias}`

        return {
          index: () => siteAlias,
          emptyReports: () => `${siteAlias}/empty-reports`,
          newReport: () => `${siteAlias}/new-report`,
          reportAlias: (reportAlias: string = `:${RoutesAlias.Report}`) => {
            const siteAliasReportAlias = `${siteAlias}/${reportAlias}`

            return {
              pending: () => `${siteAliasReportAlias}/pending`,
              ...reportRoutes(siteAliasReportAlias),
            }
          },
        }
      },
    }
  },
}

export default routes

export const reportRoutes = (path: string) => ({
  index: () => path,
  dashboard: () => `${path}/dashboard`,
  indexing: () => {
    const pathIndexing = `${path}/indexing`

    return {
      index: () => pathIndexing,
      robots: () => `${pathIndexing}/robots`,
      sitemapUrls: () => `${pathIndexing}/sitemap-urls`,
      statusCodes: () => `${pathIndexing}/status-codes`,
      redirects: () => `${pathIndexing}/redirects`,
      mirrors: () => `${pathIndexing}/mirrors`,
      canonicals: () => `${pathIndexing}/canonicals`,
      metaRobots: () => `${pathIndexing}/meta-robots`,
      xRobots: () => `${pathIndexing}/x-robots`,
    }
  },
  content: () => {
    const pathContent = `${path}/content`

    return {
      index: () => pathContent,
      titles: () => `${pathContent}/titles`,
      descriptions: () => `${pathContent}/descriptions`,
      headings: () => `${pathContent}/headings`,
      noContent: () => `${pathContent}/no-content`,
      images: () => {
        const pathContentImages = `${pathContent}/images`

        return {
          index: () => pathContentImages,
          full: () => `${pathContentImages}/images-full`,
          groups: () => `${pathContentImages}/images-groups`,
        }
      },
      notHtml: () => {
        const pathContentNotHtml = `${pathContent}/not-html`

        return {
          index: () => pathContentNotHtml,
          full: () => `${pathContentNotHtml}/not-html-full`,
          groups: () => `${pathContentNotHtml}/not-html-groups`,
        }
      },
    }
  },

  structure: () => {
    const pathStructure = `${path}/structure`

    return {
      index: () => pathStructure,
      urls: () => `${pathStructure}/urls`,
      urlTree: () => `${pathStructure}/url-tree`,
    }
  },
  links: () => {
    const pathLinks = `${path}/links`

    return {
      index: () => pathLinks,
      internal: () => `${pathLinks}/internal`,
      external: () => `${pathLinks}/external`,
    }
  },
  pages: () => {
    const pathPages = `${path}/pages`

    return {
      index: () => pathPages,
      pageId(pageId: string = `:${RoutesAlias.ResourceId}`) {
        const pathPagesPageId = `${pathPages}/${pageId}`

        return {
          index: () => pathPagesPageId,
        }
      },
    }
  },
})
