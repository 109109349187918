import {
  GetSitemapUrlsGraphPayload,
  GetSitemapUrlsGraphResponse,
} from "../../../../../../services/apiClient"
import { LoadAction } from "../../../filteredResourceAction"

export enum SitemapUrlsGraphActionType {
  Load = "@@REPORTS/SITEMAPS_URLS/GRAPH/LOAD",
  LoadSuccess = "@@REPORTS/SITEMAPS_URLS/GRAPH/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/SITEMAPS_URLS/GRAPH/LOAD_PENDING",
  LoadFailure = "@@REPORTS/SITEMAPS_URLS/GRAPH/LOAD_FAILURE",
}

export type LoadSitemapUrlsGraphAction = LoadAction<
  GetSitemapUrlsGraphPayload,
  GetSitemapUrlsGraphResponse,
  SitemapUrlsGraphActionType.Load,
  SitemapUrlsGraphActionType.LoadPending,
  SitemapUrlsGraphActionType.LoadSuccess,
  SitemapUrlsGraphActionType.LoadFailure
>
export type LoadPendingSitemapUrlsGraphAction = ReturnType<
  LoadSitemapUrlsGraphAction["meta"]["pending"]
>
export type LoadSuccessSitemapUrlsGraphAction = ReturnType<
  LoadSitemapUrlsGraphAction["meta"]["success"]
>
export type LoadFailureSitemapUrlsGraphAction = ReturnType<
  LoadSitemapUrlsGraphAction["meta"]["failure"]
>
