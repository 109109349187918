import { call, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  CanonicalReportActionType,
  ExportCanonicalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/canonical"
import {
  DescriptionReportActionType,
  ExportDescriptionReportAction,
} from "../../../../@types/actions/remote/cabinet/report/description"
import {
  ExportHeadingReportAction,
  HeadingReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/heading"
import {
  ExportMetaRobotsReportAction,
  MetaRobotsReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/metaRobots"
import {
  ExportRedirectReportAction,
  RedirectReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/redirect"
import {
  ExportRobotstxtReportAction,
  RobotstxtReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/robotstxt"
import {
  ExportStatusCodeReportAction,
  StatusCodeReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/statusCode"
import {
  ExportTitleReportAction,
  TitleReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/title"
import {
  ExportUrlReportAction,
  UrlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/url"
import {
  getCanonicals,
  getDescriptions,
  getHeadings,
  getMetaRobots,
  getRedirects,
  getRobotstxt,
  getStatusCodes,
  getTitles,
  getUrls,
} from "../../../../selectors/remote/cabinet/report"
import filteredResourceExportSaga from "../../../common/filteredResourceExportSaga"

function* fetchExportDescriptions(action: ExportDescriptionReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getDescriptions,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportTitles(action: ExportTitleReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getTitles,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportHeadings(action: ExportHeadingReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getHeadings,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportRobotstxt(action: ExportRobotstxtReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getRobotstxt,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportStatusCodes(action: ExportStatusCodeReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getStatusCodes,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportRedirects(action: ExportRedirectReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getRedirects,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportCanonicals(action: ExportCanonicalReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getCanonicals,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportMetaRobots(action: ExportMetaRobotsReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getMetaRobots,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

function* fetchExportUrls(action: ExportUrlReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getUrls,
      request: apiClient.cabinet.project.reports.exportListPages,
    })
  })
}

export default function* exportListPagesSaga(): Generator {
  yield takeLatest(TitleReportActionType.Export, fetchExportTitles)
  yield takeLatest(DescriptionReportActionType.Export, fetchExportDescriptions)
  yield takeLatest(HeadingReportActionType.Export, fetchExportHeadings)
  yield takeLatest(RobotstxtReportActionType.Export, fetchExportRobotstxt)
  yield takeLatest(StatusCodeReportActionType.Export, fetchExportStatusCodes)
  yield takeLatest(RedirectReportActionType.Export, fetchExportRedirects)
  yield takeLatest(CanonicalReportActionType.Export, fetchExportCanonicals)
  yield takeLatest(MetaRobotsReportActionType.Export, fetchExportMetaRobots)
  yield takeLatest(UrlReportActionType.Export, fetchExportUrls)
}
