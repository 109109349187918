import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetPageUrlsResponse,
} from "../../../../../services/apiClient"
import {
  LoadUrlTreeAction,
  UrlTreeActionType,
} from "../../../../@types/actions/remote/cabinet/report/urlTree"

function* fetchGetPageUrls(action: LoadUrlTreeAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetPageUrlsResponse = yield call(
      apiClient.cabinet.project.reports.getPageUrls,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getPageUrlsSaga(): Generator {
  yield takeEvery(UrlTreeActionType.Load, fetchGetPageUrls)
}
