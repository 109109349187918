import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { RobotstxtReportRecord } from "../../../../reducers/remote/cabinet/report/robotstxtReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum RobotstxtReportActionType {
  Load = "@@REPORTS/ROBOTS/LOAD",
  LoadSuccess = "@@REPORTS/ROBOTS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/ROBOTS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/ROBOTS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/ROBOTS/SET_PAGINATION",
  SetSort = "@@REPORTS/ROBOTS/SET_SORT",
  AddFilter = "@@REPORTS/ROBOTS/ADD_FILTER",
  ClearFilter = "@@REPORTS/ROBOTS/CLEAR_FILTER",
  CancelFilter = "@@REPORTS/ROBOTS/CANCEL_FILTER",
  ClearSort = "@@REPORTS/ROBOTS/CLEAR_SORT",
  Export = "@@REPORTS/ROBOTS/EXPORT",
}

export type LoadRobotstxtReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<RobotstxtReportRecord>,
  RobotstxtReportActionType.Load,
  RobotstxtReportActionType.LoadPending,
  RobotstxtReportActionType.LoadSuccess,
  RobotstxtReportActionType.LoadFailure
>
export type LoadPendingRobotstxtReportAction = ReturnType<
  LoadRobotstxtReportAction["meta"]["pending"]
>
export type LoadSuccessRobotstxtReportAction = ReturnType<
  LoadRobotstxtReportAction["meta"]["success"]
>
export type LoadFailureRobotstxtReportAction = ReturnType<
  LoadRobotstxtReportAction["meta"]["failure"]
>

export type ClearFilterRobotstxtReportAction = Action<
  RobotstxtReportActionType.ClearFilter
>

export type CancelFilterRobotstxtReportAction = CancelFilterAction<
  RobotstxtReportRecord,
  RobotstxtReportActionType.CancelFilter
>

export type ClearSortRobotstxtReportAction = Action<
  RobotstxtReportActionType.ClearSort
>

export type ExportRobotstxtReportAction = ExportAction<
  RobotstxtReportRecord,
  ExportListPagesPayload<RobotstxtReportRecord>,
  RobotstxtReportActionType.Export
>

export type AddFilterRobotstxtReportAction = AddFilterAction<
  RobotstxtReportRecord,
  RobotstxtReportActionType.AddFilter
>

export type SetSortRobotstxtReportAction = SetSortAction<
  RobotstxtReportRecord,
  RobotstxtReportActionType.SetSort
>

export type SetPaginationRobotstxtReportAction = SetPaginationAction<
  RobotstxtReportActionType.SetPagination
>
