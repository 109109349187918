import { Empty, Modal, Space, Spin, Tag, Tooltip, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { UnorderedListOutlined } from "@ant-design/icons"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import {
  ApiClientExportFormat,
  ApiClientFilterType,
} from "../../services/apiClient"
import filters from "../../services/filters"
import { ImageGroupReportRecord } from "../../state/@types/reducers/remote/cabinet/report/imageGroupReducer"
import { SourcePageReportRecord } from "../../state/@types/reducers/remote/cabinet/report/sourcePageReducer"
import {
  addFilterImageGroupReport,
  cancelFilterImageGroupReport,
  clearFilterImageGroupReport,
  clearSortImageGroupReport,
  loadImageGroupReport,
  setPaginationImageGroupReport,
  setSortImageGroupReport,
} from "../../state/actions/remote/cabinet/report/imageGroup"
import {
  addFilterSourcePageReport,
  clearSortSourcePageReport,
  clearSourcePageReport,
  exportSourcePageReport,
  loadSourcePageReport,
  setPaginationSourcePageReport,
  setSortSourcePageReport,
} from "../../state/actions/remote/cabinet/report/sourcePage"
import {
  getImageGroupReport,
  getSourcePage,
} from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import EmptyTagComponent from "../EmptyTagComponent"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Nullable from "../Nullable"
import Table from "../Table"
import InlineImage from "../ui/InlineImage"
import columnsType from "../ui/Table/columnsType"
import Url from "../Url"
import ExportButtons from "../ExportButtons"

type Props = {
  reportId: string
  shareToken?: string
}

const ImageGroupReport: React.FC<Props> = (props: Props) => {
  const { reportId, shareToken }: Props = props

  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const dispatch = useDispatch()

  const selectResource = () => {
    setIsModalVisible(true)
  }

  const onCancel = () => {
    setIsModalVisible(false)
    dispatch(clearSourcePageReport())
  }

  return (
    <>
      <Images
        reportId={reportId}
        shareToken={shareToken}
        selectResource={selectResource}
      />
      <Modal
        title="Источники"
        width={1000}
        visible={isModalVisible}
        onCancel={onCancel}
        footer={null}
      >
        <Pages shareToken={shareToken} reportId={reportId} />
      </Modal>
    </>
  )
}

export default ImageGroupReport

type ImagesProps = {
  reportId: string
  shareToken?: string
  selectResource: () => void
}

const Images: React.FC<ImagesProps> = props => {
  const { reportId, shareToken, selectResource } = props

  const imageGroupsState = useSelector(getImageGroupReport)

  const _columns: ColumnsType<ImageGroupReportRecord> = useColumns(
    [
      {
        key: "resource_url_no_scheme",
        title: "URL",
        dataIndex: "resource_url_no_scheme",
        sorter: true,
        render: (value: string, record): React.ReactNode => {
          if (value.length) {
            return <Url value={record.resource_url} />
          }

          return <InlineImage image={null} />
        },
      },
      columnsType.resourceCount({
        key: "page_resource_count",
        dataIndex: "page_resource_count",
        render: (value: number, record): React.ReactNode => {
          const showMore = value > 0

          const button = (
            <Tag
              style={showMore ? { cursor: "pointer" } : {}}
              onClick={(): void => {
                if (!showMore) {
                  return
                }

                dispatch(
                  addFilterSourcePageReport({
                    target_resource_id: [
                      [ApiClientFilterType.Eq, record.resource_id],
                    ],
                  }),
                )
                selectResource()
              }}
            >
              <Typography.Text type={showMore ? undefined : "secondary"}>
                <UnorderedListOutlined style={{ marginRight: 10 }} />
                {value}
              </Typography.Text>
            </Tag>
          )

          if (!showMore) {
            return <>{button}</>
          }

          return <Tooltip title="Посмотреть страницы">{button}</Tooltip>
        },
      }),
      columnsType.size({
        key: "resource_size",
        dataIndex: "resource_size",
      }),
      columnsType.imageExtension("resource_url", {
        key: "resource_content_type",
        dataIndex: "resource_content_type",
      }),
      columnsType.statusCode({
        key: "resource_status_code",
        dataIndex: "resource_status_code",
      }),
      columnsType.robotstxtIgnore(
        record => ({
          value: record.resource_robotstxt_ignore,
          lineNumber: record.resource_robotstxt_line,
          isExternal: record.resource_is_external,
        }),
        {
          key: "resource_robotstxt_ignore",
          dataIndex: "resource_robotstxt_ignore",
          sorter: true,
        },
      ),
    ],
    imageGroupsState.sort,
    imageGroupsState.defaultSort,
  )

  const dispatch = useDispatch()

  const { load, onChangeTable } = useTable(
    loadImageGroupReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationImageGroupReport,
    setSortImageGroupReport,
    clearSortImageGroupReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterImageGroupReport,
    cancelFilterImageGroupReport,
    clearFilterImageGroupReport,
    setSortImageGroupReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterImageGroupReport,
    cancelFilterImageGroupReport,
  )

  if (imageGroupsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        imageGroupsState.data === undefined ? (
          <Spin />
        ) : (
          <Space style={{ width: "100%" }} direction="vertical">
            <InputSearchByFields
              onSearch={onSearchInput}
              onClear={onClearInput}
              activeFilter={imageGroupsState.filter}
              options={inputOptions}
            />
            <Filters
              filters={filters.providers.imageGroups}
              activeFilter={imageGroupsState.filter}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          </Space>
        )
      }
    >
      <Table
        rowKey="resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={imageGroupsState.data}
        loading={imageGroupsState.isLoading}
        pagination={imageGroupsState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

const inputOptions: InputSearchByFieldsValueType<ImageGroupReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
]

type PagesProps = {
  reportId: string
  shareToken?: string
}

const Pages: React.FC<PagesProps> = props => {
  const { reportId, shareToken } = props

  const sourcePageState = useSelector(getSourcePage)

  const _columns = useColumns(
    pagesColumns,
    sourcePageState.sort,
    sourcePageState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadSourcePageReport.bind(null, {
      reportId,
      shareToken,
      saveParamsToUrl: false,
    }),
    setPaginationSourcePageReport,
    setSortSourcePageReport,
    clearSortSourcePageReport,
  )

  const dispatch = useDispatch()

  const onSelectExport = React.useCallback(
    (format: ApiClientExportFormat) =>
      dispatch(
        exportSourcePageReport({
          reportId,
          fileName: "Источники",
          shareToken,
          params: {
            "export-format": format,
          },
        }),
      ),
    [],
  )

  React.useEffect(() => {
    if (sourcePageState.filter) {
      load()
    }
  }, [sourcePageState.filter])

  return (
    <Space direction="vertical" align="end">
      <ExportButtons onSelect={onSelectExport} />

      <Table<SourcePageReportRecord>
        rowKey="page_resource_id"
        dataSource={sourcePageState.data}
        loading={sourcePageState.isLoading}
        pagination={sourcePageState.pagination}
        onChangeTable={onChangeTable}
        columns={_columns}
      />
    </Space>
  )
}

const pagesColumns: ColumnsType<SourcePageReportRecord> = [
  columnsType.pageInformation(
    record => ({
      resourceId: record.src_resource_id,
      url: {
        value: record.src_resource_url,
      },
      title: record.src_page_title,
    }),
    {
      key: "src_resource_url_no_scheme",
      dataIndex: "src_resource_url_no_scheme",
    },
  ),
  {
    key: "page_resource_attrs_alt",
    title: "Alt",
    dataIndex: "page_resource_attrs_alt",
    sorter: true,
    render: (value: string | null): React.ReactNode => {
      if (value === null) {
        return <Nullable />
      }

      return value.length ? (
        <Tag style={{ whiteSpace: "normal" }} color="default">
          {value}
        </Tag>
      ) : (
        <EmptyTagComponent />
      )
    },
  },
  columnsType.indexStatus(
    reocrd => ({
      canonicalUrl: reocrd.src_page_canonical_url,
      robotstxtLine: reocrd.src_resource_robotstxt_line,
      robotstxtIgnore: reocrd.src_resource_robotstxt_ignore,
    }),
    {
      key: "src_resource_index_status",
      dataIndex: "src_resource_index_status",
    },
  ),
]
