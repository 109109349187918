import { Project } from "../../../../../../types/project"
import { Report, ReportSettingAlias } from "../../../../../reportService"
import { ApiResponse, ApiResponseWithData } from "../../../response"

export type CreateProjectResponse = Project
export type CreateProjectPayload = {
  host: string
}

export type GetProjectResponse = Project
export type GetProjectPayload = { projectId: string }

export type GetReportListResponse = ApiResponseWithData<Report[]>
export type GetReportListPayload = { projectId: string; alias?: string }

export type GetProjectListResponse = ApiResponseWithData<Project[]>
export type GetProjectListPayload = {
  host: string
}

export type GetProjectByAliasResponse = ApiResponseWithData<Project | null>
export type GetProjectByAliasPayload = { alias: string }

export type DeleteProjectResponse = ApiResponse
export type DeleteProjectPayload = { projectId: string }

export type CreateReportResponse<
  Rep extends Pick<Report, "id" | "alias"> = Report
> = ApiResponseWithData<Rep>
export type CreateReportBody = {
  entry_point: string
  [ReportSettingAlias.Budget]: number
  [ReportSettingAlias.ThreadCount]: number
  [ReportSettingAlias.Sitemaps]?: string[]
  [ReportSettingAlias.UseRobotstxt]: boolean
  [ReportSettingAlias.UseMetarobots]: boolean
  [ReportSettingAlias.UseCanonical]: boolean
  [ReportSettingAlias.FollowSubdomains]: boolean
  [ReportSettingAlias.TreatWwwAsSameHost]: boolean
  [ReportSettingAlias.FetchExternalLinks]: boolean
  [ReportSettingAlias.FollowNofollowLinks]: boolean
  [ReportSettingAlias.MaxDfi]: number
  [ReportSettingAlias.TimeoutMs]: number
  [ReportSettingAlias.UserAgent]: string
  [ReportSettingAlias.RobotsUserAgent]: string
}
export type CreateReportPayload = {
  projectId: string
  body: CreateReportBody
}
