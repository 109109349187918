import { MetaRobotsReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/metaRobotsReducer"
import { addFilterMetaRobotsReport } from "../../../state/actions/remote/cabinet/report/metaRobots"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<MetaRobotsReportRecord>[] = [
  {
    name: "noindex",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterMetaRobotsReport({
          page_robots_noindex: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "nofollow",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterMetaRobotsReport({
          page_robots_nofollow: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Другие",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterMetaRobotsReport({
          page_robots_directives: [
            [ApiClientFilterType.NotIn, ["noindex", "nofollow"]],
          ],
          page_robots_directives_count: [[ApiClientFilterType.Ne, 0]],
        }),
      },
    ],
  },
  {
    name: "Не указан",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterMetaRobotsReport({
          page_robots_directives_count: [[ApiClientFilterType.Eq, 0]],
        }),
      },
    ],
  },
]

export default filters
