import { Button, Card, Space, Spin, Tree, Typography } from "antd"
import { DataNode } from "antd/lib/tree"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import decodeHostnameInUrl from "../../services/url/decodeHostnameInUrl"
import { loadUrlTree } from "../../state/actions/remote/cabinet/report/urlTree"
import { getUrlTree } from "../../state/selectors/remote/cabinet/report"
import ModalAboutPage from "../ModalAboutPage"
import StatusCode from "../StatusCode"

export type CstDataNode = DataNode & {
  childtren?: CstDataNode[]
  countAllChildren?: number
}

let expandedKeys: string[] = []

type Props = {
  reportId: string
  shareToken?: string
}

const UrlTreeReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const urlTreeState = useSelector(getUrlTree)
  const urlTreeStateData = urlTreeState.data

  // TODO: change height after event window.resize
  const treeHeight = React.useMemo(() => {
    // TODO: create and move this to context for ContentWithSidebarLayout?
    const content = document.querySelector<HTMLDivElement>(
      ".content-with-sidebar",
    )
    if (!content) {
      return 0
    }

    // parseInt(content.style.padding) * 2 - padding(top,bottom) content page
    // 24 * 2 - padding(top,bottom) card
    // 50 - height panel controls and Divider
    // 75 - offset from the page bottom
    return (
      content.clientHeight -
      (parseInt(content.style.padding) * 2 + 24 * 2 + 50 + 75)
    )
  }, [urlTreeState])

  const tree = React.useMemo<CstDataNode[]>((): CstDataNode[] => {
    if (!urlTreeStateData) {
      return []
    }

    return makeTreeFromUrls(Object.keys(urlTreeStateData))
  }, [urlTreeStateData])

  const [, setExpandedKeys] = React.useState<string[]>([])

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!urlTreeStateData && !urlTreeState.isLoading) {
      dispatch(
        loadUrlTree({
          reportId,
          shareToken,
        }),
      )
    }
  }, [dispatch])

  const extendedAllKeys = (): void => {
    const keys: string[] = []

    const getKeys = (node: CstDataNode): void => {
      if (!node.children || !node.children.length) {
        return
      }

      keys.push(node.key as string)
      node.children.forEach(getKeys)
    }

    tree.forEach(getKeys)

    setExpandedKeys(keys)
    expandedKeys = keys
  }

  if (urlTreeState.isLoading) {
    return <Spin />
  }

  return (
    <Card
      extra={
        <Space size={0}>
          <Button
            type="link"
            size="small"
            disabled={expandedKeys.length === 0}
            onClick={(): void => {
              setExpandedKeys([])
              expandedKeys = []
            }}
          >
            Свернуть все
          </Button>
          /
          <Button type="link" size="small" onClick={extendedAllKeys}>
            Развернуть все
          </Button>
        </Space>
      }
    >
      <Tree.DirectoryTree
        treeData={tree}
        selectable={false}
        expandedKeys={expandedKeys}
        className="tree-page-urls"
        height={treeHeight}
        onExpand={(_expandedKeys): void => {
          setExpandedKeys(_expandedKeys as string[])
          expandedKeys = _expandedKeys as string[]
        }}
        titleRender={(node: CstDataNode): React.ReactNode => {
          if (node.children?.length) {
            node.isLeaf = false
          }

          return (
            <>
              {node.title}{" "}
              {node.children && node.children.length !== 0 && (
                <Typography.Text type="secondary">
                  ({node.countAllChildren})
                </Typography.Text>
              )}
              {urlTreeStateData &&
                urlTreeStateData[node.key as string] !== undefined && (
                  <>
                    {urlTreeStateData[node.key] !== 200 && (
                      <StatusCode
                        value={urlTreeStateData[node.key]}
                        style={{ marginLeft: 5, marginRight: 0 }}
                      />
                    )}
                    <ModalAboutPage
                      url={node.key as string}
                      tooltip={{ placement: "right" }}
                    />
                  </>
                )}
            </>
          )
        }}
      />
    </Card>
  )
}

export default UrlTreeReport

const makeTreeFromUrls = (urls: string[]): CstDataNode[] => {
  const tree: CstDataNode[] = []

  for (const url of urls.sort((a, b) => a.length - b.length)) {
    let nodes: string[] = []
    let hasTrailingSlash: boolean

    try {
      const { pathname } = new URL(url)
      if (pathname === "/") {
        continue
      }

      hasTrailingSlash = pathname.length === pathname.lastIndexOf("/") + 1
      const indexOfPathname = url.lastIndexOf(pathname)

      nodes = url.slice(indexOfPathname, url.length).split("/").filter(Boolean)
      // remove pathname from url and add this to the beginning of the array
      nodes.unshift(url.slice(0, indexOfPathname))
    } catch (e) {
      continue
    }

    let currNode: CstDataNode[] = tree
    for (let i = 0; i < nodes.length; i++) {
      let pathElement = nodes[i]
      let key: string = ""
      if (i === 0) {
        pathElement = `${decodeHostnameInUrl(pathElement)}/`
        key = pathElement
      } else {
        pathElement = `/${pathElement}`
        key = `${nodes.slice(0, i + 1).join("/")}${hasTrailingSlash ? "/" : ""}`
      }

      const child: CstDataNode | undefined = currNode?.find(
        element => element.title === pathElement,
      )
      if (child) {
        currNode = child.children as CstDataNode[]
        continue
      }

      currNode?.push({
        key: key as string,
        title: pathElement,
        children: [],
        isLeaf: true,
        countAllChildren: urls.filter(
          url => url.indexOf(key) >= 0 && (url !== key || i === 0),
        ).length,
      })

      currNode = currNode[currNode.length - 1].children as CstDataNode[]
    }
  }

  return tree
}
