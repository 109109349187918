import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { GetOrdersResponse } from "../../../services/apiClient"
import {
  BillingTypes,
  LoadOrdersAction,
} from "../../@types/actions/remote/billing"

function* fetchOrders(action: LoadOrdersAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetOrdersResponse = yield call(apiClient.billing.getOrders)
    yield put(action.meta.success(response))
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* saga(): Generator {
  yield takeEvery(BillingTypes.LoadOrders, fetchOrders)
}
