import { clearAuth } from "../../state/actions/remote/auth"
import store from "../../state/store"
import storage from "../storage"
import { MakeRequestParams, MakeRequestPayload } from "./@types/makeRequest"
import {
  BadRequestError,
  ForbiddenError,
  HttpError,
  NotFoundError,
  UnauthorizedError,
} from "./errors"
import generateApiUrl from "./generateApiUrl"

export default async function <
  Params extends MakeRequestParams = MakeRequestParams,
  Body extends object = object
>({
  path,
  method = "GET",
  body,
  params,
  headers,
}: MakeRequestPayload<Params, Body>): Promise<Response> {
  const requestInit: RequestInit = {
    method,
    credentials: "include",
    headers,
  }

  if (method !== "GET" && body && typeof body === "object") {
    requestInit.body = JSON.stringify(body)
    requestInit.headers = {
      ...requestInit.headers,
      "Content-type": "application/json",
    }
  }

  const url = generateApiUrl(path, params)
  const res = await fetch(url, requestInit)
  if (!res.ok) {
    let message = `Error! status: ${res.status}`
    try {
      const data = await res.json()
      if (data.message) {
        message = data?.message
      }
    } catch (error) {
      // TODO: add logs
    }

    if (res.status === 401) {
      storage.setLastUrl()
      store.dispatch(clearAuth())
      throw new UnauthorizedError(message)
    } else if (res.status === 404) {
      throw new NotFoundError(message)
    } else if (res.status === 400) {
      throw new BadRequestError(message)
    } else if (res.status === 403) {
      throw new ForbiddenError(message)
    }

    throw new HttpError(message)
  }

  return res
}
