import { RobotstxtReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/robotstxtReducer"
import { addFilterRobotstxtReport } from "../../../state/actions/remote/cabinet/report/robotstxt"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<RobotstxtReportRecord>[] = [
  {
    name: "Allow (разрешено)",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterRobotstxtReport({
          resource_robotstxt_ignore: [[ApiClientFilterType.In, [false]]],
        }),
      },
    ],
  },
  {
    name: "Disallow (запрещено)",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterRobotstxtReport({
          resource_robotstxt_ignore: [[ApiClientFilterType.In, [true]]],
        }),
      },
    ],
  },
]

export default filters
