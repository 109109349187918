import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  CreateProjectResponse,
} from "../../../../../services/apiClient"
import {
  CreateProjectAction,
  ProjectTypes,
} from "../../../../@types/actions/remote/cabinet/project"

function* fetchCreateProject(action: CreateProjectAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: CreateProjectResponse = yield call(
      apiClient.cabinet.project.projects.createProject,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* createProjectSaga(): Generator {
  yield takeEvery(ProjectTypes.Create, fetchCreateProject)
}
