import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { TitleReportRecord } from "../../../../reducers/remote/cabinet/report/titleReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum TitleReportActionType {
  Load = "@@REPORTS/TITLES/LOAD",
  LoadSuccess = "@@REPORTS/TITLES/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/TITLES/LOAD_PENDING",
  LoadFailure = "@@REPORTS/TITLES/LOAD_FAILURE",
  SetPagination = "@@REPORTS/TITLES/SET_PAGINATION",
  SetSort = "@@REPORTS/TITLES/SET_SORT",
  AddFilter = "@@REPORTS/TITLES/ADD_FILTER",
  CancelFilter = "@@REPORTS/TITLES/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/TITLES/CLEAR_FILTER",
  ClearSort = "@@REPORTS/TITLES/CLEAR_SORT",
  Export = "@@REPORTS/TITLES/EXPORT",
}

export type LoadTitleReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<TitleReportRecord>,
  TitleReportActionType.Load,
  TitleReportActionType.LoadPending,
  TitleReportActionType.LoadSuccess,
  TitleReportActionType.LoadFailure
>
export type LoadPendingTitleReportAction = ReturnType<
  LoadTitleReportAction["meta"]["pending"]
>
export type LoadSuccessTitleReportAction = ReturnType<
  LoadTitleReportAction["meta"]["success"]
>
export type LoadFailureTitleReportAction = ReturnType<
  LoadTitleReportAction["meta"]["failure"]
>

export type ClearFilterTitleReportAction = Action<
  TitleReportActionType.ClearFilter
>

export type ClearSortTitleReportAction = Action<TitleReportActionType.ClearSort>

export type ExportTitleReportAction = ExportAction<
  TitleReportRecord,
  ExportListPagesPayload<TitleReportRecord>,
  TitleReportActionType.Export
>

export type AddFilterTitleReportAction = AddFilterAction<
  TitleReportRecord,
  TitleReportActionType.AddFilter
>

export type CancelFilterTitleReportAction = CancelFilterAction<
  TitleReportRecord,
  TitleReportActionType.CancelFilter
>

export type SetSortTitleReportAction = SetSortAction<
  TitleReportRecord,
  TitleReportActionType.SetSort
>

export type SetPaginationTitleReportAction = SetPaginationAction<
  TitleReportActionType.SetPagination
>
