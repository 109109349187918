import { Action } from "redux"
import {
  CreateOrderPayload,
  CreateOrderResponse,
  GetOrdersResponse,
  GetPlanByAliasPayload,
  GetPlanByAliasResponse,
  GetPlansResponse,
  GetUserFeaturesResponse,
  GetUserPlanResponse,
  PurchasePayload,
  PurchaseResponse,
} from "../../../../services/apiClient"
import { RemoteAction, RemoteActionWithPayload } from "../action"

export enum BillingTypes {
  LoadUserFeatures = "@@BILLING/USER_FEATURES/LOAD",
  LoadUserFeaturesPending = "@@BILLING/USER_FEATURES/LOAD_PENDING",
  LoadUserFeaturesSuccess = "@@BILLING/USER_FEATURES/LOAD_SUCCESS",
  LoadUserFeaturesFailure = "@@BILLING/USER_FEATURES/LOAD_FAILURE",
  UserFeaturesClear = "@@BILLING/USER_FEATURES/CLEAR",

  LoadUserPlan = "@@BILLING/USER_PLAN/LOAD",
  LoadUserPlanPending = "@@BILLING/USER_PLAN/LOAD_PENDING",
  LoadUserPlanSuccess = "@@BILLING/USER_PLAN/LOAD_SUCCESS",
  LoadUserPlanFailure = "@@BILLING/USER_PLAN/LOAD_FAILURE",

  LoadOrders = "@@BILLING/ORDERS/LOAD",
  LoadOrdersPending = "@@BILLING/ORDERS/LOAD_PENDING",
  LoadOrdersSuccess = "@@BILLING/ORDERS/LOAD_SUCCESS",
  LoadOrdersFailure = "@@BILLING/ORDERS/LOAD_FAILURE",

  LoadPlan = "@@BILLING/PLAN/LOAD",
  LoadPlanPending = "@@BILLING/PLAN/LOAD_PENDING",
  LoadPlanSuccess = "@@BILLING/PLAN/LOAD_SUCCESS",
  LoadPlanFailure = "@@BILLING/PLAN/LOAD_FAILURE",

  CreateOrder = "@@BILLING/ORDER/CREATE",
  CreateOrderPending = "@@BILLING/ORDER/CREATE_PENDING",
  CreateOrderSuccess = "@@BILLING/ORDER/CREATE_SUCCESS",
  CreateOrderFailure = "@@BILLING/ORDER/CREATE_FAILURE",

  Purchase = "@@BILLING/PURCHASE",
  PurchasePending = "@@BILLING/PURCHASE_PENDING",
  PurchaseSuccess = "@@BILLING/PURCHASE_SUCCESS",
  PurchaseFailure = "@@BILLING/PURCHASE_FAILURE",

  LoadPlans = "@@BILLING/PLANS/LOAD",
  LoadPlansPending = "@@BILLING/PLANS/LOAD_PENDING",
  LoadPlansSuccess = "@@BILLING/PLANS/LOAD_SUCCESS",
  LoadPlansFailure = "@@BILLING/PLANS/LOAD_FAILURE",
}

export type PurchaseAction = RemoteActionWithPayload<
  PurchasePayload,
  PurchaseResponse,
  BillingTypes.Purchase,
  BillingTypes.PurchasePending,
  BillingTypes.PurchaseSuccess,
  BillingTypes.PurchaseFailure
>
export type PurchasePendingAction = ReturnType<
  PurchaseAction["meta"]["pending"]
>
export type PurchaseSuccessAction = ReturnType<
  PurchaseAction["meta"]["success"]
>
export type PurchaseFailureAction = ReturnType<
  PurchaseAction["meta"]["failure"]
>

export type LoadUserPlanAction = RemoteAction<
  GetUserPlanResponse,
  BillingTypes.LoadUserPlan,
  BillingTypes.LoadUserPlanPending,
  BillingTypes.LoadUserPlanSuccess,
  BillingTypes.LoadUserPlanFailure
>
export type LoadUserPlanPendingAction = ReturnType<
  LoadUserPlanAction["meta"]["pending"]
>
export type LoadUserPlanSuccessAction = ReturnType<
  LoadUserPlanAction["meta"]["success"]
>
export type LoadUserPlanFailureAction = ReturnType<
  LoadUserPlanAction["meta"]["failure"]
>

export type LoadUserFeaturesAction = RemoteAction<
  GetUserFeaturesResponse,
  BillingTypes.LoadUserFeatures,
  BillingTypes.LoadUserFeaturesPending,
  BillingTypes.LoadUserFeaturesSuccess,
  BillingTypes.LoadUserFeaturesFailure
>
export type LoadUserFeaturesPendingAction = ReturnType<
  LoadUserFeaturesAction["meta"]["pending"]
>
export type LoadUserFeaturesSuccessAction = ReturnType<
  LoadUserFeaturesAction["meta"]["success"]
>
export type LoadUserFeaturesFailureAction = ReturnType<
  LoadUserFeaturesAction["meta"]["failure"]
>

export type LoadPlansAction = RemoteAction<
  GetPlansResponse,
  BillingTypes.LoadPlans,
  BillingTypes.LoadPlansPending,
  BillingTypes.LoadPlansSuccess,
  BillingTypes.LoadPlansFailure
>
export type LoadPlansPendingAction = ReturnType<
  LoadPlansAction["meta"]["pending"]
>
export type LoadPlansSuccessAction = ReturnType<
  LoadPlansAction["meta"]["success"]
>
export type LoadPlansFailureAction = ReturnType<
  LoadPlansAction["meta"]["failure"]
>

export type LoadPlanByAliasAction = RemoteActionWithPayload<
  GetPlanByAliasPayload,
  GetPlanByAliasResponse,
  BillingTypes.LoadPlan,
  BillingTypes.LoadPlanPending,
  BillingTypes.LoadPlanSuccess,
  BillingTypes.LoadPlanFailure
>
export type LoadPlanByAliasPendingAction = ReturnType<
  LoadPlanByAliasAction["meta"]["pending"]
>
export type LoadPlanByAliasSuccessAction = ReturnType<
  LoadPlanByAliasAction["meta"]["success"]
>
export type LoadPlanByAliasFailureAction = ReturnType<
  LoadPlanByAliasAction["meta"]["failure"]
>

export type LoadOrdersAction = RemoteAction<
  GetOrdersResponse,
  BillingTypes.LoadOrders,
  BillingTypes.LoadOrdersPending,
  BillingTypes.LoadOrdersSuccess,
  BillingTypes.LoadOrdersFailure
>
export type LoadOrdersPendingAction = ReturnType<
  LoadOrdersAction["meta"]["pending"]
>
export type LoadOrdersSuccessAction = ReturnType<
  LoadOrdersAction["meta"]["success"]
>
export type LoadOrdersFailureAction = ReturnType<
  LoadOrdersAction["meta"]["failure"]
>

export type CreateOrderAction = RemoteActionWithPayload<
  CreateOrderPayload,
  CreateOrderResponse,
  BillingTypes.CreateOrder,
  BillingTypes.CreateOrderPending,
  BillingTypes.CreateOrderSuccess,
  BillingTypes.CreateOrderFailure
>
export type CreateOrderPendingAction = ReturnType<
  CreateOrderAction["meta"]["pending"]
>
export type CreateOrderSuccessAction = ReturnType<
  CreateOrderAction["meta"]["success"]
>
export type CreateOrderFailureAction = ReturnType<
  CreateOrderAction["meta"]["failure"]
>

export type ClearFeatureAction = Action<BillingTypes.UserFeaturesClear>

export type BillingActions =
  | PurchaseAction
  | PurchasePendingAction
  | PurchaseSuccessAction
  | PurchaseFailureAction
  | LoadUserPlanAction
  | LoadUserPlanPendingAction
  | LoadUserPlanSuccessAction
  | LoadUserPlanFailureAction
  | LoadUserFeaturesAction
  | LoadUserFeaturesPendingAction
  | LoadUserFeaturesSuccessAction
  | LoadUserFeaturesFailureAction
  | ClearFeatureAction
  | LoadPlansAction
  | LoadPlansPendingAction
  | LoadPlansSuccessAction
  | LoadPlansFailureAction
  | LoadPlanByAliasAction
  | LoadPlanByAliasPendingAction
  | LoadPlanByAliasSuccessAction
  | LoadPlanByAliasFailureAction
  | LoadOrdersAction
  | LoadOrdersPendingAction
  | LoadOrdersSuccessAction
  | LoadOrdersFailureAction
  | CreateOrderAction
  | CreateOrderPendingAction
  | CreateOrderSuccessAction
  | CreateOrderFailureAction
