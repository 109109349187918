import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../../../../services/apiClient"
import {
  LoadRedirectsGraphAction,
  RedirectsGraphActionType,
} from "../../../../../@types/actions/remote/cabinet/report/redirectsGraph"

function* fetchGetRedirectsGraph(
  action: LoadRedirectsGraphAction,
): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response = yield call(
      apiClient.cabinet.project.reports.graph.getRedirectsGraph,
      action.payload,
    )
    yield put(action.meta.success(response.data))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getRedirectsGraphSaga(): Generator {
  yield takeEvery(RedirectsGraphActionType.Load, fetchGetRedirectsGraph)
}
