import { Empty, Space, Spin, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { ExternalReportRecord } from "../../state/@types/reducers/remote/cabinet/report/externalReducer"
import {
  addFilterExternalReport,
  cancelFilterExternalReport,
  clearFilterExternalReport,
  clearSortExternalReport,
  loadExternalReport,
  setPaginationExternalReport,
  setSortExternalReport,
} from "../../state/actions/remote/cabinet/report/external"
import { getExternal } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import EmptyTagComponent from "../EmptyTagComponent"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Nullable from "../Nullable"
import StatusCode from "../StatusCode"
import Table from "../Table"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const ExternalReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const externalState = useSelector(getExternal)

  const _columns = useColumns(
    columns,
    externalState.sort,
    externalState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadExternalReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationExternalReport,
    setSortExternalReport,
    clearSortExternalReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterExternalReport,
    cancelFilterExternalReport,
    clearFilterExternalReport,
    setSortExternalReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterExternalReport,
    cancelFilterExternalReport,
  )

  if (externalState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        externalState.data === undefined ? (
          <Spin />
        ) : (
          <Space style={{ width: "100%" }} direction="vertical">
            <InputSearchByFields
              onSearch={onSearchInput}
              onClear={onClearInput}
              activeFilter={externalState.filter}
              options={inputOptions}
            />
            <Filters
              filters={filters.providers.external}
              activeFilter={externalState.filter}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          </Space>
        )
      }
    >
      <Table
        rowKey="page_resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={externalState.data}
        loading={externalState.isLoading}
        pagination={externalState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

export default ExternalReport

const inputOptions: InputSearchByFieldsValueType<ExternalReportRecord>[] = [
  { value: "page_resource_url", label: "URL" },
  { value: "src_resource_url_no_scheme", label: "Источник" },
  { value: "src_page_title", label: "Title - Источник" },
  { value: "page_resource_content", label: "Анкор" },
]

const columns: ColumnsType<ExternalReportRecord> = [
  columnsType.pageInformation(
    record => ({
      url: {
        value: record.page_resource_url,
      },
      redirects: record.target_resource_redirects
        ? record.target_resource_redirects
        : [],
    }),
    {
      key: "page_resource_url",
      dataIndex: "page_resource_url",
    },
  ),
  columnsType.pageInformation(
    record => ({
      resourceId: record.src_resource_id,
      url: {
        value: record.src_resource_url,
      },
      title: record.src_page_title,
      redirects: record.src_resource_redirects
        ? record.src_resource_redirects
        : [],
      canonical: {
        url: record.src_page_canonical_url,
        isCanonical: record.src_page_is_canonical,
      },
    }),
    {
      key: "src_resource_url_no_scheme",
      dataIndex: "src_resource_url_no_scheme",
      title: "Источник",
    },
  ),
  columnsType.anchorType({
    key: "page_resource_anchor_anchor_type",
    dataIndex: "page_resource_anchor_anchor_type",
  }),
  {
    key: "page_resource_content",
    title: "Анкор",
    dataIndex: "page_resource_content",
    render: (
      value: ExternalReportRecord["page_resource_content"],
    ): React.ReactNode => {
      if (value === null) {
        return <Nullable />
      }

      return value.length ? (
        <Typography.Text>{value}</Typography.Text>
      ) : (
        <EmptyTagComponent />
      )
    },
  },
  {
    key: "page_resource_attrs_rel",
    title: "параметр rel",
    width: 120,
    dataIndex: "page_resource_attrs_rel",
    render: (
      value: ExternalReportRecord["page_resource_attrs_rel"],
    ): React.ReactNode => {
      if (value === null) {
        return <Nullable />
      }

      return value.length ? (
        <Typography.Text>{value}</Typography.Text>
      ) : (
        <EmptyTagComponent />
      )
    },
  },
  columnsType.statusCode({
    key: "target_resource_status_code",
    dataIndex: "target_resource_status_code",
    render: (
      value: ExternalReportRecord["target_resource_status_code"],
      record,
    ): React.ReactNode => (
      <StatusCode
        value={
          record.target_resource_redirect_status_code !== null
            ? record.target_resource_redirect_status_code
            : value
        }
      />
    ),
  }),
]
