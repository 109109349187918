import { UserRole } from "../../../../types/user"
import { ApiResponse } from "../../@types/response"

export type LoginPayload = { provider: AuthProvider; invite?: string }
export type LoginResponse = ApiResponse & {
  login_url: string
}

export type LogoutResponse = ApiResponse

export enum AuthProvider {
  Google = "google",
  Yandex = "yandex",
  Vk = "vk",
  Facebook = "facebook",
  MailRu = "mailru",
}

type UserProfile = {
  name: string
  email: string
  phone?: string
  company?: string
  position?: string
}

export type GetSessionResponse = ApiResponse & {
  session: {
    token: string
  }
  user: {
    id: string
    avatar_url: string
    role: UserRole
  }
  user_profile: UserProfile
  need_register?: boolean
}

export type UpdateProfilePayload = UpdateProfileBody
export type UpdateProfileBody = UserProfile
