import React from "react"
import Nullable from "../../Nullable"

type Props = {
  value: string | null
  viewEmptyLine?: boolean
}

const Description: React.FC<Props> = props => {
  const { value, viewEmptyLine = false } = props

  if (!value || !value.length) {
    return <>{viewEmptyLine ? <Nullable /> : "Description не указан"}</>
  }

  return <>{value}</>
}

export default Description
