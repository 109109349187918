import {
  AddFilterNotHtmlReportAction,
  CancelFilterNotHtmlReportAction,
  ClearFilterNotHtmlReportAction,
  ClearSortNotHtmlReportAction,
  ExportNotHtmlReportAction,
  LoadNotHtmlReportAction,
  NotHtmlReportActionType,
  SetPaginationNotHtmlReportAction,
  SetSortNotHtmlReportAction,
} from "../../../../@types/actions/remote/cabinet/report/notHtml"

const loadNotHtmlReport = (
  payload: LoadNotHtmlReportAction["payload"],
): LoadNotHtmlReportAction => ({
  type: NotHtmlReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: NotHtmlReportActionType.LoadPending,
    }),
    success: payload => ({
      type: NotHtmlReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: NotHtmlReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterNotHtmlReport = (): ClearFilterNotHtmlReportAction => ({
  type: NotHtmlReportActionType.ClearFilter,
})

const clearSortNotHtmlReport = (): ClearSortNotHtmlReportAction => ({
  type: NotHtmlReportActionType.ClearSort,
})

const exportNotHtmlReport = (
  payload: ExportNotHtmlReportAction["payload"],
): ExportNotHtmlReportAction => ({
  type: NotHtmlReportActionType.Export,
  payload,
})

const addFilterNotHtmlReport = (
  payload: AddFilterNotHtmlReportAction["payload"],
): AddFilterNotHtmlReportAction => ({
  type: NotHtmlReportActionType.AddFilter,
  payload,
})

const cancelFilterNotHtmlReport = (
  payload: CancelFilterNotHtmlReportAction["payload"],
): CancelFilterNotHtmlReportAction => ({
  type: NotHtmlReportActionType.CancelFilter,
  payload,
})

const setPaginationNotHtmlReport = (
  payload: SetPaginationNotHtmlReportAction["payload"],
): SetPaginationNotHtmlReportAction => ({
  type: NotHtmlReportActionType.SetPagination,
  payload,
})

const setSortNotHtmlReport = (
  payload: SetSortNotHtmlReportAction["payload"],
): SetSortNotHtmlReportAction => ({
  type: NotHtmlReportActionType.SetSort,
  payload,
})

export {
  loadNotHtmlReport,
  clearFilterNotHtmlReport,
  clearSortNotHtmlReport,
  exportNotHtmlReport,
  addFilterNotHtmlReport,
  cancelFilterNotHtmlReport,
  setPaginationNotHtmlReport,
  setSortNotHtmlReport,
}
