import { TooltipProps } from "antd"
import { ActionWithPayload } from "../../../state/@types/actions/action"
import { ApiClientFilter, ApiClientSort } from "../../apiClient"

export enum FilterActionType {
  Filter,
  Sort,
}

export type Filter<Rec> = {
  name: string
  actions: (FilterActionSort<Rec> | FilterActionFilter<Rec>)[]
  disabled?: boolean
  tooltip?: TooltipProps
}

export type FilterActionSort<Rec> = {
  action: ActionWithPayload<ApiClientSort<Rec>, string>
  type: FilterActionType.Sort
}

export type FilterActionFilter<Rec> = {
  action: ActionWithPayload<ApiClientFilter<Rec>, string>
  type: FilterActionType.Filter
}

export type FilterActions<Rec> = (
  | FilterActionSort<Rec>
  | FilterActionFilter<Rec>
)[]
