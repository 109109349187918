import { SitemapUrlsGraphActionType } from "../../../../@types/actions/remote/cabinet/report/sitemapUrlsGraph"
import {
  SitemapUrlsGraphActions,
  SitemapUrlsGraphState,
} from "../../../../@types/reducers/remote/cabinet/report/sitemapUrlsGraphReducer"
import {
  updateStateLoadFailure,
  updateStateLoadGraphSuccess,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: SitemapUrlsGraphState = {
  isLoading: false,
}

const sitemapUrlsGraphReducer = (
  state: SitemapUrlsGraphState = initState,
  action: SitemapUrlsGraphActions,
): SitemapUrlsGraphState => {
  switch (action.type) {
    case SitemapUrlsGraphActionType.LoadSuccess:
      return updateStateLoadGraphSuccess(state, action)

    case SitemapUrlsGraphActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case SitemapUrlsGraphActionType.LoadPending:
      return updateStateSetPending(state)

    default:
      return state
  }
}

export default sitemapUrlsGraphReducer
