import {
  GetRobotsGraphPayload,
  GetRobotsGraphResponse,
} from "../../../../../../services/apiClient"
import { LoadAction } from "../../../filteredResourceAction"

export enum RobotsGraphActionType {
  Load = "@@REPORTS/ROBOTS/GRAPH/LOAD",
  LoadSuccess = "@@REPORTS/ROBOTS/GRAPH/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/ROBOTS/GRAPH/LOAD_PENDING",
  LoadFailure = "@@REPORTS/ROBOTS/GRAPH/LOAD_FAILURE",
}

export type LoadRobotsGraphAction = LoadAction<
  GetRobotsGraphPayload,
  GetRobotsGraphResponse,
  RobotsGraphActionType.Load,
  RobotsGraphActionType.LoadPending,
  RobotsGraphActionType.LoadSuccess,
  RobotsGraphActionType.LoadFailure
>
export type LoadPendingRobotsGraphAction = ReturnType<
  LoadRobotsGraphAction["meta"]["pending"]
>
export type LoadSuccessRobotsGraphAction = ReturnType<
  LoadRobotsGraphAction["meta"]["success"]
>
export type LoadFailureRobotsGraphAction = ReturnType<
  LoadRobotsGraphAction["meta"]["failure"]
>
