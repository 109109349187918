import React from "react"
import TitleReport from "../../../components/Report/TitleReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportTitleReport } from "../../../state/actions/remote/cabinet/report/title"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.Titles)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const TitlesPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportTitleReport,
  })

  return <TitleReport shareToken={shareToken} reportId={reportId} />
}

export default TitlesPage
