import { CanonicalReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/canonicalReducer"
import { addFilterCanonicalReport } from "../../../state/actions/remote/cabinet/report/canonical"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<CanonicalReportRecord>[] = [
  {
    name: "Каноничные",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterCanonicalReport({
          page_is_canonical: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Не каноничные",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterCanonicalReport({
          page_is_canonical: [[ApiClientFilterType.Eq, false]],
        }),
      },
    ],
  },
  {
    name: "Не указан",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterCanonicalReport({
          page_canonical_url: [[ApiClientFilterType.Eq, ""]],
        }),
      },
    ],
  },
  {
    name: "С ошибкой",
    disabled: true,
    actions: [],
    tooltip: {
      title: "В процессе разработки", // TODO: implement this
    },
  },
]

export default filters
