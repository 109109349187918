import { ApiClientExportFormat } from "../../../@types/export"
import { MakeRequestPayload } from "../../../@types/makeRequest"
import {
  ResourceFilteredRequest,
  ResourceFilteredRequestExport,
} from "../../../@types/request"
import {
  DeleteReportPayload,
  DeleteReportResponse,
  ExportListPageResourcesPayload,
  ExportListPagesPayload,
  ExportListResourcesPayload,
  ExportListSitemapUrlsPayload,
  GetListPageResourcesPayload,
  GetListPageResourcesRecord,
  GetListPageResourcesResponse,
  GetListPagesPayload,
  GetListPagesRecord,
  GetListPagesResponse,
  GetListResourcesPayload,
  GetListResourcesRecord,
  GetListResourcesResponse,
  GetListSitemapUrlReportRecord,
  GetListSitemapUrlsPayload,
  GetListSitemapUrlsResponse,
  GetPageUrlsPayload,
  GetPageUrlsResponse,
  GetReportPayload,
  GetReportResponse,
  StopCrawlingPayload,
  StopCrawlingResponse,
} from "../../../@types/rest/cabinet/project/reports"
import makeRequest from "../../../makeRequest"

export * as graph from "./reports/graph"
export * as sharedLinks from "./reports/shared-links"

export const deleteReport = async (
  payload: DeleteReportPayload,
): Promise<DeleteReportResponse> => {
  const res = await makeRequest({
    method: "DELETE",
    path: `cabinet/api/project/reports/${payload.reportId}`,
  })
  return res.json()
}

export const exportListPageResources = async <Rec = GetListPageResourcesRecord>(
  payload: ExportListPageResourcesPayload<Rec>,
): Promise<void> => {
  exportFilteredResource(
    `cabinet/api/project/reports/${payload.reportId}/list-page-resources-ch/export`,
    payload,
  )
}

export const exportListPages = async <Rec = GetListPagesRecord>(
  payload: ExportListPagesPayload<Rec>,
): Promise<void> => {
  exportFilteredResource(
    `cabinet/api/project/reports/${payload.reportId}/list-pages-ch/export`,
    payload,
  )
}

export const exportListResources = async <Rec = GetListResourcesRecord>(
  payload: ExportListResourcesPayload<Rec>,
): Promise<void> =>
  exportFilteredResource(
    `cabinet/api/project/reports/${payload.reportId}/list-resources-ch/export`,
    payload,
  )
export const exportListSitemapUrls = async <
  Rec = GetListSitemapUrlReportRecord
>(
  payload: ExportListSitemapUrlsPayload<Rec>,
): Promise<void> => {
  exportFilteredResource(
    `cabinet/api/project/reports/${payload.reportId}/list-sitemap-urls-ch/export`,
    payload,
  )
}

export const getListPageResources = async <Rec = GetListPageResourcesRecord>(
  payload: GetListPageResourcesPayload<Rec>,
): Promise<GetListPageResourcesResponse<Rec>> => {
  const reqPayload = prepareFilteredResourceRequest<Rec>(
    `cabinet/api/project/reports/${payload.reportId}/list-page-resources-ch`,
    payload,
  )
  const res = await makeRequest(reqPayload)
  return res.json()
}

export const getListPages = async <Rec = GetListPagesRecord>(
  payload: GetListPagesPayload<Rec>,
): Promise<GetListPagesResponse<Rec>> => {
  const reqPayload = prepareFilteredResourceRequest<Rec>(
    `cabinet/api/project/reports/${payload.reportId}/list-pages-ch`,
    payload,
  )
  const res = await makeRequest(reqPayload)
  return res.json()
}

export const getListResources = async <Rec = GetListResourcesRecord>(
  payload: GetListResourcesPayload<Rec>,
): Promise<GetListResourcesResponse<Rec>> => {
  const reqPayload = prepareFilteredResourceRequest<Rec>(
    `cabinet/api/project/reports/${payload.reportId}/list-resources-ch`,
    payload,
  )
  const res = await makeRequest(reqPayload)
  return res.json()
}

export const getListSitemapUrls = async <Rec = GetListSitemapUrlReportRecord>(
  payload: GetListSitemapUrlsPayload<Rec>,
): Promise<GetListSitemapUrlsResponse<Rec>> => {
  const reqPayload = prepareFilteredResourceRequest<Rec>(
    `cabinet/api/project/reports/${payload.reportId}/list-sitemap-urls-ch`,
    payload,
  )
  const res = await makeRequest(reqPayload)
  return res.json()
}

export const getPageUrls = async (
  payload: GetPageUrlsPayload,
): Promise<GetPageUrlsResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}/page-urls-ch`,
  }

  if (payload.shareToken) {
    reqPayload.headers = {
      "Share-Token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return res.json()
}

export const getReport = async (
  payload: GetReportPayload,
): Promise<GetReportResponse> => {
  const reqPayload: MakeRequestPayload = {
    path: `cabinet/api/project/reports/${payload.reportId}`,
  }

  if (payload.shareToken) {
    reqPayload.headers = {
      "Share-Token": payload.shareToken,
    }
  }

  const res = await makeRequest(reqPayload)
  return res.json()
}

export const stopCrawling = async (
  payload: StopCrawlingPayload,
): Promise<StopCrawlingResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/reports/${payload.reportId}/crawling`,
    method: "DELETE",
  })
  return res.json()
}

const exportFilteredResource = async <Rec>(
  path: string,
  payload: ResourceFilteredRequestExport<Rec>,
): Promise<void> => {
  const { fileName, ...reqParams } = payload
  const request = prepareFilteredResourceRequest(path, reqParams)
  try {
    const res = await makeRequest(request)
    const blob = await res.blob()
    const url = URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.href = url

    let format: ResourceFilteredRequestExport<Rec>["params"]["export-format"] =
      ApiClientExportFormat.Csv
    if (reqParams.params["export-format"]) {
      format = reqParams.params["export-format"]
    }
    a.download = `${fileName}.${format}`
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove()
  } catch (error) {
    // TODO: add log
  }
}

function prepareFilteredResourceRequest<Rec>(
  path: string,
  payload: ResourceFilteredRequest<Rec>,
): MakeRequestPayload {
  const reqPayload: MakeRequestPayload = {
    path,
    params: payload.params,
  }
  if (payload.shareToken) {
    reqPayload.headers = {
      "Share-Token": payload.shareToken,
    }
  }

  return reqPayload
}
