import {
  AddFilterSitemapUrlReportAction,
  CancelFilterSitemapUrlReportAction,
  ClearFilterSitemapUrlReportAction,
  ClearSortSitemapUrlReportAction,
  ExportSitemapUrlReportAction,
  LoadSitemapUrlReportAction,
  SetPaginationSitemapUrlReportAction,
  SetSortSitemapUrlReportAction,
  SitemapUrlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/sitemapUrl"

const loadSitemapUrlReport = (
  payload: LoadSitemapUrlReportAction["payload"],
): LoadSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: SitemapUrlReportActionType.LoadPending,
    }),
    success: payload => ({
      type: SitemapUrlReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: SitemapUrlReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterSitemapUrlReport = (): ClearFilterSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.ClearFilter,
})

const clearSortSitemapUrlReport = (): ClearSortSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.ClearSort,
})

const exportSitemapUrlReport = (
  payload: ExportSitemapUrlReportAction["payload"],
): ExportSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.Export,
  payload,
})

const addFilterSitemapUrlReport = (
  payload: AddFilterSitemapUrlReportAction["payload"],
): AddFilterSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.AddFilter,
  payload,
})

const cancelFilterSitemapUrlReport = (
  payload: CancelFilterSitemapUrlReportAction["payload"],
): CancelFilterSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.CancelFilter,
  payload,
})

const setPaginationSitemapUrlReport = (
  payload: SetPaginationSitemapUrlReportAction["payload"],
): SetPaginationSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.SetPagination,
  payload,
})

const setSortSitemapUrlReport = (
  payload: SetSortSitemapUrlReportAction["payload"],
): SetSortSitemapUrlReportAction => ({
  type: SitemapUrlReportActionType.SetSort,
  payload,
})

export {
  loadSitemapUrlReport,
  clearFilterSitemapUrlReport,
  clearSortSitemapUrlReport,
  exportSitemapUrlReport,
  addFilterSitemapUrlReport,
  cancelFilterSitemapUrlReport,
  setPaginationSitemapUrlReport,
  setSortSitemapUrlReport,
}
