import { Card, ConfigProvider, Layout } from "antd"
import ruRU from "antd/lib/locale/ru_RU"
import React from "react"
import { Provider } from "react-redux"
import { Router } from "react-router-dom"
import env, { Environment } from "./lib/env"
import history from "./lib/history"
import RootRouter from "./pages/RootRouter"
import store from "./state/store"

// TODO: move this to internalization
// @ts-ignore
// eslint-disable-next-line no-template-curly-in-string
ruRU.Form.defaultValidateMessages.number.range = "Чило от ${min} до ${max}"

export function CabinetUi(): JSX.Element {
  return (
    <>
      <ConfigProvider locale={ruRU}>
        <Provider store={store}>
          <Layout style={{ background: "#f0f2f5" }}>
            <Router history={history}>
              <RootRouter />
            </Router>
          </Layout>
          {env.ENVIRONMENT !== Environment.Prod && <AppInformation />}
        </Provider>
      </ConfigProvider>
      {env.ENVIRONMENT !== Environment.Local && <YandexWebVisor />}
    </>
  )
}

const YandexWebVisor: React.FC = () => {
  React.useEffect(() => {
    // !Warning: This code gave yandex please not use linter and formatter for this  code

    // eslint-disable-next-line
    ;(function (m, e, t, r, i, k, a) {
      // @ts-ignore
      // prettier-ignore
      // eslint-disable-next-line
      m[i] = m[i] || function() {
        // @ts-ignore
        (m[i].a = m[i].a || []).push(arguments);
      };
      // @ts-ignore
      m[i].l = 1 * new Date()
      // @ts-ignore
      // prettier-ignore
      // eslint-disable-next-line
      ;(k = e.createElement(t)), (a = e.getElementsByTagName(t)[0]), (k.async = 1), (k.src = r), a.parentNode.insertBefore(k, a)
      // prettier-ignore
    })(window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym")
    // @ts-ignore
    ym(73101391, "init", {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    })
  }, [])

  return (
    <noscript>
      <div>
        <img
          src="https://mc.yandex.ru/watch/73101391"
          style={{ position: "absolute", left: "-9999px" }}
          alt=""
        />
      </div>
    </noscript>
  )
}

const AppInformation: React.FC = React.memo(() => (
  <Card
    size="small"
    bordered={false}
    title={`${env.ENVIRONMENT} / ${env.VERSION}`}
    className="app-information app-information_visibility"
    hoverable
    style={{
      marginRight: 100,
    }}
  />
))
