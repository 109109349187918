export enum ResourceIndexStatus {
  Index = 1,
  RobotsIgnore = 2,
  NotCanonical = 3,
  MetaRobotsNoindex = 4,
}

export enum CrawlReportResourceType {
  Other = 0,
  Html = 1,
  Style = 2,
  Script = 3,
  Archive = 4,
  Document = 5,
  Video = 6,
  Audio = 7,
  Image = 8,
  Text = 9,
  Executable = 10,
  Font = 11,
}

export interface ResourceRedirect {
  status: number
  to_url: string
}
