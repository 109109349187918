import {
  AddFilterNotHtmlGroupReportAction,
  CancelFilterNotHtmlGroupReportAction,
  ClearFilterNotHtmlGroupReportAction,
  ClearSortNotHtmlGroupReportAction,
  ExportNotHtmlGroupReportAction,
  LoadNotHtmlGroupReportAction,
  NotHtmlGroupReportActionType,
  SetPaginationNotHtmlGroupReportAction,
  SetSortNotHtmlGroupReportAction,
} from "../../../../@types/actions/remote/cabinet/report/notHtmlGroup"

const loadNotHtmlGroupReport = (
  payload: LoadNotHtmlGroupReportAction["payload"],
): LoadNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: NotHtmlGroupReportActionType.LoadPending,
    }),
    success: payload => ({
      type: NotHtmlGroupReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: NotHtmlGroupReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterNotHtmlGroupReport = (): ClearFilterNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.ClearFilter,
})

const clearSortNotHtmlGroupReport = (): ClearSortNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.ClearSort,
})

const exportNotHtmlGroupReport = (
  payload: ExportNotHtmlGroupReportAction["payload"],
): ExportNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.Export,
  payload,
})

const addFilterNotHtmlGroupReport = (
  payload: AddFilterNotHtmlGroupReportAction["payload"],
): AddFilterNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.AddFilter,
  payload,
})

const cancelFilterNotHtmlGroupReport = (
  payload: CancelFilterNotHtmlGroupReportAction["payload"],
): CancelFilterNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.CancelFilter,
  payload,
})

const setPaginationNotHtmlGroupReport = (
  payload: SetPaginationNotHtmlGroupReportAction["payload"],
): SetPaginationNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.SetPagination,
  payload,
})

const setSortNotHtmlGroupReport = (
  payload: SetSortNotHtmlGroupReportAction["payload"],
): SetSortNotHtmlGroupReportAction => ({
  type: NotHtmlGroupReportActionType.SetSort,
  payload,
})

export {
  loadNotHtmlGroupReport,
  clearFilterNotHtmlGroupReport,
  clearSortNotHtmlGroupReport,
  exportNotHtmlGroupReport,
  addFilterNotHtmlGroupReport,
  cancelFilterNotHtmlGroupReport,
  setPaginationNotHtmlGroupReport,
  setSortNotHtmlGroupReport,
}
