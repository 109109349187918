import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  ValidateSitemapResponse,
} from "../../../../services/apiClient"
import {
  HostTypes,
  ValidateSitemapAction,
} from "../../../@types/actions/remote/cabinet/host"

function* fetchValidateSitemap(action: ValidateSitemapAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: ValidateSitemapResponse = yield call(
      apiClient.cabinet.host.validateSitemap,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getValidateSitemapSaga(): Generator {
  yield takeEvery(HostTypes.ValidateSitemap, fetchValidateSitemap)
}
