import {
  GetIndexStatusGraphPayload,
  GetIndexStatusGraphResponse,
} from "../../../../../../services/apiClient"
import { LoadAction } from "../../../filteredResourceAction"

export enum IndexStatusGraphActionType {
  Load = "@@REPORTS/INDEX_STATUS/GRAPH/LOAD",
  LoadSuccess = "@@REPORTS/INDEX_STATUS/GRAPH/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/INDEX_STATUS/GRAPH/LOAD_PENDING",
  LoadFailure = "@@REPORTS/INDEX_STATUS/GRAPH/LOAD_FAILURE",
}

export type LoadIndexStatusGraphAction = LoadAction<
  GetIndexStatusGraphPayload,
  GetIndexStatusGraphResponse,
  IndexStatusGraphActionType.Load,
  IndexStatusGraphActionType.LoadPending,
  IndexStatusGraphActionType.LoadSuccess,
  IndexStatusGraphActionType.LoadFailure
>
export type LoadPendingIndexStatusGraphAction = ReturnType<
  LoadIndexStatusGraphAction["meta"]["pending"]
>
export type LoadSuccessIndexStatusGraphAction = ReturnType<
  LoadIndexStatusGraphAction["meta"]["success"]
>
export type LoadFailureIndexStatusGraphAction = ReturnType<
  LoadIndexStatusGraphAction["meta"]["failure"]
>
