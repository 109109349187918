import React from "react"
import { useDispatch } from "react-redux"
import {
  InputSearchByFieldsOnClear,
  InputSearchByFieldsOnSearch,
} from "../components/InputSearchByFields"
import { ApiClientFilter, ApiClientFilterType } from "../services/apiClient"

type UseInputSearchByFields<Rec> = {
  onSearch: InputSearchByFieldsOnSearch<Rec>
  onClear: InputSearchByFieldsOnClear<Rec>
}

const useInputSearchByFields = <Rec>(
  afterOnSearch: () => void,
  afterOnClear: () => void,
  addFilterAction: (filter: ApiClientFilter<Rec>) => void,
  cancelFilterAction: (filter: ApiClientFilter<Rec>) => void,
): UseInputSearchByFields<Rec> => {
  const dispatch = useDispatch()

  const onSearch: InputSearchByFieldsOnSearch<Rec> = React.useCallback(
    (value, selectedFields, allFields) => {
      // remove all fields from filter
      clear(allFields)

      const filters: ApiClientFilter<Rec> = {}
      selectedFields.forEach(
        field => (filters[field] = [[ApiClientFilterType.Like, value]]),
      )
      if (value.length) {
        dispatch(addFilterAction(filters))
      } else {
        dispatch(cancelFilterAction(filters))
      }

      afterOnSearch()
    },
    [addFilterAction, cancelFilterAction],
  )

  const clear = (fields: (keyof Rec)[]) => {
    const filters: ApiClientFilter<Rec> = {}
    fields.forEach(field => (filters[field] = [[ApiClientFilterType.Like, ""]]))
    dispatch(cancelFilterAction(filters))
  }

  const onClear = React.useCallback(
    (fields: (keyof Rec)[]) => {
      clear(fields)
      afterOnClear()
    },
    [cancelFilterAction],
  )

  return {
    onSearch,
    onClear,
  }
}

export default useInputSearchByFields
