import React from "react"
import HeadingReport from "../../../components/Report/HeadingReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportHeadingReport } from "../../../state/actions/remote/cabinet/report/heading"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.Headings)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const HeadingsPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportHeadingReport,
  })

  return <HeadingReport shareToken={shareToken} reportId={reportId} />
}

export default HeadingsPage
