import {
  AddFilterImageReportAction,
  CancelFilterImageReportAction,
  ClearFilterImageReportAction,
  ClearSortImageReportAction,
  ExportImageReportAction,
  ImageReportActionType,
  LoadImageReportAction,
  SetPaginationImageReportAction,
  SetSortImageReportAction,
} from "../../../../@types/actions/remote/cabinet/report/image"

const loadImageReport = (
  payload: LoadImageReportAction["payload"],
): LoadImageReportAction => ({
  type: ImageReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: ImageReportActionType.LoadPending,
    }),
    success: payload => ({
      type: ImageReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: ImageReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterImageReport = (): ClearFilterImageReportAction => ({
  type: ImageReportActionType.ClearFilter,
})

const clearSortImageReport = (): ClearSortImageReportAction => ({
  type: ImageReportActionType.ClearSort,
})

const exportImageReport = (
  payload: ExportImageReportAction["payload"],
): ExportImageReportAction => ({
  type: ImageReportActionType.Export,
  payload,
})

const addFilterImageReport = (
  payload: AddFilterImageReportAction["payload"],
): AddFilterImageReportAction => ({
  type: ImageReportActionType.AddFilter,
  payload,
})

const cancelFilterImageReport = (
  payload: CancelFilterImageReportAction["payload"],
): CancelFilterImageReportAction => ({
  type: ImageReportActionType.CancelFilter,
  payload,
})

const setPaginationImageReport = (
  payload: SetPaginationImageReportAction["payload"],
): SetPaginationImageReportAction => ({
  type: ImageReportActionType.SetPagination,
  payload,
})

const setSortImageReport = (
  payload: SetSortImageReportAction["payload"],
): SetSortImageReportAction => ({
  type: ImageReportActionType.SetSort,
  payload,
})

export {
  loadImageReport,
  clearFilterImageReport,
  clearSortImageReport,
  exportImageReport,
  addFilterImageReport,
  cancelFilterImageReport,
  setPaginationImageReport,
  setSortImageReport,
}
