import {
  ApiClientPagination,
  ApiClientSort,
  ApiResponseResourceFiltered,
  ApiResponseWithData,
  ResourceFilteredRequest,
} from "../../services/apiClient"
import { cancelFilters, mergeFilters } from "../../services/filters/utils"
import { GraphData } from "../../types/graph"
import { ActionWithPayload } from "../@types/actions/action"
import {
  AddFilterAction,
  CancelFilterAction,
  LoadAction,
} from "../@types/actions/filteredResourceAction"
import { StateRemoteData, StateResourceFiltered } from "../@types/state"

const updateStateSetSort = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
  action: ActionWithPayload<ApiClientSort<Rec>, string>,
): StateResourceFiltered<Rec, Fields> => {
  const result: StateResourceFiltered<Rec, Fields> = {
    ...state,
    sort: action.payload,
  }

  delete result.pagination

  return result
}

const updateStateSetPending = <Data, State extends StateRemoteData<Data>>(
  state: State,
): State => {
  const result: State = {
    ...state,
    isLoading: true,
  }

  delete result.error

  return result
}

const updateStateSetPagination = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
  action: ActionWithPayload<ApiClientPagination, string>,
): StateResourceFiltered<Rec, Fields> => ({
  ...state,
  pagination: {
    ...state.pagination,
    ...action.payload,
  },
})

const updateStateAddFilter = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
  action: AddFilterAction<Rec, string>,
): StateResourceFiltered<Rec, Fields> => {
  const filters = [action.payload]
  if (state.filter) {
    filters.unshift(state.filter)
  }
  const result = {
    ...state,
    filter: mergeFilters(...filters),
  }

  delete result.pagination

  return result
}

const updateStateCancelFilter = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
  action: CancelFilterAction<Rec, string>,
): StateResourceFiltered<Rec, Fields> => {
  const result = {
    ...state,
  }

  delete result.pagination

  if (!result.filter) {
    return result
  }

  result.filter = cancelFilters(result.filter, action.payload)
  if (!Object.keys(result.filter).length) {
    delete result.filter
  }

  return result
}

const updateStateLoadListSuccess = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
  action: ReturnType<
    LoadAction<
      ResourceFilteredRequest<Rec>,
      ApiResponseResourceFiltered<Rec>,
      string,
      string,
      string,
      string
    >["meta"]["success"]
  >,
): StateResourceFiltered<Rec, Fields> => ({
  ...state,
  isLoading: false,
  data: action.payload.data,
  pagination: {
    page: action.payload.meta.pagination.page,
    "per-page": action.payload.meta.pagination.per_page,
    total: action.payload.meta.pagination.total_count,
  },
})

const updateStateLoadGraphSuccess = <State>(
  state: State,
  action: ActionWithPayload<ApiResponseWithData<GraphData>, string>,
): State => ({
  ...state,
  data: action.payload,
  isLoading: false,
})

const updateStateLoadFailure = <Rec, State = StateRemoteData<Rec>>(
  state: State,
  action: ActionWithPayload<Error, string>,
): State => ({
  ...state,
  isLoading: false,
  error: action.payload,
})

const updateStateClearSort = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
): StateResourceFiltered<Rec, Fields> => {
  const result = {
    ...state,
  }

  delete result.pagination
  delete result.sort

  return result
}

const updateStateClearFilter = <Rec, Fields>(
  state: StateResourceFiltered<Rec, Fields>,
): StateResourceFiltered<Rec, Fields> => {
  const result = {
    ...state,
  }

  delete result.filter
  delete result.pagination

  return result
}

export {
  updateStateSetSort,
  updateStateSetPending,
  updateStateSetPagination,
  updateStateAddFilter,
  updateStateLoadListSuccess,
  updateStateLoadGraphSuccess,
  updateStateLoadFailure,
  updateStateClearSort,
  updateStateClearFilter,
  updateStateCancelFilter,
}
