import React from "react"
import NotHtmlReport from "../../../../components/Report/NotHtmlReport"
import findMenuItems from "../../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../../hooks/useReportPage"
import { exportNotHtmlReport } from "../../../../state/actions/remote/cabinet/report/notHtml"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.NotHtmlFull)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const NotHtmlFullPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportNotHtmlReport,
  })

  return <NotHtmlReport reportId={reportId} shareToken={shareToken} />
}

export default NotHtmlFullPage
