import { SitemapUrlReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/sitemapUrlReducer"
import { addFilterSitemapUrlReport } from "../../../state/actions/remote/cabinet/report/sitemapUrl"
import { ResourceIndexStatus } from "../../../types/reportResource"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<SitemapUrlReportRecord>[] = [
  {
    name: "Не 200 OK",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterSitemapUrlReport({
          resource_status_code: [[ApiClientFilterType.Ne, 200]],
        }),
      },
    ],
  },
  {
    name: "Неиндексируемые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterSitemapUrlReport({
          resource_index_status: [
            [ApiClientFilterType.Ne, ResourceIndexStatus.Index],
          ],
        }),
      },
    ],
  },
  {
    name: "Неканонические",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterSitemapUrlReport({
          page_is_canonical: [[ApiClientFilterType.Eq, false]],
        }),
      },
    ],
  },
  {
    name: "Осиротевшие",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterSitemapUrlReport({
          resource_without_link: [[ApiClientFilterType.Eq, true]],
          resource_status_code: [[ApiClientFilterType.Eq, 200]],
        }),
      },
    ],
  },
]

export default filters
