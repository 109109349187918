import {
  GetRedirectsGraphPayload,
  GetRedirectsGraphResponse,
} from "../../../../../../services/apiClient"
import { LoadAction } from "../../../filteredResourceAction"

export enum RedirectsGraphActionType {
  Load = "@@REPORTS/REDIRECTS/GRAPH/LOAD",
  LoadSuccess = "@@REPORTS/REDIRECTS/GRAPH/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/REDIRECTS/GRAPH/LOAD_PENDING",
  LoadFailure = "@@REPORTS/REDIRECTS/GRAPH/LOAD_FAILURE",
}

export type LoadRedirectsGraphAction = LoadAction<
  GetRedirectsGraphPayload,
  GetRedirectsGraphResponse,
  RedirectsGraphActionType.Load,
  RedirectsGraphActionType.LoadPending,
  RedirectsGraphActionType.LoadSuccess,
  RedirectsGraphActionType.LoadFailure
>
export type LoadPendingRedirectsGraphAction = ReturnType<
  LoadRedirectsGraphAction["meta"]["pending"]
>
export type LoadSuccessRedirectsGraphAction = ReturnType<
  LoadRedirectsGraphAction["meta"]["success"]
>
export type LoadFailureRedirectsGraphAction = ReturnType<
  LoadRedirectsGraphAction["meta"]["failure"]
>
