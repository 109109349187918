import { Button, Form, Input, Select } from "antd"
import React from "react"
import { SearchOutlined } from "@ant-design/icons"
import {
  ApiClientFilter,
  ApiClientFilterRules,
  ApiClientFilterType,
} from "../services/apiClient"

type FieldData = {
  name: string | number | (string | number)[]
  value?: string
  touched?: boolean
  validating?: boolean
  errors?: string[]
}

export type InputSearchByFieldsOnSearch<Rec> = (
  value: string,
  selectedFields: (keyof Rec)[],
  allFields: (keyof Rec)[],
) => void

export type InputSearchByFieldsOnClear<Rec> = (fields: (keyof Rec)[]) => void

export type InputSearchByFieldsValueType<Rec> = {
  label: string
  value: keyof Rec
}

type Props<Rec> = {
  onSearch: InputSearchByFieldsOnSearch<Rec>
  onClear?: InputSearchByFieldsOnClear<Rec>
  options: InputSearchByFieldsValueType<Rec>[]
  activeFilter?: ApiClientFilter<Rec>
}

const InputSearchByFields = <Rec,>(
  props: React.PropsWithChildren<Props<Rec>>,
): React.ReactElement => {
  const { activeFilter, options, onClear, onSearch } = props

  const initialValue = React.useMemo(() => {
    if (!activeFilter) {
      return ""
    }

    for (const option of options) {
      const filterRules: ApiClientFilterRules | undefined =
        activeFilter[option.value]
      if (!filterRules) {
        continue
      }

      const [[type, value]] = filterRules
      if (type === ApiClientFilterType.Like) {
        return value
      }
    }

    return ""
  }, [activeFilter])

  const [fields, setFields] = React.useState<(keyof Rec)[]>(() => {
    if (!activeFilter) {
      return []
    }

    const result = []
    for (const option of options) {
      const filterRules: ApiClientFilterRules | undefined =
        activeFilter[option.value]
      if (!filterRules) {
        continue
      }

      const [[type]] = filterRules
      if (type === ApiClientFilterType.Like) {
        result.push(option.value)
      }
    }

    const _fields = result.length === Object.keys(options).length ? [] : result

    return _fields
  })

  const getSelectedFields = () => {
    if (fields.length) {
      return fields
    }

    return getFields()
  }

  const getFields = () => options.map(option => option.value)

  const onInputSearch = (value: string) => {
    onSearch(value, getSelectedFields(), getFields())
  }

  const onChangeSelect = (value: string[]) => {
    setFields(value as (keyof Rec)[])
  }

  const onFinish = (values: { text: string }) => {
    onInputSearch(values.text)
  }

  const onFieldsChange = (changedFields: FieldData[]) => {
    // preset button clear input
    if (
      changedFields.length &&
      typeof changedFields[0].value === "string" &&
      !changedFields[0].value.length
    ) {
      onClear && onClear(getFields())
    }
  }

  return (
    <Form
      onFinish={onFinish}
      onFieldsChange={onFieldsChange}
      initialValues={{
        text: initialValue,
      }}
    >
      <Form.Item noStyle>
        <Input.Group>
          <Form.Item name="text" noStyle>
            <Input
              allowClear
              placeholder="Текст"
              style={{ width: `calc(50% - 55px)` }}
            />
          </Form.Item>
          <Select
            onChange={onChangeSelect}
            value={fields as string[]}
            defaultValue={fields as string[]}
            placeholder="Все поля"
            mode="tags"
            maxTagCount="responsive"
            style={{ width: `calc(50% - 55px)` }}
          >
            {options.map(option => (
              <Select.Option
                key={option.value as string}
                value={option.value as string}
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
          <Button
            icon={<SearchOutlined />}
            type="primary"
            style={{ width: `110px` }}
            htmlType="submit"
          >
            Искать
          </Button>
        </Input.Group>
      </Form.Item>
    </Form>
  )
}

export default React.memo(InputSearchByFields) as typeof InputSearchByFields
