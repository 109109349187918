import {
  Alert,
  Avatar,
  Button,
  Card,
  CardProps,
  Checkbox,
  CheckboxOptionType,
  Col,
  Descriptions,
  Empty,
  List,
  Row,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from "antd"
import { CheckboxGroupProps } from "antd/lib/checkbox"
import { CheckboxValueType } from "antd/lib/checkbox/Group"
import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import {
  ArrowRightOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  FileOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  TagOutlined,
  WarningOutlined,
} from "@ant-design/icons"
import { useDisplayFormat } from "../../hooks/useDisplayFormat"
import routes from "../../pages/routes"
import formatters from "../../services/formatters"
import reportService, {
  Report,
  ReportPayloadResultProblems,
  ReportProblemAlias,
  ReportProblemCategory,
  ReportProblemType,
  ReportSettingAlias,
  ReportStatus,
} from "../../services/reportService"
import { getAuth } from "../../state/selectors/remote/auth"
import { UserRole } from "../../types/user"
import AdminOnly from "../AdminOnly"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import DisplayFormat from "../DisplayFormat"
import ReportSettingInformationByAlias from "../ReportSettingInformationByAlias"
import RobotstxtGraph from "../RobotstxtGraph"
import SitemapUrlsGraph from "../SitemapUrlsGraph"
import Statistics from "../Statistic"
import StatusCodesGraph from "../StatusCodesGraph"

const SHOW_ALL_PROBLEMS = "all"
const SHOW_SUCCESS_PROBLEMS = "success"

type Props = {
  projectAlias?: string
  shareToken?: string
  report: Report
}

const DashboardReport: React.FC<Props> = React.memo(props => {
  const { projectAlias, report, shareToken } = props

  const [activeTabKey, setActiveTabKey] = React.useState(SHOW_ALL_PROBLEMS)

  const auth = useSelector(getAuth)

  const handlerChangeActiveTabKey = (tabKey: string): void =>
    setActiveTabKey(tabKey)

  const handlerGoToSizeOptimization = (tabKey: string): void => {
    handlerChangeActiveTabKey(tabKey)
    const elem = document.querySelector("#site-optimization")
    if (elem) {
      elem.scrollIntoView({ behavior: "smooth" })
    }
  }

  return (
    <>
      {report.status === ReportStatus.Error && (
        <Row gutter={[8, 16]}>
          <Col xs={12}>
            <Alert
              message="Во время создания отчета произошла ошибка"
              type="error"
            />
          </Col>
        </Row>
      )}
      <Row gutter={[8, 16]}>
        <Col lg={3} md={6} xs={12}>
          <CardUrlStatistics
            reportAlias={report.alias}
            projectAlias={projectAlias}
            urlCount={report.payload.result?.stats?.pages ?? 0}
          />
        </Col>
        <Col lg={3} md={6} xs={12}>
          <Card>
            <Statistics
              prefix={
                <Typography.Text style={{ fontSize: 24 }}>
                  <LinkOutlined />
                </Typography.Text>
              }
              title="URLS"
              value={formatters.number.shortFormat(
                report.payload.result?.stats?.urls ?? 0,
              )}
            />
          </Card>
        </Col>
        <Col lg={3} md={6} xs={12}>
          <Card
            hoverable
            onClick={(): void =>
              handlerGoToSizeOptimization(ReportProblemType.Error)
            }
          >
            <Statistics
              prefix={
                <Typography.Text style={{ fontSize: 24 }} type="danger">
                  <CloseCircleOutlined />
                </Typography.Text>
              }
              title="ОШИБКИ"
              value={formatters.number.shortFormat(
                report.payload.result?.stats?.errors ?? 0,
              )}
              suffix={
                projectAlias && (
                  <Button type="link" size="small">
                    <ArrowRightOutlined />
                  </Button>
                )
              }
            />
          </Card>
        </Col>
        <Col lg={3} md={6} xs={12}>
          <Card
            hoverable
            onClick={(): void =>
              handlerGoToSizeOptimization(ReportProblemType.Warning)
            }
          >
            <Statistics
              prefix={
                <Typography.Text style={{ fontSize: 24 }} type="warning">
                  <WarningOutlined />
                </Typography.Text>
              }
              title="ПРЕДУПРЕЖДЕНИЯ"
              value={formatters.number.shortFormat(
                report.payload.result?.stats?.warnings ?? 0,
              )}
              suffix={
                projectAlias && (
                  <Button type="link" size="small">
                    <ArrowRightOutlined />
                  </Button>
                )
              }
            />
          </Card>
        </Col>
        <Col lg={4} md={6} xs={12}>
          <Card
            title="Анализ robots.txt"
            style={{
              height: "100%",
            }}
            bodyStyle={{
              height: "calc(100% - (12px * 2))",
              display: "flex",
              flexDirection: "column",
            }}
            size="small"
          >
            <RobotstxtGraph shareToken={shareToken} reportId={report.id} />
          </Card>
        </Col>
        <Col lg={4} md={6} xs={12}>
          <Card
            title="Анализ sitemap.xml"
            style={{
              height: "100%",
            }}
            bodyStyle={{
              height: "calc(100% - (12px * 2))",
              display: "flex",
              flexDirection: "column",
            }}
            size="small"
          >
            <SitemapUrlsGraph shareToken={shareToken} reportId={report.id} />
          </Card>
        </Col>
        <Col lg={4} md={6} xs={12}>
          <Card
            title="Коды ответа сервера"
            style={{
              height: "100%",
            }}
            bodyStyle={{
              height: "calc(100% - (12px * 2))",
              display: "flex",
              flexDirection: "column",
            }}
            size="small"
          >
            <StatusCodesGraph shareToken={shareToken} reportId={report.id} />
          </Card>
        </Col>
        {report.payload.result?.problems && (
          <Col xs={12}>
            <CardReportProblems
              onChangeActiveTabKey={handlerChangeActiveTabKey}
              activeTabKey={activeTabKey}
              report={report}
              projectAlias={projectAlias}
            />
          </Col>
        )}
        <Col xs={12}>
          <ReportConfig report={report} isAdmin={false} />
        </Col>
        {auth.data?.user.role === UserRole.Admin && (
          <>
            <Col xs={12}>
              <ReportConfig report={report} isAdmin={true} />
            </Col>
            {report.payload.status && (
              <Col xs={12}>
                <CardWithoutBodyPadding
                  title={
                    <>
                      Статус отчета <AdminOnly key="report-status-admin-only" />
                    </>
                  }
                  size="small"
                >
                  <Descriptions size="small" bordered column={2}>
                    {Object.entries(report.payload.status).map(
                      ([key, value]) => {
                        let result: string
                        if (typeof value === "boolean") {
                          result = value ? "true" : "false"
                        } else if (Array.isArray(value)) {
                          result = value.join(", ")
                        } else if (value === null) {
                          result = "null"
                        } else {
                          result = (value as string).toString()
                        }

                        return (
                          <Descriptions.Item key={key} label={key}>
                            {key === "speed"
                              ? parseFloat(result).toFixed(1)
                              : result}
                          </Descriptions.Item>
                        )
                      },
                    )}
                    <Descriptions.Item label="Время краулинга (мин)">
                      {formatters.date.msToMin(
                        new Date(
                          report.payload.status.crawl_finished_at,
                        ).getTime() -
                          new Date(report.payload.status.started_at).getTime(),
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Общее время (мин)">
                      {formatters.date.msToMin(
                        new Date(report.payload.status.finished_at).getTime() -
                          new Date(report.payload.status.started_at).getTime(),
                      )}
                    </Descriptions.Item>
                  </Descriptions>
                </CardWithoutBodyPadding>
              </Col>
            )}
          </>
        )}
      </Row>
    </>
  )
})

export default DashboardReport

type CardUrlStatisticsProps = {
  reportAlias: string
  projectAlias?: string
  urlCount?: number
}

const CardUrlStatistics: React.FC<CardUrlStatisticsProps> = props => {
  const { reportAlias, projectAlias, urlCount } = props

  const statistic = (
    <Statistics
      prefix={
        <Typography.Text style={{ fontSize: 24 }}>
          <FileOutlined />
        </Typography.Text>
      }
      title="СТРАНИЦЫ"
      value={formatters.number.shortFormat(urlCount ?? 0)}
      suffix={
        projectAlias && (
          <Button
            type="link"
            size="small"
            style={{ marginLeft: 4, padding: 0 }}
          >
            <ArrowRightOutlined />
          </Button>
        )
      }
    />
  )

  if (!projectAlias) {
    return <Card>{statistic}</Card>
  }

  return (
    <CardWithoutBodyPadding hoverable>
      <Link
        style={{ padding: 24, display: "block", width: "100%" }}
        to={routes
          .site()
          .alias(projectAlias)
          .reportAlias(reportAlias)
          .structure()
          .urls()}
      >
        {statistic}
      </Link>
    </CardWithoutBodyPadding>
  )
}

type CardReportProblemsProps = {
  report: Report
  projectAlias?: string
  activeTabKey: string
  onChangeActiveTabKey: (tabKey: string) => void
}

const options: CheckboxOptionType[] = [
  {
    label: "Индексация",
    value: ReportProblemCategory.Indexing,
  },
  {
    label: "Контент",
    value: ReportProblemCategory.Content,
  },
  {
    label: "Ссылки",
    value: ReportProblemCategory.Link,
  },
]

const CardReportProblems: React.FC<CardReportProblemsProps> = React.memo(
  (props): JSX.Element | null => {
    const { activeTabKey, report, projectAlias, onChangeActiveTabKey } = props

    const [selectedCategory, setSelectedCategory] = React.useState<
      CheckboxValueType[]
    >(Object.values(ReportProblemCategory))

    const [displayFormat, handlerChangeDisplayFormat] = useDisplayFormat()

    const existingProblemsAlias = React.useMemo<ReportProblemAlias[]>(() => {
      const aliases: ReportProblemAlias[] = []

      if (activeTabKey !== SHOW_SUCCESS_PROBLEMS) {
        for (const alias in report.payload.result?.problems) {
          if (reportService.problems.default[alias as ReportProblemAlias]) {
            aliases.push(alias as ReportProblemAlias)
          }
        }

        return aliases
      }

      for (const alias in reportService.problems.default) {
        if (
          report.payload.result?.problems &&
          !report.payload.result?.problems[alias as ReportProblemAlias]
        ) {
          aliases.push(alias as ReportProblemAlias)
        }
      }

      return aliases
    }, [report.payload.result?.problems, activeTabKey])

    const activeProblemsAlias = React.useMemo<ReportProblemAlias[]>(() => {
      const aliases = []
      for (const problemAlias of existingProblemsAlias) {
        const problem = reportService.problems.default[problemAlias]
        if (
          activeTabKey !== SHOW_ALL_PROBLEMS &&
          problem.type !== activeTabKey &&
          activeTabKey !== SHOW_SUCCESS_PROBLEMS
        ) {
          continue
        }

        if (
          !problem.categories.find(category =>
            selectedCategory.includes(category),
          )
        ) {
          continue
        }

        aliases.push(problemAlias)
      }

      return aliases
    }, [existingProblemsAlias, activeTabKey, selectedCategory])

    const counter = React.useMemo((): {
      [key in "error" | "warning" | "success"]: number
    } => {
      const result = {
        error: 0,
        warning: 0,
        success: 0,
      }

      if (!existingProblemsAlias.length) {
        return result
      }

      for (const alias in reportService.problems.default) {
        const problem =
          reportService.problems.default[alias as ReportProblemAlias]
        if (
          !problem.categories.find(category =>
            selectedCategory.includes(category),
          )
        ) {
          continue
        }

        const value = (report.payload.result?.problems as NonNullable<
          ReportPayloadResultProblems
        >)[alias as ReportProblemAlias]
        if (value === undefined) {
          result.success += 1
          continue
        }

        switch (problem.type) {
          case ReportProblemType.Warning:
            result.warning += typeof value === "boolean" ? 1 : (value as number)
            continue
          case ReportProblemType.Error:
            result.error += typeof value === "boolean" ? 1 : (value as number)
            continue
        }
      }

      return result
    }, [report.payload.result?.problems, selectedCategory])

    const tabList = React.useMemo(
      () => [
        {
          key: SHOW_ALL_PROBLEMS,
          tab: `Все ${counter.error + counter.warning}`,
        },
        {
          key: ReportProblemType.Error,
          tab: `Ошибки ${counter.error}`,
        },
        {
          key: ReportProblemType.Warning,
          tab: `Предупреждения ${counter.warning}`,
        },
        {
          key: SHOW_SUCCESS_PROBLEMS,
          tab: `Успешно ${counter.success}`,
        },
      ],
      [counter],
    )

    if (
      !report.payload?.result?.problems ||
      !Object.keys(report.payload?.result?.problems).length
    ) {
      return null
    }

    const problemValueFormatter = (
      alias: ReportProblemAlias,
      value: number | boolean | undefined,
    ): string => {
      if (typeof value === "undefined") {
        return "Выполнено"
      }

      if (
        [ReportProblemAlias.NoRobotsTXT, ReportProblemAlias.NoSitemap].includes(
          alias,
        )
      ) {
        return "Не найден"
      }

      if (alias === ReportProblemAlias.NoSitemapInRobotsTXT) {
        return "Не указан в robots.txt"
      }

      if (typeof value === "number") {
        return formatters.number.format(value).toString()
      }

      if (typeof value === "boolean") {
        return "Да"
      }

      return ""
    }

    const tabChange: CardProps["onTabChange"] = (key: string) => {
      onChangeActiveTabKey(key)
    }

    const checkboxChange: CheckboxGroupProps["onChange"] = (
      selectedOptions: CheckboxValueType[],
    ) => {
      setSelectedCategory(selectedOptions)
    }

    return (
      <Card
        id="site-optimization"
        title="Оптимизация сайта"
        activeTabKey={activeTabKey}
        className={`${displayFormat === "card" ? "ant-card-contain-grid" : ""}`}
        tabList={tabList}
        onTabChange={tabChange}
        extra={
          <Space direction="vertical">
            <Checkbox.Group
              defaultValue={selectedCategory}
              options={options}
              onChange={checkboxChange}
            />
            <DisplayFormat
              format={displayFormat}
              onChange={handlerChangeDisplayFormat}
            />
          </Space>
        }
      >
        {activeProblemsAlias.length === 0 ? (
          <Empty />
        ) : displayFormat === "list" ? (
          <ReportProblemsList
            problemValueFormatter={problemValueFormatter}
            activeProblemsAlias={activeProblemsAlias}
            report={report}
            projectAlias={projectAlias}
          />
        ) : (
          activeProblemsAlias.map(alias => {
            const problem = reportService.problems.default[alias]
            const value = (report.payload.result?.problems as NonNullable<
              ReportPayloadResultProblems
            >)[alias]

            const cardContent = (
              <>
                <Statistic
                  value={problemValueFormatter(alias, value)}
                  prefix={
                    <ReportProblemIcon
                      value={value}
                      problemType={problem.type}
                    />
                  }
                  title={
                    <Typography.Text
                      strong
                      style={{ fontSize: 14, lineHeight: 1 }}
                    >
                      <Tooltip title={problem.description}>
                        <QuestionCircleOutlined style={{ marginRight: 4 }} />
                      </Tooltip>
                      {problem.name}
                    </Typography.Text>
                  }
                  suffix={
                    projectAlias &&
                    problem.route && (
                      <Button
                        type="link"
                        size="small"
                        style={{ marginLeft: 4, padding: 0 }}
                      >
                        <ArrowRightOutlined />
                      </Button>
                    )
                  }
                />
                <Typography.Text type="secondary">
                  <TagOutlined /> {problem.categories.join(", ")}
                </Typography.Text>
              </>
            )

            const style: React.CSSProperties = {
              minHeight: 156,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }

            if (projectAlias && problem.route) {
              const to = problem.route({
                alias: projectAlias,
                reportAlias: report.alias,
              })
              return (
                <Link
                  key={to}
                  className="ant-card-grid ant-card-grid-hoverable"
                  style={style}
                  to={to}
                >
                  {cardContent}
                </Link>
              )
            }

            return (
              <Card.Grid
                key={`card-problem-${alias}`}
                hoverable={false}
                style={style}
              >
                {cardContent}
              </Card.Grid>
            )
          })
        )}
      </Card>
    )
  },
)

type ReportProblemsListProps = {
  projectAlias?: string
  report: Report
  activeProblemsAlias: ReportProblemAlias[]
  problemValueFormatter: (
    alias: ReportProblemAlias,
    value: number | boolean,
  ) => string
}

const ReportProblemsList: React.FC<ReportProblemsListProps> = props => {
  const {
    report,
    projectAlias,
    activeProblemsAlias,
    problemValueFormatter,
  } = props

  return (
    <List
      itemLayout="vertical"
      size="small"
      dataSource={activeProblemsAlias}
      className="problems"
      renderItem={(alias): JSX.Element => {
        const problem = reportService.problems.default[alias]
        const value = (report.payload.result?.problems as NonNullable<
          ReportPayloadResultProblems
        >)[alias]

        return (
          <List.Item
            actions={[
              <>
                <TagOutlined /> {problem.categories.join(", ")}
              </>,
            ]}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  src={
                    <ReportProblemIcon
                      problemType={problem.type}
                      value={value}
                    />
                  }
                />
              }
              title={
                <>
                  {problem.name}{" "}
                  <Typography.Text
                    style={{ display: "inline-flex" }}
                    type="secondary"
                  >
                    {value !== undefined && problemValueFormatter(alias, value)}
                  </Typography.Text>
                </>
              }
              description={
                projectAlias &&
                problem.route && (
                  <Button type="link" size="small" style={{ padding: 0 }}>
                    <Link
                      to={`${problem.route({
                        alias: projectAlias,
                        reportAlias: report.alias,
                      })}`}
                    >
                      Перейти к отчету
                    </Link>
                  </Button>
                )
              }
            />
            <Typography.Paragraph className="problem-description">
              {problem.description}
            </Typography.Paragraph>
          </List.Item>
        )
      }}
    />
  )
}

type ReportProblemIconProps = {
  problemType: ReportProblemType
  value: number | boolean | undefined
}

const ReportProblemIcon: React.FC<ReportProblemIconProps> = props => {
  const { problemType, value } = props

  if (value === undefined) {
    return (
      <Typography.Text style={{ fontSize: 24 }} type="success">
        <CheckCircleOutlined />
      </Typography.Text>
    )
  }

  if (problemType === ReportProblemType.Error) {
    return (
      <Typography.Text style={{ fontSize: 24 }} type="danger">
        <CloseCircleOutlined />
      </Typography.Text>
    )
  }

  if (problemType === ReportProblemType.Warning) {
    return (
      <Typography.Text style={{ fontSize: 24 }} type="warning">
        <WarningOutlined />
      </Typography.Text>
    )
  }

  return null
}

type ReportConfigProps = {
  report: Report
  isAdmin?: boolean
}

const ReportConfig: React.FC<ReportConfigProps> = props => {
  const { report, isAdmin = false } = props

  if (isAdmin) {
    return (
      <CardWithoutBodyPadding
        title={
          <>
            Конфигурация отчета <AdminOnly />
          </>
        }
        size="small"
      >
        <Descriptions size="small" bordered column={2}>
          {Object.entries(report.payload.settings).map(([key, value]) => {
            let result: string
            if (typeof value === "boolean") {
              result = value ? "true" : "false"
            } else if (typeof value === "number") {
              result = formatters.number.format(value)
            } else if (Array.isArray(value)) {
              result = value.join(", ")
            } else if (value === null) {
              result = "null"
            } else {
              result = value.toString()
            }

            return (
              <Descriptions.Item
                key={key}
                span={key === "sitemaps" ? 2 : 1}
                label={key}
              >
                {result}
              </Descriptions.Item>
            )
          })}
        </Descriptions>
      </CardWithoutBodyPadding>
    )
  }

  return (
    <CardWithoutBodyPadding title="Конфигурация отчета" size="small">
      <Descriptions size="small" bordered column={1}>
        <Descriptions.Item key="entry_point" label="Основной адрес сайта">
          {report.entry_point}
        </Descriptions.Item>
        {Object.values(ReportSettingAlias).map((alias: ReportSettingAlias) => {
          const value = report.payload.settings[alias]

          let result: string
          if (typeof value === "boolean") {
            result = value ? "да" : "нет"
          } else if (Array.isArray(value)) {
            result = value.join(", ")
          } else if (typeof value === "number") {
            if (alias === "thread_sleep_ms" || alias === "timeout_ms") {
              result = formatters.date.msToSec(value).toString()
            } else {
              result = formatters.number.format(value)
            }
          } else {
            result = value.toString()
          }

          return (
            <Descriptions.Item
              key={alias}
              label={
                <ReportSettingInformationByAlias
                  alias={alias}
                  appendDescription
                />
              }
            >
              {result}
            </Descriptions.Item>
          )
        })}
      </Descriptions>
    </CardWithoutBodyPadding>
  )
}
