import { all, fork } from "redux-saga/effects"
import userFeaturesSaga from "./getUserFeatures"
import getOrdersSaga from "./getOrders"
import userPlanSaga from "./getUserPlan"
import getPlanByAlias from "./getPlanByAlias"
import createOrderSaga from "./createOrder"
import purchaseSaga from "./purchase"
import getPlansSaga from "./getPlans"

export default function* billingSagas(): Generator {
  yield all([
    fork(userFeaturesSaga),
    fork(getOrdersSaga),
    fork(userPlanSaga),
    fork(getPlanByAlias),
    fork(createOrderSaga),
    fork(purchaseSaga),
    fork(getPlansSaga),
  ])
}
