import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../../../../services/apiClient"
import {
  LoadRobotsGraphAction,
  RobotsGraphActionType,
} from "../../../../../@types/actions/remote/cabinet/report/robotsGraph"

function* fetchGetRobotsGraph(action: LoadRobotsGraphAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response = yield call(
      apiClient.cabinet.project.reports.graph.getRobotsGraph,
      action.payload,
    )
    yield put(action.meta.success(response.data))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getRobotsGraphSaga(): Generator {
  yield takeEvery(RobotsGraphActionType.Load, fetchGetRobotsGraph)
}
