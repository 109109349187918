import {
  AddFilterTitleReportAction,
  CancelFilterTitleReportAction,
  ClearFilterTitleReportAction,
  ClearSortTitleReportAction,
  ExportTitleReportAction,
  LoadTitleReportAction,
  SetPaginationTitleReportAction,
  SetSortTitleReportAction,
  TitleReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/title"

const loadTitleReport = (
  payload: LoadTitleReportAction["payload"],
): LoadTitleReportAction => ({
  type: TitleReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: TitleReportActionType.LoadPending,
    }),
    success: payload => ({
      type: TitleReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: TitleReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterTitleReport = (): ClearFilterTitleReportAction => ({
  type: TitleReportActionType.ClearFilter,
})

const clearSortTitleReport = (): ClearSortTitleReportAction => ({
  type: TitleReportActionType.ClearSort,
})

const exportTitleReport = (
  payload: ExportTitleReportAction["payload"],
): ExportTitleReportAction => ({
  type: TitleReportActionType.Export,
  payload,
})

const addFilterTitleReport = (
  payload: AddFilterTitleReportAction["payload"],
): AddFilterTitleReportAction => ({
  type: TitleReportActionType.AddFilter,
  payload,
})

const cancelFilterTitleReport = (
  payload: CancelFilterTitleReportAction["payload"],
): CancelFilterTitleReportAction => ({
  type: TitleReportActionType.CancelFilter,
  payload,
})

const setPaginationTitleReport = (
  payload: SetPaginationTitleReportAction["payload"],
): SetPaginationTitleReportAction => ({
  type: TitleReportActionType.SetPagination,
  payload,
})

const setSortTitleReport = (
  payload: SetSortTitleReportAction["payload"],
): SetSortTitleReportAction => ({
  type: TitleReportActionType.SetSort,
  payload,
})

export {
  loadTitleReport,
  clearFilterTitleReport,
  clearSortTitleReport,
  exportTitleReport,
  addFilterTitleReport,
  cancelFilterTitleReport,
  setPaginationTitleReport,
  setSortTitleReport,
}
