import React from "react"
import { useDispatch, useSelector } from "react-redux"
import DashboardReport from "../../components/Report/DashboardReport"
import findMenuItems from "../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../components/ui/Sidebar/menu/reportMenu"
import { useTitle } from "../../hooks/useTitle"
import { loadReport } from "../../state/actions/remote/cabinet/report/report"
import { getReport } from "../../state/selectors/remote/cabinet/report"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.Dashboard)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const DashboardPage: React.FC<Props> = React.memo((props: Props) => {
  const { reportId, shareToken }: Props = props

  const reportState = useSelector(getReport)
  const reportStateData = reportState.data

  const dispatch = useDispatch()

  useTitle(title)

  React.useEffect(() => {
    dispatch(
      loadReport({
        reportId,
        shareToken,
      }),
    )
  }, [reportId])

  if (!reportStateData || reportState.isLoading) return null

  return <DashboardReport shareToken={shareToken} report={reportStateData} />
})

export default DashboardPage
