import React from "react"
import ExternalReport from "../../../components/Report/ExternalReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportExternalReport } from "../../../state/actions/remote/cabinet/report/external"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.External)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const ExternalPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportExternalReport,
  })

  return <ExternalReport reportId={reportId} shareToken={shareToken} />
}

export default ExternalPage
