import {
  ProjectCreatedState,
  ProjectCurrentState,
  ProjectListState,
} from "../../../@types/reducers/remote/cabinet/project"
import { Selector } from "../../../@types/selectors/selectors"

const getProject: Selector<ProjectCurrentState> = state =>
  state.remote.cabinet.project.current

const getProjectCreated: Selector<ProjectCreatedState> = state =>
  state.remote.cabinet.project.created

const getProjectList: Selector<ProjectListState> = state =>
  state.remote.cabinet.project.list

export { getProject, getProjectList, getProjectCreated }
