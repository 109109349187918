import React from "react"
import { DisplayFormats } from "../components/DisplayFormat"
import LocalStorage from "../services/storage"

type UseDisplayFormatResult = [DisplayFormats, (format: DisplayFormats) => void]

export function useDisplayFormat(): UseDisplayFormatResult {
  const [activeFormat, setActiveFormat] = React.useState<DisplayFormats>(() => {
    const format = LocalStorage.getDisplayFormat()
    if (!format) {
      return "card"
    }

    return format
  })

  const change = React.useCallback(
    (format: DisplayFormats): void => {
      if (format === activeFormat) {
        return
      }

      LocalStorage.setDisplayFormat(format)
      setActiveFormat(format)
    },
    [activeFormat],
  )

  return [activeFormat, change]
}
