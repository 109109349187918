import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  DeleteReportResponse,
} from "../../../../../services/apiClient"
import {
  DeleteReportAction,
  ReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/report"

function* fetchDeleteReport(action: DeleteReportAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: DeleteReportResponse = yield call(
      apiClient.cabinet.project.reports.deleteReport,
      action.payload,
    )
    if (response.success) {
      yield put(action.meta.success(action.payload))
    }
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* deleteReportSaga(): Generator {
  yield takeEvery(ReportActionType.Delete, fetchDeleteReport)
}
