import {
  AddFilterSourcePageReportAction,
  ClearFilterSourcePageReportAction,
  ClearSortSourcePageReportAction,
  ClearSourcePageReportAction,
  ExportSourcePageReportAction,
  LoadSourcePageReportAction,
  SetPaginationSourcePageReportAction,
  SetSortSourcePageReportAction,
  SourcePageReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/sourcePage"

const loadSourcePageReport = (
  payload: LoadSourcePageReportAction["payload"],
): LoadSourcePageReportAction => ({
  type: SourcePageReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: SourcePageReportActionType.LoadPending,
    }),
    success: payload => ({
      type: SourcePageReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: SourcePageReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterSourcePageReport = (): ClearFilterSourcePageReportAction => ({
  type: SourcePageReportActionType.ClearFilter,
})

const clearSourcePageReport = (): ClearSourcePageReportAction => ({
  type: SourcePageReportActionType.Clear,
})

const clearSortSourcePageReport = (): ClearSortSourcePageReportAction => ({
  type: SourcePageReportActionType.ClearSort,
})

const exportSourcePageReport = (
  payload: ExportSourcePageReportAction["payload"],
): ExportSourcePageReportAction => ({
  type: SourcePageReportActionType.Export,
  payload,
})

const addFilterSourcePageReport = (
  payload: AddFilterSourcePageReportAction["payload"],
): AddFilterSourcePageReportAction => ({
  type: SourcePageReportActionType.AddFilter,
  payload,
})

const setPaginationSourcePageReport = (
  payload: SetPaginationSourcePageReportAction["payload"],
): SetPaginationSourcePageReportAction => ({
  type: SourcePageReportActionType.SetPagination,
  payload,
})

const setSortSourcePageReport = (
  payload: SetSortSourcePageReportAction["payload"],
): SetSortSourcePageReportAction => ({
  type: SourcePageReportActionType.SetSort,
  payload,
})

export {
  loadSourcePageReport,
  clearFilterSourcePageReport,
  clearSortSourcePageReport,
  exportSourcePageReport,
  addFilterSourcePageReport,
  setPaginationSourcePageReport,
  setSortSourcePageReport,
  clearSourcePageReport,
}
