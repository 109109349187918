import React from "react"
import { Tag, Typography } from "antd"
import { ArrowRightOutlined, EnterOutlined } from "@ant-design/icons"

type Props = {
  infinity?: boolean
  redirectItem?: React.ReactNode
  url: string | null
}

const RedirectCanonicalItem: React.FC<Props> = props => {
  const { url, infinity, redirectItem } = props

  if (!redirectItem) {
    return (
      <div className="table__url-redirect-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <EnterOutlined className="table__url-redirect-container__icon-redirect" />
          <Tag
            style={{
              padding: "0 4px",
              marginRight: 5,
              fontSize: ".8em",
              lineHeight: "inherit",
            }}
          >
            Canonical
          </Tag>
          <ArrowRightOutlined className="table__url-redirect-container__icon-redirect-to" />
          <Typography.Text style={{ fontSize: ".9em" }}>{url}</Typography.Text>
        </div>
      </div>
    )
  }

  return (
    <>
      {!infinity && redirectItem}
      <div className="table__url-redirect-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <EnterOutlined className="table__url-redirect-container__icon-redirect" />
          <Tag
            style={{
              padding: "0 4px",
              marginRight: 0,
              fontSize: ".8em",
              lineHeight: "inherit",
            }}
          >
            Canonical
          </Tag>
          <ArrowRightOutlined className="table__url-redirect-container__icon-redirect-to" />
          <Typography.Text style={{ fontSize: ".9em" }}>{url}</Typography.Text>
        </div>
      </div>
      {infinity && redirectItem}
    </>
  )
}

export default RedirectCanonicalItem
