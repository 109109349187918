import { all, fork } from "redux-saga/effects"
import getIndexStatusGraphSaga from "./getIndexStatusGraphSaga"
import getRedirectsGraphSaga from "./getRedirectsGraphSaga"
import getRobotsGraphSaga from "./getRobotsGraphSaga"
import getSitemapUrlsGraphSaga from "./getSitemapUrlsGraphSaga"
import getStatusCodesGraphSaga from "./getStatusCodesGraphSaga"

export default function* projectReportsGraphSagas(): Generator {
  yield all([
    fork(getIndexStatusGraphSaga),
    fork(getRedirectsGraphSaga),
    fork(getRobotsGraphSaga),
    fork(getSitemapUrlsGraphSaga),
    fork(getStatusCodesGraphSaga),
  ])
}
