import React from "react"
import Nullable from "./Nullable"

type Props = {
  value: React.Key | null
}

const KB = 1024
const MB = KB ** 2

// Component for page size display
const Size: React.FC<Props> = props => {
  let value = props.value
  if (value === null) {
    return <Nullable />
  }

  if (typeof value === "string") {
    value = parseInt(value)
  }

  if (value < KB) {
    return <>{value} B</>
  }

  if (MB > value) {
    return <>{(value / KB).toFixed(2)} KB</>
  }

  return <>{(value / MB).toFixed(2)} MB</>
}

export default Size
