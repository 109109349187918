import {
  AddFilterImageGroupReportAction,
  CancelFilterImageGroupReportAction,
  ClearFilterImageGroupReportAction,
  ClearSortImageGroupReportAction,
  ExportImageGroupReportAction,
  ImageGroupReportActionType,
  LoadImageGroupReportAction,
  SetPaginationImageGroupReportAction,
  SetSortImageGroupReportAction,
} from "../../../../@types/actions/remote/cabinet/report/imageGroup"

const loadImageGroupReport = (
  payload: LoadImageGroupReportAction["payload"],
): LoadImageGroupReportAction => ({
  type: ImageGroupReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: ImageGroupReportActionType.LoadPending,
    }),
    success: payload => ({
      type: ImageGroupReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: ImageGroupReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterImageGroupReport = (): ClearFilterImageGroupReportAction => ({
  type: ImageGroupReportActionType.ClearFilter,
})

const clearSortImageGroupReport = (): ClearSortImageGroupReportAction => ({
  type: ImageGroupReportActionType.ClearSort,
})

const exportImageGroupReport = (
  payload: ExportImageGroupReportAction["payload"],
): ExportImageGroupReportAction => ({
  type: ImageGroupReportActionType.Export,
  payload,
})

const addFilterImageGroupReport = (
  payload: AddFilterImageGroupReportAction["payload"],
): AddFilterImageGroupReportAction => ({
  type: ImageGroupReportActionType.AddFilter,
  payload,
})

const cancelFilterImageGroupReport = (
  payload: CancelFilterImageGroupReportAction["payload"],
): CancelFilterImageGroupReportAction => ({
  type: ImageGroupReportActionType.CancelFilter,
  payload,
})

const setPaginationImageGroupReport = (
  payload: SetPaginationImageGroupReportAction["payload"],
): SetPaginationImageGroupReportAction => ({
  type: ImageGroupReportActionType.SetPagination,
  payload,
})

const setSortImageGroupReport = (
  payload: SetSortImageGroupReportAction["payload"],
): SetSortImageGroupReportAction => ({
  type: ImageGroupReportActionType.SetSort,
  payload,
})

export {
  loadImageGroupReport,
  clearFilterImageGroupReport,
  clearSortImageGroupReport,
  exportImageGroupReport,
  addFilterImageGroupReport,
  cancelFilterImageGroupReport,
  setPaginationImageGroupReport,
  setSortImageGroupReport,
}
