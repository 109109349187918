import {
  AddFilterUrlReportAction,
  CancelFilterUrlReportAction,
  ClearFilterUrlReportAction,
  ClearSortUrlReportAction,
  ExportUrlReportAction,
  LoadUrlReportAction,
  SetPaginationUrlReportAction,
  SetSortUrlReportAction,
  UrlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/url"

const loadUrlReport = (
  payload: LoadUrlReportAction["payload"],
): LoadUrlReportAction => ({
  type: UrlReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: UrlReportActionType.LoadPending,
    }),
    success: payload => ({
      type: UrlReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: UrlReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterUrlReport = (): ClearFilterUrlReportAction => ({
  type: UrlReportActionType.ClearFilter,
})

const clearSortUrlReport = (): ClearSortUrlReportAction => ({
  type: UrlReportActionType.ClearSort,
})

const exportUrlReport = (
  payload: ExportUrlReportAction["payload"],
): ExportUrlReportAction => ({
  type: UrlReportActionType.Export,
  payload,
})

const addFilterUrlReport = (
  payload: AddFilterUrlReportAction["payload"],
): AddFilterUrlReportAction => ({
  type: UrlReportActionType.AddFilter,
  payload,
})

const cancelFilterUrlReport = (
  payload: CancelFilterUrlReportAction["payload"],
): CancelFilterUrlReportAction => ({
  type: UrlReportActionType.CancelFilter,
  payload,
})

const setPaginationUrlReport = (
  payload: SetPaginationUrlReportAction["payload"],
): SetPaginationUrlReportAction => ({
  type: UrlReportActionType.SetPagination,
  payload,
})

const setSortUrlReport = (
  payload: SetSortUrlReportAction["payload"],
): SetSortUrlReportAction => ({
  type: UrlReportActionType.SetSort,
  payload,
})

export {
  loadUrlReport,
  clearFilterUrlReport,
  clearSortUrlReport,
  exportUrlReport,
  addFilterUrlReport,
  cancelFilterUrlReport,
  setPaginationUrlReport,
  setSortUrlReport,
}
