import {
  PageHeaderActions,
  PageHeaderActionType,
} from "../../@types/actions/ui/pageHeader"
import { PageHeaderState } from "../../@types/reducers/ui/pageHeader"

const initState: PageHeaderState = {
  title: "",
}

const pageHeaderReducer = (
  state: PageHeaderState = initState,
  action: PageHeaderActions,
): PageHeaderState => {
  switch (action.type) {
    case PageHeaderActionType.Change: {
      let title = action.payload.title
      if (title) {
        if (
          Array.isArray(title) &&
          title.some(value => typeof value === "string")
        ) {
          title = title.join(" / ")
        }
      } else {
        title = state.title
      }

      return { ...action.payload, title }
    }

    case PageHeaderActionType.ChangeTitle: {
      let title = action.payload.title
      if (title) {
        if (
          Array.isArray(title) &&
          title.some(value => typeof value === "string")
        ) {
          title = title.join(" / ")
        }
      } else {
        title = state.title
      }

      return { ...state, title }
    }

    default:
      return state
  }
}

export default pageHeaderReducer
