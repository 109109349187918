import { Tooltip } from "antd"
import { BaseType } from "antd/lib/typography/Base"
import Text from "antd/lib/typography/Text"
import React from "react"
import { QuestionCircleOutlined } from "@ant-design/icons"
import Nullable from "../../Nullable"

type Props = {
  value: string | null
  viewLabel?: boolean
  typeForText?: BaseType
}

const Canonical: React.FC<Props> = props => {
  const { value, viewLabel = false, typeForText } = props

  if (value === null) {
    return <Nullable />
  }

  return (
    <>
      {viewLabel && (
        <div className="index-status__information">
          <Tooltip
            placement="leftTop"
            title="URL, указанный в теге canonical, отличается от URL страницы, где он был найден"
          >
            <Text>Canonical</Text>
            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      )}
      <Text type={typeForText}>{value.length ? value : "Не указан"}</Text>
    </>
  )
}

export default Canonical
