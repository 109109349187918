import { Empty, Space, Spin, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { InternalReportRecord } from "../../state/@types/reducers/remote/cabinet/report/internalReducer"
import {
  addFilterInternalReport,
  cancelFilterInternalReport,
  clearFilterInternalReport,
  clearSortInternalReport,
  loadInternalReport,
  setPaginationInternalReport,
  setSortInternalReport,
} from "../../state/actions/remote/cabinet/report/internal"
import { getInternal } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import EmptyTagComponent from "../EmptyTagComponent"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Nullable from "../Nullable"
import StatusCode from "../StatusCode"
import Table from "../Table"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const InternalReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const internalState = useSelector(getInternal)

  const _columns = useColumns(
    columns,
    internalState.sort,
    internalState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadInternalReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationInternalReport,
    setSortInternalReport,
    clearSortInternalReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterInternalReport,
    cancelFilterInternalReport,
    clearFilterInternalReport,
    setSortInternalReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterInternalReport,
    cancelFilterInternalReport,
  )

  if (internalState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        internalState.data === undefined ? (
          <Spin />
        ) : (
          <Space style={{ width: "100%" }} direction="vertical">
            <InputSearchByFields
              onSearch={onSearchInput}
              onClear={onClearInput}
              activeFilter={internalState.filter}
              options={inputOptions}
            />
            <Filters
              filters={filters.providers.internal}
              activeFilter={internalState.filter}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          </Space>
        )
      }
    >
      <Table
        rowKey="page_resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={internalState.data}
        loading={internalState.isLoading}
        pagination={internalState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

export default InternalReport

const inputOptions: InputSearchByFieldsValueType<InternalReportRecord>[] = [
  { value: "page_resource_url", label: "URL" },
  { value: "src_resource_url_no_scheme", label: "Источники" },
  { value: "src_page_title", label: "Title - Источники" },
  { value: "page_resource_content", label: "Анкор" },
]

const columns: ColumnsType<InternalReportRecord> = [
  columnsType.pageInformation(
    record => ({
      url: {
        value: record.page_resource_url,
      },
      redirects: record.target_resource_redirects
        ? record.target_resource_redirects
        : [],
      suffix: (
        <Typography.Text
          type="secondary"
          ellipsis
          style={{ fontSize: "0.9em", display: "block" }}
        >
          href={record.page_resource_raw_url}
        </Typography.Text>
      ),
    }),
    {
      key: "page_resource_url",
      dataIndex: "page_resource_url",
    },
  ),
  columnsType.pageInformation(
    record => ({
      resourceId: record.src_resource_id,
      url: {
        value: record.src_resource_url,
      },
      title: record.src_page_title,
      redirects: record.src_resource_redirects
        ? record.src_resource_redirects
        : [],
      canonical: {
        url: record.src_page_canonical_url,
        isCanonical: record.src_page_is_canonical,
      },
    }),
    {
      key: "src_resource_url_no_scheme",
      title: "Источник",
      dataIndex: "src_resource_url_no_scheme",
    },
  ),
  columnsType.anchorType({
    key: "page_resource_anchor_anchor_type",
    dataIndex: "page_resource_anchor_anchor_type",
  }),
  {
    key: "page_resource_content",
    title: "Анкор",
    dataIndex: "page_resource_content",
    width: 250,
    render: (
      value: InternalReportRecord["page_resource_content"],
    ): React.ReactNode => {
      if (value === null) {
        return <Nullable />
      }

      return value.length ? (
        <Typography.Text>{value}</Typography.Text>
      ) : (
        <EmptyTagComponent />
      )
    },
  },
  {
    key: "page_resource_attrs_rel",
    title: "параметр rel",
    dataIndex: "page_resource_attrs_rel",
    width: 100,
    render: (
      value: InternalReportRecord["page_resource_attrs_rel"],
    ): React.ReactNode => {
      if (value === null) {
        return <Nullable />
      }

      return value.length ? (
        <Typography.Text>{value}</Typography.Text>
      ) : (
        <EmptyTagComponent />
      )
    },
  },
  columnsType.statusCode({
    key: "target_resource_status_code",
    dataIndex: "target_resource_status_code",
    render: (
      value: InternalReportRecord["target_resource_status_code"],
      record,
    ): React.ReactNode => (
      <StatusCode
        value={
          record.target_resource_redirect_status_code !== null
            ? record.target_resource_redirect_status_code
            : value
        }
      />
    ),
  }),
]
