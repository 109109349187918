import { Col, Empty, Row, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumnsWithExpandable from "../../hooks/useColumnsWithExpandable"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { HeadingReportRecord } from "../../state/@types/reducers/remote/cabinet/report/headingReducer"
import {
  addFilterHeadingReport,
  cancelFilterHeadingReport,
  clearFilterHeadingReport,
  clearSortHeadingReport,
  loadHeadingReport,
  setPaginationHeadingReport,
  setSortHeadingReport,
} from "../../state/actions/remote/cabinet/report/heading"
import { getHeadings } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import Headings from "../ui/Headings"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const HeadingReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const rowKey = "resource_id"

  const headingsState = useSelector(getHeadings)

  const showRowsByDataIndex = (): string[] => {
    if (!headingsState.data) {
      return []
    }

    return headingsState.data.map(record => record[rowKey])
  }

  const { columns: _columns, expandedRowKeys } = useColumnsWithExpandable<
    HeadingReportRecord
  >(
    rowKey,
    columns,
    showRowsByDataIndex,
    headingsState.sort,
    headingsState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadHeadingReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationHeadingReport,
    setSortHeadingReport,
    clearSortHeadingReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterHeadingReport,
    cancelFilterHeadingReport,
    clearFilterHeadingReport,
    setSortHeadingReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterHeadingReport,
    cancelFilterHeadingReport,
  )

  if (headingsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        headingsState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.headings}
                activeFilter={headingsState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={headingsState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table<HeadingReportRecord>
        rowKey={rowKey}
        dataSource={headingsState.data}
        pagination={headingsState.pagination}
        loading={headingsState.isLoading}
        onChangeTable={onChangeTable}
        columns={_columns}
        expandable={{
          expandedRowKeys,
          expandedRowRender: (record): React.ReactNode => (
            <Headings
              headings={{
                h1: record.page_headers_h1,
                h2: record.page_headers_h2,
                h3: record.page_headers_h3,
              }}
            />
          ),
        }}
      />
    </CardWithoutBodyPadding>
  )
}

export default HeadingReport

const inputOptions: InputSearchByFieldsValueType<HeadingReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
  { label: "Title", value: "page_title" },
]

const columns: ColumnsType<HeadingReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      url: {
        value: record.resource_url,
      },
      title: record.page_title,
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.indexStatus(
    reocrd => ({
      robotstxtIgnore: reocrd.resource_robotstxt_ignore,
      robotstxtLine: reocrd.resource_robotstxt_line,
      canonicalUrl: reocrd.page_canonical_url,
    }),
    {
      key: "resource_index_status",
      dataIndex: "resource_index_status",
    },
  ),
]
