import {
  PageHeaderActionType,
  PageHeaderChangeAction,
  PageHeaderChangeTitleAction,
} from "../../@types/actions/ui/pageHeader"

const changePageHeader = (
  payload: PageHeaderChangeAction["payload"],
): PageHeaderChangeAction => ({
  type: PageHeaderActionType.Change,
  payload,
})

const changeTitlePageHeader = (
  payload: PageHeaderChangeTitleAction["payload"],
): PageHeaderChangeTitleAction => ({
  type: PageHeaderActionType.ChangeTitle,
  payload,
})

export { changePageHeader, changeTitlePageHeader }
