import { Action } from "redux"
import {
  ApiClientSort,
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { StatusCodeReportRecord } from "../../../../reducers/remote/cabinet/report/statusCodeReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum StatusCodeReportActionType {
  Load = "@@REPORTS/STATUS_CODE/LOAD",
  LoadSuccess = "@@REPORTS/STATUS_CODE/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/STATUS_CODE/LOAD_PENDING",
  LoadFailure = "@@REPORTS/STATUS_CODE/LOAD_FAILURE",
  SetPagination = "@@REPORTS/STATUS_CODE/SET_PAGINATION_",
  SetSort = "@@REPORTS/STATUS_CODE/SET_SORT",
  AddFilter = "@@REPORTS/STATUS_CODE/ADD_FILTER",
  CancelFilter = "@@REPORTS/STATUS_CODE/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/STATUS_CODE/CLEAR_FILTER",
  ClearSort = "@@REPORTS/STATUS_CODE/CLEAR_SORT",
  Export = "@@REPORTS/STATUS_CODE/EXPORT",
}

export type LoadStatusCodeReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<StatusCodeReportRecord>,
  StatusCodeReportActionType.Load,
  StatusCodeReportActionType.LoadPending,
  StatusCodeReportActionType.LoadSuccess,
  StatusCodeReportActionType.LoadFailure
>
export type LoadPendingStatusCodeReportAction = ReturnType<
  LoadStatusCodeReportAction["meta"]["pending"]
>
export type LoadSuccessStatusCodeReportAction = ReturnType<
  LoadStatusCodeReportAction["meta"]["success"]
>
export type LoadFailureStatusCodeReportAction = ReturnType<
  LoadStatusCodeReportAction["meta"]["failure"]
>

export type ClearFilterStatusCodeReportAction = Action<
  StatusCodeReportActionType.ClearFilter
>

export type ClearSortStatusCodeReportAction = Action<
  StatusCodeReportActionType.ClearSort
>

export type ExportStatusCodeReportAction = ExportAction<
  StatusCodeReportRecord,
  ExportListPagesPayload<StatusCodeReportRecord>,
  StatusCodeReportActionType.Export
>

export type AddFilterStatusCodeReportAction = AddFilterAction<
  StatusCodeReportRecord,
  StatusCodeReportActionType.AddFilter
>

export type CancelFilterStatusCodeReportAction = CancelFilterAction<
  StatusCodeReportRecord,
  StatusCodeReportActionType.CancelFilter
>

export type SetSortStatusCodeReportAction = SetSortAction<
  ApiClientSort<StatusCodeReportRecord>,
  StatusCodeReportActionType.SetSort
>

export type SetPaginationStatusCodeReportAction = SetPaginationAction<
  StatusCodeReportActionType.SetPagination
>
