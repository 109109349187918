import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetProjectByAliasResponse,
} from "../../../../../services/apiClient"
import {
  LoadProjectByAliasAction,
  ProjectTypes,
} from "../../../../@types/actions/remote/cabinet/project"

function* fetchLoadProjectByAlias(
  action: LoadProjectByAliasAction,
): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetProjectByAliasResponse = yield call(
      apiClient.cabinet.project.projects.getProjectByAlias,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getProjectByAliasSaga(): Generator {
  yield takeEvery(ProjectTypes.LoadByAlias, fetchLoadProjectByAlias)
}
