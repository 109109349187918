import {
  LoadSitemapUrlsGraphAction,
  SitemapUrlsGraphActionType,
} from "../../../../@types/actions/remote/cabinet/report/sitemapUrlsGraph"

const loadSitemapUrlsGraph = (
  payload: LoadSitemapUrlsGraphAction["payload"],
): LoadSitemapUrlsGraphAction => ({
  type: SitemapUrlsGraphActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: SitemapUrlsGraphActionType.LoadPending,
    }),
    success: payload => ({
      type: SitemapUrlsGraphActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: SitemapUrlsGraphActionType.LoadFailure,
      payload,
    }),
  },
})

export { loadSitemapUrlsGraph }
