enum LocalStorageAlias {
  // Number of data items per page for Table component
  PaginationPageSize = "pagination_page_size",
  // Path for redirect after login
  LastUrl = "last_url",
  // Format for display problems on dashboard page
  DisplayFormat = "display_format",
}

const storage = {
  getPageSize(): number | null {
    const value = localStorage.getItem(LocalStorageAlias.PaginationPageSize)
    return value ? parseInt(value) : null
  },
  setPageSize(value: number): void {
    localStorage.setItem(LocalStorageAlias.PaginationPageSize, value.toString())
  },
  getLastUrl(): string | null {
    return localStorage.getItem(LocalStorageAlias.LastUrl)
  },
  setLastUrl(): void {
    const { pathname, search, hash } = window.location
    const path = `${pathname}${search}${hash}`
    localStorage.setItem(LocalStorageAlias.LastUrl, path)
  },
  removeLastUrl(): void {
    localStorage.removeItem(LocalStorageAlias.LastUrl)
  },
  getDisplayFormat(): "card" | "list" | null {
    return localStorage.getItem(LocalStorageAlias.DisplayFormat) as
      | "card"
      | "list"
      | null
  },
  setDisplayFormat(format: "card" | "list"): void {
    localStorage.setItem(LocalStorageAlias.DisplayFormat, format)
  },
}

export default storage
