import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../../routes"
import ExternalPage from "./ExternalPage"
import InternalPage from "./InternalPage"

type Props = {
  reportId: string
  shareToken: string
}

const LinksRouter: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const path = routes.s(shareToken).links()

  return (
    <Switch>
      <Route
        path={path.internal()}
        render={(): JSX.Element => (
          <InternalPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.external()}
        render={(): JSX.Element => (
          <ExternalPage reportId={reportId} shareToken={shareToken} />
        )}
      />
    </Switch>
  )
}

export default LinksRouter
