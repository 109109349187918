import { Button, Descriptions, Spin } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { ArrowLeftOutlined } from "@ant-design/icons"
import history from "../../lib/history"
import { loadSelectedPage } from "../../state/actions/remote/cabinet/report/selectedPage"
import { getSelectedPage } from "../../state/selectors/remote/cabinet/report"
import Size from "../SIze"
import StatusCode from "../StatusCode"
import Title from "../Title"
import Canonical from "../ui/Canonical"
import Description from "../ui/Description"
import Headings from "../ui/Headings"
import MetaRobots from "../ui/MetaRobots"
import Redirects from "../ui/Redirects"
import Url from "../Url"

type Props = {
  reportId: string
  showGoBack?: boolean
}

const ShortAboutPage: React.FC<Props> = props => {
  const { reportId, showGoBack = true } = props

  const selectedPageState = useSelector(getSelectedPage)
  const selectedPageStateData = selectedPageState.data

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (
      !selectedPageState.isLoading &&
      !selectedPageStateData &&
      !selectedPageState.error &&
      selectedPageState.filter
    ) {
      dispatch(
        loadSelectedPage({
          reportId,
          saveParamsToUrl: false,
        }),
      )
    }
  }, [selectedPageState.filter])

  const labelStyle: React.CSSProperties = { whiteSpace: "nowrap" }

  if (!selectedPageStateData || !selectedPageStateData.length) {
    return null
  }

  return (
    <>
      {showGoBack && history.length > 1 && (
        <Button
          type="ghost"
          style={{ marginBottom: 30 }}
          onClick={(): void => {
            history.goBack()
          }}
        >
          <ArrowLeftOutlined />
          Вернуться назад
        </Button>
      )}
      <Spin spinning={selectedPageState.isLoading || !selectedPageStateData}>
        {selectedPageStateData && (
          <Descriptions bordered>
            <Descriptions.Item labelStyle={labelStyle} label="Title" span={3}>
              <Title
                viewEmptyLine={true}
                value={selectedPageStateData[0].page_title}
              />
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label="URL" span={3}>
              <Url value={selectedPageStateData[0].resource_url} />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ whiteSpace: "nowrap", width: 145 }}
              label="Canonical"
              span={3}
            >
              <Canonical value={selectedPageStateData[0].page_canonical_url} />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={labelStyle}
              label="Description"
              span={3}
            >
              <Description
                viewEmptyLine={true}
                value={selectedPageStateData[0].page_description}
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={labelStyle}
              label="Редиректы"
              span={3}
            >
              <Redirects
                viewEmpty={true}
                items={selectedPageStateData[0].resource_redirects}
                urlStyle={{ maxWidth: 700 }}
              />
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label="Статус код">
              <StatusCode
                value={selectedPageStateData[0].resource_status_code}
              />
            </Descriptions.Item>
            <Descriptions.Item labelStyle={labelStyle} label="Размер">
              <Size value={selectedPageStateData[0].resource_size} />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={labelStyle}
              label="Время загрузки (мс)"
            >
              {selectedPageStateData[0].resource_load_time_ms}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ whiteSpace: "nowrap", width: 145 }}
              label="Метатег robots"
              span={3}
            >
              <MetaRobots
                directives={selectedPageStateData[0].page_robots_directives}
              />
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={labelStyle}
              label="Заголовки"
              span={3}
            >
              <Headings
                headings={{
                  h1: selectedPageStateData[0].page_headers_h1,
                  h2: selectedPageStateData[0].page_headers_h2,
                  h3: selectedPageStateData[0].page_headers_h3,
                }}
              />
            </Descriptions.Item>
          </Descriptions>
        )}
      </Spin>
    </>
  )
}

export default ShortAboutPage
