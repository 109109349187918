import { Tag, Tooltip, Typography } from "antd"
import { PresetStatusColorType } from "antd/lib/_util/colors"
import { BaseType } from "antd/lib/typography/Base"
import React from "react"
import Nullable from "./Nullable"

const data: { [status: number]: string } = {
  0: "Not Valid",
  100: "Continue",
  101: "Switching Protocols",
  102: "Processing (WebDAV; RFC 2518)",
  103: "Early Hints (RFC 8297)",
  200: "OK",
  201: "Created",
  202: "Accepted",
  203: "Non-Authoritative Information (since HTTP/1.1)",
  204: "No Content",
  205: "Reset Content",
  206: "Partial Content (RFC 7233)",
  207: "Multi-Status (WebDAV; RFC 4918)",
  208: "Already Reported (WebDAV; RFC 5842)",
  226: "IM Used (RFC 3229)",
  300: "Multiple Choices",
  301: "Moved Permanently",
  302: 'Found (Previously "Moved temporarily")',
  303: "See Other (since HTTP/1.1)",
  304: "Not Modified (RFC 7232)",
  305: "Use Proxy (since HTTP/1.1)",
  306: "Switch Proxy",
  307: "Temporary Redirect (since HTTP/1.1)",
  308: "Permanent Redirect (RFC 7538)",
  400: "Bad Request",
  401: "Unauthorized (RFC 7235)",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  407: "Proxy Authentication Required (RFC 7235)",
  408: "Request Timeout",
  409: "Conflict",
  410: "Gone",
  411: "Length Required",
  412: "Precondition Failed (RFC 7232)",
  413: "Payload Too Large (RFC 7231)",
  414: "URI Too Long (RFC 7231)",
  415: "Unsupported Media Type (RFC 7231)",
  416: "Range Not Satisfiable (RFC 7233)",
  417: "Expectation Failed",
  418: "I'm a teapot (RFC 2324, RFC 7168)",
  421: "Misdirected Request (RFC 7540)",
  422: "Unprocessable Entity (WebDAV; RFC 4918)",
  423: "Locked (WebDAV; RFC 4918)",
  424: "Failed Dependency (WebDAV; RFC 4918)",
  425: "Too Early (RFC 8470)",
  426: "Upgrade Required",
  428: "Precondition Required (RFC 6585)",
  429: "Too Many Requests (RFC 6585)",
  431: "Request Header Fields Too Large (RFC 6585)",
  451: "Unavailable For Legal Reasons (RFC 7725)",
  500: "Internal Server Error",
  501: "Not Implemented",
  502: "Bad Gateway",
  503: "Service Unavailable",
  504: "Gateway Timeout",
  505: "HTTP Version Not Supported",
  506: "Variant Also Negotiates (RFC 2295)",
  507: "Insufficient Storage (WebDAV; RFC 4918)",
  508: "Loop Detected (WebDAV; RFC 5842)",
  510: "Not Extended (RFC 2774)",
  511: "Network Authentication Required (RFC 6585)",
}

export type StatusCodeProps = {
  wrapToTooltip?: boolean
  useTag?: boolean
}

type Props = StatusCodeProps & {
  value: number | null
  style?: React.CSSProperties
}

const StatusCode: React.FC<Props> = props => {
  const { value, style, wrapToTooltip = true, useTag = true } = props
  if (value === null) {
    return <Nullable />
  }

  let color
  if (value >= 200 && value <= 299) {
    color = "success"
  } else if (value >= 300 && value <= 399) {
    color = "warning"
  } else if (value >= 400 && value <= 599) {
    color = useTag ? "error" : "danger"
  }

  const message = data[value]
  if (!message) {
    return null
  }

  const component = useTag ? (
    <Tag color={color as PresetStatusColorType} style={style}>
      {value} {!wrapToTooltip ? message : ""}
    </Tag>
  ) : (
    <Typography.Text
      type={color as BaseType}
      style={{ display: "inline-block", ...style }}
    >
      {value} {!wrapToTooltip ? message : ""}
    </Typography.Text>
  )

  if (wrapToTooltip) {
    return <Tooltip title={message}>{component}</Tooltip>
  }

  return component
}

export default StatusCode
