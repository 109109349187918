import React from "react"
import ImageGroupReport from "../../../../components/Report/ImageGroupReport"
import findMenuItems from "../../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../../hooks/useReportPage"
import { exportImageGroupReport } from "../../../../state/actions/remote/cabinet/report/imageGroup"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.ImagesGroups)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const ImageGroupPage: React.FC<Props> = (props: Props) => {
  const { reportId, shareToken }: Props = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportImageGroupReport,
  })

  return <ImageGroupReport shareToken={shareToken} reportId={reportId} />
}

export default ImageGroupPage
