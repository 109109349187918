import {
  GetPageUrlsPayload,
  GetPageUrlsResponse,
} from "../../../../../../services/apiClient"
import { LoadAction } from "../../../filteredResourceAction"

export enum UrlTreeActionType {
  Load = "@@REPORTS/URLS_TREE/LOAD",
  LoadSuccess = "@@REPORTS/URLS_TREE/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/URLS_TREE/LOAD_PENDING",
  LoadFailure = "@@REPORTS/URLS_TREE/LOAD_FAILURE",
}

export type LoadUrlTreeAction = LoadAction<
  GetPageUrlsPayload,
  GetPageUrlsResponse,
  UrlTreeActionType.Load,
  UrlTreeActionType.LoadPending,
  UrlTreeActionType.LoadSuccess,
  UrlTreeActionType.LoadFailure
>
export type LoadPendingUrlTreeAction = ReturnType<
  LoadUrlTreeAction["meta"]["pending"]
>
export type LoadSuccessUrlTreeAction = ReturnType<
  LoadUrlTreeAction["meta"]["success"]
>
export type LoadFailureUrlTreeAction = ReturnType<
  LoadUrlTreeAction["meta"]["failure"]
>
