import decodeHostnameInUrl from "../../../../../services/url/decodeHostnameInUrl"
import { ReportActionType } from "../../../../@types/actions/remote/cabinet/report/report"
import {
  ReportActions,
  ReportState,
} from "../../../../@types/reducers/remote/cabinet/report/reportReducer"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: ReportState = {
  current: {
    isLoading: false,
  },
  created: {
    isLoading: false,
  },
  stopCrawling: {
    isLoading: false,
  },
  list: {
    isLoading: false,
  },
}

const reportMainReducer = (
  state: ReportState = initState,
  action: ReportActions,
): ReportState => {
  switch (action.type) {
    case ReportActionType.LoadSuccess: {
      const currentData = { ...action.payload }

      currentData.entry_point = decodeHostnameInUrl(currentData.entry_point)
      currentData.payload.settings.sitemaps = currentData.payload.settings.sitemaps.map(
        url => decodeHostnameInUrl(url),
      )

      return {
        ...state,
        current: {
          data: currentData,
          isLoading: false,
        },
        list: {
          ...state.list,
          data: state.list.data?.map(report =>
            report.id === action.payload.id ? action.payload : report,
          ),
        },
      }
    }
    case ReportActionType.LoadFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case ReportActionType.LoadPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case ReportActionType.DeleteSuccess: {
      return {
        ...state,
        current: JSON.parse(JSON.stringify(initState.current)),
        list: {
          ...state.list,
          data: state.list.data?.filter(
            report => report.id !== action.payload.reportId,
          ),
        },
      }
    }
    case ReportActionType.DeleteFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case ReportActionType.DeletePending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case ReportActionType.CreateSuccess: {
      return {
        ...state,
        created: {
          data: action.payload.data,
          isLoading: false,
        },
        list: {
          ...state.list,
          data: [action.payload.data, ...(state.list.data ?? [])],
        },
      }
    }
    case ReportActionType.CreateFailure:
      return {
        ...state,
        created: updateStateLoadFailure(state.created, action),
      }

    case ReportActionType.CreatePending:
      return {
        ...state,
        created: updateStateSetPending(state.created),
      }

    case ReportActionType.CreatedClear: {
      return {
        ...state,
        created: JSON.parse(JSON.stringify(initState.created)),
      }
    }

    case ReportActionType.LoadListSuccess:
      return {
        ...state,
        list: { data: action.payload.data, isLoading: false },
      }

    case ReportActionType.LoadListFailure:
      return {
        ...state,
        list: updateStateLoadFailure(state.list, action),
      }

    case ReportActionType.LoadListPending:
      return {
        ...state,
        list: updateStateSetPending(state.list),
      }

    case ReportActionType.LoadByAliasSuccess: {
      if (action.payload.data.length) {
        const data = { ...action.payload.data[0] }
        data.entry_point = decodeHostnameInUrl(data.entry_point)
        data.payload.settings.sitemaps = data.payload.settings.sitemaps.map(
          url => decodeHostnameInUrl(url),
        )

        return {
          ...state,
          current: { data, isLoading: false },
        }
      }

      return {
        ...state,
        current: { data: null, isLoading: false },
      }
    }
    case ReportActionType.LoadByAliasFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case ReportActionType.LoadByAliasPending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case ReportActionType.StopCrawlingSuccess:
      return {
        ...state,
        stopCrawling: { data: action.payload, isLoading: false },
      }

    case ReportActionType.StopCrawlingFailure:
      return {
        ...state,
        stopCrawling: { data: false, isLoading: false },
      }

    case ReportActionType.StopCrawlingPending:
      return {
        ...state,
        stopCrawling: updateStateSetPending(state.stopCrawling),
      }

    case ReportActionType.StopCrawlingReset:
      return {
        ...state,
        stopCrawling: JSON.parse(JSON.stringify(initState.stopCrawling)),
      }

    case ReportActionType.CurrentClear:
      return {
        ...state,
        current: JSON.parse(JSON.stringify(initState.current)),
      }

    case ReportActionType.ClearList:
      return {
        ...state,
        list: JSON.parse(JSON.stringify(initState.list)),
      }

    default:
      return state
  }
}

export default reportMainReducer
