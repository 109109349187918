import {
  LoadUrlTreeAction,
  UrlTreeActionType,
} from "../../../../@types/actions/remote/cabinet/report/urlTree"

const loadUrlTree = (
  payload: LoadUrlTreeAction["payload"],
): LoadUrlTreeAction => ({
  type: UrlTreeActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: UrlTreeActionType.LoadPending,
    }),
    success: payload => ({
      type: UrlTreeActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: UrlTreeActionType.LoadFailure,
      payload,
    }),
  },
})

export { loadUrlTree }
