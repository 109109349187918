import { RedirectReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/redirectReducer"
import { addFilterRedirectReport } from "../../../state/actions/remote/cabinet/report/redirect"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<RedirectReportRecord>[] = [
  {
    name: "Временные",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterRedirectReport({
          resource_status_code: [[ApiClientFilterType.In, [302, 307]]],
        }),
      },
    ],
  },
  {
    name: "Цепочки",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterRedirectReport({
          resource_redirects_count: [[ApiClientFilterType.Gte, 2]],
        }),
      },
    ],
  },
  {
    name: "Бесконечный",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterRedirectReport({
          resource_redirects_count: [[ApiClientFilterType.Gte, 9]],
        }),
      },
    ],
  },
]

export default filters
