import { call, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  ExternalReportActionType,
  LoadExternalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/external"
import {
  ImageReportActionType,
  LoadImageReportAction,
} from "../../../../@types/actions/remote/cabinet/report/image"
import {
  InternalReportActionType,
  LoadInternalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/internal"
import {
  LoadNotHtmlReportAction,
  NotHtmlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/notHtml"
import {
  LoadSourcePageReportAction,
  SourcePageReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/sourcePage"
import {
  addFilterExternalReport,
  setPaginationExternalReport,
  setSortExternalReport,
} from "../../../../actions/remote/cabinet/report/external"
import {
  addFilterImageReport,
  setPaginationImageReport,
  setSortImageReport,
} from "../../../../actions/remote/cabinet/report/image"
import {
  addFilterInternalReport,
  setPaginationInternalReport,
  setSortInternalReport,
} from "../../../../actions/remote/cabinet/report/internal"
import {
  addFilterNotHtmlReport,
  setPaginationNotHtmlReport,
  setSortNotHtmlReport,
} from "../../../../actions/remote/cabinet/report/notHtml"
import {
  addFilterSourcePageReport,
  setPaginationSourcePageReport,
  setSortSourcePageReport,
} from "../../../../actions/remote/cabinet/report/sourcePage"
import {
  getExternal,
  getImages,
  getInternal,
  getNotHtml,
  getSourcePage,
} from "../../../../selectors/remote/cabinet/report"
import filteredResourceSaga from "../../../common/filteredResourceSaga"

function* fetchInternal(action: LoadInternalReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListPageResources,
      selector: getInternal,
      actions: {
        addFilter: addFilterInternalReport,
        setPagination: setPaginationInternalReport,
        setSort: setSortInternalReport,
      },
    })
  })
}

function* fetchExternal(action: LoadExternalReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListPageResources,
      selector: getExternal,
      actions: {
        addFilter: addFilterExternalReport,
        setPagination: setPaginationExternalReport,
        setSort: setSortExternalReport,
      },
    })
  })
}

function* fetchImages(action: LoadImageReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListPageResources,
      selector: getImages,
      actions: {
        addFilter: addFilterImageReport,
        setPagination: setPaginationImageReport,
        setSort: setSortImageReport,
      },
    })
  })
}

function* fetchNotHtml(action: LoadNotHtmlReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListPageResources,
      selector: getNotHtml,
      actions: {
        addFilter: addFilterNotHtmlReport,
        setPagination: setPaginationNotHtmlReport,
        setSort: setSortNotHtmlReport,
      },
    })
  })
}

function* fetchSourcePage(action: LoadSourcePageReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListPageResources,
      selector: getSourcePage,
      actions: {
        addFilter: addFilterSourcePageReport,
        setPagination: setPaginationSourcePageReport,
        setSort: setSortSourcePageReport,
      },
    })
  })
}

export default function* getListPageResourcesSaga(): Generator {
  yield takeLatest(InternalReportActionType.Load, fetchInternal)
  yield takeLatest(ExternalReportActionType.Load, fetchExternal)
  yield takeLatest(NotHtmlReportActionType.Load, fetchNotHtml)
  yield takeLatest(ImageReportActionType.Load, fetchImages)
  yield takeLatest(SourcePageReportActionType.Load, fetchSourcePage)
}
