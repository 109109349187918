import { Card, CardProps } from "antd"
import React from "react"

type Props = CardProps

const CardWithoutBodyPadding: React.FC<Props> = props => {
  return (
    <Card {...props} bodyStyle={{ padding: 0, ...props.bodyStyle }}>
      {props.children}
    </Card>
  )
}

export default CardWithoutBodyPadding
