import {
  AddFilterCanonicalReportAction,
  CancelFilterCanonicalReportAction,
  CanonicalReportActionType,
  ClearFilterCanonicalReportAction,
  ClearSortCanonicalReportAction,
  ExportCanonicalReportAction,
  LoadCanonicalReportAction,
  SetPaginationCanonicalReportAction,
  SetSortCanonicalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/canonical"

const loadCanonicalReport = (
  payload: LoadCanonicalReportAction["payload"],
): LoadCanonicalReportAction => ({
  type: CanonicalReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: CanonicalReportActionType.LoadPending,
    }),
    success: payload => ({
      type: CanonicalReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: CanonicalReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterCanonicalReport = (): ClearFilterCanonicalReportAction => ({
  type: CanonicalReportActionType.ClearFilter,
})

const clearSortCanonicalReport = (): ClearSortCanonicalReportAction => ({
  type: CanonicalReportActionType.ClearSort,
})

const exportCanonicalReport = (
  payload: ExportCanonicalReportAction["payload"],
): ExportCanonicalReportAction => ({
  type: CanonicalReportActionType.Export,
  payload,
})

const addFilterCanonicalReport = (
  payload: AddFilterCanonicalReportAction["payload"],
): AddFilterCanonicalReportAction => ({
  type: CanonicalReportActionType.AddFilter,
  payload,
})

const cancelFilterCanonicalReport = (
  payload: CancelFilterCanonicalReportAction["payload"],
): CancelFilterCanonicalReportAction => ({
  type: CanonicalReportActionType.CancelFilter,
  payload,
})

const setPaginationCanonicalReport = (
  payload: SetPaginationCanonicalReportAction["payload"],
): SetPaginationCanonicalReportAction => ({
  type: CanonicalReportActionType.SetPagination,
  payload,
})

const setSortCanonicalReport = (
  payload: SetSortCanonicalReportAction["payload"],
): SetSortCanonicalReportAction => ({
  type: CanonicalReportActionType.SetSort,
  payload,
})

export {
  loadCanonicalReport,
  clearFilterCanonicalReport,
  clearSortCanonicalReport,
  exportCanonicalReport,
  addFilterCanonicalReport,
  cancelFilterCanonicalReport,
  setPaginationCanonicalReport,
  setSortCanonicalReport,
}
