import React from "react"
import { useDispatch } from "react-redux"
import { useRobotsGraph } from "../../hooks/useRobotsGraph"
import { loadRobotsGraph } from "../../state/actions/remote/cabinet/report/robotsGraph"
import Pie from "../Pie"

type Props = {
  reportId: string
  shareToken?: string
  legend?: boolean
}

const RobotstxtGraph: React.FC<Props> = React.memo(
  props => {
    const { reportId, shareToken, legend } = props
    const { state, info } = useRobotsGraph()

    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!state.data && !state.isLoading) {
        dispatch(
          loadRobotsGraph({
            reportId,
            shareToken,
          }),
        )
      }
    }, [])

    return <Pie graph={state} legend={legend} info={info} />
  },
  () => true, // one render
)

export default RobotstxtGraph
