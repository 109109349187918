import { Button, Card, message, Space, Tooltip } from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useLocation } from "react-router-dom"
import google from "../assets/images/icons/auth/google-brands.svg"
import vk from "../assets/images/icons/auth/vk-brands.svg"
import yandex from "../assets/images/icons/auth/yandex-brands.svg"
import { useTitle } from "../hooks/useTitle"
import { AuthProvider } from "../services/apiClient"
import { login } from "../state/actions/remote/auth"
import { getAuth } from "../state/selectors/remote/auth"
import routes from "./routes"

const socialList = [
  {
    name: "Google",
    icon: google,
    key: AuthProvider.Google,
  },
  {
    name: "Yandex",
    icon: yandex,
    key: AuthProvider.Yandex,
  },
  {
    name: "Vk",
    icon: vk,
    key: AuthProvider.Vk,
  },
  // TODO: uncomment after beta test
  // {
  //   name: "Mail.ru",
  //   icon: mailRu,
  //   key: AuthProvider.MailRu,
  // },
  // {
  //   name: "Facebook",
  //   icon: facebook,
  //   key: AuthProvider.Facebook,
  // },
]

const LoginPage: React.FC = () => {
  const auth = useSelector(getAuth)

  const location = useLocation()

  const dispatch = useDispatch()

  useTitle("Авторизация")

  React.useEffect(() => {
    const params = new URLSearchParams(location.search)
    if (params.get("error")) {
      message.error("Упс! Произошла ошибка. Попробуйте еще раз.")
    }
  }, [])

  const selectProvider = (name: AuthProvider): void => {
    const invite = new URLSearchParams(location.search).get("invite")
    if (invite) {
      dispatch(
        login({
          provider: name,
          invite,
        }),
      )
      return
    }

    dispatch(
      login({
        provider: name,
      }),
    )
  }

  if (auth.data) {
    return <Redirect to={routes.index()} />
  }

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card title="Авторизация">
        <Space size={25}>
          {socialList.map(social => (
            <Tooltip title={social.name} key={social.key}>
              <Button
                type="primary"
                shape="circle"
                onClick={(): void => selectProvider(social.key)}
                icon={
                  <img
                    src={social.icon}
                    alt={social.name}
                    style={{
                      display: "inline-block",
                      fontSize: "inherit",
                      height: "1em",
                      overflow: "visible",
                      verticalAlign: "-0.125em",
                      color: "#fff",
                    }}
                  />
                }
              />
            </Tooltip>
          ))}
        </Space>
      </Card>
    </div>
  )
}

export default LoginPage
