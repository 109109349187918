import { NotHtmlGroupReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/notHtmlGroupReducer"
import { addFilterNotHtmlGroupReport } from "../../../state/actions/remote/cabinet/report/notHtmlGroup"
import { CrawlReportResourceType } from "../../../types/reportResource"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<NotHtmlGroupReportRecord>[] = [
  {
    name: "CSS",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlGroupReport({
          resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Style],
          ],
        }),
      },
    ],
  },
  {
    name: "JS",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlGroupReport({
          resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Script],
          ],
        }),
      },
    ],
  },
  {
    name: "Fonts",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlGroupReport({
          resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Font],
          ],
        }),
      },
    ],
  },
  {
    name: "Неиндексируемое",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlGroupReport({
          resource_robotstxt_ignore: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
]

export default filters
