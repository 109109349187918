import React from "react"
import { Space, Typography } from "antd"

enum Keys {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
}

type Props = {
  headings: {
    [key in Keys]: string[]
  }
}

const Headings: React.FC<Props> = props => {
  const { headings } = props

  const keys = Object.keys(headings) as Keys[]

  return (
    <div>
      {keys.map((key, index) => {
        const items = headings[key]
        return (
          <div key={`${key}_${index}`} style={{ display: "flex" }}>
            <strong>{key}</strong>
            <Space key={key} direction="vertical" style={{ marginLeft: 15 }}>
              {!items.length && key === Keys.H1 && (
                <Typography.Text type="secondary">Не найден</Typography.Text>
              )}
              {items.length !== 0 &&
                items.map(text => (
                  <Typography.Text
                    key={text}
                    type={text.length ? undefined : "secondary"}
                  >
                    {text.length ? text : "пустой"}
                  </Typography.Text>
                ))}
            </Space>
          </div>
        )
      })}
    </div>
  )
}

export default Headings
