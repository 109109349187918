import { Button, Dropdown, Menu } from "antd"
import React from "react"
import { ExportOutlined } from "@ant-design/icons"
import { ApiClientExportFormat } from "../services/apiClient"

const formats = [
  {
    extension: ApiClientExportFormat.Csv,
    label: "CSV",
  },
  {
    extension: ApiClientExportFormat.Xlsx,
    label: "Excel",
  },
]

type Props = {
  onSelect: (format: ApiClientExportFormat) => void
}

const ExportButtons: React.FC<Props> = props => {
  const { onSelect } = props

  return (
    <Dropdown overlay={<ExportButtonsMenu onSelect={onSelect} />}>
      <Button type="primary" icon={<ExportOutlined />}>
        Экспорт
      </Button>
    </Dropdown>
  )
}

export default ExportButtons

type ExportButtonsMenuProps = Props

const ExportButtonsMenu: React.FC<ExportButtonsMenuProps> = props => (
  <Menu>
    {formats.map(format => (
      <Menu.Item
        onClick={() => props.onSelect(format.extension)}
        key={format.extension}
      >
        В {format.label}
      </Menu.Item>
    ))}
  </Menu>
)
