import { Action } from "redux"
import {
  ExportListPageResourcesPayload,
  GetListPageResourcesPayload,
  GetListPageResourcesResponse,
} from "../../../../../../services/apiClient"
import { ExternalReportRecord } from "../../../../reducers/remote/cabinet/report/externalReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum ExternalReportActionType {
  Load = "@@REPORTS/EXTERNAL/LOAD",
  LoadSuccess = "@@REPORTS/EXTERNAL/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/EXTERNAL/LOAD_PENDING",
  LoadFailure = "@@REPORTS/EXTERNAL/LOAD_FAILURE",
  SetPagination = "@@REPORTS/EXTERNAL/SET_PAGINATION",
  SetSort = "@@REPORTS/EXTERNAL/SET_SORT",
  AddFilter = "@@REPORTS/EXTERNAL/ADD_FILTER",
  CancelFilter = "@@REPORTS/EXTERNAL/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/EXTERNAL/CLEAR_FILTER",
  ClearSort = "@@REPORTS/EXTERNAL/CLEAR_SORT",
  Export = "@@REPORTS/EXTERNAL/EXPORT",
}

export type LoadExternalReportAction = LoadAction<
  GetListPageResourcesPayload<ExternalReportRecord>,
  GetListPageResourcesResponse<ExternalReportRecord>,
  ExternalReportActionType.Load,
  ExternalReportActionType.LoadPending,
  ExternalReportActionType.LoadSuccess,
  ExternalReportActionType.LoadFailure
>
export type LoadPendingExternalReportAction = ReturnType<
  LoadExternalReportAction["meta"]["pending"]
>
export type LoadSuccessExternalReportAction = ReturnType<
  LoadExternalReportAction["meta"]["success"]
>
export type LoadFailureExternalReportAction = ReturnType<
  LoadExternalReportAction["meta"]["failure"]
>

export type ClearFilterExternalReportAction = Action<
  ExternalReportActionType.ClearFilter
>

export type ClearSortExternalReportAction = Action<
  ExternalReportActionType.ClearSort
>

export type ExportExternalReportAction = ExportAction<
  ExternalReportRecord,
  ExportListPageResourcesPayload<ExternalReportRecord>,
  ExternalReportActionType.Export
>

export type AddFilterExternalReportAction = AddFilterAction<
  ExternalReportRecord,
  ExternalReportActionType.AddFilter
>

export type CancelFilterExternalReportAction = CancelFilterAction<
  ExternalReportRecord,
  ExternalReportActionType.CancelFilter
>

export type SetSortExternalReportAction = SetSortAction<
  ExternalReportRecord,
  ExternalReportActionType.SetSort
>

export type SetPaginationExternalReportAction = SetPaginationAction<
  ExternalReportActionType.SetPagination
>
