import { IMenuItem } from "./createMenuForSidebar"

export default function findMenuItems(
  menu: IMenuItem[],
  alias: string,
): IMenuItem[] {
  const items: IMenuItem[] = []

  const appendToItems = (parentAlias: string): void => {
    const findItem = menu.find(item => item.alias === parentAlias)
    if (findItem) {
      items.push(findItem)

      if (findItem.parentAlias) {
        const parentItem = menu.find(
          item => item.alias === findItem.parentAlias,
        )
        if (parentItem) appendToItems(parentItem.alias)
      }
    }
  }

  appendToItems(alias)

  return items.reverse()
}
