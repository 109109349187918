import { SagaIterator } from "redux-saga"
import { select } from "redux-saga/effects"
import {
  ResourceFilteredRequest,
  ResourceFilteredRequestExport,
} from "../../../services/apiClient"
import { ExportAction } from "../../@types/actions/filteredResourceAction"
import { Selector } from "../../@types/selectors/selectors"
import { StateResourceFiltered } from "../../@types/state"
import prepareFilteredResourceParams from "../../utils/prepareFilteredResourceParams"

type FilteredResourceSagaArgs<
  Rec,
  Action extends ExportAction<Rec> = ExportAction<Rec>
> = {
  selector: Selector<StateResourceFiltered<Rec, (keyof Rec)[]>>
  action: Action
  request(params: ResourceFilteredRequestExport<Rec>): void
}

export default function* filteredResourceExportSaga<
  Rec,
  Action extends ExportAction<Rec> = ExportAction<Rec>
>({
  selector,
  action,
  request,
}: FilteredResourceSagaArgs<Rec, Action>): SagaIterator<void> {
  const state = yield select(selector)
  const params = prepareParams(state)
  request({
    ...action.payload,
    params: {
      ...action.payload.params,
      ...params,
    },
  })
}

export const prepareParams = <Rec>(
  state: StateResourceFiltered<Rec, (keyof Rec)[]>,
): Omit<ResourceFilteredRequestExport<Rec>["params"], "export-format"> => {
  const params: ResourceFilteredRequest<
    Rec
  >["params"] = prepareFilteredResourceParams(state)

  return {
    sort: params.sort,
    fields: params.fields,
    filter: params.filter,
  }
}
