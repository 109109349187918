import { Dropdown, Menu, message, Typography } from "antd"
import copy from "copy-to-clipboard"
import React from "react"
import { LockOutlined, MoreOutlined } from "@ant-design/icons"
import decodeHostnameInUrl from "../services/url/decodeHostnameInUrl"
import removeProtocolFromUrl from "../services/url/removeProtocolFromUrl"
import ModalAboutPage from "./ModalAboutPage"
import Nullable from "./Nullable"

export type UrlProps = {
  value: string | null
  resourceId?: string | null
}

const Url: React.FC<UrlProps> = props => {
  const { value = "", resourceId } = props

  if (value === null) {
    return <Nullable />
  }

  const copyUrl = (): void => {
    if (copy(value)) {
      message.success("URL скопирован")
    } else {
      message.error("Не удалось скопировать URL")
    }
  }

  let clearUrl: string = decodeHostnameInUrl(value)
  clearUrl = removeProtocolFromUrl(clearUrl)

  const menu = (
    <Menu>
      <Menu.Item onClick={copyUrl}>Скопировать URL</Menu.Item>
    </Menu>
  )

  return (
    <Typography.Text className="table__url">
      {value.includes("https://") && (
        <LockOutlined className="table__url__protocol table__url__protocol_type_is-secure" />
      )}
      <a href={value} target="_blank" rel="noreferrer" title={value}>
        {clearUrl}
      </a>
      <Dropdown overlay={menu}>
        <MoreOutlined className="table__url__icon-external" />
      </Dropdown>
      {resourceId && <ModalAboutPage resourceId={resourceId} />}
    </Typography.Text>
  )
}

export default Url
