const numberFormatShort = new Intl.NumberFormat("default", {
  notation: "compact",
  compactDisplay: "short",
})

const numberFormat = new Intl.NumberFormat("default")

const format = (value: number): string => {
  return numberFormat.format(value)
}

const shortFormat = (value: number): string => {
  return numberFormatShort.format(value)
}

export { format, shortFormat }
