import { combineReducers } from "redux"
import authReducer from "./remote/authReducer"
import billingReducer from "./remote/billingReducer"
import hostReducer from "./remote/cabinet/hostReducer"
import projectReducer from "./remote/cabinet/projectReducer"
import canonicalReducer from "./remote/cabinet/report/canonicalReducer"
import descriptionReducer from "./remote/cabinet/report/descriptionReducer"
import externalReducer from "./remote/cabinet/report/externalReducer"
import headingReducer from "./remote/cabinet/report/headingReducer"
import imageGroupReducer from "./remote/cabinet/report/imageGroupReducer"
import imageReducer from "./remote/cabinet/report/imageReducer"
import indexStatusGraphReducer from "./remote/cabinet/report/indexStatusGraphReducer"
import internalReducer from "./remote/cabinet/report/internalReducer"
import metaRobotsReducer from "./remote/cabinet/report/metaRobotsReducer"
import notHtmlGroupReducer from "./remote/cabinet/report/notHtmlGroupReducer"
import notHtmlReducer from "./remote/cabinet/report/notHtmlReducer"
import redirectReducer from "./remote/cabinet/report/redirectReducer"
import redirectsGraphReducer from "./remote/cabinet/report/redirectsGraphReducer"
import reportReducer from "./remote/cabinet/report/reportReducer"
import robotsGraphReducer from "./remote/cabinet/report/robotsGraphReducer"
import robotstxtReducer from "./remote/cabinet/report/robotstxtReducer"
import selectedPageReducer from "./remote/cabinet/report/selectedPageReducer"
import sharedLinkReducer from "./remote/cabinet/report/sharedLinkReducer"
import sitemapUrlReducer from "./remote/cabinet/report/sitemapUrlReducer"
import sitemapUrlsGraphReducer from "./remote/cabinet/report/sitemapUrlsGraphReducer"
import sourcePageReducer from "./remote/cabinet/report/sourcePageReducer"
import statusCodeReducer from "./remote/cabinet/report/statusCodeReducer"
import statusCodesGraphReducer from "./remote/cabinet/report/statusCodesGraphReducer"
import titleReducer from "./remote/cabinet/report/titleReducer"
import urlReducer from "./remote/cabinet/report/urlReducer"
import urlTreeReducer from "./remote/cabinet/report/urlTreeReducer"
import pageHeaderReducer from "./ui/pageHeaderReducer"

const rootReducer = combineReducers({
  remote: combineReducers({
    auth: authReducer,
    billing: billingReducer,
    cabinet: combineReducers({
      host: hostReducer,
      project: projectReducer,
      report: combineReducers({
        main: reportReducer,
        title: titleReducer,
        description: descriptionReducer,
        heading: headingReducer,
        image: imageReducer,
        imageGroup: imageGroupReducer,
        notHtml: notHtmlReducer,
        notHtmlGroup: notHtmlGroupReducer,
        robotstxt: robotstxtReducer,
        robotsGraph: robotsGraphReducer,
        statusCode: statusCodeReducer,
        statusCodesGraph: statusCodesGraphReducer,
        redirect: redirectReducer,
        redirectsGraph: redirectsGraphReducer,
        canonical: canonicalReducer,
        metaRobots: metaRobotsReducer,
        sitemapUrl: sitemapUrlReducer,
        sitemapUrlsGraph: sitemapUrlsGraphReducer,
        indexStatusGraph: indexStatusGraphReducer,
        internal: internalReducer,
        external: externalReducer,
        url: urlReducer,
        urlTree: urlTreeReducer,
        selectedPage: selectedPageReducer,
        sourcePage: sourcePageReducer,
        sharedLink: sharedLinkReducer,
      }),
    }),
  }),
  ui: combineReducers({
    pageHeader: pageHeaderReducer,
  }),
})

export default rootReducer
