import React from "react"
import RedirectReport from "../../../components/Report/RedirectReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportRedirectReport } from "../../../state/actions/remote/cabinet/report/redirect"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.Redirects)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const RedirectsPage: React.FC<Props> = React.memo(props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportRedirectReport,
  })

  return <RedirectReport reportId={reportId} shareToken={shareToken} />
})

export default RedirectsPage
