import { Typography } from "antd"
import React from "react"
import Nullable from "./Nullable"

type Props = {
  value: string | null
  viewEmptyLine?: boolean
}

const Title: React.FC<Props> = props => {
  const { value, viewEmptyLine = false } = props

  const isEmpty = !value || !value.length

  if (isEmpty && viewEmptyLine) {
    return <Nullable />
  }

  const title = isEmpty ? "Title не указан" : value

  return (
    <Typography.Text
      title={title as string}
      className={`table__title ${isEmpty && "table__title_type_empty"}`}
    >
      {title}
    </Typography.Text>
  )
}

export default Title
