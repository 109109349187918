import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { CreateOrderResponse } from "../../../services/apiClient"
import {
  BillingTypes,
  CreateOrderAction,
} from "../../@types/actions/remote/billing"
import { purchase } from "../../actions/remote/billing"

function* fetchCreateOrder(action: CreateOrderAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: CreateOrderResponse = yield call(
      apiClient.billing.createOrder,
      action.payload,
    )
    yield put(
      purchase({
        orderId: response.data.id,
      }),
    )
    yield put(action.meta.success(response))
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* saga(): Generator {
  yield takeEvery(BillingTypes.CreateOrder, fetchCreateOrder)
}
