import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { LoginResponse } from "../../../services/apiClient"
import { AuthTypes, LoginAction } from "../../@types/actions/remote/auth"

function* fetchLogin(action: LoginAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: LoginResponse = yield call(
      apiClient.auth.login,
      action.payload,
    )
    window.location.href = response.login_url
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* loginSaga(): Generator {
  yield takeEvery(AuthTypes.Login, fetchLogin)
}
