import { Action } from "redux"
import {
  ExportListPageResourcesPayload,
  GetListPageResourcesPayload,
  GetListPageResourcesResponse,
} from "../../../../../../services/apiClient"
import { SourcePageReportRecord } from "../../../../reducers/remote/cabinet/report/sourcePageReducer"
import { ActionWithPayload } from "../../../action"
import {
  AddFilterAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum SourcePageReportActionType {
  Load = "@@REPORTS/SOURCE_PAGES/LOAD",
  LoadSuccess = "@@REPORTS/SOURCE_PAGES/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/SOURCE_PAGES/LOAD_PENDING",
  LoadFailure = "@@REPORTS/SOURCE_PAGES/LOAD_FAILURE",
  SetPagination = "@@REPORTS/SOURCE_PAGES/SET_PAGINATION",
  SetSort = "@@REPORTS/SOURCE_PAGES/SET_SORT",
  AddFilter = "@@REPORTS/SOURCE_PAGES/ADD_FILTER",
  ClearFilter = "@@REPORTS/SOURCE_PAGES/CLEAR_FILTER",
  ClearSort = "@@REPORTS/SOURCE_PAGES/CLEAR_SORT",
  Export = "@@REPORTS/SOURCE_PAGES/EXPORT",
  Clear = "@@REPORTS/SOURCE_PAGES/CLEAR",
}

export type LoadSourcePageReportAction = LoadAction<
  GetListPageResourcesPayload<SourcePageReportRecord>,
  GetListPageResourcesResponse<SourcePageReportRecord>,
  SourcePageReportActionType.Load,
  SourcePageReportActionType.LoadPending,
  SourcePageReportActionType.LoadSuccess,
  SourcePageReportActionType.LoadFailure
>
export type LoadPendingSourcePageReportAction = ReturnType<
  LoadSourcePageReportAction["meta"]["pending"]
>
export type LoadSuccessSourcePageReportAction = ReturnType<
  LoadSourcePageReportAction["meta"]["success"]
>
export type LoadFailureSourcePageReportAction = ReturnType<
  LoadSourcePageReportAction["meta"]["failure"]
>

export type ClearFilterSourcePageReportAction = Action<
  SourcePageReportActionType.ClearFilter
>

export type ClearSortSourcePageReportAction = Action<
  SourcePageReportActionType.ClearSort
>

export type ClearSourcePageReportAction = Action<
  SourcePageReportActionType.Clear
>

export type ExportSourcePageReportAction = ActionWithPayload<
  ExportListPageResourcesPayload<SourcePageReportRecord>,
  SourcePageReportActionType.Export
>

export type AddFilterSourcePageReportAction = AddFilterAction<
  SourcePageReportRecord,
  SourcePageReportActionType.AddFilter
>

export type SetSortSourcePageReportAction = SetSortAction<
  SourcePageReportRecord,
  SourcePageReportActionType.SetSort
>

export type SetPaginationSourcePageReportAction = SetPaginationAction<
  SourcePageReportActionType.SetPagination
>
