import env from "../../lib/env"
import { MakeRequestParams } from "./@types/makeRequest"

export default function generateApiUrl(
  path: string,
  query?: MakeRequestParams,
): string {
  const apiUrl = new URL(env.API_URL + path)

  if (query && Object.keys(query).length) {
    const params: Record<string, string> = {}
    for (const field in query) {
      const value = query[field]
      if (value === null || value === undefined) {
        continue
      }

      if (typeof value === "object") {
        params[field] = JSON.stringify(value)
      } else {
        params[field] = value.toString()
      }
    }

    apiUrl.search = new URLSearchParams(params).toString()
  }

  return apiUrl.toString()
}
