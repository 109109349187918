import { Action } from "redux"
import {
  ExportListSitemapUrlsPayload,
  GetListSitemapUrlsPayload,
  GetListSitemapUrlsResponse,
} from "../../../../../../services/apiClient"
import { SitemapUrlReportRecord } from "../../../../reducers/remote/cabinet/report/sitemapUrlReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum SitemapUrlReportActionType {
  Load = "@@REPORTS/SITEMAP_URLS/LOAD",
  LoadSuccess = "@@REPORTS/SITEMAP_URLS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/SITEMAP_URLS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/SITEMAP_URLS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/SITEMAP_URLS/SET_PAGINATION_",
  SetSort = "@@REPORTS/SITEMAP_URLS/SET_SORT",
  AddFilter = "@@REPORTS/SITEMAP_URLS/ADD_FILTER",
  CancelFilter = "@@REPORTS/SITEMAP_URLS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/SITEMAP_URLS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/SITEMAP_URLS/CLEAR_SORT",
  Export = "@@REPORTS/SITEMAP_URLS/EXPORT",
}

export type LoadSitemapUrlReportAction = LoadAction<
  GetListSitemapUrlsPayload,
  GetListSitemapUrlsResponse<SitemapUrlReportRecord>,
  SitemapUrlReportActionType.Load,
  SitemapUrlReportActionType.LoadPending,
  SitemapUrlReportActionType.LoadSuccess,
  SitemapUrlReportActionType.LoadFailure
>
export type LoadPendingSitemapUrlReportAction = ReturnType<
  LoadSitemapUrlReportAction["meta"]["pending"]
>
export type LoadSuccessSitemapUrlReportAction = ReturnType<
  LoadSitemapUrlReportAction["meta"]["success"]
>
export type LoadFailureSitemapUrlReportAction = ReturnType<
  LoadSitemapUrlReportAction["meta"]["failure"]
>

export type ClearFilterSitemapUrlReportAction = Action<
  SitemapUrlReportActionType.ClearFilter
>

export type ClearSortSitemapUrlReportAction = Action<
  SitemapUrlReportActionType.ClearSort
>

export type ExportSitemapUrlReportAction = ExportAction<
  SitemapUrlReportRecord,
  ExportListSitemapUrlsPayload<SitemapUrlReportRecord>,
  SitemapUrlReportActionType.Export
>

export type AddFilterSitemapUrlReportAction = AddFilterAction<
  SitemapUrlReportRecord,
  SitemapUrlReportActionType.AddFilter
>

export type CancelFilterSitemapUrlReportAction = CancelFilterAction<
  SitemapUrlReportRecord,
  SitemapUrlReportActionType.CancelFilter
>

export type SetSortSitemapUrlReportAction = SetSortAction<
  SitemapUrlReportRecord,
  SitemapUrlReportActionType.SetSort
>

export type SetPaginationSitemapUrlReportAction = SetPaginationAction<
  SitemapUrlReportActionType.SetPagination
>
