import { Card, Col, Empty, Row, Space, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import { useRedirectsGraph } from "../../hooks/useRedirectsGraph"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import formatters from "../../services/formatters"
import { RedirectReportRecord } from "../../state/@types/reducers/remote/cabinet/report/redirectReducer"
import {
  addFilterRedirectReport,
  cancelFilterRedirectReport,
  clearFilterRedirectReport,
  clearSortRedirectReport,
  loadRedirectReport,
  setPaginationRedirectReport,
  setSortRedirectReport,
} from "../../state/actions/remote/cabinet/report/redirect"
import { getRedirects } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import RedirectsGraph from "../RedirectsGraph"
import Statistics from "../Statistic"
import Table from "../Table"
import IconWithTooltip from "../ui/IconWithTooltip"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const RedirectReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Information reportId={reportId} shareToken={shareToken} />
      <RedirectReportTable reportId={reportId} shareToken={shareToken} />
    </Space>
  )
}

export default RedirectReport

type RedirectReportTableProps = {
  reportId: string
  shareToken?: string
}

const RedirectReportTable: React.FC<RedirectReportTableProps> = props => {
  const { reportId, shareToken } = props

  const redirectsState = useSelector(getRedirects)

  const _columns = useColumns(
    columns,
    redirectsState.sort,
    redirectsState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadRedirectReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationRedirectReport,
    setSortRedirectReport,
    clearSortRedirectReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterRedirectReport,
    cancelFilterRedirectReport,
    clearFilterRedirectReport,
    setSortRedirectReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterRedirectReport,
    cancelFilterRedirectReport,
  )

  if (redirectsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        redirectsState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.redirects}
                activeFilter={redirectsState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={redirectsState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={redirectsState.data}
        loading={redirectsState.isLoading}
        pagination={redirectsState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

const inputOptions: InputSearchByFieldsValueType<RedirectReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
  { label: "Конечный URL", value: "resource_redirect_url" },
  { label: "Title", value: "page_title" },
]

const columns: ColumnsType<RedirectReportRecord> = [
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      redirects: record.resource_redirects,
      url: {
        value: record.resource_url,
      },
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.statusCode({
    key: "resource_status_code",
    dataIndex: "resource_status_code",
  }),
  columnsType.indexStatus(
    record => ({
      robotstxtIgnore: record.resource_robotstxt_ignore,
      robotstxtLine: record.resource_robotstxt_line,
      canonicalUrl: record.page_canonical_url,
    }),
    {
      key: "resource_index_status",
      dataIndex: "resource_index_status",
    },
  ),
  columnsType.pageInformation(
    record => ({
      title: record.page_title,
      url: {
        value: record.resource_redirect_url,
      },
    }),
    {
      key: "resource_redirect_url",
      title: "Конечный URL",
      dataIndex: "resource_redirect_url",
    },
  ),
  columnsType.statusCode({
    key: "resource_redirect_status_code",
    title: "Код ответа конечного URL",
    dataIndex: "resource_redirect_status_code",
    width: 115,
  }),
]

type InformationProps = {
  reportId: string
  shareToken?: string
}

const Information: React.FC<InformationProps> = props => {
  const { reportId, shareToken } = props
  const { state, info } = useRedirectsGraph()

  return (
    <Row>
      <Col span={4}>
        <Card>
          <RedirectsGraph
            shareToken={shareToken}
            legend={false}
            reportId={reportId}
          />
        </Card>
      </Col>
      {state.data && (
        <Col span={8}>
          <Row style={{ height: "100%" }}>
            {state.data.values.map(item => (
              <Col key={item.key} span={6}>
                <Card style={{ height: "100%" }}>
                  <Statistics
                    title={info[item.key].shortLabel}
                    value={formatters.number.format(item.value)}
                    groupSeparator=" "
                    titlePrefix={
                      <IconWithTooltip
                        description={info[item.key].description}
                      />
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  )
}
