import React from "react"
import SitemapUrlReport from "../../../components/Report/SitemapUrlReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportSitemapUrlReport } from "../../../state/actions/remote/cabinet/report/sitemapUrl"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.SitemapUrls)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const SitemapUrlsPage: React.FC<Props> = React.memo((props: Props) => {
  const { reportId, shareToken }: Props = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportSitemapUrlReport,
  })

  return <SitemapUrlReport reportId={reportId} shareToken={shareToken} />
})

export default SitemapUrlsPage
