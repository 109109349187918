import {
  AuthTypes,
  ClearAuthAction,
  LoadSessionAction,
  LoginAction,
  LogoutAction,
  UpdateProfileAction,
} from "../../@types/actions/remote/auth"

const clearAuth = (): ClearAuthAction => ({
  type: AuthTypes.Clear,
})

const loadSession = (): LoadSessionAction => ({
  type: AuthTypes.Load,
  meta: {
    pending: () => ({ type: AuthTypes.LoadPending }),
    success: session => ({
      type: AuthTypes.LoadSuccess,
      payload: session,
    }),
    failure: error => ({
      type: AuthTypes.LoadFailure,
      payload: error,
    }),
  },
})

const login = (payload: LoginAction["payload"]): LoginAction => ({
  type: AuthTypes.Login,
  payload,
  meta: {
    pending: () => ({ type: AuthTypes.LoginPending }),
    success: () => ({ type: AuthTypes.LoginSuccess }),
    failure: error => ({
      type: AuthTypes.LoginFailure,
      payload: error,
    }),
  },
})

const logout = (): LogoutAction => ({
  type: AuthTypes.Logout,
  meta: {
    pending: () => ({ type: AuthTypes.LogoutPending }),
    success: () => ({ type: AuthTypes.LogoutSuccess }),
    failure: error => ({ type: AuthTypes.LogoutFailure, payload: error }),
  },
})

const updateProfile = (
  payload: UpdateProfileAction["payload"],
): UpdateProfileAction => ({
  type: AuthTypes.UpdateProfile,
  payload,
  meta: {
    pending: () => ({ type: AuthTypes.UpdateProfilePending }),
    success: payload => ({ type: AuthTypes.UpdateProfileSuccess, payload }),
    failure: error => ({
      type: AuthTypes.UpdateProfileFailure,
      payload: error,
    }),
  },
})

export { clearAuth, loadSession, login, logout, updateProfile }
