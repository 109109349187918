/**
 * APP_NAME represents a name of the application, should be constant.
 */
const APP_NAME = "cabinet-ui"

/**
 * APP_VERSION represents a build tag. It's set during a build phase and should not
 * be changed in the code.
 */
const APP_VERSION = "master-7a122b4-20221226T12:49:45"

interface IEnv {
  APP_NAME: string
  VERSION: string
  ENVIRONMENT: string
  API_URL: string
  CABINET_URL: string
  ADMIN_URL: string
  LANDING_URL: string
  DISCOUNT_YEARLY: number
}

/**
 * Supported environment names.
 * TODO: not sure how to reference this enum type in IConfig.logLevel environment.
 */
export enum Environment {
  Local = "local",
  Dev = "dev",
  Prod = "prod",
}

const env: IEnv = {
  APP_NAME: APP_NAME,
  VERSION: APP_VERSION,
  ENVIRONMENT: getEnvOrDefault("REACT_APP_ENVIRONMENT", Environment.Local),
  API_URL: addTrailingSlash(getEnvOrPanic("REACT_APP_API_URL")),
  CABINET_URL: addTrailingSlash(getEnvOrPanic("REACT_APP_CABINET_URL")),
  ADMIN_URL: addTrailingSlash(getEnvOrPanic("REACT_APP_ADMIN_URL")),
  LANDING_URL: addTrailingSlash(getEnvOrPanic("REACT_APP_LANDING_URL")),
  DISCOUNT_YEARLY: getEnvIntOrDefault("REACT_APP_DISCOUNT_YEARLY", 20),
}

function getEnvOrDefault(varName: string, defaultVal: string): string {
  return process.env[varName] || defaultVal
}

function getEnvOrPanic(varName: string): string {
  if (typeof process.env[varName] === undefined || !process.env[varName]) {
    throw new Error(`ERROR: environment variable not specified: ${varName}`)
  }

  return process.env[varName] || ""
}

function getEnvIntOrDefault(varName: string, defaultVal: number): number {
  if (typeof process.env[varName] === undefined || !process.env[varName]) {
    return defaultVal
  }

  return parseInt(String(process.env[varName]))
}

function addTrailingSlash(s: string): string {
  return s.endsWith("/") ? s : s + "/"
}

export default env
