import { Col, Empty, Row, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { NotHtmlReportRecord } from "../../state/@types/reducers/remote/cabinet/report/notHtmlReducer"
import {
  addFilterNotHtmlReport,
  cancelFilterNotHtmlReport,
  clearFilterNotHtmlReport,
  clearSortNotHtmlReport,
  loadNotHtmlReport,
  setPaginationNotHtmlReport,
  setSortNotHtmlReport,
} from "../../state/actions/remote/cabinet/report/notHtml"
import { getNotHtml } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import columnsType from "../ui/Table/columnsType"
import Url from "../Url"

type Props = {
  reportId: string
  shareToken?: string
}

const NotHtmlReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const notHtmlState = useSelector(getNotHtml)

  const _columns = useColumns(
    columns,
    notHtmlState.sort,
    notHtmlState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadNotHtmlReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationNotHtmlReport,
    setSortNotHtmlReport,
    clearSortNotHtmlReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterNotHtmlReport,
    cancelFilterNotHtmlReport,
    clearFilterNotHtmlReport,
    setSortNotHtmlReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterNotHtmlReport,
    cancelFilterNotHtmlReport,
  )

  if (notHtmlState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        notHtmlState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters<NotHtmlReportRecord>
                filters={filters.providers.notHtml}
                activeFilter={notHtmlState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={notHtmlState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="page_resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={notHtmlState.data}
        loading={notHtmlState.isLoading}
        pagination={notHtmlState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

export default NotHtmlReport

const inputOptions: InputSearchByFieldsValueType<NotHtmlReportRecord>[] = [
  { label: "URL", value: "page_resource_url" },
  { label: "Источник", value: "src_resource_url" },
  { label: "Title - Источник", value: "src_page_title" },
]

const columns: ColumnsType<NotHtmlReportRecord> = [
  {
    key: "page_resource_url",
    title: "URL",
    dataIndex: "page_resource_url",
    sorter: true,
    render: (value: string, record) => <Url value={value} />,
  },
  columnsType.pageInformation(
    record => ({
      resourceId: record.src_resource_id,
      url: {
        value: record.src_resource_url,
      },
      title: record.src_page_title,
    }),
    {
      key: "src_resource_url",
      title: "Источник",
      dataIndex: "src_resource_url",
    },
  ),
  columnsType.statusCode({
    key: "target_resource_status_code",
    dataIndex: "target_resource_status_code",
  }),
  columnsType.resourceType("target_resource_content_type", {
    key: "target_resource_type",
    dataIndex: "target_resource_type",
  }),
  columnsType.robotstxtIgnore(
    record => ({
      value: record.target_resource_robotstxt_ignore,
      lineNumber: record.target_resource_robotstxt_line,
      isExternal: record.target_resource_is_external,
    }),
    {
      key: "target_resource_robotstxt_ignore",
      dataIndex: "target_resource_robotstxt_ignore",
      sorter: true,
    },
  ),
]
