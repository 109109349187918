import { InternalReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/internalReducer"
import { addFilterInternalReport } from "../../../state/actions/remote/cabinet/report/internal"
import { AnchorType } from "../../../types/reportLink"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<InternalReportRecord>[] = [
  {
    name: "Битые ссылки",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterInternalReport({
          page_resource_is_broken: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "С редиректом",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterInternalReport({
          target_resource_status_code: [
            [ApiClientFilterType.Gte, 300],
            [ApiClientFilterType.Lte, 399],
          ],
        }),
      },
    ],
  },
  {
    name: "nofollow",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterInternalReport({
          page_resource_anchor_is_nofollow: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Анкор - текст",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterInternalReport({
          page_resource_anchor_anchor_type: [
            [ApiClientFilterType.In, [AnchorType.TEXT]],
          ],
        }),
      },
    ],
  },
  {
    name: "Анкор - img",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterInternalReport({
          page_resource_anchor_anchor_type: [
            [ApiClientFilterType.In, [AnchorType.IMG]],
          ],
        }),
      },
    ],
  },
  {
    name: "Анкор - другое",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterInternalReport({
          page_resource_anchor_anchor_type: [
            [ApiClientFilterType.In, [AnchorType.OTHER]],
          ],
        }),
      },
    ],
  },
]

export default filters
