import {
  ApiClientFilter,
  ApiClientFilterRules,
  ApiClientFilterType,
} from "../apiClient"

const mergeFilters = <Rec>(
  ...filters: ApiClientFilter<Rec>[]
): ApiClientFilter<Rec> | undefined => {
  if (filters.length === 1) {
    return filters[0]
  }

  const result: { [key in keyof Rec]?: ApiClientFilterRules } = {}

  for (const filter of filters) {
    for (const field in filter) {
      const rules = filter[field]
      for (const [filterType, value] of rules as ApiClientFilterRules) {
        if (!result[field]) {
          result[field] = []
        }

        // check exist the rules for field and check exist filter with value filterType
        if (
          !result[field]?.length ||
          !result[field]?.filter(([type]) => filterType === type).length
        ) {
          result[field]?.push([filterType, value])
          continue
        }

        result[field] = result[field]?.map(([type, val]) => {
          if (filterType !== type) {
            return [type, val]
          }

          if (
            [ApiClientFilterType.In, ApiClientFilterType.NotIn].includes(
              type,
            ) &&
            Array.isArray(val) &&
            Array.isArray(value)
          ) {
            return [type, [...val, ...value]]
          }

          return [type, value]
        })
      }
    }
  }

  return result as ApiClientFilter<Rec>
}
/**
 * Check has filedFilter inside mainFilter
 *
 * @param mainFilter - filter transmitted from state
 * @param filter - filter which the required check inside stateFilter
 *
 * @return boolean
 *
 * */
const hasIsActiveFilter = <Rec>(
  mainFilter?: ApiClientFilter<Rec>,
  filter?: ApiClientFilter<Rec>,
): boolean => {
  if (!mainFilter || !filter) {
    return false
  }

  let countActiveFilters = 0
  let countFilters = 0
  for (const field in filter) {
    countFilters += filter[field]?.length ?? 0
    if (!mainFilter[field]) {
      continue
    }

    for (const [ft, val] of filter[field] as ApiClientFilterRules) {
      const filterParamsItem = mainFilter[field]?.filter(
        ([filterType]) => filterType === ft,
      )
      if (!filterParamsItem?.length) {
        continue
      }

      const [, _val] = filterParamsItem[0]
      if (
        (ft === ApiClientFilterType.In || ft === ApiClientFilterType.NotIn) &&
        Array.isArray(_val) &&
        Array.isArray(val)
      ) {
        if (_val.length === 1 && val.length === 1) {
          if (_val[0] === val[0]) {
            countActiveFilters++
            continue
          }

          continue
        }

        if (!_val.filter(_v => val.includes(_v)).length) {
          continue
        }

        countActiveFilters++
        continue
      }

      if (val === _val) {
        countActiveFilters++
      }
    }
  }

  return countFilters === countActiveFilters
}

const cancelFilters = <Rec>(
  mainFilter: ApiClientFilter<Rec>,
  canceledFilter: ApiClientFilter<Rec>,
): ApiClientFilter<Rec> => {
  const resultFilter: ApiClientFilter<Rec> = {
    ...mainFilter,
  }
  for (const field in canceledFilter) {
    const activeFilter = mainFilter[field as keyof Rec] as ApiClientFilterRules
    if (!activeFilter) {
      continue
    }

    const filterRules = canceledFilter[field] as ApiClientFilterRules
    for (const [filterType, filterValue] of filterRules) {
      resultFilter[field] = (resultFilter[
        field
      ] as ApiClientFilterRules).filter(
        ([activeFilterType, activeFilterValue]) => {
          if (activeFilterType !== filterType) {
            return true
          }

          if (activeFilterType === ApiClientFilterType.Like && !filterValue) {
            return false
          }

          if (activeFilterValue !== filterValue) {
            return true
          }

          return false
        },
      )
    }
  }

  return resultFilter
}

export { mergeFilters, hasIsActiveFilter, cancelFilters }
