import { Card, Col, Empty, Row, Space, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import {
  useRobotsGraph,
  UseRobotsGraphResult,
} from "../../hooks/useRobotsGraph"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import formatters from "../../services/formatters"
import {
  RobotstxtReportRecord,
  RobotstxtReportState,
} from "../../state/@types/reducers/remote/cabinet/report/robotstxtReducer"
import {
  addFilterRobotstxtReport,
  cancelFilterRobotstxtReport,
  clearFilterRobotstxtReport,
  clearSortRobotstxtReport,
  loadRobotstxtReport,
  setPaginationRobotstxtReport,
  setSortRobotstxtReport,
} from "../../state/actions/remote/cabinet/report/robotstxt"
import { getRobotstxt } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import RobotstxtGraph from "../RobotstxtGraph"
import Statistics from "../Statistic"
import Table from "../Table"
import IconWithTooltip from "../ui/IconWithTooltip"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const RobotstxtReport: React.FC<Props> = (props: Props) => {
  const { reportId, shareToken }: Props = props
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Information shareToken={shareToken} reportId={reportId} />
      <RobotstxtReportTable shareToken={shareToken} reportId={reportId} />
    </Space>
  )
}

export default RobotstxtReport

type RobotstxtReportTableProps = {
  reportId: string
  shareToken?: string
}

const RobotstxtReportTable: React.FC<RobotstxtReportTableProps> = (
  props: RobotstxtReportTableProps,
) => {
  const { reportId, shareToken }: RobotstxtReportTableProps = props

  const robotstxtState: RobotstxtReportState = useSelector(getRobotstxt)

  const _columns: ColumnsType<RobotstxtReportRecord> = useColumns(
    columns,
    robotstxtState.sort,
    robotstxtState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadRobotstxtReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationRobotstxtReport,
    setSortRobotstxtReport,
    clearSortRobotstxtReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterRobotstxtReport,
    cancelFilterRobotstxtReport,
    clearFilterRobotstxtReport,
    setSortRobotstxtReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterRobotstxtReport,
    cancelFilterRobotstxtReport,
  )

  if (robotstxtState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        robotstxtState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.robotstxt}
                activeFilter={robotstxtState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={robotstxtState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        dataSource={robotstxtState.data}
        loading={robotstxtState.isLoading}
        onChangeTable={onChangeTable}
        columns={_columns}
        pagination={robotstxtState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

const inputOptions: InputSearchByFieldsValueType<RobotstxtReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
]

const columns: ColumnsType<RobotstxtReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  columnsType.pageInformation(
    (record: RobotstxtReportRecord) => ({
      resourceId: record.resource_id,
      redirects: record.resource_redirects,
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
      url: {
        value: record.resource_url,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.robotstxtIgnore(
    (record: RobotstxtReportRecord) => ({
      value: record.resource_robotstxt_ignore,
      lineNumber: record.resource_robotstxt_line,
    }),
    {
      key: "resource_robotstxt_ignore",
      dataIndex: "resource_robotstxt_ignore",
      sorter: true,
    },
  ),
  columnsType.statusCode({
    key: "resource_status_code",
    dataIndex: "resource_status_code",
  }),
]

type InformationProps = {
  reportId: string
  shareToken?: string
}

const Information: React.FC<InformationProps> = props => {
  const { reportId, shareToken }: InformationProps = props

  const { state, info }: UseRobotsGraphResult = useRobotsGraph()

  if (state.isLoading) {
    return <Spin />
  }

  return (
    <Row>
      <Col span={4}>
        <Card>
          <RobotstxtGraph
            shareToken={shareToken}
            legend={false}
            reportId={reportId}
          />
        </Card>
      </Col>
      {state.data && (
        <Col span={8}>
          <Row style={{ height: "100%" }}>
            <Col key="key" span={6}>
              <Card style={{ height: "100%" }}>
                <Statistics
                  title="Всего страниц просканировано"
                  value={formatters.number.shortFormat(state.data?.total)}
                  titlePrefix={
                    <IconWithTooltip description="Общее количество страниц, обнаруженных при сканировании сайта" />
                  }
                />
              </Card>
            </Col>
            {state.data.values.map(item => (
              <Col key={item.key} span={6}>
                <Card style={{ height: "100%" }}>
                  <Statistics
                    title={info[item.key].label}
                    value={formatters.number.shortFormat(item.value)}
                    groupSeparator=" "
                    titlePrefix={
                      <IconWithTooltip
                        description={info[item.key].description}
                      />
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  )
}
