import { UrlTreeActionType } from "../../../../@types/actions/remote/cabinet/report/urlTree"
import {
  UrlTreeActions,
  UrlTreeState,
} from "../../../../@types/reducers/remote/cabinet/report/urlTreeReducer"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: UrlTreeState = {
  isLoading: false,
}

const urlTreeReducer = (
  state: UrlTreeState = initState,
  action: UrlTreeActions,
): UrlTreeState => {
  switch (action.type) {
    case UrlTreeActionType.LoadSuccess: {
      return {
        data: action.payload.data,
        isLoading: false,
      }
    }
    case UrlTreeActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case UrlTreeActionType.LoadPending:
      return updateStateSetPending(state)

    default:
      return state
  }
}

export default urlTreeReducer
