import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { MetaRobotsReportRecord } from "../../../../reducers/remote/cabinet/report/metaRobotsReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum MetaRobotsReportActionType {
  Load = "@@REPORTS/META_ROBOTS/LOAD",
  LoadSuccess = "@@REPORTS/META_ROBOTS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/META_ROBOTS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/META_ROBOTS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/META_ROBOTS/SET_PAGINATION",
  SetSort = "@@REPORTS/META_ROBOTS/SET_SORT",
  AddFilter = "@@REPORTS/META_ROBOTS/ADD_FILTER",
  CancelFilter = "@@REPORTS/META_ROBOTS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/META_ROBOTS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/META_ROBOTS/CLEAR_SORTs",
  Export = "@@REPORTS/META_ROBOTS/EXPORT",
}

export type LoadMetaRobotsReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<MetaRobotsReportRecord>,
  MetaRobotsReportActionType.Load,
  MetaRobotsReportActionType.LoadPending,
  MetaRobotsReportActionType.LoadSuccess,
  MetaRobotsReportActionType.LoadFailure
>
export type LoadPendingMetaRobotsReportAction = ReturnType<
  LoadMetaRobotsReportAction["meta"]["pending"]
>
export type LoadSuccessMetaRobotsReportAction = ReturnType<
  LoadMetaRobotsReportAction["meta"]["success"]
>
export type LoadFailureMetaRobotsReportAction = ReturnType<
  LoadMetaRobotsReportAction["meta"]["failure"]
>

export type ClearFilterMetaRobotsReportAction = Action<
  MetaRobotsReportActionType.ClearFilter
>

export type ClearSortMetaRobotsReportAction = Action<
  MetaRobotsReportActionType.ClearSort
>

export type ExportMetaRobotsReportAction = ExportAction<
  MetaRobotsReportRecord,
  ExportListPagesPayload<MetaRobotsReportRecord>,
  MetaRobotsReportActionType.Export
>

export type AddFilterMetaRobotsReportAction = AddFilterAction<
  MetaRobotsReportRecord,
  MetaRobotsReportActionType.AddFilter
>

export type CancelFilterMetaRobotsReportAction = CancelFilterAction<
  MetaRobotsReportRecord,
  MetaRobotsReportActionType.CancelFilter
>

export type SetSortMetaRobotsReportAction = SetSortAction<
  MetaRobotsReportRecord,
  MetaRobotsReportActionType.SetSort
>

export type SetPaginationMetaRobotsReportAction = SetPaginationAction<
  MetaRobotsReportActionType.SetPagination
>
