import {
  AddFilterDescriptionReportAction,
  CancelFilterDescriptionReportAction,
  ClearFilterDescriptionReportAction,
  ClearSortDescriptionReportAction,
  DescriptionReportActionType,
  ExportDescriptionReportAction,
  LoadDescriptionReportAction,
  SetPaginationDescriptionReportAction,
  SetSortDescriptionReportAction,
} from "../../../../@types/actions/remote/cabinet/report/description"

const loadDescriptionReport = (
  payload: LoadDescriptionReportAction["payload"],
): LoadDescriptionReportAction => ({
  type: DescriptionReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: DescriptionReportActionType.LoadPending,
    }),
    success: payload => ({
      type: DescriptionReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: DescriptionReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterDescriptionReport = (): ClearFilterDescriptionReportAction => ({
  type: DescriptionReportActionType.ClearFilter,
})

const clearSortDescriptionReport = (): ClearSortDescriptionReportAction => ({
  type: DescriptionReportActionType.ClearSort,
})

const exportDescriptionReport = (
  payload: ExportDescriptionReportAction["payload"],
): ExportDescriptionReportAction => ({
  type: DescriptionReportActionType.Export,
  payload,
})

const addFilterDescriptionReport = (
  payload: AddFilterDescriptionReportAction["payload"],
): AddFilterDescriptionReportAction => ({
  type: DescriptionReportActionType.AddFilter,
  payload,
})

const cancelFilterDescriptionReport = (
  payload: CancelFilterDescriptionReportAction["payload"],
): CancelFilterDescriptionReportAction => ({
  type: DescriptionReportActionType.CancelFilter,
  payload,
})

const setPaginationDescriptionReport = (
  payload: SetPaginationDescriptionReportAction["payload"],
): SetPaginationDescriptionReportAction => ({
  type: DescriptionReportActionType.SetPagination,
  payload,
})

const setSortDescriptionReport = (
  payload: SetSortDescriptionReportAction["payload"],
): SetSortDescriptionReportAction => ({
  type: DescriptionReportActionType.SetSort,
  payload,
})

export {
  loadDescriptionReport,
  clearFilterDescriptionReport,
  clearSortDescriptionReport,
  exportDescriptionReport,
  addFilterDescriptionReport,
  cancelFilterDescriptionReport,
  setPaginationDescriptionReport,
  setSortDescriptionReport,
}
