import { Card, Col, Empty, Row, Space, Spin, Tag } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import { useSitemapUrlsGraph } from "../../hooks/useSitemapUrlsGraph"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import formatters from "../../services/formatters"
import { SitemapUrlReportRecord } from "../../state/@types/reducers/remote/cabinet/report/sitemapUrlReducer"
import {
  addFilterSitemapUrlReport,
  cancelFilterSitemapUrlReport,
  clearFilterSitemapUrlReport,
  clearSortSitemapUrlReport,
  loadSitemapUrlReport,
  setPaginationSitemapUrlReport,
  setSortSitemapUrlReport,
} from "../../state/actions/remote/cabinet/report/sitemapUrl"
import { getSitemapUrls } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import SitemapUrlsGraph from "../SitemapUrlsGraph"
import Statistics from "../Statistic"
import Table from "../Table"
import IconWithTooltip from "../ui/IconWithTooltip"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const SitemapUrlReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Information shareToken={shareToken} reportId={reportId} />
      <SitemapUrlReportTable shareToken={shareToken} reportId={reportId} />
    </Space>
  )
}

export default SitemapUrlReport

type SitemapUrlReportTableProps = {
  reportId: string
  shareToken?: string
}

const SitemapUrlReportTable: React.FC<SitemapUrlReportTableProps> = props => {
  const { reportId, shareToken } = props

  const sitemapUrlReportState = useSelector(getSitemapUrls)

  const _columns = useColumns(
    columns,
    sitemapUrlReportState.sort,
    sitemapUrlReportState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadSitemapUrlReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationSitemapUrlReport,
    setSortSitemapUrlReport,
    clearSortSitemapUrlReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterSitemapUrlReport,
    cancelFilterSitemapUrlReport,
    clearFilterSitemapUrlReport,
    setSortSitemapUrlReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterSitemapUrlReport,
    cancelFilterSitemapUrlReport,
  )

  if (sitemapUrlReportState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        sitemapUrlReportState.data === undefined ? (
          <Spin />
        ) : (
          <Space style={{ width: "100%" }} direction="vertical">
            <InputSearchByFields
              onSearch={onSearchInput}
              onClear={onClearInput}
              activeFilter={sitemapUrlReportState.filter}
              options={inputOptions}
            />
            <Filters
              filters={filters.providers.sitemapUrls}
              activeFilter={sitemapUrlReportState.filter}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          </Space>
        )
      }
    >
      <Table
        rowKey="sitemap_url_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={sitemapUrlReportState.data}
        loading={sitemapUrlReportState.isLoading}
        pagination={sitemapUrlReportState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

const inputOptions: InputSearchByFieldsValueType<SitemapUrlReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
]

const columns: ColumnsType<SitemapUrlReportRecord> = [
  columnsType.pageInformation(
    (record: SitemapUrlReportRecord) => ({
      resourceId: record.resource_id,
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
      url: {
        value:
          record.resource_url === null
            ? record.sitemap_url_url
            : record.resource_url,
      },
    }),
    {
      key: "resource_url_no_scheme",
      title: "URL страницы/редиректа",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.statusCode({
    key: "resource_status_code",
    dataIndex: "resource_status_code",
  }),
  columnsType.indexStatus(
    record => ({
      robotstxtIgnore: record.resource_robotstxt_ignore,
      robotstxtLine: record.resource_robotstxt_line,
      canonicalUrl: record.page_canonical_url,
    }),
    {
      key: "resource_index_status",
      dataIndex: "resource_index_status",
    },
  ),
  {
    key: "resource_without_link",
    title: "Осиротевший",
    align: "center",
    dataIndex: "resource_without_link",
    width: 125,
    render: (value: boolean | null): React.ReactNode =>
      !value ? <Tag color="success">Нет</Tag> : <Tag color="error">Да</Tag>,
  },
]

type InformationProps = {
  reportId: string
  shareToken?: string
}

const Information: React.FC<InformationProps> = props => {
  const { reportId, shareToken } = props
  const { state, info } = useSitemapUrlsGraph()

  return (
    <Row>
      <Col span={4}>
        <Card>
          <SitemapUrlsGraph
            reportId={reportId}
            legend={false}
            shareToken={shareToken}
          />
        </Card>
      </Col>
      {state.data && (
        <Col span={8}>
          <Row style={{ height: "100%" }}>
            {state.data.values.map(item => (
              <Col key={item.key} span={6}>
                <Card style={{ height: "100%" }}>
                  <Statistics
                    title={info[item.key].label}
                    value={formatters.number.format(item.value)}
                    groupSeparator=" "
                    titlePrefix={
                      <IconWithTooltip
                        description={info[item.key].description}
                      />
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  )
}
