import { Col, Empty, Row, Spin, Tooltip } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { UrlReportRecord } from "../../state/@types/reducers/remote/cabinet/report/urlReducer"
import {
  addFilterUrlReport,
  cancelFilterUrlReport,
  clearFilterUrlReport,
  clearSortUrlReport,
  loadUrlReport,
  setPaginationUrlReport,
  setSortUrlReport,
} from "../../state/actions/remote/cabinet/report/url"
import { getUrls } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const UrlReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const urlsState = useSelector(getUrls)

  const _columns = useColumns(columns, urlsState.sort, urlsState.defaultSort)

  const { load, onChangeTable } = useTable(
    loadUrlReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationUrlReport,
    setSortUrlReport,
    clearSortUrlReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterUrlReport,
    cancelFilterUrlReport,
    clearFilterUrlReport,
    setSortUrlReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterUrlReport,
    cancelFilterUrlReport,
  )

  if (urlsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        urlsState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.urls}
                activeFilter={urlsState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={urlsState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        onChangeTable={onChangeTable}
        dataSource={urlsState.data}
        loading={urlsState.isLoading}
        pagination={urlsState.pagination}
        columns={_columns}
      />
    </CardWithoutBodyPadding>
  )
}

export default UrlReport

const inputOptions: InputSearchByFieldsValueType<UrlReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
]

const columns: ColumnsType<UrlReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      url: {
        value: record.resource_url,
      },
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.statusCode({
    key: "resource_status_code",
    dataIndex: "resource_status_code",
  }),
  columnsType.indexStatus(
    record => ({
      canonicalUrl: record.page_canonical_url,
      robotstxtIgnore: record.resource_robotstxt_ignore,
      robotstxtLine: record.resource_robotstxt_line,
    }),
    {
      key: "resource_index_status",
      dataIndex: "resource_index_status",
    },
  ),
  {
    key: "resource_params",
    title: "Параметры",
    dataIndex: "resource_params",
    width: 200,
    render: (_: string, record): React.ReactNode => {
      const url = new URL(record.resource_url)
      // TODO: implement this
      if (url.search.length) {
        const params: { [key: string]: string } = {}
        url.searchParams.forEach((value, key) => (params[key] = value))

        return (
          <Tooltip title="В процессе разработки">
            <pre>{JSON.stringify(params, null, 2)}</pre>
          </Tooltip> // TODO: implement this
        )
      }

      return (
        <Tooltip title="В процессе разработки">
          <span>Отсутствует</span>
        </Tooltip> // TODO: implement this
      )
    },
  },
]
