import { Action } from "redux"
import {
  ExportListResourcesPayload,
  GetListResourcesPayload,
  GetListResourcesResponse,
} from "../../../../../../services/apiClient"
import { ImageGroupReportRecord } from "../../../../reducers/remote/cabinet/report/imageGroupReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum ImageGroupReportActionType {
  Load = "@@REPORTS/IMAGE_GROUPS/LOAD",
  LoadSuccess = "@@REPORTS/IMAGE_GROUPS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/IMAGE_GROUPS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/IMAGE_GROUPS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/IMAGE_GROUPS/SET_PAGINATION",
  SetSort = "@@REPORTS/IMAGE_GROUPS/SET_SORT",
  AddFilter = "@@REPORTS/IMAGE_GROUPS/ADD_FILTER",
  CancelFilter = "@@REPORTS/IMAGE_GROUPS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/IMAGE_GROUPS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/IMAGE_GROUPS/CLEAR_SORT",
  Export = "@@REPORTS/IMAGE_GROUPS/EXPORT",
}

export type LoadImageGroupReportAction = LoadAction<
  GetListResourcesPayload,
  GetListResourcesResponse<ImageGroupReportRecord>,
  ImageGroupReportActionType.Load,
  ImageGroupReportActionType.LoadPending,
  ImageGroupReportActionType.LoadSuccess,
  ImageGroupReportActionType.LoadFailure
>
export type LoadPendingImageGroupReportAction = ReturnType<
  LoadImageGroupReportAction["meta"]["pending"]
>
export type LoadSuccessImageGroupReportAction = ReturnType<
  LoadImageGroupReportAction["meta"]["success"]
>
export type LoadFailureImageGroupReportAction = ReturnType<
  LoadImageGroupReportAction["meta"]["failure"]
>

export type ClearFilterImageGroupReportAction = Action<
  ImageGroupReportActionType.ClearFilter
>

export type ClearSortImageGroupReportAction = Action<
  ImageGroupReportActionType.ClearSort
>

export type ExportImageGroupReportAction = ExportAction<
  ImageGroupReportRecord,
  ExportListResourcesPayload<ImageGroupReportRecord>,
  ImageGroupReportActionType.Export
>

export type AddFilterImageGroupReportAction = AddFilterAction<
  ImageGroupReportRecord,
  ImageGroupReportActionType.AddFilter
>

export type CancelFilterImageGroupReportAction = CancelFilterAction<
  ImageGroupReportRecord,
  ImageGroupReportActionType.CancelFilter
>

export type SetSortImageGroupReportAction = SetSortAction<
  ImageGroupReportRecord,
  ImageGroupReportActionType.SetSort
>

export type SetPaginationImageGroupReportAction = SetPaginationAction<
  ImageGroupReportActionType.SetPagination
>
