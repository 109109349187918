import makeRequest from "../../makeRequest"
import {
  GetEntryPointsResponse,
  CheckRobotstxtAndSitemapsResponse,
  ValidateSitemapResponse,
  GetEntryPointsPayload,
  CheckRobotstxtAndSitemapsPayload,
  ValidateSitemapPayload,
} from "../../@types/rest/host"

const getEntryPoints = async (
  payload: GetEntryPointsPayload,
): Promise<GetEntryPointsResponse> => {
  const res = await makeRequest({
    path: "cabinet/api/host/entry-points",
    params: payload,
  })
  return await res.json()
}

const checkRobotstxtAndSitemaps = async (
  payload: CheckRobotstxtAndSitemapsPayload,
): Promise<CheckRobotstxtAndSitemapsResponse> => {
  const res = await makeRequest({
    path: "cabinet/api/host/robotstxt-and-sitemaps",
    params: {
      entry_point: new URL(payload.entryPoint).toString(),
      "user-agent": payload["user-agent"],
    },
  })
  return await res.json()
}

const validateSitemap = async (
  payload: ValidateSitemapPayload,
): Promise<ValidateSitemapResponse> => {
  const res = await makeRequest({
    path: "cabinet/api/host/sitemap",
    params: {
      sitemap: new URL(payload.sitemap).toString(),
      "user-agent": payload["user-agent"],
    },
  })
  return await res.json()
}

export { getEntryPoints, checkRobotstxtAndSitemaps, validateSitemap }
