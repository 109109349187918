import { SelectedPageActionType } from "../../../../@types/actions/remote/cabinet/report/selectedPage"
import {
  SelectedPageActions,
  SelectedPageState,
} from "../../../../@types/reducers/remote/cabinet/report/selectedPageReducer"
import {
  updateStateAddFilter,
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: SelectedPageState = {
  isLoading: false,
  fields: [
    "resource_id",
    "page_title",
    "page_description",
    "resource_url",
    "page_headers_h1",
    "page_headers_h2",
    "page_headers_h3",
    "resource_status_code",
    "resource_size",
    "resource_load_time_ms",
    "resource_errored",
    "resource_redirects",
    "resource_error",
    "page_canonical_url",
    "page_is_canonical",
    "page_robots_directives",
  ],
}

const selectedPageReducer = (
  state: SelectedPageState = initState,
  action: SelectedPageActions,
): SelectedPageState => {
  switch (action.type) {
    case SelectedPageActionType.LoadSuccess:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      }

    case SelectedPageActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case SelectedPageActionType.LoadPending:
      return updateStateSetPending(state)

    case SelectedPageActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case SelectedPageActionType.Clear:
      return JSON.parse(JSON.stringify(initState))

    default:
      return state
  }
}

export default selectedPageReducer
