import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  ReportActionType,
  StopCrawlingAction,
} from "../../../../@types/actions/remote/cabinet/report/report"

function* fetchStopCrawling(action: StopCrawlingAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response = yield call(
      apiClient.cabinet.project.reports.stopCrawling,
      action.payload,
    )
    yield put(action.meta.success(response.success))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* stopCrawlingSaga(): Generator {
  yield takeEvery(ReportActionType.StopCrawling, fetchStopCrawling)
}
