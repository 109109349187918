import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { GetReportResponse } from "../../../../../services/apiClient"
import {
  LoadReportAction,
  ReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/report"

function* fetchLoadReport(action: LoadReportAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const report: GetReportResponse = yield call(
      apiClient.cabinet.project.reports.getReport,
      action.payload,
    )
    yield put(action.meta.success(report))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getReportSaga(): Generator {
  yield takeEvery(ReportActionType.Load, fetchLoadReport)
}
