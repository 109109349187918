import { all, fork } from "redux-saga/effects"
import getSessionSaga from "./getSessionSaga"
import loginSaga from "./loginSaga"
import logoutSaga from "./logoutSaga"
import updateProfile from "./updateProfileSaga"

export default function* authSagas(): Generator {
  yield all([
    fork(getSessionSaga),
    fork(loginSaga),
    fork(logoutSaga),
    fork(updateProfile),
  ])
}
