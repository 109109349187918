import { Space, Tooltip, Typography } from "antd"
import { BaseType } from "antd/lib/typography/Base"
import React from "react"
import { QuestionCircleOutlined } from "@ant-design/icons"
import Nullable from "../../Nullable"

const { Text } = Typography

type Props = {
  viewLabel?: boolean
  directives?: string[] | null
  typeText?: BaseType
}

// View is ignored url in meta robots
const MetaRobots: React.FC<Props> = props => {
  const { directives, viewLabel = false, typeText } = props

  if (directives == null) {
    return <Nullable />
  }

  return (
    <>
      {viewLabel && (
        <div className="index-status__information">
          <Tooltip
            placement="leftTop"
            title="meta.robots тег страницы имеет директиву noindex"
          >
            <Text>Meta</Text>
            <QuestionCircleOutlined style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      )}
      {directives.length ? (
        <Space>
          {directives.map((value, index) => (
            <Text key={`${value}_${index}`} type={typeText}>
              {value}
            </Text>
          ))}
        </Space>
      ) : (
        <Nullable />
      )}
    </>
  )
}

export default MetaRobots
