import {
  AddFilterSelectedPageAction,
  ClearSelectedPageAction,
  LoadSelectedPageAction,
  SelectedPageActionType,
} from "../../../../@types/actions/remote/cabinet/report/selectedPage"

export const clearSelectedPage = (): ClearSelectedPageAction => ({
  type: SelectedPageActionType.Clear,
})

export const loadSelectedPage = (
  payload: LoadSelectedPageAction["payload"],
): LoadSelectedPageAction => ({
  type: SelectedPageActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: SelectedPageActionType.LoadPending,
    }),
    success: payload => ({
      type: SelectedPageActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: SelectedPageActionType.LoadFailure,
      payload,
    }),
  },
})

export const addFilterSelectedPage = (
  payload: AddFilterSelectedPageAction["payload"],
): AddFilterSelectedPageAction => ({
  type: SelectedPageActionType.AddFilter,
  payload,
})
