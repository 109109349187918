export enum ApiClientFilterType {
  Eq = "eq",
  Ne = "ne",
  Lt = "lt",
  Lte = "lte",
  Gt = "gt",
  Gte = "gte",
  In = "in",
  NotIn = "not_in",
  Like = "like",
}
export type FilterValue = string | number | null | boolean | FilterValue[]

export type ApiClientFilterRules = [ApiClientFilterType, FilterValue][]
export type ApiClientFilter<Rec> = {
  [key in keyof Rec]?: ApiClientFilterRules
}
