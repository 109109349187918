import { Button, Modal, Tooltip } from "antd"
import { TooltipProps } from "antd/lib/tooltip"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { FileTextOutlined } from "@ant-design/icons"
import history from "../lib/history"
import routes from "../pages/routes"
import { ApiClientFilterType } from "../services/apiClient"
import {
  addFilterSelectedPage,
  clearSelectedPage,
  loadSelectedPage,
} from "../state/actions/remote/cabinet/report/selectedPage"
import { getProject } from "../state/selectors/remote/cabinet/project"
import {
  getReport,
  getSelectedPage,
  getShare,
} from "../state/selectors/remote/cabinet/report"
import ShortAboutPage from "./ShortAboutPage"

type Props = {
  url?: string
  resourceId?: string
  tooltip?: Omit<TooltipProps, "title">
}

const ModalAboutPage: React.FC<Props> = props => {
  const { resourceId, url, tooltip } = props

  const [visibility, setVisibility] = React.useState(false)

  const selectedPageState = useSelector(getSelectedPage)
  const reportState = useSelector(getReport)
  const reportStateData = reportState.data
  const shareState = useSelector(getShare)
  const shareStateData = shareState.data

  const dispatch = useDispatch()

  React.useEffect(() => {
    return (): void => {
      if (!visibility) {
        return
      }

      setVisibility(false)
    }
  }, [])

  if (!reportStateData) {
    return null
  }

  const handlerOnCancel = (): void => {
    setVisibility(false)
    dispatch(clearSelectedPage())
  }

  const showPage = (): void => {
    if (resourceId) {
      dispatch(
        addFilterSelectedPage({
          resource_id: [[ApiClientFilterType.Eq, resourceId]],
        }),
      )
    } else if (url) {
      dispatch(
        addFilterSelectedPage({
          resource_url: [[ApiClientFilterType.Eq, url]],
        }),
      )
    }

    dispatch(
      loadSelectedPage({
        reportId: reportStateData.id,
        shareToken: shareStateData?.token,
        saveParamsToUrl: false,
      }),
    )

    setVisibility(true)
  }

  return (
    <>
      <Tooltip title="Информация о странице" {...tooltip}>
        <Button
          className="show-modal-about-page"
          size="small"
          onClick={showPage}
          icon={<FileTextOutlined />}
          type="text"
        />
      </Tooltip>
      {visibility && (
        <Modal
          title="Информация о странице"
          visible={visibility}
          width={1000}
          zIndex={1001}
          onCancel={handlerOnCancel}
          footer={
            selectedPageState.data &&
            selectedPageState.data.length !== 0 && (
              <ButtonGoToPage
                resourceId={selectedPageState.data[0].resource_id}
              />
            )
          }
        >
          <ShortAboutPage reportId={reportStateData.id} showGoBack={false} />
        </Modal>
      )}
    </>
  )
}

export default ModalAboutPage

type ButtonGoToPageProps = {
  resourceId: string
}

const ButtonGoToPage: React.FC<ButtonGoToPageProps> = React.memo(props => {
  const { resourceId } = props

  const reportState = useSelector(getReport)
  const projectState = useSelector(getProject)

  const showInformationAboutPage = (): void => {
    history.push(
      routes
        .site()
        .alias(projectState.data?.alias)
        .reportAlias(reportState.data?.alias)
        .pages()
        .pageId(resourceId)
        .index(),
    )
  }

  return (
    <Button type="primary" onClick={showInformationAboutPage}>
      Подробнее
    </Button>
  )
})
