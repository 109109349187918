import { GraphData } from "../../../../../../../types/graph"
import { ApiResponseWithData } from "../../../../response"

export type GetIndexStatusGraphResponse = ApiResponseWithData<GraphData>
export type GetIndexStatusGraphPayload = {
  reportId: string
  shareToken?: string
}

export type GetRedirectsGraphResponse = ApiResponseWithData<GraphData>
export type GetRedirectsGraphPayload = {
  reportId: string
  shareToken?: string
}
export enum RedirectsGraphAlias {
  StatusCode301 = "status_code_301",
  StatusCode302 = "status_code_302",
  StatusCode307 = "status_code_307",
}

export type GetRobotsGraphResponse = ApiResponseWithData<GraphData>
export type GetRobotsGraphPayload = {
  reportId: string
  shareToken?: string
}
export enum RobotsGraphAlias {
  Allow = "allow",
  Disallow = "disallow",
}

export type GetSitemapUrlsGraphResponse = ApiResponseWithData<GraphData>
export type GetSitemapUrlsGraphPayload = {
  reportId: string
  shareToken?: string
}
export enum SitemapUrlsGraphAlias {
  InSitemap = "in_sitemap",
  NotInCrawl = "not_in_crawl",
  OnlyInCrawl = "only_in_crawl",
}

export type GetStatusCodesGraphResponse = ApiResponseWithData<GraphData>
export type GetStatusCodesGraphPayload = {
  reportId: string
  shareToken?: string
}
export enum StatusCodesGraphAlias {
  "2xx" = "2xx",
  "3xx" = "3xx",
  "4xx" = "4xx",
  "5xx" = "5xx",
}
