import { MakeRequestPayload } from "../../../@types/makeRequest"
import {
  CreateProjectPayload,
  CreateProjectResponse,
  CreateReportPayload,
  CreateReportResponse,
  DeleteProjectPayload,
  DeleteProjectResponse,
  GetProjectByAliasPayload,
  GetProjectByAliasResponse,
  GetProjectListPayload,
  GetProjectListResponse,
  GetProjectPayload,
  GetProjectResponse,
  GetReportListPayload,
  GetReportListResponse,
} from "../../../@types/rest/cabinet/project/projects"
import makeRequest from "../../../makeRequest"

export const createProject = async (
  payload: CreateProjectPayload,
): Promise<CreateProjectResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/projects`,
    method: "POST",
    body: {
      host: payload.host,
    },
  })
  return await res.json()
}

export const createReport = async (
  payload: CreateReportPayload,
): Promise<CreateReportResponse> => {
  const body = payload.body
  try {
    body.entry_point = new URL(body.entry_point).toString()
    body.sitemaps = body.sitemaps?.map(url => new URL(url).toString())
  } catch (e) {}

  const res = await makeRequest({
    path: `cabinet/api/project/projects/${payload.projectId}/new-report`,
    method: "POST",
    body,
  })
  return await res.json()
}

export const deleteProject = async (
  payload: DeleteProjectPayload,
): Promise<DeleteProjectResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/projects/${payload.projectId}`,
    method: "DELETE",
  })
  return await res.json()
}

export const getProjectByAlias = async (
  payload: GetProjectByAliasPayload,
): Promise<GetProjectByAliasResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/projects/alias/${payload.alias}`,
  })
  return await res.json()
}

export const getProjectList = async (
  payload?: GetProjectListPayload,
): Promise<GetProjectListResponse> => {
  const requestPayload: MakeRequestPayload = {
    path: `cabinet/api/project/projects`,
  }

  if (payload) {
    requestPayload.params = {
      host: payload.host,
    }
  }

  const res = await makeRequest(requestPayload)
  return res.json()
}

export const getProject = async (
  payload: GetProjectPayload,
): Promise<GetProjectResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/projects/${payload.projectId}`,
  })
  return await res.json()
}

export const getReportList = async (
  payload: GetReportListPayload,
): Promise<GetReportListResponse> => {
  const requestPayload: MakeRequestPayload = {
    path: `cabinet/api/project/projects/${payload.projectId}/reports`,
  }

  if (payload.alias) {
    requestPayload.params = {
      alias: payload.alias,
    }
  }

  const res = await makeRequest(requestPayload)
  return res.json()
}
