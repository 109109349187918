import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../../../routes"
import ImageGroupReportPage from "./ImageGroupsPage"
import ImagesFullPage from "./ImagesFullPage"

type Props = {
  reportId: string
  shareToken: string
}

const ImagesPageRouter: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const path = routes.s(shareToken).content().images()

  return (
    <Switch>
      <Route
        path={path.full()}
        render={(): JSX.Element => (
          <ImagesFullPage shareToken={shareToken} reportId={reportId} />
        )}
      />
      <Route
        path={path.groups()}
        render={(): JSX.Element => (
          <ImageGroupReportPage shareToken={shareToken} reportId={reportId} />
        )}
      />
    </Switch>
  )
}

export default ImagesPageRouter
