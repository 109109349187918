import { Card, Col, Empty, Row, Space, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import { useStatusCodesGraph } from "../../hooks/useStatusCodesGraph"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import formatters from "../../services/formatters"
import { StatusCodeReportRecord } from "../../state/@types/reducers/remote/cabinet/report/statusCodeReducer"
import {
  addFilterStatusCodeReport,
  cancelFilterStatusCodeReport,
  clearFilterStatusCodeReport,
  clearSortStatusCodeReport,
  loadStatusCodeReport,
  setPaginationStatusCodeReport,
  setSortStatusCodeReport,
} from "../../state/actions/remote/cabinet/report/statusCode"
import { getStatusCodes } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Statistics from "../Statistic"
import StatusCodesGraph from "../StatusCodesGraph"
import Table from "../Table"
import IconWithTooltip from "../ui/IconWithTooltip"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const StatusCodeReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Information shareToken={shareToken} reportId={reportId} />
      <StatusCodeReportTable shareToken={shareToken} reportId={reportId} />
    </Space>
  )
}

export default StatusCodeReport

type StatusCodeReportTableProps = {
  reportId: string
  shareToken?: string
}

const StatusCodeReportTable: React.FC<StatusCodeReportTableProps> = (
  props: StatusCodeReportTableProps,
) => {
  const { reportId, shareToken }: StatusCodeReportTableProps = props

  const statusCodesState = useSelector(getStatusCodes)

  const _columns = useColumns(
    columns,
    statusCodesState.sort,
    statusCodesState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadStatusCodeReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationStatusCodeReport,
    setSortStatusCodeReport,
    clearSortStatusCodeReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterStatusCodeReport,
    cancelFilterStatusCodeReport,
    clearFilterStatusCodeReport,
    setSortStatusCodeReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterStatusCodeReport,
    cancelFilterStatusCodeReport,
  )

  if (statusCodesState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        statusCodesState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.statusCodes}
                activeFilter={statusCodesState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={statusCodesState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table<StatusCodeReportRecord>
        rowKey="resource_id"
        columns={_columns}
        dataSource={statusCodesState.data}
        onChangeTable={onChangeTable}
        loading={statusCodesState.isLoading}
        pagination={statusCodesState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

const inputOptions: InputSearchByFieldsValueType<StatusCodeReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
  { label: "Title", value: "page_title" },
]

const columns: ColumnsType<StatusCodeReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      title: record.page_title,
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
      url: {
        value: record.resource_url,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.statusCode(
    {
      key: "resource_status_code",
      dataIndex: "resource_status_code",
    },
    {
      useTag: false,
      wrapToTooltip: false,
    },
  ),
  columnsType.indexStatus(
    record => ({
      robotstxtLine: record.resource_robotstxt_line,
      robotstxtIgnore: record.resource_robotstxt_ignore,
      canonicalUrl: record.page_canonical_url,
    }),
    {
      key: "resource_index_status",
      dataIndex: "resource_index_status",
    },
  ),
]

type InformationProps = {
  reportId: string
  shareToken?: string
}

const Information: React.FC<InformationProps> = props => {
  const { reportId, shareToken } = props
  const { state, info } = useStatusCodesGraph()

  if (state.isLoading) {
    return <Spin />
  }

  return (
    <Row>
      <Col span={4}>
        <Card>
          <StatusCodesGraph
            reportId={reportId}
            legend={false}
            shareToken={shareToken}
          />
        </Card>
      </Col>
      {state.data && (
        <Col span={8}>
          <Row style={{ height: "100%" }}>
            {state.data.values.map(item => (
              <Col key={item.key} span={6}>
                <Card style={{ height: "100%" }}>
                  <Statistics
                    title={info[item.key].shortLabel}
                    value={formatters.number.format(item.value)}
                    groupSeparator=" "
                    titlePrefix={
                      <IconWithTooltip
                        description={info[item.key].description}
                      />
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      )}
    </Row>
  )
}
