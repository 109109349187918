import {
  AddFilterRobotstxtReportAction,
  CancelFilterRobotstxtReportAction,
  ClearFilterRobotstxtReportAction,
  ClearSortRobotstxtReportAction,
  ExportRobotstxtReportAction,
  LoadRobotstxtReportAction,
  RobotstxtReportActionType,
  SetPaginationRobotstxtReportAction,
  SetSortRobotstxtReportAction,
} from "../../../../@types/actions/remote/cabinet/report/robotstxt"

const loadRobotstxtReport = (
  payload: LoadRobotstxtReportAction["payload"],
): LoadRobotstxtReportAction => ({
  type: RobotstxtReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: RobotstxtReportActionType.LoadPending,
    }),
    success: payload => ({
      type: RobotstxtReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: RobotstxtReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterRobotstxtReport = (): ClearFilterRobotstxtReportAction => ({
  type: RobotstxtReportActionType.ClearFilter,
})

const cancelFilterRobotstxtReport = (
  payload: CancelFilterRobotstxtReportAction["payload"],
): CancelFilterRobotstxtReportAction => ({
  type: RobotstxtReportActionType.CancelFilter,
  payload,
})

const clearSortRobotstxtReport = (): ClearSortRobotstxtReportAction => ({
  type: RobotstxtReportActionType.ClearSort,
})

const exportRobotstxtReport = (
  payload: ExportRobotstxtReportAction["payload"],
): ExportRobotstxtReportAction => ({
  type: RobotstxtReportActionType.Export,
  payload,
})

const addFilterRobotstxtReport = (
  payload: AddFilterRobotstxtReportAction["payload"],
): AddFilterRobotstxtReportAction => ({
  type: RobotstxtReportActionType.AddFilter,
  payload,
})

const setPaginationRobotstxtReport = (
  payload: SetPaginationRobotstxtReportAction["payload"],
): SetPaginationRobotstxtReportAction => ({
  type: RobotstxtReportActionType.SetPagination,
  payload,
})

const setSortRobotstxtReport = (
  payload: SetSortRobotstxtReportAction["payload"],
): SetSortRobotstxtReportAction => ({
  type: RobotstxtReportActionType.SetSort,
  payload,
})

export {
  loadRobotstxtReport,
  clearFilterRobotstxtReport,
  clearSortRobotstxtReport,
  exportRobotstxtReport,
  addFilterRobotstxtReport,
  setPaginationRobotstxtReport,
  setSortRobotstxtReport,
  cancelFilterRobotstxtReport,
}
