import { Col, Empty, Row, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { DescriptionReportRecord } from "../../state/@types/reducers/remote/cabinet/report/descriptionReducer"
import {
  addFilterDescriptionReport,
  cancelFilterDescriptionReport,
  clearFilterDescriptionReport,
  clearSortDescriptionReport,
  loadDescriptionReport,
  setPaginationDescriptionReport,
  setSortDescriptionReport,
} from "../../state/actions/remote/cabinet/report/description"
import { getDescriptions } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const DescriptionReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const descriptionsState = useSelector(getDescriptions)

  const _columns = useColumns(
    columns,
    descriptionsState.sort,
    descriptionsState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadDescriptionReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationDescriptionReport,
    setSortDescriptionReport,
    clearSortDescriptionReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterDescriptionReport,
    cancelFilterDescriptionReport,
    clearFilterDescriptionReport,
    setSortDescriptionReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterDescriptionReport,
    cancelFilterDescriptionReport,
  )

  if (descriptionsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        descriptionsState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.descriptions}
                activeFilter={descriptionsState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={descriptionsState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        onChangeTable={onChangeTable}
        pagination={descriptionsState.pagination}
        dataSource={descriptionsState.data}
        loading={descriptionsState.isLoading}
        columns={_columns}
      />
    </CardWithoutBodyPadding>
  )
}

export default DescriptionReport

const inputOptions: InputSearchByFieldsValueType<DescriptionReportRecord>[] = [
  { label: "Description", value: "page_description" },
  { label: "URL", value: "resource_url_no_scheme" },
  { label: "Title", value: "page_title" },
]

const columns: ColumnsType<DescriptionReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  {
    key: "page_description",
    title: "Description",
    dataIndex: "page_description",
    sorter: true,
  },
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      url: {
        value: record.resource_url,
      },
      title: record.page_title,
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  columnsType.indexStatus(
    record => ({
      robotstxtLine: record.resource_robotstxt_line,
      robotstxtIgnore: record.resource_robotstxt_ignore,
      canonicalUrl: record.page_canonical_url,
    }),
    {
      key: "resource_index_status",
      dataIndex: "resource_index_status",
    },
  ),
]
