import { Filter, FilterActions } from "./types/filter"

const getActionsByName = <Rec>(
  filters: Filter<Rec>[],
  name: string,
): FilterActions<Rec> => {
  const filter = findFilterByName<Rec>(filters, name)
  return filter.actions
}

function findFilterByName<Rec>(
  filters: Filter<Rec>[],
  name: string,
): Filter<Rec> {
  const item = filters.find(item => item.name === name)
  if (!item) {
    throw new Error("filter not found")
  }

  return item
}

export default getActionsByName
