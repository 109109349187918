import {
  BillingActions,
  BillingTypes,
} from "../../@types/actions/remote/billing"
import { BillingState } from "../../@types/reducers/remote/billing"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../utils/reducer"

const initState: BillingState = {
  userFeatures: {
    isLoading: false,
  },
  orders: {
    isLoading: false,
  },
  plan: {
    isLoading: false,
  },
  plans: {
    isLoading: false,
  },
  userPlan: {
    isLoading: false,
  },
  createdOrder: {
    isLoading: false,
  },
  purchase: {
    isLoading: false,
  },
}

const billingReducer = (
  state: BillingState = initState,
  action: BillingActions,
): BillingState => {
  switch (action.type) {
    case BillingTypes.LoadUserFeaturesSuccess:
      return {
        ...state,
        userFeatures: {
          ...state.userFeatures,
          isLoading: false,
          data: action.payload.data,
        },
      }

    case BillingTypes.LoadUserFeaturesFailure:
      return {
        ...state,
        userFeatures: updateStateLoadFailure(state.userFeatures, action),
      }

    case BillingTypes.LoadUserFeaturesPending:
      return {
        ...state,
        userFeatures: updateStateSetPending(state.userFeatures),
      }

    case BillingTypes.UserFeaturesClear:
      return {
        ...state,
        userFeatures: JSON.parse(JSON.stringify(initState.userFeatures)),
      }

    case BillingTypes.LoadOrdersSuccess:
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          data: action.payload.data,
        },
      }

    case BillingTypes.LoadOrdersFailure:
      return {
        ...state,
        userFeatures: updateStateLoadFailure(state.userFeatures, action),
      }

    case BillingTypes.LoadOrdersPending:
      return {
        ...state,
        userFeatures: updateStateSetPending(state.userFeatures),
      }

    case BillingTypes.LoadPlansSuccess:
      return {
        ...state,
        plans: {
          ...state.plans,
          isLoading: false,
          data: action.payload.data,
        },
      }

    case BillingTypes.LoadPlansFailure:
      return { ...state, plans: updateStateLoadFailure(state.plans, action) }

    case BillingTypes.LoadPlansPending:
      return { ...state, plans: updateStateSetPending(state.plans) }

    case BillingTypes.LoadUserPlanSuccess: {
      return {
        ...state,
        userPlan: {
          ...state.userPlan,
          isLoading: false,
          data: action.payload.data,
        },
      }
    }
    case BillingTypes.LoadUserPlanFailure:
      return {
        ...state,
        userPlan: updateStateLoadFailure(state.userPlan, action),
      }

    case BillingTypes.LoadUserPlanPending:
      return { ...state, userPlan: updateStateSetPending(state.userPlan) }

    case BillingTypes.LoadPlanSuccess:
      return {
        ...state,
        plan: { ...state.plan, isLoading: false, data: action.payload.data },
      }

    case BillingTypes.LoadPlanFailure:
      return { ...state, plan: updateStateLoadFailure(state.plan, action) }

    case BillingTypes.LoadPlanPending:
      return { ...state, plan: updateStateSetPending(state.plan) }

    case BillingTypes.PurchaseSuccess:
      return {
        ...state,
        purchase: {
          ...state.purchase,
          isLoading: false,
          data: action.payload.data,
        },
      }

    case BillingTypes.PurchaseFailure:
      return {
        ...state,
        purchase: updateStateLoadFailure(state.purchase, action),
      }

    case BillingTypes.PurchasePending:
      return { ...state, purchase: updateStateSetPending(state.purchase) }

    case BillingTypes.CreateOrderSuccess:
      return {
        ...state,
        createdOrder: {
          ...state.createdOrder,
          isLoading: false,
          data: action.payload.data,
        },
      }

    case BillingTypes.CreateOrderFailure:
      return {
        ...state,
        createdOrder: updateStateLoadFailure(state.createdOrder, action),
      }

    case BillingTypes.CreateOrderPending:
      return {
        ...state,
        createdOrder: updateStateSetPending(state.createdOrder),
      }

    default:
      return state
  }
}

export default billingReducer
