import { RobotsGraphActionType } from "../../../../@types/actions/remote/cabinet/report/robotsGraph"
import {
  RobotsGraphActions,
  RobotsGraphState,
} from "../../../../@types/reducers/remote/cabinet/report/robotsGraphReducer"
import {
  updateStateLoadFailure,
  updateStateLoadGraphSuccess,
  updateStateSetPending,
} from "../../../../utils/reducer"

const initState: RobotsGraphState = {
  isLoading: false,
}

const robotsGraphReducer = (
  state: RobotsGraphState = initState,
  action: RobotsGraphActions,
): RobotsGraphState => {
  switch (action.type) {
    case RobotsGraphActionType.LoadSuccess:
      return updateStateLoadGraphSuccess(state, action)

    case RobotsGraphActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case RobotsGraphActionType.LoadPending:
      return updateStateSetPending(state)

    default:
      return state
  }
}

export default robotsGraphReducer
