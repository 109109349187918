import { HeadingReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/headingReducer"
import { addFilterHeadingReport } from "../../../state/actions/remote/cabinet/report/heading"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<HeadingReportRecord>[] = [
  {
    name: "Несколько h1",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterHeadingReport({
          page_headers_h1_count: [[ApiClientFilterType.Gt, 1]],
        }),
      },
    ],
  },
  {
    name: "Отсутствует h1",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterHeadingReport({
          page_headers_h1_absence: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
]

export default filters
