import React from "react"
import { Tooltip, Typography } from "antd"
import {
  ArrowRightOutlined,
  ClockCircleOutlined,
  EnterOutlined,
} from "@ant-design/icons"
import Url from "../../../Url"
import { ResourceRedirect } from "../../../../types/reportResource"
import RedirectCanonicalItem from "../RedirectCanonicalItem"
import StatusCode from "../../../StatusCode"

type Props = ResourceRedirect & {
  infinity: boolean
  isLast: boolean
  canonical?: {
    url: string | null
    isCanonical: boolean | null
  }
}

/**
 * Components for render redirect item
 */
const RedirectsItem: React.FC<Props> = props => {
  const { to_url, status, infinity, isLast, canonical } = props

  let item
  if (infinity) {
    item = (
      <div className="table__url-redirect-container__item">
        <EnterOutlined className="table__url-redirect-container__icon-redirect" />
        <Tooltip title="Бесконечный">
          <Typography.Text
            className="table__url-redirect-container__icon-redirect-to"
            style={{ fontSize: 14 }}
          >
            ∞
          </Typography.Text>
        </Tooltip>
      </div>
    )
  } else {
    let type
    if (status !== 302 && status !== 307) {
      type = (
        <Tooltip title="Цепочка">
          <ArrowRightOutlined className="table__url-redirect-container__icon-redirect-to" />
        </Tooltip>
      )
    } else {
      type = (
        <Tooltip title="Временный">
          <ClockCircleOutlined className="table__url-redirect-container__icon-redirect-to" />
        </Tooltip>
      )
    }

    item = (
      <div className="table__url-redirect-container__item">
        <EnterOutlined className="table__url-redirect-container__icon-redirect" />
        <StatusCode
          value={status}
          style={{
            padding: "0 4px",
            marginRight: 5,
            fontSize: ".8em",
            lineHeight: "inherit",
          }}
        />
        {type}
        <Url value={to_url} />
      </div>
    )
  }

  if (isLast && canonical && canonical.url && canonical.isCanonical === false) {
    return (
      <RedirectCanonicalItem
        infinity={infinity}
        redirectItem={item}
        url={canonical.url}
      />
    )
  }

  return item
}

export default RedirectsItem
