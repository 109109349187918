import React from "react"
import RobotstxtReport from "../../../components/Report/RobotstxtReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportRobotstxtReport } from "../../../state/actions/remote/cabinet/report/robotstxt"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.Robots)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const RobotsPage: React.FC<Props> = React.memo(props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportRobotstxtReport,
  })

  return <RobotstxtReport reportId={reportId} shareToken={shareToken} />
})

export default RobotsPage
