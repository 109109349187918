import React from "react"
import { Tag, Typography } from "antd"

const EmptyTagComponent: React.FC = () => {
  return (
    <Tag>
      <Typography.Text type="secondary">Пустой</Typography.Text>
    </Tag>
  )
}

export default EmptyTagComponent
