import { Action } from "redux"
import {
  ExportListResourcesPayload,
  GetListResourcesPayload,
  GetListResourcesResponse,
} from "../../../../../../services/apiClient"
import { NotHtmlGroupReportRecord } from "../../../../reducers/remote/cabinet/report/notHtmlGroupReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum NotHtmlGroupReportActionType {
  Load = "@@REPORTS/NOT_HTML_GROUPS/LOAD",
  LoadSuccess = "@@REPORTS/NOT_HTML_GROUPS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/NOT_HTML_GROUPS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/NOT_HTML_GROUPS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/NOT_HTML_GROUPS/SET_PAGINATION",
  SetSort = "@@REPORTS/NOT_HTML_GROUPS/SET_SORT",
  AddFilter = "@@REPORTS/NOT_HTML_GROUPS/ADD_FILTER",
  CancelFilter = "@@REPORTS/NOT_HTML_GROUPS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/NOT_HTML_GROUPS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/NOT_HTML_GROUPS/CLEAR_SORT",
  Export = "@@REPORTS/NOT_HTML_GROUPS/EXPORT",
}

export type LoadNotHtmlGroupReportAction = LoadAction<
  GetListResourcesPayload<NotHtmlGroupReportRecord>,
  GetListResourcesResponse<NotHtmlGroupReportRecord>,
  NotHtmlGroupReportActionType.Load,
  NotHtmlGroupReportActionType.LoadPending,
  NotHtmlGroupReportActionType.LoadSuccess,
  NotHtmlGroupReportActionType.LoadFailure
>
export type LoadPendingNotHtmlGroupReportAction = ReturnType<
  LoadNotHtmlGroupReportAction["meta"]["pending"]
>
export type LoadSuccessNotHtmlGroupReportAction = ReturnType<
  LoadNotHtmlGroupReportAction["meta"]["success"]
>
export type LoadFailureNotHtmlGroupReportAction = ReturnType<
  LoadNotHtmlGroupReportAction["meta"]["failure"]
>

export type ClearFilterNotHtmlGroupReportAction = Action<
  NotHtmlGroupReportActionType.ClearFilter
>

export type ClearSortNotHtmlGroupReportAction = Action<
  NotHtmlGroupReportActionType.ClearSort
>

export type ExportNotHtmlGroupReportAction = ExportAction<
  NotHtmlGroupReportRecord,
  ExportListResourcesPayload<NotHtmlGroupReportRecord>,
  NotHtmlGroupReportActionType.Export
>

export type AddFilterNotHtmlGroupReportAction = AddFilterAction<
  NotHtmlGroupReportRecord,
  NotHtmlGroupReportActionType.AddFilter
>

export type CancelFilterNotHtmlGroupReportAction = CancelFilterAction<
  NotHtmlGroupReportRecord,
  NotHtmlGroupReportActionType.CancelFilter
>

export type SetSortNotHtmlGroupReportAction = SetSortAction<
  NotHtmlGroupReportRecord,
  NotHtmlGroupReportActionType.SetSort
>

export type SetPaginationNotHtmlGroupReportAction = SetPaginationAction<
  NotHtmlGroupReportActionType.SetPagination
>
