import React from "react"
import Nullable from "../../Nullable"

type Props = {
  image: string | null
  imageLength?: number
  showEndSlice?: boolean
}

// Component for render inline images
const InlineImage: React.FC<Props> = props => {
  const { image, imageLength = 40, showEndSlice = false } = props

  if (image === null) {
    return <Nullable />
  }

  let sliceImage = image

  if (imageLength !== 0) {
    if (!showEndSlice && sliceImage.length > imageLength) {
      sliceImage = `${sliceImage.slice(0, imageLength)}...`
    } else if (sliceImage.length > 60) {
      sliceImage = `${sliceImage.slice(0, 30)}...${sliceImage.substr(
        sliceImage.length - 10,
      )}` // TODO: change length for url after testing
    }
  }

  return <span>{sliceImage}</span>
}

export default InlineImage
