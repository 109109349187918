import { Space } from "antd"
import React from "react"
import { useDispatch } from "react-redux"
import ExportButtons from "../components/ExportButtons"
import SharedLinkModal from "../components/SharedLinkModal"
import { ApiClientExportFormat } from "../services/apiClient"
import { ExportAction } from "../state/@types/actions/filteredResourceAction"
import { changePageHeader } from "../state/actions/ui/pageHeader"
import { useTitle } from "./useTitle"

type UseReportPageProps<Rec> = {
  reportId: string
  title: string
  shareToken?: string
  exportAction?: (payload: ExportAction<Rec>["payload"]) => ExportAction<Rec>
  shareModalProps?: {
    path: string
  }
}

const useReportPage = <Rec,>({
  reportId,
  title,
  exportAction,
  shareToken,
  shareModalProps,
}: UseReportPageProps<Rec>): void => {
  const dispatch = useDispatch()

  const onSelectExport = React.useCallback(
    (format: ApiClientExportFormat) =>
      exportAction &&
      dispatch(
        exportAction({
          reportId,
          shareToken: shareToken,
          fileName: title,
          params: {
            "export-format": format,
          },
        }),
      ),
    [reportId],
  )

  useTitle(title)

  React.useEffect(() => {
    dispatch(
      changePageHeader({
        extra: (
          <Space>
            {shareModalProps && (
              <SharedLinkModal
                title={title}
                reportId={reportId}
                path={shareModalProps.path}
              />
            )}
            {exportAction && <ExportButtons onSelect={onSelectExport} />}
          </Space>
        ),
      }),
    )

    return () => {
      dispatch(
        changePageHeader({
          extra: null,
        }),
      )
    }
  }, [])
}

export default useReportPage
