import { takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  CanonicalReportActionType,
  LoadCanonicalReportAction,
} from "../../../../@types/actions/remote/cabinet/report/canonical"
import {
  DescriptionReportActionType,
  LoadDescriptionReportAction,
} from "../../../../@types/actions/remote/cabinet/report/description"
import {
  HeadingReportActionType,
  LoadHeadingReportAction,
} from "../../../../@types/actions/remote/cabinet/report/heading"
import {
  LoadMetaRobotsReportAction,
  MetaRobotsReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/metaRobots"
import {
  LoadRedirectReportAction,
  RedirectReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/redirect"
import {
  LoadRobotstxtReportAction,
  RobotstxtReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/robotstxt"
import {
  LoadSelectedPageAction,
  SelectedPageActionType,
} from "../../../../@types/actions/remote/cabinet/report/selectedPage"
import {
  LoadStatusCodeReportAction,
  StatusCodeReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/statusCode"
import {
  LoadTitleReportAction,
  TitleReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/title"
import {
  LoadUrlReportAction,
  UrlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/url"
import {
  addFilterCanonicalReport,
  setPaginationCanonicalReport,
  setSortCanonicalReport,
} from "../../../../actions/remote/cabinet/report/canonical"
import {
  addFilterDescriptionReport,
  setPaginationDescriptionReport,
  setSortDescriptionReport,
} from "../../../../actions/remote/cabinet/report/description"
import {
  addFilterHeadingReport,
  setPaginationHeadingReport,
  setSortHeadingReport,
} from "../../../../actions/remote/cabinet/report/heading"
import {
  addFilterMetaRobotsReport,
  setPaginationMetaRobotsReport,
  setSortMetaRobotsReport,
} from "../../../../actions/remote/cabinet/report/metaRobots"
import {
  addFilterRedirectReport,
  setPaginationRedirectReport,
  setSortRedirectReport,
} from "../../../../actions/remote/cabinet/report/redirect"
import {
  addFilterRobotstxtReport,
  setPaginationRobotstxtReport,
  setSortRobotstxtReport,
} from "../../../../actions/remote/cabinet/report/robotstxt"
import {
  addFilterStatusCodeReport,
  setPaginationStatusCodeReport,
  setSortStatusCodeReport,
} from "../../../../actions/remote/cabinet/report/statusCode"
import {
  addFilterTitleReport,
  setPaginationTitleReport,
  setSortTitleReport,
} from "../../../../actions/remote/cabinet/report/title"
import {
  addFilterUrlReport,
  setPaginationUrlReport,
  setSortUrlReport,
} from "../../../../actions/remote/cabinet/report/url"
import {
  getCanonicals,
  getDescriptions,
  getHeadings,
  getMetaRobots,
  getRedirects,
  getRobotstxt,
  getSelectedPage,
  getStatusCodes,
  getTitles,
  getUrls,
} from "../../../../selectors/remote/cabinet/report"
import filteredResourceSaga from "../../../common/filteredResourceSaga"

function* fetchRobotstxt(action: LoadRobotstxtReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getRobotstxt,
    actions: {
      addFilter: addFilterRobotstxtReport,
      setPagination: setPaginationRobotstxtReport,
      setSort: setSortRobotstxtReport,
    },
  })
}

function* fetchDescription(action: LoadDescriptionReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getDescriptions,
    actions: {
      addFilter: addFilterDescriptionReport,
      setPagination: setPaginationDescriptionReport,
      setSort: setSortDescriptionReport,
    },
  })
}

function* fetchTitles(action: LoadTitleReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getTitles,
    actions: {
      addFilter: addFilterTitleReport,
      setPagination: setPaginationTitleReport,
      setSort: setSortTitleReport,
    },
  })
}

function* fetchHeadings(action: LoadHeadingReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getHeadings,
    actions: {
      addFilter: addFilterHeadingReport,
      setPagination: setPaginationHeadingReport,
      setSort: setSortHeadingReport,
    },
  })
}

function* fetchStatusCodes(action: LoadStatusCodeReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getStatusCodes,
    actions: {
      addFilter: addFilterStatusCodeReport,
      setPagination: setPaginationStatusCodeReport,
      setSort: setSortStatusCodeReport,
    },
  })
}

function* fetchRedirects(action: LoadRedirectReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getRedirects,
    actions: {
      addFilter: addFilterRedirectReport,
      setPagination: setPaginationRedirectReport,
      setSort: setSortRedirectReport,
    },
  })
}

function* fetchCanonicals(action: LoadCanonicalReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getCanonicals,
    actions: {
      addFilter: addFilterCanonicalReport,
      setPagination: setPaginationCanonicalReport,
      setSort: setSortCanonicalReport,
    },
  })
}

function* fetchMetaRobots(action: LoadMetaRobotsReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getMetaRobots,
    actions: {
      addFilter: addFilterMetaRobotsReport,
      setPagination: setPaginationMetaRobotsReport,
      setSort: setSortMetaRobotsReport,
    },
  })
}

function* fetchUrls(action: LoadUrlReportAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getUrls,
    actions: {
      addFilter: addFilterUrlReport,
      setPagination: setPaginationUrlReport,
      setSort: setSortUrlReport,
    },
  })
}

function* fetchSelectedPage(action: LoadSelectedPageAction) {
  yield filteredResourceSaga({
    action,
    request: apiClient.cabinet.project.reports.getListPages,
    selector: getSelectedPage,
  })
}

export default function* getListPagesSaga(): Generator {
  yield takeLatest(TitleReportActionType.Load, fetchTitles)
  yield takeLatest(RobotstxtReportActionType.Load, fetchRobotstxt)
  yield takeLatest(DescriptionReportActionType.Load, fetchDescription)
  yield takeLatest(HeadingReportActionType.Load, fetchHeadings)
  yield takeLatest(StatusCodeReportActionType.Load, fetchStatusCodes)
  yield takeLatest(RedirectReportActionType.Load, fetchRedirects)
  yield takeLatest(CanonicalReportActionType.Load, fetchCanonicals)
  yield takeLatest(MetaRobotsReportActionType.Load, fetchMetaRobots)
  yield takeLatest(UrlReportActionType.Load, fetchUrls)
  yield takeLatest(SelectedPageActionType.Load, fetchSelectedPage)
}
