import { useSelector } from "react-redux"
import { RedirectsGraphAlias } from "../services/apiClient"
import { getRedirectsGraph } from "../state/selectors/remote/cabinet/report"
import { GraphRecordInfo } from "../types/graph"

type UseRedirectsGraph = {
  state: ReturnType<typeof getRedirectsGraph>
  info: GraphRecordInfo
}

export function useRedirectsGraph(): UseRedirectsGraph {
  const state = useSelector(getRedirectsGraph)
  const info = {
    [RedirectsGraphAlias.StatusCode301]: {
      label: "301 Moved Permanently (постоянный)",
      shortLabel: "301 Moved Permanently",
      description:
        "Количество URL с кодом ответа сервера 301, обозначающим перенаправление (редирект) на новый постоянный адрес",
    },
    [RedirectsGraphAlias.StatusCode302]: {
      label: "302 Moved Temporarily (временный)",
      shortLabel: "302 Moved Temporarily",
      description:
        "Количество URL с кодом ответа сервера 302, обозначающим временное перенаправление (редирект) на новый адрес",
    },
    [RedirectsGraphAlias.StatusCode307]: {
      label: "307 Temporary Redirect (временный)",
      shortLabel: "307 Temporary Redirect",
      description:
        "Количество URL с кодом ответа сервера 307, обозначающим перенаправление (редирект) на короткое время",
    },
  }

  return { state, info }
}
