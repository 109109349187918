import { Action } from "redux"
import {
  GetSessionResponse,
  LoginPayload,
  UpdateProfileBody,
  UpdateProfilePayload,
} from "../../../../services/apiClient"
import { RemoteAction, RemoteActionWithPayload } from "../action"

export enum AuthTypes {
  Load = "@@AUTH/LOAD",
  LoadPending = "@@AUTH/LOAD_PENDING",
  LoadSuccess = "@@AUTH/LOAD_SUCCESS",
  LoadFailure = "@@AUTH/LOAD_FAILURE",

  Login = "@@AUTH/LOGIN",
  LoginPending = "@@AUTH/LOGIN_PENDING",
  LoginSuccess = "@@AUTH/LOGIN_SUCCESS",
  LoginFailure = "@@AUTH/LOGIN_FAILURE",

  Logout = "@@AUTH/LOGOUT",
  LogoutPending = "@@AUTH/LOGOUT_PENDING",
  LogoutSuccess = "@@AUTH/LOGOUT_SUCCESS",
  LogoutFailure = "@@AUTH/LOGOUT_FAILURE",

  UpdateProfile = "@@AUTH/UPDATE/PROFILE",
  UpdateProfilePending = "@@AUTH/UPDATE/PROFILE_PENDING",
  UpdateProfileSuccess = "@@AUTH/UPDATE/PROFILE_SUCCESS",
  UpdateProfileFailure = "@@AUTH/UPDATE/PROFILE_FAILURE",

  Clear = "@@AUTH/CLEAR",
}

export type LogoutAction = RemoteAction<
  undefined,
  AuthTypes.Logout,
  AuthTypes.LogoutPending,
  AuthTypes.LogoutSuccess,
  AuthTypes.LogoutFailure
>
export type LogoutSuccessAction = ReturnType<LogoutAction["meta"]["success"]>
export type LogoutPendingAction = ReturnType<LogoutAction["meta"]["pending"]>
export type LogoutFailureAction = ReturnType<LogoutAction["meta"]["failure"]>

export type UpdateProfileAction = RemoteActionWithPayload<
  UpdateProfilePayload,
  UpdateProfileBody,
  AuthTypes.UpdateProfile,
  AuthTypes.UpdateProfilePending,
  AuthTypes.UpdateProfileSuccess,
  AuthTypes.UpdateProfileFailure
>
export type UpdateProfileSuccessAction = ReturnType<
  UpdateProfileAction["meta"]["success"]
>
export type UpdateProfilePendingAction = ReturnType<
  UpdateProfileAction["meta"]["pending"]
>
export type UpdateProfileFailureAction = ReturnType<
  UpdateProfileAction["meta"]["failure"]
>

export type LoadSessionAction = RemoteAction<
  GetSessionResponse,
  AuthTypes.Load,
  AuthTypes.LoadPending,
  AuthTypes.LoadSuccess,
  AuthTypes.LoadFailure
>
export type LoadSessionSuccessAction = ReturnType<
  LoadSessionAction["meta"]["success"]
>
export type LoadSessionPendingAction = ReturnType<
  LoadSessionAction["meta"]["pending"]
>
export type LoadSessionFailureAction = ReturnType<
  LoadSessionAction["meta"]["failure"]
>

export type LoginAction = RemoteActionWithPayload<
  LoginPayload,
  undefined,
  AuthTypes.Login,
  AuthTypes.LoginPending,
  AuthTypes.LoginSuccess,
  AuthTypes.LoginFailure
>
export type LoginSuccessAction = ReturnType<LoginAction["meta"]["success"]>
export type LoginPendingAction = ReturnType<LoginAction["meta"]["pending"]>
export type LoginFailureAction = ReturnType<LoginAction["meta"]["failure"]>

export type ClearAuthAction = Action<AuthTypes.Clear>
