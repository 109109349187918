import { call, takeEvery, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  ExportImageGroupReportAction,
  ImageGroupReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/imageGroup"
import {
  ExportNotHtmlGroupReportAction,
  NotHtmlGroupReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/notHtmlGroup"
import {
  getImageGroupReport,
  getNotHtmlGroups,
} from "../../../../selectors/remote/cabinet/report"
import filteredResourceExportSaga from "../../../common/filteredResourceExportSaga"

function* fetchExportImageGroupReport(action: ExportImageGroupReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getImageGroupReport,
      request: apiClient.cabinet.project.reports.exportListResources,
    })
  })
}

function* fetchExportNotHtmlGroups(action: ExportNotHtmlGroupReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getNotHtmlGroups,
      request: apiClient.cabinet.project.reports.exportListResources,
    })
  })
}

export default function* exportListResourcesSaga(): Generator {
  yield takeEvery(
    ImageGroupReportActionType.Export,
    fetchExportImageGroupReport,
  )
  yield takeLatest(
    NotHtmlGroupReportActionType.Export,
    fetchExportNotHtmlGroups,
  )
}
