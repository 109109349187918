import { call, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  ExportExternalReportAction,
  ExternalReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/external"
import {
  ExportImageReportAction,
  ImageReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/image"
import {
  ExportInternalReportAction,
  InternalReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/internal"
import {
  ExportNotHtmlReportAction,
  NotHtmlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/notHtml"
import {
  ExportSourcePageReportAction,
  SourcePageReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/sourcePage"
import {
  getExternal,
  getImages,
  getInternal,
  getNotHtml,
  getSourcePage,
} from "../../../../selectors/remote/cabinet/report"
import filteredResourceExportSaga from "../../../common/filteredResourceExportSaga"

function* fetchExportImages(action: ExportImageReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getImages,
      request: apiClient.cabinet.project.reports.exportListPageResources,
    })
  })
}
function* fetchExportNotHtml(action: ExportNotHtmlReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getNotHtml,
      request: apiClient.cabinet.project.reports.exportListPageResources,
    })
  })
}
function* fetchExportExternal(action: ExportExternalReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getExternal,
      request: apiClient.cabinet.project.reports.exportListPageResources,
    })
  })
}
function* fetchExportInternal(action: ExportInternalReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getInternal,
      request: apiClient.cabinet.project.reports.exportListPageResources,
    })
  })
}

function* fetchExportSourcePage(action: ExportSourcePageReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getSourcePage,
      request: apiClient.cabinet.project.reports.exportListPageResources,
    })
  })
}

export default function* exportListPageResourcesSaga(): Generator {
  yield takeLatest(ImageReportActionType.Export, fetchExportImages)
  yield takeLatest(NotHtmlReportActionType.Export, fetchExportNotHtml)
  yield takeLatest(ExternalReportActionType.Export, fetchExportExternal)
  yield takeLatest(InternalReportActionType.Export, fetchExportInternal)
  yield takeLatest(SourcePageReportActionType.Export, fetchExportSourcePage)
}
