import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { CrawlReportResourceType } from "../../../../../types/reportResource"
import { NotHtmlGroupReportActionType } from "../../../../@types/actions/remote/cabinet/report/notHtmlGroup"
import {
  NotHtmlGroupReportActions,
  NotHtmlGroupReportState,
} from "../../../../@types/reducers/remote/cabinet/report/notHtmlGroupReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: NotHtmlGroupReportState = {
  isLoading: false,
  fields: [
    "resource_id",
    "resource_url",
    "resource_url_no_scheme",
    "resource_status_code",
    "resource_type",
    "resource_robotstxt_ignore",
    "resource_robotstxt_line",
    "resource_content_type",
    "resource_is_external",
    "page_resource_count",
  ],
  defaultFilter: {
    resource_type: [
      [
        ApiClientFilterType.NotIn,
        [CrawlReportResourceType.Html, CrawlReportResourceType.Image],
      ],
    ],
  },
  alwaysSort: {
    resource_id: ApiClientSortOrder.Asc,
  },
}

const notHtmlGroupsReducer = (
  state: NotHtmlGroupReportState = initState,
  action: NotHtmlGroupReportActions,
): NotHtmlGroupReportState => {
  switch (action.type) {
    case NotHtmlGroupReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case NotHtmlGroupReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case NotHtmlGroupReportActionType.LoadPending:
      return updateStateSetPending(state)

    case NotHtmlGroupReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case NotHtmlGroupReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case NotHtmlGroupReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case NotHtmlGroupReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case NotHtmlGroupReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case NotHtmlGroupReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default notHtmlGroupsReducer
