import { Action } from "redux"
import {
  ExportListPageResourcesPayload,
  GetListPageResourcesPayload,
  GetListPageResourcesResponse,
} from "../../../../../../services/apiClient"
import { InternalReportRecord } from "../../../../reducers/remote/cabinet/report/internalReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum InternalReportActionType {
  Load = "@@REPORTS/INTERNAL/LOAD",
  LoadSuccess = "@@REPORTS/INTERNAL/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/INTERNAL/LOAD_PENDING",
  LoadFailure = "@@REPORTS/INTERNAL/LOAD_FAILURE",
  SetPagination = "@@REPORTS/INTERNAL/SET_PAGINATION",
  SetSort = "@@REPORTS/INTERNAL/SET_SORT",
  AddFilter = "@@REPORTS/INTERNAL/ADD_FILTER",
  CancelFilter = "@@REPORTS/INTERNAL/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/INTERNAL/CLEAR_FILTER",
  ClearSort = "@@REPORTS/INTERNAL/CLEAR_SORT",
  Export = "@@REPORTS/INTERNAL/EXPORT",
}

export type LoadInternalReportAction = LoadAction<
  GetListPageResourcesPayload<InternalReportRecord>,
  GetListPageResourcesResponse<InternalReportRecord>,
  InternalReportActionType.Load,
  InternalReportActionType.LoadPending,
  InternalReportActionType.LoadSuccess,
  InternalReportActionType.LoadFailure
>
export type LoadPendingInternalReportAction = ReturnType<
  LoadInternalReportAction["meta"]["pending"]
>
export type LoadSuccessInternalReportAction = ReturnType<
  LoadInternalReportAction["meta"]["success"]
>
export type LoadFailureInternalReportAction = ReturnType<
  LoadInternalReportAction["meta"]["failure"]
>

export type ClearFilterInternalReportAction = Action<
  InternalReportActionType.ClearFilter
>
export type ClearSortInternalReportAction = Action<
  InternalReportActionType.ClearSort
>

export type ExportInternalReportAction = ExportAction<
  InternalReportRecord,
  ExportListPageResourcesPayload<InternalReportRecord>,
  InternalReportActionType.Export
>

export type AddFilterInternalReportAction = AddFilterAction<
  InternalReportRecord,
  InternalReportActionType.AddFilter
>

export type CancelFilterInternalReportAction = CancelFilterAction<
  InternalReportRecord,
  InternalReportActionType.CancelFilter
>

export type SetSortInternalReportAction = SetSortAction<
  InternalReportRecord,
  InternalReportActionType.SetSort
>

export type SetPaginationInternalReportAction = SetPaginationAction<
  InternalReportActionType.SetPagination
>
