import { Col, PageHeader as PageHeaderAnt, Row } from "antd"
import { PageHeaderProps } from "antd/lib/page-header"
import React from "react"
import { useSelector } from "react-redux"
import { getPageHeader } from "../state/selectors/ui"

type Props = PageHeaderProps

const PageHeader: React.FC<Props> = React.memo(props => {
  const pageHeaderState = useSelector(getPageHeader)
  return (
    <Row style={{ background: "#fff" }}>
      <Col xl={{ span: 12, offset: 0 }} xxl={{ span: 10, offset: 1 }}>
        <PageHeaderAnt {...props} {...pageHeaderState} />
      </Col>
    </Row>
  )
})

export default PageHeader
