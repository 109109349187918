import React from "react"
import Url, { UrlProps } from "../../../Url"
import Title from "../../../Title"
import Description from "../../Description"
import Redirects from "../../Redirects"
import { ResourceRedirect } from "../../../../types/reportResource"

export type PageInformationCellProps = {
  resourceId?: string | null
  title?: string | null
  description?: string | null
  url: UrlProps
  canonical?: {
    url: string | null
    isCanonical: boolean | null
  }
  redirects?: ResourceRedirect[]
}

type Props = PageInformationCellProps

const PageInformationCell: React.FC<Props> = props => {
  const {
    title,
    description,
    redirects = [],
    url,
    canonical,
    resourceId,
  } = props

  const pageInformation = React.useRef<HTMLDivElement>(null)

  return (
    <div className="page-information" ref={pageInformation}>
      {title ? <Title value={title} /> : null}
      <Url {...url} resourceId={resourceId} />
      {redirects.length !== 0 && (
        <Redirects items={redirects} canonical={canonical} />
      )}
      {typeof description === "string" && (
        <p
          className={`table__description ${
            !description.length && "table__description_type_empty"
          }`}
          style={{ marginTop: redirects && redirects.length ? 10 : 0 }}
        >
          <Description value={description} />
        </p>
      )}
    </div>
  )
}

export default PageInformationCell
