import {
  BillingTypes,
  ClearFeatureAction,
  CreateOrderAction,
  LoadOrdersAction,
  LoadPlanByAliasAction,
  LoadPlansAction,
  LoadUserFeaturesAction,
  LoadUserPlanAction,
  PurchaseAction,
} from "../../@types/actions/remote/billing"

const loadUserFeatures = (): LoadUserFeaturesAction => ({
  type: BillingTypes.LoadUserFeatures,
  meta: {
    pending: () => ({ type: BillingTypes.LoadUserFeaturesPending }),
    success: data => ({
      type: BillingTypes.LoadUserFeaturesSuccess,
      payload: data,
    }),
    failure: error => ({
      type: BillingTypes.LoadUserFeaturesFailure,
      payload: error,
    }),
  },
})

const clearFeatures = (): ClearFeatureAction => ({
  type: BillingTypes.UserFeaturesClear,
})

const createOrder = (
  payload: CreateOrderAction["payload"],
): CreateOrderAction => ({
  type: BillingTypes.CreateOrder,
  payload,
  meta: {
    pending: () => ({ type: BillingTypes.CreateOrderPending }),
    success: data => ({
      type: BillingTypes.CreateOrderSuccess,
      payload: data,
    }),
    failure: error => ({
      type: BillingTypes.CreateOrderFailure,
      payload: error,
    }),
  },
})

const loadOrders = (): LoadOrdersAction => ({
  type: BillingTypes.LoadOrders,
  meta: {
    pending: () => ({ type: BillingTypes.LoadOrdersPending }),
    success: payload => ({
      type: BillingTypes.LoadOrdersSuccess,
      payload,
    }),
    failure: error => ({
      type: BillingTypes.LoadOrdersFailure,
      payload: error,
    }),
  },
})

const loadPlanByAlias = (
  payload: LoadPlanByAliasAction["payload"],
): LoadPlanByAliasAction => ({
  type: BillingTypes.LoadPlan,
  payload,
  meta: {
    pending: () => ({ type: BillingTypes.LoadPlanPending }),
    success: payload => ({
      type: BillingTypes.LoadPlanSuccess,
      payload,
    }),
    failure: error => ({
      type: BillingTypes.LoadPlanFailure,
      payload: error,
    }),
  },
})

const loadPlans = (): LoadPlansAction => ({
  type: BillingTypes.LoadPlans,
  meta: {
    pending: () => ({ type: BillingTypes.LoadPlansPending }),
    success: payload => ({
      type: BillingTypes.LoadPlansSuccess,
      payload,
    }),
    failure: error => ({
      type: BillingTypes.LoadPlansFailure,
      payload: error,
    }),
  },
})

const loadUserPlan = (): LoadUserPlanAction => ({
  type: BillingTypes.LoadUserPlan,
  meta: {
    pending: () => ({ type: BillingTypes.LoadUserPlanPending }),
    success: payload => ({
      type: BillingTypes.LoadUserPlanSuccess,
      payload,
    }),
    failure: payload => ({
      type: BillingTypes.LoadUserPlanFailure,
      payload,
    }),
  },
})

const purchase = (payload: PurchaseAction["payload"]): PurchaseAction => ({
  type: BillingTypes.Purchase,
  payload,
  meta: {
    pending: () => ({ type: BillingTypes.PurchasePending }),
    success: data => ({
      type: BillingTypes.PurchaseSuccess,
      payload: data,
    }),
    failure: error => ({
      type: BillingTypes.PurchaseFailure,
      payload: error,
    }),
  },
})

export {
  loadUserFeatures,
  clearFeatures,
  createOrder,
  loadOrders,
  loadPlanByAlias,
  loadPlans,
  loadUserPlan,
  purchase,
}
