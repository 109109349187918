import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../services/apiClient"
import {
  AuthTypes,
  UpdateProfileAction,
} from "../../@types/actions/remote/auth"

function* fetchUpdateProfile(action: UpdateProfileAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    yield call(apiClient.auth.updateProfile, action.payload)
    yield put(action.meta.success(action.payload))
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* profileSaga(): Generator {
  yield takeEvery(AuthTypes.UpdateProfile, fetchUpdateProfile)
}
