import { call, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  ImageGroupReportActionType,
  LoadImageGroupReportAction,
} from "../../../../@types/actions/remote/cabinet/report/imageGroup"
import {
  LoadNotHtmlGroupReportAction,
  NotHtmlGroupReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/notHtmlGroup"
import {
  addFilterCanonicalReport,
  setPaginationCanonicalReport,
  setSortCanonicalReport,
} from "../../../../actions/remote/cabinet/report/canonical"
import {
  addFilterImageGroupReport,
  setPaginationImageGroupReport,
  setSortImageGroupReport,
} from "../../../../actions/remote/cabinet/report/imageGroup"
import {
  getImageGroupReport,
  getNotHtmlGroups,
} from "../../../../selectors/remote/cabinet/report"
import filteredResourceSaga from "../../../common/filteredResourceSaga"

function* fetchImageGroupReport(action: LoadImageGroupReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListResources,
      selector: getImageGroupReport,
      actions: {
        addFilter: addFilterImageGroupReport,
        setPagination: setPaginationImageGroupReport,
        setSort: setSortImageGroupReport,
      },
    })
  })
}

function* fetchNotHtmlGroups(action: LoadNotHtmlGroupReportAction) {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      request: apiClient.cabinet.project.reports.getListResources,
      selector: getNotHtmlGroups,
      actions: {
        addFilter: addFilterCanonicalReport,
        setPagination: setPaginationCanonicalReport,
        setSort: setSortCanonicalReport,
      },
    })
  })
}

export default function* getListResourcesSaga(): Generator {
  yield takeLatest(ImageGroupReportActionType.Load, fetchImageGroupReport)
  yield takeLatest(NotHtmlGroupReportActionType.Load, fetchNotHtmlGroups)
}
