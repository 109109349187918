import { applyMiddleware, compose, createStore, Store } from "redux"
import createSagaMiddleware from "redux-saga"
import env, { Environment } from "../lib/env"
import rootReducer from "./reducers/rootReducer"
import rootSagas from "./sagas"

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

const middlewareEnhancer = [applyMiddleware(...middlewares)]

// add MW REDUX DEVTOOLS EXTENSION if build don`t to production and has extension in browser
if (
  env.ENVIRONMENT !== Environment.Prod &&
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__
) {
  // @ts-ignore
  middlewareEnhancer.push(
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
      // @ts-ignore
      window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true, traceLimit: 25 }),
  )
}

const store: Store = createStore(rootReducer, compose(...middlewareEnhancer))

sagaMiddleware.run(rootSagas)

export default store
