import { Tooltip } from "antd"
import React from "react"
import { CrawlReportResourceType } from "../../../types/reportResource"
import Nullable from "../../Nullable"

type Props = {
  value: CrawlReportResourceType
  contentType: string | null
}

const ResourceType: React.FC<Props> = (props): JSX.Element => {
  const { value, contentType } = props

  let type

  switch (value) {
    case CrawlReportResourceType.Other:
      type = "Other"
      break
    case CrawlReportResourceType.Html:
      type = "Html"
      break
    case CrawlReportResourceType.Style:
      type = "Style"
      break
    case CrawlReportResourceType.Script:
      type = "Script"
      break
    case CrawlReportResourceType.Archive:
      type = "Archive"
      break
    case CrawlReportResourceType.Document:
      type = "Document"
      break
    case CrawlReportResourceType.Video:
      type = "Video"
      break
    case CrawlReportResourceType.Audio:
      type = "Audio"
      break
    case CrawlReportResourceType.Image:
      type = "Image"
      break
    case CrawlReportResourceType.Text:
      type = "Text"
      break
    case CrawlReportResourceType.Executable:
      type = "Executable"
      break
    case CrawlReportResourceType.Font:
      type = "Font"
      break
    default:
      return <Nullable />
  }

  if (!contentType) {
    return <>{type}</>
  }

  return <Tooltip title={contentType}>{type}</Tooltip>
}

export default ResourceType
