import { all, fork } from "redux-saga/effects"
import createReportSaga from "./createReportSaga"
import createProjectSaga from "./createProjectSaga"
import getProjectSaga from "./getProjectSaga"
import getProjectByAliasSaga from "./getProjectByAliasSaga"
import getProjectListSaga from "./getProjectListSaga"
import deleteProjectSaga from "./deleteProjectSaga"
import getReportListSaga from "./getReportListSaga"

export default function* projectsSagas(): Generator {
  yield all([
    fork(createReportSaga),
    fork(createProjectSaga),
    fork(getProjectSaga),
    fork(getProjectListSaga),
    fork(deleteProjectSaga),
    fork(getReportListSaga),
    fork(getProjectByAliasSaga),
  ])
}
