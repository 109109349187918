import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../../../routes"
import NotHtmlFullPage from "./NotHtmlFullPage"
import NotHtmlGroupsPage from "./NotHtmlGroupsPage"

type Props = {
  reportId: string
  shareToken: string
}

const NotHtmlRouter: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const path = routes.s(shareToken).content().notHtml()

  return (
    <Switch>
      <Route
        path={path.full()}
        render={(): JSX.Element => (
          <NotHtmlFullPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.groups()}
        render={(): JSX.Element => (
          <NotHtmlGroupsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
    </Switch>
  )
}

export default NotHtmlRouter
