import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { SitemapUrlReportActionType } from "../../../../@types/actions/remote/cabinet/report/sitemapUrl"
import {
  SitemapUrlReportActions,
  SitemapUrlReportState,
} from "../../../../@types/reducers/remote/cabinet/report/sitemapUrlReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: SitemapUrlReportState = {
  isLoading: false,
  fields: [
    "sitemap_url_id",
    "sitemap_url_url",
    "resource_url",
    "resource_url_no_scheme",
    "resource_status_code",
    "resource_redirect_url",
    "resource_redirect_status_code",
    "resource_redirects",
    "resource_without_link",
    "resource_index_status",
    "resource_is_external",
    "page_robots_noindex",
    "resource_robotstxt_ignore",
    "resource_robotstxt_line",
    "page_canonical_url",
    "page_is_canonical",
    "resource_id",
  ],
  alwaysSort: {
    sitemap_url_id: ApiClientSortOrder.Asc,
  },
}

const sitemapUrlsReducer = (
  state: SitemapUrlReportState = initState,
  action: SitemapUrlReportActions,
): SitemapUrlReportState => {
  switch (action.type) {
    case SitemapUrlReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case SitemapUrlReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case SitemapUrlReportActionType.LoadPending:
      return updateStateSetPending(state)

    case SitemapUrlReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case SitemapUrlReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case SitemapUrlReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case SitemapUrlReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case SitemapUrlReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case SitemapUrlReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default sitemapUrlsReducer
