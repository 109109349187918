import React from "react"
import UrlReport from "../../../components/Report/UrlReport"
import findMenuItems from "../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../hooks/useReportPage"
import { exportUrlReport } from "../../../state/actions/remote/cabinet/report/url"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.Urls)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const UrlsPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({ reportId, title, shareToken, exportAction: exportUrlReport })

  return <UrlReport reportId={reportId} shareToken={shareToken} />
}

export default UrlsPage
