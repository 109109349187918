import {
  ClearSharedLinkAction,
  ClearCreatedSharedLinkAction,
  CreateSharedLinkAction,
  DeleteSharedLinkAction,
  LoadSharedLinkAction,
  LoadSharedLinkByTokenAction,
  SharedLinkActionType,
} from "../../../../@types/actions/remote/cabinet/report/share"

const loadSharedLink = (
  payload: LoadSharedLinkAction["payload"],
): LoadSharedLinkAction => ({
  type: SharedLinkActionType.Load,
  payload,
  meta: {
    pending: () => ({ type: SharedLinkActionType.LoadPending }),
    success: payload => ({
      type: SharedLinkActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: SharedLinkActionType.LoadFailure,
      payload,
    }),
  },
})

const loadSharedLinkByToken = (
  payload: LoadSharedLinkByTokenAction["payload"],
): LoadSharedLinkByTokenAction => ({
  type: SharedLinkActionType.LoadByToken,
  payload,
  meta: {
    pending: () => ({ type: SharedLinkActionType.LoadByTokenPending }),
    success: payload => ({
      type: SharedLinkActionType.LoadByTokenSuccess,
      payload,
    }),
    failure: payload => ({
      type: SharedLinkActionType.LoadByTokenFailure,
      payload,
    }),
  },
})

const createSharedLink = (
  payload: CreateSharedLinkAction["payload"],
): CreateSharedLinkAction => ({
  type: SharedLinkActionType.Create,
  payload,
  meta: {
    pending: () => ({ type: SharedLinkActionType.CreatePending }),
    success: payload => ({
      type: SharedLinkActionType.CreateSuccess,
      payload,
    }),
    failure: payload => ({
      type: SharedLinkActionType.CreateFailure,
      payload,
    }),
  },
})

const deleteSharedLink = (
  payload: DeleteSharedLinkAction["payload"],
): DeleteSharedLinkAction => ({
  type: SharedLinkActionType.Delete,
  payload,
  meta: {
    pending: () => ({ type: SharedLinkActionType.DeletePending }),
    success: payload => ({
      type: SharedLinkActionType.DeleteSuccess,
      payload,
    }),
    failure: payload => ({
      type: SharedLinkActionType.DeleteFailure,
      payload,
    }),
  },
})

const clearSharedLink = (): ClearSharedLinkAction => ({
  type: SharedLinkActionType.CurrentClear,
})

const clearCreatedSharedLink = (): ClearCreatedSharedLinkAction => ({
  type: SharedLinkActionType.CreatedClear,
})

export {
  loadSharedLink,
  loadSharedLinkByToken,
  createSharedLink,
  clearSharedLink,
  clearCreatedSharedLink,
  deleteSharedLink,
}
