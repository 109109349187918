import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { GetUserFeaturesResponse } from "../../../services/apiClient"
import {
  BillingTypes,
  LoadUserFeaturesAction,
} from "../../@types/actions/remote/billing"

function* fetchGetUserFeatures(action: LoadUserFeaturesAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetUserFeaturesResponse = yield call(
      apiClient.billing.getUserFeatures,
    )
    yield put(action.meta.success(response))
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* saga(): Generator {
  yield takeEvery(BillingTypes.LoadUserFeatures, fetchGetUserFeatures)
}
