import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetProjectListResponse,
} from "../../../../../services/apiClient"
import {
  LoadProjectByHostAction,
  LoadProjectListAction,
  ProjectTypes,
} from "../../../../@types/actions/remote/cabinet/project"

type Actions = LoadProjectListAction | LoadProjectByHostAction

function* fetchGetProjectList(action: Actions): SagaIterator {
  yield put(action.meta.pending())
  try {
    let payload
    if (action.type === ProjectTypes.LoadByHost) {
      payload = action.payload
    }

    const response: GetProjectListResponse = yield call(
      apiClient.cabinet.project.projects.getProjectList,
      payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getProjectListSaga(): Generator {
  yield takeEvery(ProjectTypes.LoadList, fetchGetProjectList)
  yield takeEvery(ProjectTypes.LoadByHost, fetchGetProjectList)
}
