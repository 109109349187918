import { PageHeaderProps } from "antd"
import { ActionWithPayload } from "../action"

export enum PageHeaderActionType {
  Change = "@@UI/PAGE_HEADER/CHANGE",
  ChangeTitle = "@@UI/PAGE_HEADER/CHANGE_TITLE",
}

export type PageHeaderChangeTitleAction = ActionWithPayload<
  Pick<PageHeaderProps, "title">,
  PageHeaderActionType.ChangeTitle
>

export type ChangePageHeaderPayload = PageHeaderProps
export type PageHeaderChangeAction = ActionWithPayload<
  ChangePageHeaderPayload,
  PageHeaderActionType.Change
>

export type PageHeaderActions =
  | PageHeaderChangeTitleAction
  | PageHeaderChangeAction
