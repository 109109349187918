import { ResourceFilteredRequest } from "../../services/apiClient/@types/request"
import { mergeFilters } from "../../services/filters/utils"
import { StateResourceFiltered } from "../@types/state"

type PrepareParamsFunc<Rec> = (
  state: StateResourceFiltered<Rec, (keyof Rec)[]>,
) => ResourceFilteredRequest<Rec>["params"]

export default function prepareFilteredResourceParams<Rec>(
  state: StateResourceFiltered<Rec, (keyof Rec)[]>,
): ReturnType<PrepareParamsFunc<Rec>> {
  const params: ResourceFilteredRequest<Rec>["params"] = {}

  const assignSort = !state.sort ? state.defaultSort : state.sort
  params.sort = Object.assign({}, assignSort, state.alwaysSort)

  const filters = []
  if (state.filter) {
    filters.push(state.filter)
  }

  if (state.defaultFilter) {
    filters.push(state.defaultFilter)
  }

  if (filters.length) {
    const filter = mergeFilters<Rec>(...filters)
    if (filter) {
      params.filter = filter
    }
  }

  params.fields = state.fields

  return params
}
