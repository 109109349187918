import { Drawer, Tooltip, Typography } from "antd"
import { TextProps } from "antd/lib/typography/Text"
import React from "react"
import { useSelector } from "react-redux"
import { FileTextOutlined, QuestionCircleOutlined } from "@ant-design/icons"
import { getReport } from "../state/selectors/remote/cabinet/report"
import Nullable from "./Nullable"

export type RobotstxtIsIgnoreProps = {
  lineNumber?: number | null
  value: boolean | null
  isExternal?: boolean | null
  viewLabel?: boolean
}

type Props = RobotstxtIsIgnoreProps

// View is ignored url in robots.txt
const RobotstxtIsIgnore: React.FC<Props> = props => {
  const { lineNumber, value, isExternal, viewLabel = false } = props

  const [visibleDrawer, setVisibleDrawer] = React.useState(false)

  const reportState = useSelector(getReport)
  const reportStateData = reportState.data

  if (value === null || isExternal === null || isExternal || !reportStateData) {
    return <Nullable />
  }

  const robotstxtLines = reportStateData.payload.result?.robotstxt_lines
  let label = null
  if (viewLabel) {
    label = (
      <>
        <Tooltip placement="leftTop" title="URL запрещен в robots.txt">
          <Typography.Text>robots.txt</Typography.Text>
          <QuestionCircleOutlined style={{ marginLeft: 5 }} />
        </Tooltip>
        <br />
      </>
    )
  }

  const type = !value ? "success" : "danger"

  const result = (
    <Typography.Text type={type}>
      {!value ? "Allowed" : "Disallowed"}
    </Typography.Text>
  )

  // if lineNumber not exist then url Allowed
  if (!lineNumber || !robotstxtLines) {
    return (
      <>
        {label}
        {result}
      </>
    )
  }

  // get line with directive
  const line = parseInt(robotstxtLines[lineNumber - 1])

  const onCloseDrawer = () => {
    setVisibleDrawer(false)
  }

  return (
    <>
      {label}
      {!line ? (
        result
      ) : (
        <>
          <Typography.Text type={type} style={{ marginLeft: 5 }}>
            {line}
            <Tooltip title="Посмотреть подробно">
              <FileTextOutlined
                style={{ cursor: "pointer", marginLeft: 5, color: "initial" }}
                onClick={(): void => {
                  setVisibleDrawer(true)
                }}
              />
            </Tooltip>
          </Typography.Text>
        </>
      )}
      {visibleDrawer && (
        <RobotstxtDrawer
          reportEntryPoint={reportStateData.entry_point}
          line={lineNumber}
          isIgnore={value}
          robotstxtLines={robotstxtLines}
          onClose={onCloseDrawer}
        />
      )}
    </>
  )
}

export default RobotstxtIsIgnore

type RobotstxtDrawerProps = {
  reportEntryPoint: string
  robotstxtLines: string[]
  line: number
  isIgnore: boolean
  onClose: () => void
}

const RobotstxtDrawer: React.FC<RobotstxtDrawerProps> = React.memo(props => {
  const {
    robotstxtLines,
    reportEntryPoint,
    line: selectedLine,
    isIgnore: selectedIsIgonore,
    onClose,
  } = props

  return (
    <Drawer
      placement="right"
      title={`${reportEntryPoint}/robots.txt`}
      onClose={onClose}
      width={576}
      visible={true}
      bodyStyle={{
        overflow: "auto",
      }}
    >
      {robotstxtLines.map((line, index) => {
        const textProps: TextProps = {}
        if (selectedLine - 1 === index) {
          textProps.type = !selectedIsIgonore ? "success" : "danger"
        }

        return (
          <div style={{ whiteSpace: "nowrap" }} key={`${line}_fragment`}>
            <Typography.Text type="secondary">{index + 1}</Typography.Text>
            <Typography.Text {...textProps}>. {line}</Typography.Text>
            {robotstxtLines.length !== index && <br />}
          </div>
        )
      })}
    </Drawer>
  )
})
