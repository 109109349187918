import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { GetPlansResponse } from "../../../services/apiClient"
import {
  BillingTypes,
  LoadPlansAction,
} from "../../@types/actions/remote/billing"

function* fetchPlans(action: LoadPlansAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetPlansResponse = yield call(apiClient.billing.getPlans)
    yield put(action.meta.success(response))
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* getPlansSaga(): Generator {
  yield takeEvery(BillingTypes.LoadPlans, fetchPlans)
}
