import { Button, Tooltip } from "antd"
import React from "react"
import { FilterOutlined } from "@ant-design/icons"
import { ApiClientFilter } from "../services/apiClient"
import {
  Filter,
  FilterActionFilter,
  FilterActionType,
} from "../services/filters"
import { hasIsActiveFilter } from "../services/filters/utils"

export type FiltersOnChange<Rec> = (
  actions: Filter<Rec>["actions"],
  prevActions: Filter<Rec>["actions"] | null,
) => void

export type FiltersOnClear = () => void

type FiltersProps<Rec> = {
  activeFilter?: ApiClientFilter<Rec>
  filters: Filter<Rec>[]
  onChange: FiltersOnChange<Rec>
  onClear?: () => void
}

const Filters = <Rec,>(props: FiltersProps<Rec>) => {
  const { filters, activeFilter, onChange, onClear }: FiltersProps<Rec> = props

  React.useEffect(() => {
    for (const item of filters) {
      const itemFilters = item.actions
        .filter(action => action.type === FilterActionType.Filter)
        .map(
          action =>
            action.action.payload as FilterActionFilter<
              Rec
            >["action"]["payload"],
        )

      if (
        hasIsActiveFilter(activeFilter, {
          ...itemFilters[0],
        })
      ) {
        prevActions.current = item.actions
      }
    }
  }, [])

  const prevActions = React.useRef<Filter<Rec>["actions"] | null>(null)

  const onClearFilter = () => {
    prevActions.current = null
    onClear && onClear()
  }

  const onSelectFilter = (actions: Filter<Rec>["actions"]) => {
    onChange(actions, prevActions.current)
    prevActions.current = actions
  }

  return (
    <>
      <Button.Group>
        {filters.map(item => {
          const itemFilters = item.actions
            .filter(action => action.type === FilterActionType.Filter)
            .map(
              action =>
                action.action.payload as FilterActionFilter<
                  Rec
                >["action"]["payload"],
            )

          const isActive = hasIsActiveFilter(activeFilter, {
            ...itemFilters[0],
          })
          const button: JSX.Element = (
            <Button
              disabled={item.disabled}
              key={item.name}
              style={{ pointerEvents: isActive ? "none" : "visible" }}
              type={isActive ? "primary" : "default"}
              onClick={() => onSelectFilter(item.actions)}
            >
              {item.name}
            </Button>
          )

          if (!item.tooltip) {
            return button
          }

          return (
            <Tooltip key={`${item.name}_tooltip`} {...item.tooltip}>
              {button}
            </Tooltip>
          )
        })}
        <Button
          icon={<FilterOutlined />}
          type="default"
          disabled={activeFilter === undefined}
          onClick={() => onClearFilter()}
        >
          Очистить
        </Button>
      </Button.Group>
    </>
  )
}

export default React.memo(Filters) as typeof Filters
