import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../../routes"
import DescriptionsPage from "./DescriptionsPage"
import HeadingsPage from "./HeadingsPage"
import ImagesPage from "./images/ImagesPageRouter"
import NotHtmlPage from "./notHtml/NotHtmlRouter"
import TitlesPage from "./TitlesPage"

type Props = {
  reportId: string
  shareToken: string
}

const ContentRouter: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const path = routes.s(shareToken).content()

  return (
    <Switch>
      <Route
        exact
        path={path.titles()}
        render={(): JSX.Element => (
          <TitlesPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        exact
        path={path.descriptions()}
        render={(): JSX.Element => (
          <DescriptionsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        exact
        path={path.headings()}
        render={(): JSX.Element => (
          <HeadingsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.images().index()}
        render={(): JSX.Element => (
          <ImagesPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={path.notHtml().index()}
        render={(): JSX.Element => (
          <NotHtmlPage reportId={reportId} shareToken={shareToken} />
        )}
      />
    </Switch>
  )
}

export default ContentRouter
