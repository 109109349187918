import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetEntryPointsResponse,
} from "../../../../services/apiClient"
import {
  HostTypes,
  LoadEntryPointsAction,
} from "../../../@types/actions/remote/cabinet/host"

function* fetchEntryPoints(action: LoadEntryPointsAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetEntryPointsResponse = yield call(
      apiClient.cabinet.host.getEntryPoints,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getEntryPointsSaga(): Generator {
  yield takeEvery(HostTypes.LoadEntryPoints, fetchEntryPoints)
}
