import { ImageGroupReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/imageGroupReducer"
import { addFilterImageGroupReport } from "../../../state/actions/remote/cabinet/report/imageGroup"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<ImageGroupReportRecord>[] = [
  {
    name: "Битые ссылки",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageGroupReport({
          resource_is_broken: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Alt отсутствует",
    disabled: true,
    actions: [],
    tooltip: {
      title: "В процессе разработки", // TODO: implement this
    },
  },
  {
    name: "Неиндексируемые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageGroupReport({
          resource_robotstxt_ignore: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Индексируемые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageGroupReport({
          resource_robotstxt_ignore: [[ApiClientFilterType.Eq, false]],
        }),
      },
    ],
  },
]

export default filters
