import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  CreateReportResponse,
} from "../../../../../services/apiClient"
import {
  CreateReportAction,
  ReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/report"

function* fetchCreateReport(action: CreateReportAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: CreateReportResponse = yield call(
      apiClient.cabinet.project.projects.createReport,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* createReportSaga(): SagaIterator {
  yield takeEvery(ReportActionType.Create, fetchCreateReport)
}
