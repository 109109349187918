import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../services/apiClient"
import { AuthTypes, LogoutAction } from "../../@types/actions/remote/auth"

function* fetchLogout(action: LogoutAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    yield call(apiClient.auth.logout)
    yield put(action.meta.success())
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* logoutSaga(): Generator {
  yield takeEvery(AuthTypes.Logout, fetchLogout)
}
