import {
  LoadRobotsGraphAction,
  RobotsGraphActionType,
} from "../../../../@types/actions/remote/cabinet/report/robotsGraph"

const loadRobotsGraph = (
  payload: LoadRobotsGraphAction["payload"],
): LoadRobotsGraphAction => ({
  type: RobotsGraphActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: RobotsGraphActionType.LoadPending,
    }),
    success: payload => ({
      type: RobotsGraphActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: RobotsGraphActionType.LoadFailure,
      payload,
    }),
  },
})

export { loadRobotsGraph }
