export enum ReportSettingAlias {
  ThreadCount = "thread_count",
  ThreadSleepMs = "thread_sleep_ms",
  Budget = "budget",
  MaxDfi = "max_dfi",
  TimeoutMs = "timeout_ms",
  Sitemaps = "sitemaps",
  UseRobotstxt = "use_robotstxt",
  UseMetarobots = "use_metarobots",
  UseCanonical = "use_canonical",
  FollowSubdomains = "follow_subdomains",
  TreatWwwAsSameHost = "treat_www_as_same_host",
  FollowNofollowLinks = "follow_nofollow_links",
  FetchExternalLinks = "fetch_external_links",
  UserAgent = "user_agent",
  RobotsUserAgent = "robots_user_agent",
}
