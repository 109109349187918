import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { DescriptionReportRecord } from "../../../../reducers/remote/cabinet/report/descriptionReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum DescriptionReportActionType {
  Load = "@@REPORTS/DESCRIPTIONS/LOAD",
  LoadSuccess = "@@REPORTS/DESCRIPTIONS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/DESCRIPTIONS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/DESCRIPTIONS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/DESCRIPTIONS/SET_PAGINATION",
  SetSort = "@@REPORTS/DESCRIPTIONS/SET_SORT",
  AddFilter = "@@REPORTS/DESCRIPTIONS/ADD_FILTER",
  CancelFilter = "@@REPORTS/DESCRIPTIONS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/DESCRIPTIONS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/DESCRIPTIONS/CLEAR_SORT",
  Export = "@@REPORTS/DESCRIPTIONS/EXPORT",
}

export type LoadDescriptionReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<DescriptionReportRecord>,
  DescriptionReportActionType.Load,
  DescriptionReportActionType.LoadPending,
  DescriptionReportActionType.LoadSuccess,
  DescriptionReportActionType.LoadFailure
>

export type LoadPendingDescriptionReportAction = ReturnType<
  LoadDescriptionReportAction["meta"]["pending"]
>
export type LoadSuccessDescriptionReportAction = ReturnType<
  LoadDescriptionReportAction["meta"]["success"]
>
export type LoadFailureDescriptionReportAction = ReturnType<
  LoadDescriptionReportAction["meta"]["failure"]
>

export type ClearFilterDescriptionReportAction = Action<
  DescriptionReportActionType.ClearFilter
>

export type ClearSortDescriptionReportAction = Action<
  DescriptionReportActionType.ClearSort
>

export type ExportDescriptionReportAction = ExportAction<
  DescriptionReportRecord,
  ExportListPagesPayload<DescriptionReportRecord>,
  DescriptionReportActionType.Export
>

export type AddFilterDescriptionReportAction = AddFilterAction<
  DescriptionReportRecord,
  DescriptionReportActionType.AddFilter
>

export type CancelFilterDescriptionReportAction = CancelFilterAction<
  DescriptionReportRecord,
  DescriptionReportActionType.CancelFilter
>

export type SetSortDescriptionReportAction = SetSortAction<
  DescriptionReportRecord,
  DescriptionReportActionType.SetSort
>

export type SetPaginationDescriptionReportAction = SetPaginationAction<
  DescriptionReportActionType.SetPagination
>
