import { Tag } from "antd"
import { ColumnType } from "antd/lib/table/interface"
import { GetComponentProps } from "rc-table/lib/interface"
import React from "react"
import { AnchorType } from "../../../types/reportLink"
import {
  CrawlReportResourceType,
  ResourceIndexStatus,
} from "../../../types/reportResource"
import Nullable from "../../Nullable"
import RobotstxtIsIgnore, {
  RobotstxtIsIgnoreProps,
} from "../../RobotstxtIsIgnore"
import Size from "../../SIze"
import StatusCode, { StatusCodeProps } from "../../StatusCode"
import Canonical from "../Canonical"
import Dfi from "../Dfi"
import ImageExtension from "../ImageExtension"
import MetaRobots from "../MetaRobots"
import ResourceType from "../ResourceType"
import PageInformationCell, {
  PageInformationCellProps,
} from "./PageInformationCell"

const anchorType = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "Тип",
  align: "center",
  width: 80,
  render: (value: AnchorType): React.ReactNode => {
    switch (value) {
      case AnchorType.IMG:
        return <>Img</>
      case AnchorType.TEXT:
        return <>Текст</>
      case AnchorType.OTHER:
        return <>Другое</>
      default:
        return <Nullable />
    }
  },
  ...props,
})

const dfi = <Fields extends string, Rec extends Record<Fields, Rec[Fields]>>(
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "DFI",
  sorter: true,
  showSorterTooltip: {
    title: "Глубина вложенности",
  },
  align: "center",
  width: 75,
  render: (value: number | null): React.ReactNode => <Dfi value={value} />,
  ...props,
})

const imageExtension = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  urlKey: string,
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "Тип",
  align: "center",
  width: 75,
  render: (value: string, record): React.ReactNode => (
    <ImageExtension
      mimeType={value}
      url={(record as { [key: string]: string })[urlKey]}
    />
  ),
  ...props,
})

const indexStatus = <Rec,>(
  callback: (
    record: Rec,
  ) => {
    robotstxtIgnore: boolean | null
    robotstxtLine: number | null
    canonicalUrl: string | null
  },
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "Индексация",
  align: "center",
  width: 150,
  showSorterTooltip: {
    title: "Статус индексации / причина запрета",
  },
  render: (value: ResourceIndexStatus, record: Rec): React.ReactNode => {
    const { robotstxtIgnore, canonicalUrl, robotstxtLine } = callback(record)
    if (value === null) {
      return <Nullable />
    }

    if (value === ResourceIndexStatus.Index) {
      return <Tag color="success">Да</Tag>
    }

    if (value === ResourceIndexStatus.RobotsIgnore) {
      return (
        <RobotstxtIsIgnore
          value={robotstxtIgnore}
          lineNumber={robotstxtLine}
          viewLabel={true}
        />
      )
    }

    if (value === ResourceIndexStatus.MetaRobotsNoindex) {
      return (
        <MetaRobots
          directives={["noindex"]}
          typeText="danger"
          viewLabel={true}
        />
      )
    }

    if (value === ResourceIndexStatus.NotCanonical) {
      return (
        <Canonical value={canonicalUrl} viewLabel={true} typeForText="danger" />
      )
    }

    return <Nullable /> // TODO: use the "nullable" type  for if not exist to UI??
  },
  ...props,
})

const pageInformation = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  callback: (
    record: Rec,
  ) => PageInformationCellProps & { suffix?: React.ReactNode },
  props?: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "URL",
  sorter: true,
  onCell: (): ReturnType<GetComponentProps<Rec>> => ({
    style: {
      padding: "0px 16px",
    },
  }),
  render: (_, record): React.ReactNode => {
    const { suffix, ...PICProps } = callback(record)
    return (
      <>
        <PageInformationCell {...PICProps} />
        {suffix}
      </>
    )
  },
  ...props,
})

const resourceCount = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "Источники",
  sorter: true,
  align: "center",
  width: 125,
  ...props,
})

const resourceType = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  contentTypeKey: string,
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "Тип контента",
  align: "center",
  width: 125,
  render: (value: CrawlReportResourceType, record): React.ReactNode => (
    <ResourceType
      value={value}
      contentType={
        (record as { [key: string]: string | null })[contentTypeKey] as
          | string
          | null
      }
    />
  ),
  ...props,
})

const robotstxtIgnore = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  callback: (record: Rec) => RobotstxtIsIgnoreProps,
  props: ColumnType<Rec>,
): ColumnType<Rec> => ({
  title: "Индексация",
  align: "center",
  showSorterTooltip: {
    title: "Статус индексации / причина запрета",
  },
  width: 150,
  render: (_, record): React.ReactNode => (
    <RobotstxtIsIgnore {...callback(record)} />
  ),
  ...props,
})

// type Props<Fields extends string, Rec extends Record<Fields, Rec[Fields]>> = ColumnType<Rec> & {
//   clearFilter: () => void
//   // @ts-ignore
//   AddFilter: (filter: ApiClientFilter<Rec>) => void
// }

// const search = <Fields extends string, Rec extends Record<Fields, Rec[Fields]>>(props: Props<Rec>): ColumnType<Rec> => {
//   const { title, ...columnProps } = props

//   const search = (value: string): void => {
//     if (!value.length) {
//       props.clearFilter()
//       return
//     }

//     // @ts-ignore
//     props.addFilter({
//       [props.dataIndex as keyof Rec]: [[ApiClientFilterType.Like, value]],
//     })
//   }

//   return {
//     title: (
//       <Space direction="vertical">
//         {title}
//         <Input.Group className="input-with-search">
//           <SearchOutlined />
//           <InputSearch search={search} />
//         </Input.Group>
//       </Space>
//     ),
//     showSorterTooltip: false,
//     ...columnProps,
//   }
// }

// const InputSearch: React.FC<{ search: (value: string) => void }> = props => {
//   const [value, setValue] = React.useState("")
//   const prevValue = useRef("")

//   const handlerSearch = (): void => {
//     if (prevValue.current === value) {
//       return
//     }

//     const newValue = value.trim()
//     prevValue.current = newValue
//     props.search(newValue)
//   }

//   return (
//     <Input
//       onPressEnter={(): void => handlerSearch()}
//       onBlur={(): void => handlerSearch()}
//       bordered={false}
//       onClick={(e): void => e.stopPropagation()}
//       onPaste={(e): void => {
//         e.preventDefault()
//         // remove protocol before request
//         setValue(removeProtocolFromUrl(e.clipboardData.getData("Text")))
//         handlerSearch()
//       }}
//       onInput={(e): void => setValue(e.currentTarget.value)}
//       value={value}
//       placeholder="Поиск"
//     />
//   )
// }

const size = <Fields extends string, Rec extends Record<Fields, Rec[Fields]>>(
  props: ColumnType<Rec>,
  statusCodeProps?: StatusCodeProps,
): ColumnType<Rec> => ({
  title: "Размер",
  align: "center",
  width: 100,
  sorter: true,
  render: (value: number | null): React.ReactNode => <Size value={value} />,
  ...props,
})

const statusCode = <
  Fields extends string,
  Rec extends Record<Fields, Rec[Fields]>
>(
  props: ColumnType<Rec>,
  statusCodeProps?: StatusCodeProps,
): ColumnType<Rec> => ({
  title: "Код ответа",
  sorter: true,
  align: "center",
  showSorterTooltip: {
    title: "Код ответа сервера",
  },
  width: 100,
  render: (value: number): React.ReactNode => (
    <StatusCode value={value} {...statusCodeProps} />
  ),
  ...props,
})

export default {
  anchorType,
  dfi,
  imageExtension,
  indexStatus,
  pageInformation,
  resourceCount,
  resourceType,
  robotstxtIgnore,
  size,
  statusCode,
}
