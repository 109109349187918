import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { UrlReportRecord } from "../../../../reducers/remote/cabinet/report/urlReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum UrlReportActionType {
  Load = "@@REPORTS/URLS/LOAD",
  LoadSuccess = "@@REPORTS/URLS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/URLS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/URLS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/URLS/SET_PAGINATION",
  SetSort = "@@REPORTS/URLS/SET_SORT",
  AddFilter = "@@REPORTS/URLS/ADD_FILTER",
  CancelFilter = "@@REPORTS/URLS/Cancel_FILTER",
  ClearFilter = "@@REPORTS/URLS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/URLS/CLEAR_SORT",
  Export = "@@REPORTS/URLS/EXPORT",
}

export type LoadUrlReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<UrlReportRecord>,
  UrlReportActionType.Load,
  UrlReportActionType.LoadPending,
  UrlReportActionType.LoadSuccess,
  UrlReportActionType.LoadFailure
>
export type LoadPendingUrlReportAction = ReturnType<
  LoadUrlReportAction["meta"]["pending"]
>
export type LoadSuccessUrlReportAction = ReturnType<
  LoadUrlReportAction["meta"]["success"]
>
export type LoadFailureUrlReportAction = ReturnType<
  LoadUrlReportAction["meta"]["failure"]
>

export type ClearFilterUrlReportAction = Action<UrlReportActionType.ClearFilter>

export type ClearSortUrlReportAction = Action<UrlReportActionType.ClearSort>

export type ExportUrlReportAction = ExportAction<
  UrlReportRecord,
  ExportListPagesPayload<UrlReportRecord>,
  UrlReportActionType.Export
>

export type AddFilterUrlReportAction = AddFilterAction<
  UrlReportRecord,
  UrlReportActionType.AddFilter
>

export type CancelFilterUrlReportAction = CancelFilterAction<
  UrlReportRecord,
  UrlReportActionType.CancelFilter
>

export type SetSortUrlReportAction = SetSortAction<
  UrlReportRecord,
  UrlReportActionType.SetSort
>

export type SetPaginationUrlReportAction = SetPaginationAction<
  UrlReportActionType.SetPagination
>
