import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ApiClientSort } from "../services/apiClient"
import useColumns from "./useColumns"
import { RenderedCell } from "rc-table/lib/interface"

type UseColumnsWithExpandable<Rec> = {
  expandedRowKeys: string[]
  columns: ColumnsType<Rec>
}

export default function useColumnsWithExpandable<Rec>(
  dataIndex: string,
  columns: ColumnsType<Rec>,
  showRowsByDataIndex: () => string[],
  sort?: ApiClientSort<Rec>,
  defaultSort?: ApiClientSort<Rec>,
): UseColumnsWithExpandable<Rec> {
  const [expandedRowKeys, setExpandedRowKeys] = React.useState<string[]>([])

  const onShow = (...values: string[]): void => {
    setExpandedRowKeys(values)
  }

  const onHideAll = (): void => {
    setExpandedRowKeys([])
  }

  // save columns for not re-render
  const columnsMemo: ColumnsType<Rec> = useColumns(columns, sort, defaultSort)
  const columnsMemoWithExpandable = React.useMemo(
    (): ColumnsType<Rec> => [
      {
        title: (
          <button
            className={`ant-table-row-expand-icon ${
              expandedRowKeys.length
                ? "ant-table-row-expand-icon-expanded"
                : "ant-table-row-expand-icon-collapsed"
            }`}
            onClick={(): void => {
              if (expandedRowKeys.length) {
                onHideAll()
                return
              }

              onShow(...showRowsByDataIndex())
            }}
          />
        ),
        key: "expandable",
        dataIndex,
        width: 50,
        render: (value: string): RenderedCell<Rec> => {
          return {
            children: (
              <button
                className={`ant-table-row-expand-icon ${
                  expandedRowKeys.includes(value)
                    ? "ant-table-row-expand-icon-expanded"
                    : "ant-table-row-expand-icon-collapsed"
                }`}
                onClick={(): void => {
                  onShow(value)
                }}
              />
            ),
          }
        },
      },
      ...columnsMemo,
    ],
    [expandedRowKeys, columnsMemo],
  )

  return {
    expandedRowKeys,
    columns: columnsMemoWithExpandable,
  }
}
