import { call, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  ExportSitemapUrlReportAction,
  SitemapUrlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/sitemapUrl"
import { getSitemapUrls } from "../../../../selectors/remote/cabinet/report"
import filteredResourceExportSaga from "../../../common/filteredResourceExportSaga"

function* exportListSitemapUrls(action: ExportSitemapUrlReportAction) {
  yield call(function* () {
    yield filteredResourceExportSaga({
      action,
      selector: getSitemapUrls,
      request: apiClient.cabinet.project.reports.exportListSitemapUrls,
    })
  })
}

export default function* exportListSitemapUrlsSaga(): Generator {
  yield takeLatest(SitemapUrlReportActionType.Export, exportListSitemapUrls)
}
