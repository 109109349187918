import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { GetUserPlanResponse } from "../../../services/apiClient"
import {
  BillingTypes,
  LoadUserPlanAction,
} from "../../@types/actions/remote/billing"

function* fetchUserPlan(action: LoadUserPlanAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetUserPlanResponse = yield call(
      apiClient.billing.getUserPlan,
    )
    yield put(action.meta.success(response))
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* getUserPlanSaga(): SagaIterator {
  yield takeEvery(BillingTypes.LoadUserPlan, fetchUserPlan)
}
