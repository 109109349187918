import React from "react"
import Nullable from "../../Nullable"

type Props = {
  mimeType: string | null
  url: string | null
}

const ImageExtension: React.FC<Props> = props => {
  const { mimeType, url } = props

  if (mimeType !== null) {
    const [type, extension] = mimeType.split("/")
    if (type === "image") {
      return <>{extension}</>
    }
  }

  if (!url) {
    return <Nullable />
  }

  try {
    const { pathname } = new URL(url)
    const extension = pathname.split(".").pop()
    if (!extension || extension.length > 7) {
      return <Nullable />
    }

    return <>{extension}</>
  } catch (e) {
    return <Nullable />
  }
}

export default ImageExtension
