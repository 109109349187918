import React from "react"
import { Route, Switch } from "react-router-dom"
import routes from "../../routes"
import UrlsPage from "./UrlsPage"
import UrlTreePage from "./UrlTreePage"

type Props = {
  reportId: string
  shareToken: string
}

const StructureRouter: React.FC<Props> = props => {
  const { shareToken, reportId } = props

  const structureRoutes = routes.s(shareToken).structure()

  return (
    <Switch>
      <Route
        path={structureRoutes.urls()}
        render={(): JSX.Element => (
          <UrlsPage reportId={reportId} shareToken={shareToken} />
        )}
      />
      <Route
        path={structureRoutes.urlTree()}
        render={(): JSX.Element => (
          <UrlTreePage reportId={reportId} shareToken={shareToken} />
        )}
      />
    </Switch>
  )
}

export default StructureRouter
