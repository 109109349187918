import {
  GetSessionResponse,
  LoginPayload,
  LoginResponse,
  LogoutResponse,
  UpdateProfilePayload,
} from "../@types/rest/auth"
import makeRequest from "../makeRequest"

export const updateProfile = async (
  payload: UpdateProfilePayload,
): Promise<void> => {
  const res = await makeRequest({
    path: `auth/profile`,
    method: "PUT",
    body: payload,
  })
  return await res.json()
}

export const getSession = async (): Promise<GetSessionResponse> => {
  const res = await makeRequest({
    path: "auth/session",
  })
  return await res.json()
}

export const login = async (payload: LoginPayload): Promise<LoginResponse> => {
  const res = await makeRequest({
    path: `auth/${payload.provider}/login`,
    method: "POST",
  })
  return await res.json()
}

export const logout = async (): Promise<LogoutResponse> => {
  const res = await makeRequest({
    path: "auth/logout",
    method: "DELETE",
  })
  return await res.json()
}
