import { Action } from "redux"
import {
  CheckRobotstxtAndSitemapsPayload,
  CheckRobotstxtAndSitemapsResponse,
  GetEntryPointsPayload,
  GetEntryPointsResponse,
  ValidateSitemapPayload,
  ValidateSitemapResponse,
} from "../../../../../services/apiClient"
import { RemoteActionWithPayload } from "../../action"

export enum HostTypes {
  LoadEntryPoints = "@@HOST/ENTRY_POINTS/LOAD",
  LoadEntryPointsSuccess = "@@HOST/ENTRY_POINTS/LOAD_SUCCESS",
  LoadEntryPointsPending = "@@HOST/ENTRY_POINTS/LOAD_PENDING",
  LoadEntryPointsFailure = "@@HOST/ENTRY_POINTS/LOAD_FAILURE",
  ClearEntryPoints = "@@HOST/ENTRY_POINTS/CLEAR",

  LoadRobotsAndSitemaps = "@@HOST/ROBOTS_AND_SITEMAPS/LOAD",
  LoadRobotsAndSitemapsSuccess = "@@HOST/ROBOTS_AND_SITEMAPS/LOAD_SUCCESS",
  LoadRobotsAndSitemapsPending = "@@HOST/ROBOTS_AND_SITEMAPS/LOAD_PENDING",
  LoadRobotsAndSitemapsFailure = "@@HOST/ROBOTS_AND_SITEMAPS/LOAD_FAILURE",
  ClearRobotsAndSitemaps = "@@HOST/ROBOTS_AND_SITEMAPS/CLEAR",

  ValidateSitemap = "@@HOST/VALIDATE_SITEMAP/VALIDATE",
  ValidateSitemapSuccess = "@@HOST/VALIDATE_SITEMAP/SUCCESS",
  ValidateSitemapPending = "@@HOST/VALIDATE_SITEMAP/PENDING",
  ValidateSitemapFailure = "@@HOST/VALIDATE_SITEMAP/FAILURE",
  ClearIsValidSitemap = "@@HOST/VALID_SITEMAP/CLEAR",
}

export type ClearEntryPointsAction = Action<HostTypes.ClearEntryPoints>

export type LoadEntryPointsAction = RemoteActionWithPayload<
  GetEntryPointsPayload,
  GetEntryPointsResponse,
  HostTypes.LoadEntryPoints,
  HostTypes.LoadEntryPointsPending,
  HostTypes.LoadEntryPointsSuccess,
  HostTypes.LoadEntryPointsFailure
>
export type LoadEntryPointsPendingAction = ReturnType<
  LoadEntryPointsAction["meta"]["pending"]
>
export type LoadEntryPointsSuccessAction = ReturnType<
  LoadEntryPointsAction["meta"]["success"]
>
export type LoadEntryPointsFailureAction = ReturnType<
  LoadEntryPointsAction["meta"]["failure"]
>

export type ValidateSitemapAction = RemoteActionWithPayload<
  ValidateSitemapPayload,
  ValidateSitemapResponse,
  HostTypes.ValidateSitemap,
  HostTypes.ValidateSitemapPending,
  HostTypes.ValidateSitemapSuccess,
  HostTypes.ValidateSitemapFailure
>
export type ValidateSitemapPendingAction = ReturnType<
  ValidateSitemapAction["meta"]["pending"]
>
export type ValidateSitemapSuccessAction = ReturnType<
  ValidateSitemapAction["meta"]["success"]
>
export type ValidateSitemapFailureAction = ReturnType<
  ValidateSitemapAction["meta"]["failure"]
>

export type CheckRobotstxtAndSitemapsAction = RemoteActionWithPayload<
  CheckRobotstxtAndSitemapsPayload,
  CheckRobotstxtAndSitemapsResponse,
  HostTypes.LoadRobotsAndSitemaps,
  HostTypes.LoadRobotsAndSitemapsPending,
  HostTypes.LoadRobotsAndSitemapsSuccess,
  HostTypes.LoadRobotsAndSitemapsFailure
>
export type CheckRobotstxtAndSitemapsPendingAction = ReturnType<
  CheckRobotstxtAndSitemapsAction["meta"]["pending"]
>
export type CheckRobotstxtAndSitemapsSuccessAction = ReturnType<
  CheckRobotstxtAndSitemapsAction["meta"]["success"]
>
export type CheckRobotstxtAndSitemapsFailureAction = ReturnType<
  CheckRobotstxtAndSitemapsAction["meta"]["failure"]
>

export type ClearRobotstxtAndSitemapsAction = Action<
  HostTypes.ClearRobotsAndSitemaps
>

export type ClearValidSitemapAction = Action<HostTypes.ClearIsValidSitemap>
