import { useSelector } from "react-redux"
import { StatusCodesGraphAlias } from "../services/apiClient"
import { getStatusCodesGraph } from "../state/selectors/remote/cabinet/report"
import { GraphRecordInfo } from "../types/graph"

type UseStatusCodesGraphResult = {
  state: ReturnType<typeof getStatusCodesGraph>
  info: GraphRecordInfo
}

export function useStatusCodesGraph(): UseStatusCodesGraphResult {
  const state = useSelector(getStatusCodesGraph)
  const info = {
    [StatusCodesGraphAlias["2xx"]]: {
      label: "2xx: Success (успешно)",
      shortLabel: "2xx: Success",
      description:
        "Количество страниц с кодами ответа сервера 2xx, обозначающими успешную обработку запроса",
    },
    [StatusCodesGraphAlias["3xx"]]: {
      label: "3xx: Redirection (перенаправление)",
      shortLabel: "3xx: Redirection",
      description:
        "Количество страниц с кодами ответа сервера 3xx, обозначающими перенаправление (редирект)",
    },
    [StatusCodesGraphAlias["4xx"]]: {
      label: "4xx: Client Error (ошибка клиента)",
      shortLabel: "4xx: Client Error",
      description:
        "Количество страниц с кодами ответа сервера 4xx, обозначающими ошибку",
    },
    [StatusCodesGraphAlias["5xx"]]: {
      label: "5xx: Server Error (ошибка сервера)",
      shortLabel: "5xx: Server Error",
      description:
        "Количество страниц с кодами ответа сервера 5xx, обозначающими ошибку",
    },
  }

  return { state, info }
}
