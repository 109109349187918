import React from "react"
import { useDispatch } from "react-redux"
import { TableOnChange } from "../components/Table"
import { ApiClientPagination, ApiClientSort } from "../services/apiClient"

type UseTable<Rec> = {
  load: () => void
  onChangeTable: TableOnChange<Rec>
}

const useTable = <Rec>(
  loadAction: () => void,
  setPaginationAction: (pagination: ApiClientPagination) => void,
  setSortAction: (sort: ApiClientSort<Rec>) => void,
  clearSortAction: () => void,
): UseTable<Rec> => {
  const dispatch = useDispatch()

  const load = React.useCallback(() => dispatch(loadAction()), [loadAction])

  const onChangeTable: TableOnChange<Rec> = React.useCallback(
    action => {
      if (action.type === "pagination") {
        dispatch(setPaginationAction(action.value))
      } else if (action.type === "sort") {
        if (action.value === null) {
          dispatch(clearSortAction())
        } else {
          dispatch(setSortAction(action.value))
        }
      }

      load()
    },
    [setPaginationAction, clearSortAction, setSortAction],
  )

  React.useEffect(() => {
    load()
  }, [])

  return {
    onChangeTable,
    load,
  }
}

export default useTable
