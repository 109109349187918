import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { RedirectReportRecord } from "../../../../reducers/remote/cabinet/report/redirectReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum RedirectReportActionType {
  Load = "@@REPORTS/REDIRECT/LOAD",
  LoadSuccess = "@@REPORTS/REDIRECT/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/REDIRECT/LOAD_PENDING",
  LoadFailure = "@@REPORTS/REDIRECT/LOAD_FAILURE",
  SetPagination = "@@REPORTS/REDIRECT/SET_PAGINATION",
  SetSort = "@@REPORTS/REDIRECT/SET_SORT",
  AddFilter = "@@REPORTS/REDIRECT/ADD_FILTER",
  CancelFilter = "@@REPORTS/REDIRECT/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/REDIRECT/CLEAR_FILTER",
  ClearSort = "@@REPORTS/REDIRECT/CLEAR_SORT",
  Export = "@@REPORTS/REDIRECT/EXPORT",
}

export type LoadRedirectReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<RedirectReportRecord>,
  RedirectReportActionType.Load,
  RedirectReportActionType.LoadPending,
  RedirectReportActionType.LoadSuccess,
  RedirectReportActionType.LoadFailure
>
export type LoadPendingRedirectReportAction = ReturnType<
  LoadRedirectReportAction["meta"]["pending"]
>
export type LoadSuccessRedirectReportAction = ReturnType<
  LoadRedirectReportAction["meta"]["success"]
>
export type LoadFailureRedirectReportAction = ReturnType<
  LoadRedirectReportAction["meta"]["failure"]
>

export type ClearFilterRedirectReportAction = Action<
  RedirectReportActionType.ClearFilter
>

export type ClearSortRedirectReportAction = Action<
  RedirectReportActionType.ClearSort
>

export type ExportRedirectReportAction = ExportAction<
  RedirectReportRecord,
  ExportListPagesPayload<RedirectReportRecord>,
  RedirectReportActionType.Export
>

export type AddFilterRedirectReportAction = AddFilterAction<
  RedirectReportRecord,
  RedirectReportActionType.AddFilter
>

export type CancelFilterRedirectReportAction = CancelFilterAction<
  RedirectReportRecord,
  RedirectReportActionType.CancelFilter
>

export type SetSortRedirectReportAction = SetSortAction<
  RedirectReportRecord,
  RedirectReportActionType.SetSort
>

export type SetPaginationRedirectReportAction = SetPaginationAction<
  RedirectReportActionType.SetPagination
>
