import { Report } from "../services/reportService"

export enum ProjectStatus {
  New = 1,
  Created = 2,
  Deleted = 3,
}

export interface Project {
  id: string
  created_by: string
  reports?: Report[]
  host: string
  status: ProjectStatus
  created_at: string
  updated_at: string
  deleted_at: string | null
  payload: {}
  alias: string
}
