import { ApiResponseWithData } from "../response"

export enum AntiBot {
  Variti = "variti",
  Cloudflare = "cloudflare",
  CloudFront = "cloudfront",
  AntiBotCloud = "antibotcloud",
  Qrator = "qrator",
}

export type GetEntryPointsPayload = {
  host: string
  "user-agent"?: string
}
export type GetEntryPointsResponse = { entry_points: EntryPoint[] }
type EntryPoint = {
  redirect_url: string | null
  redirect_status: number | null
  status: number
  url: string
  valid: boolean
  antibots: AntiBot[]
}

export type InformationSitemap = {
  path: string
  from_robotstxt: boolean
  valid: boolean
}

export type CheckRobotstxtAndSitemapsPayload = {
  entryPoint: string
  "user-agent"?: string
}
export type CheckRobotstxtAndSitemapsResponse = ApiResponseWithData<{
  has_robotstxt: boolean
  sitemaps: InformationSitemap[]
}>

export type ValidateSitemapPayload = { sitemap: string; "user-agent"?: string }
export type ValidateSitemapResponse = {
  valid: boolean
}
