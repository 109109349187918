import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { ReportPageResourceType } from "../../../../../types/reportPageResource"
import { ExternalReportActionType } from "../../../../@types/actions/remote/cabinet/report/external"
import {
  ExternalReportActions,
  ExternalReportState,
} from "../../../../@types/reducers/remote/cabinet/report/externalReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: ExternalReportState = {
  fields: [
    "page_resource_id",
    "page_resource_is_external",
    "page_resource_anchor_is_nofollow",
    "page_resource_is_broken",
    "page_resource_url",
    "page_resource_content",
    "src_resource_url",
    "src_resource_url_no_scheme",
    "src_resource_redirects",
    "target_resource_status_code",
    "page_resource_attrs_rel",
    "src_page_title",
    "target_resource_redirect_url",
    "target_resource_redirect_status_code",
    "target_resource_redirects",
    "page_resource_anchor_anchor_type",
    "src_resource_id",
    "src_page_canonical_url",
    "src_page_is_canonical",
    "page_resource_type",
  ],
  isLoading: false,
  defaultFilter: {
    page_resource_is_external: [[ApiClientFilterType.Eq, true]],
    page_resource_type: [
      [ApiClientFilterType.Eq, ReportPageResourceType.Anchor],
    ],
  },
  alwaysSort: {
    page_resource_id: ApiClientSortOrder.Asc,
  },
}

const externalReducer = (
  state: ExternalReportState = initState,
  action: ExternalReportActions,
): ExternalReportState => {
  switch (action.type) {
    case ExternalReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case ExternalReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case ExternalReportActionType.LoadPending:
      return updateStateSetPending(state)

    case ExternalReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case ExternalReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case ExternalReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case ExternalReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case ExternalReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case ExternalReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default externalReducer
