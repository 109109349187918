import { Col, Empty, Row, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { MetaRobotsReportRecord } from "../../state/@types/reducers/remote/cabinet/report/metaRobotsReducer"
import {
  addFilterMetaRobotsReport,
  cancelFilterMetaRobotsReport,
  clearFilterMetaRobotsReport,
  clearSortMetaRobotsReport,
  loadMetaRobotsReport,
  setPaginationMetaRobotsReport,
  setSortMetaRobotsReport,
} from "../../state/actions/remote/cabinet/report/metaRobots"
import { getMetaRobots } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import MetaRobots from "../ui/MetaRobots"
import columnsType from "../ui/Table/columnsType"

type Props = {
  reportId: string
  shareToken?: string
}

const MetaRobotsReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const metaRobotsState = useSelector(getMetaRobots)

  const _columns = useColumns(
    columns,
    metaRobotsState.sort,
    metaRobotsState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadMetaRobotsReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationMetaRobotsReport,
    setSortMetaRobotsReport,
    clearSortMetaRobotsReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterMetaRobotsReport,
    cancelFilterMetaRobotsReport,
    clearFilterMetaRobotsReport,
    setSortMetaRobotsReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterMetaRobotsReport,
    cancelFilterMetaRobotsReport,
  )

  if (metaRobotsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        metaRobotsState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8}>
            <Col>
              <Filters
                filters={filters.providers.metaRobots}
                activeFilter={metaRobotsState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={metaRobotsState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={metaRobotsState.data}
        loading={metaRobotsState.isLoading}
        pagination={metaRobotsState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

export default MetaRobotsReport

const inputOptions: InputSearchByFieldsValueType<MetaRobotsReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
  { label: "Title", value: "page_title" },
]

const columns: ColumnsType<MetaRobotsReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      url: {
        value: record.resource_url,
      },
      title: record.page_title,
      canonical: {
        url: record.page_canonical_url,
        isCanonical: record.page_is_canonical,
      },
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  {
    key: "page_robots_noindex",
    title: "meta robots",
    dataIndex: "page_robots_noindex",
    render: (value: string, record): JSX.Element[] | JSX.Element => (
      <MetaRobots directives={record.page_robots_directives} />
    ),
  },
]
