import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { UrlReportActionType } from "../../../../@types/actions/remote/cabinet/report/url"
import {
  UrlReportActions,
  UrlReportState,
} from "../../../../@types/reducers/remote/cabinet/report/urlReducer"
import {
  updateStateAddFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: UrlReportState = {
  isLoading: false,
  fields: [
    "resource_id",
    "resource_url",
    "resource_url_no_scheme",
    "resource_status_code",
    "resource_index_status",
    "resource_is_external",
    "resource_robotstxt_line",
    "resource_robotstxt_ignore",
    "page_canonical_url",
    "page_is_canonical",
    "page_robots_noindex",
    "page_dfi",
    "page_internal_only",
  ],
  defaultFilter: {
    page_internal_only: [[ApiClientFilterType.Eq, true]],
    resource_is_external: [[ApiClientFilterType.Eq, false]],
  },
  defaultSort: {
    page_dfi: ApiClientSortOrder.Asc,
  },
  alwaysSort: {
    resource_id: ApiClientSortOrder.Asc,
  },
}

const urlsReducer = (
  state: UrlReportState = initState,
  action: UrlReportActions,
): UrlReportState => {
  switch (action.type) {
    case UrlReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case UrlReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case UrlReportActionType.LoadPending:
      return updateStateSetPending(state)

    case UrlReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case UrlReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case UrlReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case UrlReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case UrlReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default urlsReducer
