import { ColumnsType } from "antd/lib/table"
import { SortOrder } from "antd/lib/table/interface"
import React from "react"
import { ApiClientSort, ApiClientSortOrder } from "../services/apiClient"

type UseColumns<Rec> = ColumnsType<Rec>

const useColumns = <Rec>(
  columns: ColumnsType<Rec>,
  sort?: ApiClientSort<Rec>,
  defaultSort?: ApiClientSort<Rec>,
): UseColumns<Rec> =>
  React.useMemo(
    (): ColumnsType<Rec> =>
      columns.map(column => {
        const key: keyof Rec = column.key as keyof Rec
        if (sort && sort[key]) {
          const order = sort[key]
          column.sortOrder = sortOrderToAntSortOrder(
            order as ApiClientSortOrder,
          )
        } else {
          delete column.sortOrder
        }

        if (!sort && defaultSort && defaultSort[key]) {
          const order = defaultSort[key]
          column.sortOrder = sortOrderToAntSortOrder(
            order as ApiClientSortOrder,
          )
        }

        return column
      }),
    [sort, defaultSort],
  )

export default useColumns

export const sortOrderToAntSortOrder = (
  order: ApiClientSortOrder,
): SortOrder | null => {
  if (order === ApiClientSortOrder.Asc) {
    return "ascend"
  }

  if (order === ApiClientSortOrder.Desc) {
    return "descend"
  }

  return null
}
