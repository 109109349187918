import { SourcePageReportActionType } from "../../../../@types/actions/remote/cabinet/report/sourcePage"
import {
  SourcePageReportActions,
  SourcePageReportState,
} from "../../../../@types/reducers/remote/cabinet/report/sourcePageReducer"
import {
  updateStateAddFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: SourcePageReportState = {
  isLoading: false,
  fields: [
    "page_resource_id",
    "target_resource_id",
    "src_resource_url",
    "src_resource_url_no_scheme",
    "src_page_title",
    "src_resource_robotstxt_line",
    "src_resource_robotstxt_ignore",
    "src_resource_index_status",
    "src_page_canonical_url",
    "page_resource_attrs_alt",
    "src_resource_id",
  ],
}

const sourcePageReducer = (
  state: SourcePageReportState = initState,
  action: SourcePageReportActions,
): SourcePageReportState => {
  switch (action.type) {
    case SourcePageReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case SourcePageReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case SourcePageReportActionType.LoadPending:
      return updateStateSetPending(state)

    case SourcePageReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case SourcePageReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case SourcePageReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case SourcePageReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case SourcePageReportActionType.ClearSort:
      return updateStateClearSort(state)

    case SourcePageReportActionType.Clear:
      return initState

    default:
      return state
  }
}

export default sourcePageReducer
