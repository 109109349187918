import routes from "../../pages/routes"
import { ApiClientFilter, ApiClientSort } from "../apiClient"
import base64 from "../base64"
import filters, { Filter, FilterActionType } from "../filters"
import {
  ReportProblemAlias,
  ReportProblemCategory,
  ReportProblems,
  ReportProblemType,
} from "./types/problems"

const problems: ReportProblems = {
  [ReportProblemAlias.NoRobotsTXT]: {
    type: ReportProblemType.Error,
    name: "Robots.txt",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Проверьте, создан ли текстовый файл с названием robots.txt в корневом каталоге сайта, доступен ли он (имеет статус кода ответа 200 ОК), оформлен в соответствии с требованиями поисковых систем и его размер не превышает 500 KB.",
  },
  [ReportProblemAlias.StatusCode4xx]: {
    type: ReportProblemType.Error,
    name: "Все ошибки с кодом 4xx",
    categories: [ReportProblemCategory.Indexing],
    description:
      "HTTP-коды состояния 4xx указывают на «ошибки клиента», т.е. ошибки, когда сервер не смог выполнить клиентский запрос. Например, запрошенный документ не найден на сервере, доступ к документу запрещен и так далее.",
    actions: filters.getActionsByName(
      filters.providers.statusCodes,
      "Коды 4xx",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .statusCodes()}?${search}`
    },
  },
  [ReportProblemAlias.StatusCode4xxInSitemap]: {
    type: ReportProblemType.Error,
    name: "Ошибки 4xx в sitemap.xml",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Файл sitemap.xml дает поисковым системам информацию об актуальной структуре сайте и содержит ссылки на все разрешенные к индексированию URL. Поисковые роботы регулярно проверяют sitemap.xml на обновления и ошибки. Sitemap.xml должен содержать канонические URL с кодом ответа 200 ОК.",
    actions: filters.getActionsByName(
      filters.providers.sitemapUrls,
      "Не 200 OK",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .sitemapUrls()}?${search}`
    },
  },
  [ReportProblemAlias.StatusCode5xx]: {
    type: ReportProblemType.Error,
    name: "Все ошибки с кодом 5xx",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Коды ошибок 5xx обозначают проблему на стороне сервера, не касаются пользователей и их действий. Серверные ошибки 5xx могут возникать из-за неправильной конфигурации сервера, повышенной нагрузки, ошибок в файле htaccess и т. п. Обратитесь к вашему системному администратору или свяжитесь с хостинг-провайдером, чтобы решить возникшие ошибки.",
    actions: filters.getActionsByName(
      filters.providers.statusCodes,
      "Коды 5xx",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .statusCodes()}?${search}`
    },
  },
  [ReportProblemAlias.PageTitleEmpty]: {
    type: ReportProblemType.Error,
    name: "Пустые или отсутствующие title",
    categories: [ReportProblemCategory.Content],
    description:
      "Title описывает содержание страницы для поисковых систем и выступает в качестве заголовка сниппета в поисковой выдаче. Заголовок title сообщает о релевантности страницы запросам и является сильным сигналом ранжирования. Для каждой страницы сайта заголовок title должен быть заполнен.",
    actions: filters.getActionsByName(filters.providers.titles, "пустые"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .titles()}?${search}`
    },
  },
  [ReportProblemAlias.PageH1Empty]: {
    type: ReportProblemType.Error,
    name: "Пустой или отсутствующий h1",
    categories: [ReportProblemCategory.Content],
    description:
      "h1 – важная часть структуры контента на странице. Это название, основной заголовок всего документа. Он нужен как для пользователей, так и для поисковых систем. Важно, чтобы каждая страница содержала краткий и информативный заголовок h1.",
    actions: filters.getActionsByName(
      filters.providers.headings,
      "Отсутствует h1",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .headings()}?${search}`
    },
  },
  [ReportProblemAlias.PageDescriptionNotUnique]: {
    type: ReportProblemType.Error,
    name: "Дубли description",
    categories: [ReportProblemCategory.Content],
    description:
      "Meta description или метаописание содержит сводную информацию о странице, кратко описывает ее содержание. На основе этой информации поисковые системы формируют описание страницы в результатах поиска. Для каждой страницы сайта метаописание должно быть уникальным.",
    actions: filters.getActionsByName(filters.providers.descriptions, "пустые"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .descriptions()}?${search}`
    },
  },
  [ReportProblemAlias.LinkStatusCode4xxInternal]: {
    type: ReportProblemType.Error,
    name: "Внутренние ссылки с кодом 4xx",
    categories: [ReportProblemCategory.Indexing, ReportProblemCategory.Link],
    description:
      "На сайте не должно быть неработающих «битых» ссылок. Все внутренние ссылки должны отдавать код ответа 200 OK. Ссылки, ведущие на несуществующие страницы или разделы должны быть исправлены.",
    actions: filters.getActionsByName(
      filters.providers.internal,
      "Битые ссылки",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .links()
        .internal()}?${search}`
    },
  },
  [ReportProblemAlias.PageTitleNotUnique]: {
    type: ReportProblemType.Error,
    name: "Дубли заголовков страниц title",
    categories: [ReportProblemCategory.Content],
    description:
      "Title описывает содержание страницы для поисковых систем и выступает в качестве заголовка сниппета в поисковой выдаче. Заголовок title сообщает о релевантности страницы запросам и является сильным сигналом ранжирования. Для каждой страницы сайта заголовок title должен быть уникальным.",
    actions: filters.getActionsByName(filters.providers.titles, "дубликат"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .titles()}?${search}`
    },
  },
  [ReportProblemAlias.NoSitemap]: {
    type: ReportProblemType.Warning,
    name: "Sitemap",
    categories: [ReportProblemCategory.Indexing],
    description: `Файл Sitemap.xml дает поисковым системам информацию об актуальной структуре сайте и содержит ссылки на все разрешенные к индексированию URL. Поисковые роботы регулярно проверяют sitemap.xml на обновления и ошибки.
    Проверьте, чтобы файл sitemap.xml был создан в нужном формате с учетом требований поисковых систем и находился по адресу, указанному в robots.txt.`,
  },
  [ReportProblemAlias.NoSitemapInRobotsTXT]: {
    type: ReportProblemType.Warning,
    name: "Sitemap",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Путь к файлу карты сайта прописывается в robots.txt с помощью директивы sitemap. Если используется несколько файлов карты сайта, то указываются все.",
  },
  [ReportProblemAlias.ImageTargetStatusCode4xx]: {
    type: ReportProblemType.Warning,
    name: "Изображения с кодом 4xx",
    categories: [ReportProblemCategory.Content],
    description:
      "Изображения, использующиеся на странице сайта должны быть доступны - ссылки (как внутренние, так и внешние) на изображения должны быть рабочими и отдавать код ответа 200 OK.",
    actions: filters.getActionsByName(filters.providers.images, "Битые ссылки"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .images()
        .full()}?${search}`
    },
  },
  [ReportProblemAlias.PageNotInCrawl]: {
    type: ReportProblemType.Warning,
    name: "Осиротевшие страницы (без внутренних ссылок)",
    categories: [ReportProblemCategory.Indexing],
    description:
      "URL-адреса, найденные в файле sitemap.xml, но не обнаруженные во время сканирования принято называть «осиротевшими» или потерянными. Такие URL могут не существовать на сайте, но быть ошибочно добавленными в sitemap.xml либо существовать, но не иметь внутренних ссылок, т.е. быть вырванными из структуры сайта.",
    actions: filters.getActionsByName(
      filters.providers.sitemapUrls,
      "Осиротевшие",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .sitemapUrls()}?${search}`
    },
  },
  [ReportProblemAlias.StatusCodePermanentRedirect]: {
    type: ReportProblemType.Warning,
    name: "Все URL с кодом ответа 3xx",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Если запрашиваемые страницы находятся по другому адресу, происходит перенаправление (редиректы с кодами 3xx). Для уменьшения времени задержки лучше избегать перенаправлений или минимизировать их количество. Идеальная ситуация — когда все внутренние ссылки ведут на целевые канонические URL-адреса без использования редиректа.",
    actions: filters.getActionsByName(
      filters.providers.statusCodes,
      "Коды 3xx",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .statusCodes()}?${search}`
    },
  },
  [ReportProblemAlias.StatusCodeTemporaryRedirect]: {
    type: ReportProblemType.Warning,
    name: "Временные редиректы (код ответа 302, 307)",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Поисковые системы не рекомендуют использовать временные редиректы в качестве альтернативы постоянным перенаправлениям. Страницы с кодом ответа 302, 307 не будут проиндексированы и не будут передавать ссылочный вес на целевую страницу.",
    actions: filters.getActionsByName(filters.providers.redirects, "Временные"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .redirects()}?${search}`
    },
  },
  [ReportProblemAlias.RedirectChain]: {
    type: ReportProblemType.Warning,
    name: "Цепочки редиректов",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Если при перенаправлении возникает серия из двух и более шагов, возникает цепочка переадресаций, замедляющая отображение страниц сайта. Поисковые системы рекомендуют избегать цепочек редиректов и настраивать перенаправление сразу на целевой конечный URL.",
    actions: filters.getActionsByName(filters.providers.redirects, "Цепочки"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .redirects()}?${search}`
    },
  },
  [ReportProblemAlias.StatusCode3xxInSitemap]: {
    type: ReportProblemType.Warning,
    name: "Страницы с кодом ответа 3xx в sitemap.xml",
    categories: [ReportProblemCategory.Indexing],
    description:
      "Файл sitemap.xml должен содержать ссылки на канонические целевые URL с кодом ответа 200 ОК без промежуточных перенаправлений.",
    actions: filters.getActionsByName(
      filters.providers.sitemapUrls,
      "Не 200 OK",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .sitemapUrls()}?${search}`
    },
  },
  [ReportProblemAlias.PageH1NotUnique]: {
    type: ReportProblemType.Warning,
    name: "Дублирующиеся заголовки h1",
    categories: [ReportProblemCategory.Content],
    description:
      "h1 – важная часть структуры контента на странице. Это название, основной заголовок всего документа. Он нужен как для пользователей, так и для поисковых систем. Важно, чтобы каждая страница содержала краткий, информативный и желательно уникальный заголовок h1.",
  },
  [ReportProblemAlias.PageDescriptionEmpty]: {
    type: ReportProblemType.Warning,
    name: "Пустые или отсутствующие description",
    categories: [ReportProblemCategory.Content],
    description:
      "Meta description или метаописание содержит сводную информацию о странице, кратко описывает ее содержание. На основе этой информации поисковые системы формируют описание страницы в результатах поиска. Для каждой страницы сайта метаописание должно быть прописано.",
    actions: filters.getActionsByName(filters.providers.descriptions, "пустые"),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .descriptions()}?${search}`
    },
  },
  [ReportProblemAlias.ImagesWithoutAlt]: {
    type: ReportProblemType.Warning,
    name: "Отсутствуют атрибуты alt у изображений",
    categories: [ReportProblemCategory.Content],
    description:
      "Текст атрибута alt в теге <img> является альтернативным источником информации при отключенных или не загруженных по любой причине изображениях. Атрибут описывает изображение и является одним из сигналов релевантности страницы.",
    actions: filters.getActionsByName(
      filters.providers.images,
      "Alt отсутствует",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .content()
        .images()
        .full()}?${search}`
    },
  },
  [ReportProblemAlias.LinkStatusCode4xxExternal]: {
    type: ReportProblemType.Warning,
    name: "Исходящие ссылки с кодом ответа 4xx",
    categories: [ReportProblemCategory.Link],
    description:
      "На сайте не должно быть исходящих неработающих ссылок. Все ссылки должны вести на существующие внешние URL. Ссылки, ведущие на несуществующие страницы или разделы должны быть исправлены.",
    actions: filters.getActionsByName(
      filters.providers.external,
      "Битые ссылки",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .links()
        .external()}?${search}`
    },
  },
  [ReportProblemAlias.SeveralCanonicalOnPage]: {
    type: ReportProblemType.Warning,
    name: "Несколько canonical на странице",
    categories: [ReportProblemCategory.Indexing],
    description: `С помощью атрибута rel="canonical" указывается каноническую версия страницы если она одновременно доступна по нескольким URL. Это позволяет избежать дублирования контента и предоставить сигнал поисковым системам, какая именно страница является предпочтительной для поисковой выдачи.
    Для любой страницы может быть указан только один канонический URL.`,
  },
  [ReportProblemAlias.CanonicalTargetStatusCode4xx]: {
    type: ReportProblemType.Warning,
    name: "Canonical ведет на страницу c кодом ответа 4xx",
    categories: [ReportProblemCategory.Indexing],
    description: `С помощью атрибута rel="canonical" указывается каноническую версия страницы если она одновременно доступна по нескольким URL. Это позволяет избежать дублирования контента и предоставить сигнал поисковым системам, какая именно страница является предпочтительной для поисковой выдачи.
    Канонические версии URL должны быть индексируемыми и отдавать код ответа 200 ОК.`,
  },
  [ReportProblemAlias.CanonicalTargetNoIndex]: {
    type: ReportProblemType.Warning,
    name: "Canonical ведет на неиндексируемую страницу",
    categories: [ReportProblemCategory.Indexing],
    description: `С помощью атрибута rel="canonical" указывается каноническую версия страницы если она одновременно доступна по нескольким URL. Это позволяет избежать дублирования контента и предоставить сигнал поисковым системам, какая именно страница является предпочтительной для поисковой выдачи.
    Канонические версии URL должны быть индексируемыми и отдавать код ответа 200 ОК. Канонические ссылки, ведущие на неиндексируемые страницы необходимо исправить.`,
    actions: filters.getActionsByName(
      filters.providers.canonicals,
      "Не каноничные",
    ),
    route(params) {
      const filteredResourceParams: {
        sort?: ApiClientSort<object>
        filter?: ApiClientFilter<object>
      } = {}
      for (const action of this.actions as NonNullable<
        Filter<object>["actions"]
      >) {
        const key = action.type === FilterActionType.Sort ? "sort" : "filter"
        filteredResourceParams[key] = action.action.payload
      }

      const search = new URLSearchParams({
        c: base64.encode(JSON.stringify(filteredResourceParams)),
      }).toString()
      return `${routes
        .site()
        .alias(params.alias)
        .reportAlias(params.reportAlias)
        .indexing()
        .canonicals()}?${search}`
    },
  },
}

export default problems
