import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient from "../../../../../../services/apiClient"
import {
  LoadStatusCodesGraphAction,
  StatusCodesGraphActionType,
} from "../../../../../@types/actions/remote/cabinet/report/statusCodesGraph"

function* fetchLoadStatusCodesGraph(
  action: LoadStatusCodesGraphAction,
): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response = yield call(
      apiClient.cabinet.project.reports.graph.getStatusCodesGraph,
      action.payload,
    )
    yield put(action.meta.success(response.data))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getStatusCodesGraphSaga(): Generator {
  yield takeEvery(StatusCodesGraphActionType.Load, fetchLoadStatusCodesGraph)
}
