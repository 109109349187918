import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetReportSharedLinkResponse,
} from "../../../../../../services/apiClient"
import {
  LoadSharedLinkAction,
  SharedLinkActionType,
} from "../../../../../@types/actions/remote/cabinet/report/share"

function* fetchGetShare(action: LoadSharedLinkAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetReportSharedLinkResponse = yield call(
      apiClient.cabinet.project.reports.sharedLinks.getShare,
      action.payload,
    )
    if (response.success) {
      yield put(action.meta.success(response))
    }
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getShareSaga(): Generator {
  yield takeEvery(SharedLinkActionType.Load, fetchGetShare)
}
