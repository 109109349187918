import { ImageReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/imageReducer"
import { addFilterImageReport } from "../../../state/actions/remote/cabinet/report/image"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<ImageReportRecord>[] = [
  {
    name: "Битые ссылки",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageReport({
          page_resource_is_broken: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Alt отсутствует",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageReport({
          page_resource_attrs_alt: [[ApiClientFilterType.Eq, null]],
        }),
      },
    ],
  },
  {
    name: "Неиндексируемые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageReport({
          target_resource_robotstxt_ignore: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "Индексируемые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterImageReport({
          target_resource_robotstxt_ignore: [[ApiClientFilterType.Eq, false]],
        }),
      },
    ],
  },
]

export default filters
