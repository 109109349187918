import { Action } from "redux"
import {
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { SelectedPageRecord } from "../../../../reducers/remote/cabinet/report/selectedPageReducer"
import { AddFilterAction, LoadAction } from "../../../filteredResourceAction"

export enum SelectedPageActionType {
  Load = "@@REPORT/SELECTED_PAGE/LOAD",
  LoadSuccess = "@@REPORT/SELECTED_PAGE/LOAD_SUCCESS",
  LoadPending = "@@REPORT/SELECTED_PAGE/LOAD_PENDING",
  LoadFailure = "@@REPORT/SELECTED_PAGE/LOAD_FAILURE",
  AddFilter = "@@REPORT/SELECTED_PAGE/ADD_FILTER",
  Clear = "@@REPORT/SELECTED_PAGE/CLEAR",
}

export type LoadSelectedPageAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<SelectedPageRecord>,
  SelectedPageActionType.Load,
  SelectedPageActionType.LoadPending,
  SelectedPageActionType.LoadSuccess,
  SelectedPageActionType.LoadFailure
>
export type LoadPendingSelectedPageAction = ReturnType<
  LoadSelectedPageAction["meta"]["pending"]
>
export type LoadSuccessSelectedPageAction = ReturnType<
  LoadSelectedPageAction["meta"]["success"]
>
export type LoadFailureSelectedPageAction = ReturnType<
  LoadSelectedPageAction["meta"]["failure"]
>

export type AddFilterSelectedPageAction = AddFilterAction<
  SelectedPageRecord,
  SelectedPageActionType.AddFilter
>

export type ClearSelectedPageAction = Action<SelectedPageActionType.Clear>
