import React from "react"
import { EyeOutlined } from "@ant-design/icons"
import IconWithTooltip, { IconWithTooltipProps } from "./ui/IconWithTooltip"

type Props = Omit<IconWithTooltipProps, "description">

const AdminOnly: React.FC<Props> = (props: Props) => {
  return (
    <IconWithTooltip
      {...props}
      description="Admin only"
      icon={<EyeOutlined />}
      textProps={{ type: "warning" }}
    />
  )
}

export default AdminOnly
