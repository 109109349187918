import React from "react"
import ReactDOM from "react-dom"
import { CabinetUi } from "./CabinetUi"
import "./assets/less/index.less"
import env, { Environment } from "./lib/env"
import "moment/locale/ru"

if (env.ENVIRONMENT !== Environment.Prod) {
  // eslint-disable-next-line no-console
  console.log("starting the app", {
    app: env.APP_NAME,
    version: env.VERSION,
    environment: env.ENVIRONMENT,
  })
}

ReactDOM.render(<CabinetUi />, document.getElementById("root"))
