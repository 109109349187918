import { RoutesAlias } from "../../../pages/routes"
import { Filter } from "../../filters"

export enum ReportProblemType {
  Error = "errors",
  Warning = "warnings",
}

export enum ReportProblemCategory {
  Indexing = "Индексация",
  Link = "Ссылки",
  Content = "Контент",
}

export enum ReportProblemAlias {
  NoRobotsTXT = "no_robotstxt",
  StatusCode4xx = "status_code_4xx",
  StatusCode4xxInSitemap = "status_code_4xx_in_sitemap",
  LinkStatusCode4xxInternal = "links_status_code_4xx_internal",
  StatusCode5xx = "status_code_5xx",
  PageTitleNotUnique = "page_title_not_unique",
  PageDescriptionNotUnique = "page_description_not_unique",
  PageTitleEmpty = "page_title_empty",
  PageH1Empty = "page_h1_empty",

  NoSitemap = "no_sitemap",
  NoSitemapInRobotsTXT = "no_sitemap_in_robotstxt",
  ImageTargetStatusCode4xx = "images_target_status_code_4xx",
  PageNotInCrawl = "page_not_in_crawl",
  StatusCodePermanentRedirect = "status_code_permanent_redirect",
  StatusCodeTemporaryRedirect = "status_code_temporary_redirect",
  RedirectChain = "redirect_chains",
  StatusCode3xxInSitemap = "status_code_3xx_in_sitemap",
  PageH1NotUnique = "page_h1_not_unique",
  PageDescriptionEmpty = "page_description_empty",
  ImagesWithoutAlt = "images_without_alt",
  LinkStatusCode4xxExternal = "link_status_code_4xx_external",
  SeveralCanonicalOnPage = "several_canonical_on_page",
  CanonicalTargetStatusCode4xx = "canonical_target_status_code_4xx",
  CanonicalTargetNoIndex = "canonical_target_not_index",
}

export type ReportProblem<Rec = object> = {
  type: ReportProblemType
  name: string
  categories: ReportProblemCategory[]
  description: string
  actions?: Filter<Rec>["actions"]
  route?: (params: {
    [RoutesAlias.Site]: string
    [RoutesAlias.Report]: string
  }) => string
}

export type ReportProblems = Record<ReportProblemAlias, ReportProblem>
