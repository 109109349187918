import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { CrawlReportResourceType } from "../../../../../types/reportResource"
import { NotHtmlReportActionType } from "../../../../@types/actions/remote/cabinet/report/notHtml"
import {
  NotHtmlReportActions,
  NotHtmlReportState,
} from "../../../../@types/reducers/remote/cabinet/report/notHtmlReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: NotHtmlReportState = {
  isLoading: false,
  fields: [
    "page_resource_id",
    "page_resource_url",
    "target_resource_status_code",
    "target_resource_type",
    "target_resource_content_type",
    "target_resource_robotstxt_ignore",
    "target_resource_robotstxt_line",
    "src_resource_url",
    "src_page_title",
    "target_resource_is_external",
    "src_resource_id",
  ],
  defaultFilter: {
    target_resource_type: [
      [
        ApiClientFilterType.NotIn,
        [CrawlReportResourceType.Html, CrawlReportResourceType.Image],
      ],
    ],
  },
  alwaysSort: {
    page_resource_id: ApiClientSortOrder.Asc,
  },
}

const notHtmlReducer = (
  state: NotHtmlReportState = initState,
  action: NotHtmlReportActions,
): NotHtmlReportState => {
  switch (action.type) {
    case NotHtmlReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case NotHtmlReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case NotHtmlReportActionType.LoadPending:
      return updateStateSetPending(state)

    case NotHtmlReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case NotHtmlReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case NotHtmlReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case NotHtmlReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case NotHtmlReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case NotHtmlReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default notHtmlReducer
