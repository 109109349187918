import {
  GetStatusCodesGraphPayload,
  GetStatusCodesGraphResponse,
} from "../../../../../../services/apiClient"
import { LoadAction } from "../../../filteredResourceAction"

export enum StatusCodesGraphActionType {
  Load = "@@REPORTS/STATUS_CODE/GRAPH/LOAD",
  LoadSuccess = "@@REPORTS/STATUS_CODE/GRAPH/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/STATUS_CODE/GRAPH/LOAD_PENDING",
  LoadFailure = "@@REPORTS/STATUS_CODE/GRAPH/LOAD_FAILURE",
}

export type LoadStatusCodesGraphAction = LoadAction<
  GetStatusCodesGraphPayload,
  GetStatusCodesGraphResponse,
  StatusCodesGraphActionType.Load,
  StatusCodesGraphActionType.LoadPending,
  StatusCodesGraphActionType.LoadSuccess,
  StatusCodesGraphActionType.LoadFailure
>
export type LoadPendingStatusCodesGraphAction = ReturnType<
  LoadStatusCodesGraphAction["meta"]["pending"]
>
export type LoadSuccessStatusCodesGraphAction = ReturnType<
  LoadStatusCodesGraphAction["meta"]["success"]
>
export type LoadFailureStatusCodesGraphAction = ReturnType<
  LoadStatusCodesGraphAction["meta"]["failure"]
>
