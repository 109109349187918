import { Action } from "redux"
import {
  ExportListPageResourcesPayload,
  GetListPageResourcesPayload,
  GetListPageResourcesResponse,
} from "../../../../../../services/apiClient"
import { NotHtmlReportRecord } from "../../../../reducers/remote/cabinet/report/notHtmlReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum NotHtmlReportActionType {
  Load = "@@REPORTS/NOT_HTML/LOAD",
  LoadSuccess = "@@REPORTS/NOT_HTML/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/NOT_HTML/LOAD_PENDING",
  LoadFailure = "@@REPORTS/NOT_HTML/LOAD_FAILURE",
  SetPagination = "@@REPORTS/NOT_HTML/SET_PAGINATION",
  SetSort = "@@REPORTS/NOT_HTML/SET_SORT",
  AddFilter = "@@REPORTS/NOT_HTML/ADD_FILTER",
  CancelFilter = "@@REPORTS/NOT_HTML/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/NOT_HTML/CLEAR_FILTER",
  ClearSort = "@@REPORTS/NOT_HTML/CLEAR_SORT",
  Export = "@@REPORTS/NOT_HTML/EXPORT",
}

export type LoadNotHtmlReportAction = LoadAction<
  GetListPageResourcesPayload<NotHtmlReportRecord>,
  GetListPageResourcesResponse<NotHtmlReportRecord>,
  NotHtmlReportActionType.Load,
  NotHtmlReportActionType.LoadPending,
  NotHtmlReportActionType.LoadSuccess,
  NotHtmlReportActionType.LoadFailure
>
export type LoadPendingNotHtmlReportAction = ReturnType<
  LoadNotHtmlReportAction["meta"]["pending"]
>
export type LoadSuccessNotHtmlReportAction = ReturnType<
  LoadNotHtmlReportAction["meta"]["success"]
>
export type LoadFailureNotHtmlReportAction = ReturnType<
  LoadNotHtmlReportAction["meta"]["failure"]
>

export type ClearFilterNotHtmlReportAction = Action<
  NotHtmlReportActionType.ClearFilter
>

export type ClearSortNotHtmlReportAction = Action<
  NotHtmlReportActionType.ClearSort
>

export type ExportNotHtmlReportAction = ExportAction<
  NotHtmlReportRecord,
  ExportListPageResourcesPayload<NotHtmlReportRecord>,
  NotHtmlReportActionType.Export
>

export type AddFilterNotHtmlReportAction = AddFilterAction<
  NotHtmlReportRecord,
  NotHtmlReportActionType.AddFilter
>

export type CancelFilterNotHtmlReportAction = CancelFilterAction<
  NotHtmlReportRecord,
  NotHtmlReportActionType.CancelFilter
>

export type SetSortNotHtmlReportAction = SetSortAction<
  NotHtmlReportRecord,
  NotHtmlReportActionType.SetSort
>

export type SetPaginationNotHtmlReportAction = SetPaginationAction<
  NotHtmlReportActionType.SetPagination
>
