import {
  Button,
  Col,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Spin,
  Tooltip,
  Typography,
} from "antd"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { DeleteOutlined, ShareAltOutlined } from "@ant-design/icons"
import env from "../lib/env"
import routes from "../pages/routes"
import { CreateReportSharedLinkDuration } from "../services/apiClient"
import {
  clearCreatedSharedLink,
  clearSharedLink,
  createSharedLink,
  deleteSharedLink,
  loadSharedLink,
} from "../state/actions/remote/cabinet/report/share"
import {
  getCreatedShare,
  getShare,
} from "../state/selectors/remote/cabinet/report"

type Props = {
  path: string
  reportId: string
  title: string
}

const SharedLinkModal: React.FC<Props> = props => {
  const { path, reportId, title } = props

  const [visible, setVisible] = React.useState(false)

  const shareState = useSelector(getShare)
  const createdShareState = useSelector(getCreatedShare)

  let shareData
  if (shareState.data) {
    shareData = shareState.data
  } else if (createdShareState.data) {
    shareData = createdShareState.data
  }

  const dispatch = useDispatch()

  const onCancelModal = () => {
    setVisible(false)
    dispatch(clearCreatedSharedLink())
    dispatch(clearSharedLink())
  }

  const onDelete = React.useCallback(() => {
    onCancelModal()
    message.success("Ссылка успешно удалена!")
  }, [])

  const showModal = () => {
    setVisible(true)
    dispatch(
      loadSharedLink({
        reportId,
        params: {
          path,
        },
      }),
    )
  }

  return (
    <>
      <Button
        icon={<ShareAltOutlined />}
        onClick={() => showModal()}
        type="primary"
      >
        Поделиться
      </Button>
      <Modal
        title="Поделиться отчётом"
        footer={null}
        centered
        visible={visible}
        onCancel={onCancelModal}
      >
        {shareState.isLoading || createdShareState.isLoading ? (
          <Spin />
        ) : !shareData ? (
          <CreateShareForm title={title} path={path} reportId={reportId} />
        ) : (
          <ShareInformation
            shareToken={shareData.token}
            shareId={shareData.id}
            reportId={shareData.report_id}
            onDelete={onDelete}
          />
        )}
      </Modal>
    </>
  )
}

export default SharedLinkModal

type CreateShareFormProps = {
  reportId: string
  path: string
  title: string
}

const CreateShareForm: React.FC<CreateShareFormProps> = props => {
  const { path, reportId, title } = props
  const dispatch = useDispatch()

  const onFinish = (store: { duration: CreateReportSharedLinkDuration }) => {
    let _path = path
    const search = window.location.search.toString()
    if (search) {
      _path += search
    }

    dispatch(
      createSharedLink({
        reportId,
        body: {
          path: _path,
          duration: store.duration,
        },
      }),
    )
  }

  return (
    <>
      <Form
        name="create-report-share"
        onFinish={onFinish}
        initialValues={{ duration: CreateReportSharedLinkDuration.Week }}
      >
        <Form.Item>
          <Typography.Text>
            Отчет “{title}” будет доступен всем, у кого есть ссылка:
          </Typography.Text>
        </Form.Item>
        <Form.Item
          name="duration"
          label="Срок действия"
          rules={[{ required: true }]}
        >
          <Select>
            <Select.Option value={CreateReportSharedLinkDuration.Day}>
              1 день
            </Select.Option>
            <Select.Option value={CreateReportSharedLinkDuration.Week}>
              7 дней
            </Select.Option>
            <Select.Option value={CreateReportSharedLinkDuration.Month}>
              30 дней
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button style={{ float: "right" }} htmlType="submit" type="primary">
            Создать ссылку
          </Button>
        </Form.Item>
      </Form>
    </>
  )
}

type ShareInformationProps = {
  shareToken: string
  shareId: string
  reportId: string
  onDelete: () => void
}

const ShareInformation: React.FC<ShareInformationProps> = props => {
  const { shareToken, shareId, reportId, onDelete } = props

  const dispatch = useDispatch()

  const onDeleteShare = () => {
    dispatch(
      deleteSharedLink({
        reportId,
        id: shareId,
      }),
    )
    onDelete()
  }

  const shareLink = `${env.CABINET_URL.slice(0, -1)}${routes
    .s(shareToken)
    .index()}`

  return (
    <Row align="middle">
      <Col flex={1}>
        <Row>
          <Col>
            <Typography.Text strong>
              Ссылка для доступа к отчёту
            </Typography.Text>
            <Col flex={1}>
              <Typography.Text copyable>{shareLink}</Typography.Text>
            </Col>
          </Col>
        </Row>
      </Col>
      <Col>
        <Popconfirm
          title="Вы уверены, что хотите удалить ссылку?"
          okText="Да"
          cancelText="Нет"
          onConfirm={onDeleteShare}
        >
          <Tooltip title="Удалить ссылку">
            <Button danger type="primary" icon={<DeleteOutlined />} />
          </Tooltip>
        </Popconfirm>
      </Col>
    </Row>
  )
}
