import React from "react"
import ImageReport from "../../../../components/Report/ImageReport"
import findMenuItems from "../../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../../hooks/useReportPage"
import { exportImageReport } from "../../../../state/actions/remote/cabinet/report/image"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.ImagesFull)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const ImagesFullPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportImageReport,
  })

  return <ImageReport shareToken={shareToken} reportId={reportId} />
}

export default ImagesFullPage
