import {
  CreateOrderPayload,
  CreateOrderResponse,
  GetOrdersResponse,
  GetPlanByAliasPayload,
  GetPlanByAliasResponse,
  GetPlansResponse,
  GetUserFeaturesResponse,
  GetUserPlanResponse,
  PurchasePayload,
  PurchaseResponse,
} from "../@types/rest/billing"
import makeRequest from "../makeRequest"

const createOrder = async (
  payload: CreateOrderPayload,
): Promise<CreateOrderResponse> => {
  const res = await makeRequest({
    path: "billing/api/orders",
    method: "POST",
    body: payload,
  })
  return res.json()
}

const getOrders = async (): Promise<GetOrdersResponse> => {
  const res = await makeRequest({
    path: "billing/api/orders",
  })
  return await res.json()
}

const getPlanByAlias = async (
  payload: GetPlanByAliasPayload,
): Promise<GetPlanByAliasResponse> => {
  const res = await makeRequest({
    path: `billing/api/plans/alias/${payload.alias}`,
  })
  return await res.json()
}

const getPlans = async (): Promise<GetPlansResponse> => {
  const res = await makeRequest({
    path: "billing/api/plans",
  })
  return await res.json()
}

const getUserFeatures = async (): Promise<GetUserFeaturesResponse> => {
  const res = await makeRequest({
    path: "billing/api/user/features",
  })
  return await res.json()
}

const getUserPlan = async (): Promise<GetUserPlanResponse> => {
  const res = await makeRequest({
    path: "billing/api/user/plan",
  })
  return await res.json()
}

const purchase = async (
  payload: PurchasePayload,
): Promise<PurchaseResponse> => {
  const res = await makeRequest({
    path: `billing/api/orders/${payload.orderId}/purchase`,
    method: "POST",
  })
  return await res.json()
}

export {
  createOrder,
  getOrders,
  getPlanByAlias,
  getPlans,
  getUserFeatures,
  getUserPlan,
  purchase,
}
