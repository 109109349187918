import { Empty, Space, Spin, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { ImageReportRecord } from "../../state/@types/reducers/remote/cabinet/report/imageReducer"
import {
  addFilterImageReport,
  cancelFilterImageReport,
  clearFilterImageReport,
  clearSortImageReport,
  loadImageReport,
  setPaginationImageReport,
  setSortImageReport,
} from "../../state/actions/remote/cabinet/report/image"
import { getImages } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import InlineImage from "../ui/InlineImage"
import columnsType from "../ui/Table/columnsType"
import Url from "../Url"

type Props = {
  reportId: string
  shareToken?: string
}

const ImageReport: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  const imageState = useSelector(getImages)

  const _columns = useColumns(columns, imageState.sort, imageState.defaultSort)

  const { load, onChangeTable } = useTable(
    loadImageReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationImageReport,
    setSortImageReport,
    clearSortImageReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterImageReport,
    cancelFilterImageReport,
    clearFilterImageReport,
    setSortImageReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterImageReport,
    cancelFilterImageReport,
  )

  if (imageState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        imageState.data === undefined ? (
          <Spin />
        ) : (
          <Space style={{ width: "100%" }} direction="vertical">
            <InputSearchByFields
              onSearch={onSearchInput}
              onClear={onClearInput}
              activeFilter={imageState.filter}
              options={inputOptions}
            />
            <Filters
              filters={filters.providers.images}
              activeFilter={imageState.filter}
              onChange={onChangeFilter}
              onClear={onClearFilter}
            />
          </Space>
        )
      }
    >
      <Table
        rowKey="page_resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={imageState.data}
        loading={imageState.isLoading}
        pagination={imageState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

export default ImageReport

const inputOptions: InputSearchByFieldsValueType<ImageReportRecord>[] = [
  { label: "URL", value: "page_resource_url" },
  { label: "Источник", value: "src_resource_url" },
  { label: "Title - Источник", value: "src_page_title" },
  { label: "Alt", value: "page_resource_attrs_alt" },
]

const columns: ColumnsType<ImageReportRecord> = [
  {
    key: "page_resource_url",
    title: "URL",
    dataIndex: "page_resource_url",
    sorter: true,
    render: (value: string, record): React.ReactNode => {
      if (value && value.length) {
        return <Url value={value} />
      }

      return <InlineImage image={record.page_resource_image_inline_src} />
    },
  },
  columnsType.pageInformation(
    record => ({
      resourceId: record.src_resource_id,
      url: {
        value: record.src_resource_url,
      },
      title: record.src_page_title,
    }),
    {
      key: "src_resource_url",
      dataIndex: "src_resource_url",
      title: "Источник",
    },
  ),
  {
    key: "page_resource_attrs_alt",
    title: "Alt",
    dataIndex: "page_resource_attrs_alt",
    width: 150,
    sorter: true,
    render: (value: string | null): React.ReactNode => (
      <Typography.Text
        style={{ whiteSpace: "normal" }}
        type={value === null || !value.length ? "secondary" : undefined}
      >
        {value ? value : "Отсутствует или пустой"}
      </Typography.Text>
    ),
  },
  columnsType.imageExtension("page_resource_url", {
    key: "target_resource_content_type",
    dataIndex: "target_resource_content_type",
  }),
  columnsType.size({
    key: "target_resource_size",
    dataIndex: "target_resource_size",
  }),
  columnsType.statusCode({
    key: "target_resource_status_code",
    dataIndex: "target_resource_status_code",
  }),
  columnsType.robotstxtIgnore(
    record => ({
      value: record.target_resource_robotstxt_ignore,
      lineNumber: record.target_resource_robotstxt_line,
      isExternal: record.target_resource_is_external,
    }),
    {
      key: "target_resource_robotstxt_ignore",
      dataIndex: "target_resource_robotstxt_ignore",
      sorter: true,
    },
  ),
]
