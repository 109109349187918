import { StatusCodeReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/statusCodeReducer"
import { addFilterStatusCodeReport } from "../../../state/actions/remote/cabinet/report/statusCode"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<StatusCodeReportRecord>[] = [
  {
    name: "Код 200 OK",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterStatusCodeReport({
          resource_status_code: [[ApiClientFilterType.Eq, 200]],
        }),
      },
    ],
  },
  {
    name: "Коды 3xx",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterStatusCodeReport({
          resource_status_code: [
            [ApiClientFilterType.Gte, 300],
            [ApiClientFilterType.Lte, 399],
          ],
        }),
      },
    ],
  },
  {
    name: "Коды 4xx",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterStatusCodeReport({
          resource_status_code: [
            [ApiClientFilterType.Gte, 400],
            [ApiClientFilterType.Lte, 499],
          ],
        }),
      },
    ],
  },
  {
    name: "Коды 5xx",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterStatusCodeReport({
          resource_status_code: [
            [ApiClientFilterType.Gte, 500],
            [ApiClientFilterType.Lte, 599],
          ],
        }),
      },
    ],
  },
]

export default filters
