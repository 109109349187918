import { NotHtmlReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/notHtmlReducer"
import { addFilterNotHtmlReport } from "../../../state/actions/remote/cabinet/report/notHtml"
import { CrawlReportResourceType } from "../../../types/reportResource"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<NotHtmlReportRecord>[] = [
  {
    name: "CSS",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlReport({
          target_resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Style],
          ],
        }),
      },
    ],
  },
  {
    name: "JS",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlReport({
          target_resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Script],
          ],
        }),
      },
    ],
  },
  {
    name: "Fonts",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlReport({
          target_resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Font],
          ],
        }),
      },
    ],
  },
  {
    name: "Other",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlReport({
          target_resource_type: [
            [ApiClientFilterType.Eq, CrawlReportResourceType.Other],
          ],
        }),
      },
    ],
  },
  {
    name: "Неиндексируемое",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterNotHtmlReport({
          target_resource_robotstxt_ignore: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
]

export default filters
