export class HttpError extends Error {
  name: string = "HttpError"
}

export class BadRequestError extends HttpError {
  name = "BadRequestError"
}

export class NotFoundError extends HttpError {
  name = "NotFoundError"
}

export class UnauthorizedError extends HttpError {
  name = "UnauthorizedError"
}

export class ForbiddenError extends HttpError {
  name = "ForbiddenError"
}
