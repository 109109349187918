import {
  ClearProjectAction,
  ClearProjectCreatedAction,
  CreateProjectAction,
  DeleteProjectAction,
  LoadProjectAction,
  LoadProjectByAliasAction,
  LoadProjectByHostAction,
  LoadProjectListAction,
  ProjectTypes,
} from "../../../@types/actions/remote/cabinet/project"

const clearProject = (): ClearProjectAction => ({
  type: ProjectTypes.Clear,
})

const clearProjectCreated = (): ClearProjectCreatedAction => ({
  type: ProjectTypes.CreatedClear,
})

const createProject = (
  payload: CreateProjectAction["payload"],
): CreateProjectAction => ({
  type: ProjectTypes.Create,
  payload,
  meta: {
    pending: () => ({ type: ProjectTypes.CreatePending }),
    success: payload => ({
      type: ProjectTypes.CreateSuccess,
      payload,
    }),
    failure: payload => ({
      type: ProjectTypes.CreateFailure,
      payload,
    }),
  },
})

const loadProject = (
  payload: LoadProjectAction["payload"],
): LoadProjectAction => ({
  type: ProjectTypes.Load,
  payload,
  meta: {
    pending: () => ({ type: ProjectTypes.LoadPending }),
    success: payload => ({
      type: ProjectTypes.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: ProjectTypes.LoadFailure,
      payload,
    }),
  },
})

const loadProjectByAlias = (
  payload: LoadProjectByAliasAction["payload"],
): LoadProjectByAliasAction => ({
  type: ProjectTypes.LoadByAlias,
  payload,
  meta: {
    pending: () => ({ type: ProjectTypes.LoadByAliasPending }),
    success: payload => ({
      type: ProjectTypes.LoadByAliasSuccess,
      payload,
    }),
    failure: payload => ({
      type: ProjectTypes.LoadByAliasFailure,
      payload,
    }),
  },
})

const loadProjectByHost = (
  payload: LoadProjectByHostAction["payload"],
): LoadProjectByHostAction => ({
  type: ProjectTypes.LoadByHost,
  payload,
  meta: {
    pending: () => ({ type: ProjectTypes.LoadByHostPending }),
    success: payload => ({
      type: ProjectTypes.LoadByHostSuccess,
      payload,
    }),
    failure: payload => ({
      type: ProjectTypes.LoadByHostFailure,
      payload,
    }),
  },
})

const loadProjectList = (): LoadProjectListAction => ({
  type: ProjectTypes.LoadList,
  meta: {
    pending: () => ({ type: ProjectTypes.LoadListPending }),
    success: payload => ({
      type: ProjectTypes.LoadListSuccess,
      payload,
    }),
    failure: payload => ({
      type: ProjectTypes.LoadListFailure,
      payload,
    }),
  },
})

const deleteProject = (
  payload: DeleteProjectAction["payload"],
): DeleteProjectAction => ({
  type: ProjectTypes.Delete,
  payload,
  meta: {
    pending: () => ({ type: ProjectTypes.DeletePending }),
    success: payload => ({ type: ProjectTypes.DeleteSuccess, payload }),
    failure: payload => ({ type: ProjectTypes.DeleteFailure, payload }),
  },
})

export {
  clearProject,
  clearProjectCreated,
  createProject,
  deleteProject,
  loadProject,
  loadProjectByAlias,
  loadProjectByHost,
  loadProjectList,
}
