import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, { GetSessionResponse } from "../../../services/apiClient"
import { AuthTypes, LoadSessionAction } from "../../@types/actions/remote/auth"
import { loadUserFeatures } from "../../actions/remote/billing"
import { loadProjectList } from "../../actions/remote/cabinet/project"

function* fetchSession(action: LoadSessionAction): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetSessionResponse = yield call(apiClient.auth.getSession)
    yield put(action.meta.success(response))
    yield put(loadProjectList())
    yield put(loadUserFeatures())
  } catch (e) {
    yield put(action.meta.failure(e))
  }
}

export default function* saga(): Generator {
  yield takeEvery(AuthTypes.Load, fetchSession)
}
