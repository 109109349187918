import React from "react"
import { Space, Tooltip, Typography } from "antd"
import {
  AppstoreFilled,
  AppstoreOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"

export type DisplayFormats = "card" | "list"

type Props = {
  format: DisplayFormats
  onChange: (format: DisplayFormats) => void
}

const DisplayFormat: React.FC<Props> = props => {
  const { format, onChange } = props

  const handlerChangeDisplayFormat = (format: DisplayFormats): void => {
    onChange(format)
  }

  const CardIcon = format === "list" ? AppstoreOutlined : AppstoreFilled

  return (
    <Space style={{ justifyContent: "flex-end", width: "100%" }} size="large">
      <Tooltip title="Сетка">
        <Typography.Text style={{ fontSize: 18 }}>
          <CardIcon onClick={(): void => handlerChangeDisplayFormat("card")} />
        </Typography.Text>
      </Tooltip>
      <Tooltip title="Списком">
        <Typography.Text style={{ fontSize: 18 }}>
          <UnorderedListOutlined
            onClick={(): void => handlerChangeDisplayFormat("list")}
          />
        </Typography.Text>
      </Tooltip>
    </Space>
  )
}

export default DisplayFormat
