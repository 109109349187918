import React from "react"
import {
  AlignLeftOutlined,
  BarChartOutlined,
  LinkOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons"
import { IMenuItem } from "../createMenuForSidebar"

export enum ReportMenuAlias {
  Dashboard = "dashboard",
  Indexing = "indexing",
  Robots = "robots",
  SitemapUrls = "sitemap-urls",
  StatusCodes = "status-codes",
  Redirects = "redirects",
  Canonicals = "canonicals",
  MetaRobots = "meta-robots",
  Content = "content",
  Titles = "titles",
  Descriptions = "descriptions",
  Headings = "headings",
  Structure = "structure",
  Urls = "urls",
  UrlTree = "url-tree",
  NotHtml = "not-html",
  NotHtmlFull = "not-html-full",
  NotHtmlGroups = "not-html-groups",
  Images = "images",
  ImagesFull = "images-full",
  ImagesGroups = "images-groups",
  Links = "links",
  Internal = "internal",
  External = "external",
}

const menu: IMenuItem[] = [
  {
    title: "Диагностика",
    icon: <BarChartOutlined />,
    alias: ReportMenuAlias.Dashboard,
  },
  {
    title: "Индексация",
    icon: <SearchOutlined />,
    alias: ReportMenuAlias.Indexing,
  },
  {
    title: "Robots.txt",
    parentAlias: ReportMenuAlias.Indexing,
    alias: ReportMenuAlias.Robots,
  },
  {
    title: "Sitemap.xml",
    parentAlias: ReportMenuAlias.Indexing,
    alias: ReportMenuAlias.SitemapUrls,
  },
  {
    title: "Ответы сервера",
    parentAlias: ReportMenuAlias.Indexing,
    alias: ReportMenuAlias.StatusCodes,
  },
  {
    title: "Редиректы",
    parentAlias: ReportMenuAlias.Indexing,
    alias: ReportMenuAlias.Redirects,
  },
  {
    title: "Canonical",
    parentAlias: ReportMenuAlias.Indexing,
    alias: ReportMenuAlias.Canonicals,
  },
  {
    title: "Метатег robots",
    parentAlias: ReportMenuAlias.Indexing,
    alias: ReportMenuAlias.MetaRobots,
  },
  {
    title: "Контент",
    icon: <AlignLeftOutlined />,
    alias: ReportMenuAlias.Content,
  },
  {
    title: "Title",
    parentAlias: ReportMenuAlias.Content,
    alias: ReportMenuAlias.Titles,
  },
  {
    title: "Description",
    parentAlias: ReportMenuAlias.Content,
    alias: ReportMenuAlias.Descriptions,
  },
  {
    title: "Заголовки H",
    parentAlias: ReportMenuAlias.Content,
    alias: ReportMenuAlias.Headings,
  },
  {
    title: "Структура",
    icon: <UnorderedListOutlined />,
    alias: ReportMenuAlias.Structure,
  },
  {
    title: "Полный список URL",
    parentAlias: ReportMenuAlias.Structure,
    alias: ReportMenuAlias.Urls,
  },
  {
    title: "Вложенность URL",
    parentAlias: ReportMenuAlias.Structure,
    alias: ReportMenuAlias.UrlTree,
  },
  {
    title: "Не HTML содержимое",
    parentAlias: ReportMenuAlias.Content,
    alias: ReportMenuAlias.NotHtml,
  },
  {
    title: "Полный список",
    parentAlias: ReportMenuAlias.NotHtml,
    alias: ReportMenuAlias.NotHtmlFull,
  },
  {
    title: "C группировкой по URL",
    parentAlias: ReportMenuAlias.NotHtml,
    alias: ReportMenuAlias.NotHtmlGroups,
  },
  {
    title: "Изображения",
    parentAlias: ReportMenuAlias.Content,
    alias: ReportMenuAlias.Images,
  },
  {
    title: "Полный список",
    parentAlias: ReportMenuAlias.Images,
    alias: ReportMenuAlias.ImagesFull,
  },
  {
    title: "C группировкой по URL",
    parentAlias: ReportMenuAlias.Images,
    alias: ReportMenuAlias.ImagesGroups,
  },
  {
    title: "Ссылки",
    icon: <LinkOutlined />,
    alias: ReportMenuAlias.Links,
  },
  {
    title: "Внутренние",
    parentAlias: ReportMenuAlias.Links,
    alias: ReportMenuAlias.Internal,
  },
  {
    title: "Исходящие",
    parentAlias: ReportMenuAlias.Links,
    alias: ReportMenuAlias.External,
  },
]

export default menu
