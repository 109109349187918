import makeRequest from "../../../../makeRequest"
import {
  CreateReportSharedLinkPayload,
  CreateReportSharedLinkResponse,
  DeleteReportSharedLinkPayload,
  DeleteReportSharedLinkResponse,
  GetReportSharedLinkByTokenPayload,
  GetReportSharedLinkPayload,
  GetReportSharedLinkResponse,
} from "../../../../@types/rest/cabinet/project/reports/share"

export const createShare = async (
  payload: CreateReportSharedLinkPayload,
): Promise<CreateReportSharedLinkResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/reports/${payload.reportId}/shared-links`,
    body: payload.body,
    method: "POST",
  })
  return await res.json()
}

export const deleteShare = async (
  payload: DeleteReportSharedLinkPayload,
): Promise<DeleteReportSharedLinkResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/reports/${payload.reportId}/shared-links/${payload.id}`,
    method: "DELETE",
  })
  return await res.json()
}

export const getShare = async (
  payload: GetReportSharedLinkPayload,
): Promise<GetReportSharedLinkResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/reports/${payload.reportId}/shared-links`,
    params: payload.params,
  })
  return await res.json()
}

export const getShareByToken = async (
  payload: GetReportSharedLinkByTokenPayload,
): Promise<GetReportSharedLinkResponse> => {
  const res = await makeRequest({
    path: `cabinet/api/project/reports/shared-links/${payload.token}`,
  })
  return await res.json()
}
