import { Col, Empty, Row, Spin } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { useSelector } from "react-redux"
import useColumns from "../../hooks/useColumns"
import useFilters from "../../hooks/useFilters"
import useInputSearchByFields from "../../hooks/useInputSearchByFields"
import useTable from "../../hooks/useTable"
import filters from "../../services/filters"
import { CanonicalReportRecord } from "../../state/@types/reducers/remote/cabinet/report/canonicalReducer"
import {
  addFilterCanonicalReport,
  cancelFilterCanonicalReport,
  clearFilterCanonicalReport,
  clearSortCanonicalReport,
  loadCanonicalReport,
  setPaginationCanonicalReport,
  setSortCanonicalReport,
} from "../../state/actions/remote/cabinet/report/canonical"
import { getCanonicals } from "../../state/selectors/remote/cabinet/report"
import CardWithoutBodyPadding from "../Card/CardWithoutBodyPadding"
import Filters from "../Filters"
import InputSearchByFields, {
  InputSearchByFieldsValueType,
} from "../InputSearchByFields"
import Table from "../Table"
import Canonical from "../ui/Canonical"
import columnsType from "../ui/Table/columnsType"

type CanonicalReportProps = {
  reportId: string
  shareToken?: string
}

const CanonicalReport: React.FC<CanonicalReportProps> = props => {
  const { reportId, shareToken } = props

  const canonicalsState = useSelector(getCanonicals)

  const _columns = useColumns(
    columns,
    canonicalsState.sort,
    canonicalsState.defaultSort,
  )

  const { load, onChangeTable } = useTable(
    loadCanonicalReport.bind(null, {
      reportId,
      shareToken,
    }),
    setPaginationCanonicalReport,
    setSortCanonicalReport,
    clearSortCanonicalReport,
  )

  const afterChangeFilter = () => load()
  const afterClearFilter = () => load()

  const { onChange: onChangeFilter, onClear: onClearFilter } = useFilters(
    afterChangeFilter,
    afterClearFilter,
    addFilterCanonicalReport,
    cancelFilterCanonicalReport,
    clearFilterCanonicalReport,
    setSortCanonicalReport,
  )

  const afterOnSearch = () => load()
  const afterOnClear = () => load()

  const {
    onSearch: onSearchInput,
    onClear: onClearInput,
  } = useInputSearchByFields(
    afterOnSearch,
    afterOnClear,
    addFilterCanonicalReport,
    cancelFilterCanonicalReport,
  )

  if (canonicalsState.error) {
    return <Empty />
  }

  return (
    <CardWithoutBodyPadding
      headStyle={{ fontWeight: "normal" }}
      title={
        canonicalsState.data === undefined ? (
          <Spin />
        ) : (
          <Row gutter={8} justify="space-between">
            <Col>
              <Filters
                filters={filters.providers.canonicals}
                activeFilter={canonicalsState.filter}
                onChange={onChangeFilter}
                onClear={onClearFilter}
              />
            </Col>
            <Col flex={1}>
              <InputSearchByFields
                onSearch={onSearchInput}
                onClear={onClearInput}
                activeFilter={canonicalsState.filter}
                options={inputOptions}
              />
            </Col>
          </Row>
        )
      }
    >
      <Table
        rowKey="resource_id"
        columns={_columns}
        onChangeTable={onChangeTable}
        dataSource={canonicalsState.data}
        loading={canonicalsState.isLoading}
        pagination={canonicalsState.pagination}
      />
    </CardWithoutBodyPadding>
  )
}

export default CanonicalReport

const inputOptions: InputSearchByFieldsValueType<CanonicalReportRecord>[] = [
  { label: "URL", value: "resource_url_no_scheme" },
  { label: "Canonical", value: "page_canonical_url" },
  { label: "Title", value: "page_title" },
]

const columns: ColumnsType<CanonicalReportRecord> = [
  columnsType.dfi({
    key: "page_dfi",
    dataIndex: "page_dfi",
  }),
  columnsType.pageInformation(
    record => ({
      resourceId: record.resource_id,
      url: {
        value: record.resource_url,
      },
      title: record.page_title,
    }),
    {
      key: "resource_url_no_scheme",
      dataIndex: "resource_url_no_scheme",
    },
  ),
  {
    key: "page_canonical_url",
    title: "Canonical",
    dataIndex: "page_canonical_url",
    render: (value: string): React.ReactNode => <Canonical value={value} />,
  },
]
