import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { ReportPageResourceType } from "../../../../../types/reportPageResource"
import { ImageReportActionType } from "../../../../@types/actions/remote/cabinet/report/image"
import {
  ImageReportActions,
  ImageReportState,
} from "../../../../@types/reducers/remote/cabinet/report/imageReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: ImageReportState = {
  isLoading: false,
  fields: [
    "page_resource_id",
    "page_resource_url",
    "page_resource_image_inline_src",
    "page_resource_type",
    "page_resource_attrs_alt",
    "page_resource_is_broken",
    "target_resource_robotstxt_line",
    "target_resource_status_code",
    "target_resource_robotstxt_ignore",
    "target_resource_content_type",
    "target_resource_size",
    "src_resource_url",
    "src_page_title",
    "target_resource_is_external",
    "src_resource_id",
  ],
  defaultFilter: {
    page_resource_type: [
      [ApiClientFilterType.Eq, ReportPageResourceType.Image],
    ],
  },
  alwaysSort: {
    page_resource_id: ApiClientSortOrder.Asc,
  },
}

const imagesReducer = (
  state: ImageReportState = initState,
  action: ImageReportActions,
): ImageReportState => {
  switch (action.type) {
    case ImageReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case ImageReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case ImageReportActionType.LoadPending:
      return updateStateSetPending(state)

    case ImageReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case ImageReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case ImageReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case ImageReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case ImageReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case ImageReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default imagesReducer
