import React from "react"
import NotHtmlGroupReport from "../../../../components/Report/NotHtmlGroupReport"
import findMenuItems from "../../../../components/ui/Sidebar/findMenuItems"
import reportMenu, {
  ReportMenuAlias,
} from "../../../../components/ui/Sidebar/menu/reportMenu"
import useReportPage from "../../../../hooks/useReportPage"
import { exportNotHtmlGroupReport } from "../../../../state/actions/remote/cabinet/report/notHtmlGroup"

const menuItems = findMenuItems(reportMenu, ReportMenuAlias.NotHtmlGroups)
const title = menuItems.map(item => item.title).join(" / ")

type Props = {
  reportId: string
  shareToken: string
}

const NotHtmlGroupsPage: React.FC<Props> = props => {
  const { reportId, shareToken } = props

  useReportPage({
    reportId,
    title,
    shareToken,
    exportAction: exportNotHtmlGroupReport,
  })

  return <NotHtmlGroupReport reportId={reportId} shareToken={shareToken} />
}

export default NotHtmlGroupsPage
