import { Action } from "redux"
import {
  CreateReportSharedLinkPayload,
  CreateReportSharedLinkResponse,
  DeleteReportSharedLinkPayload,
  DeleteReportSharedLinkResponse,
  GetReportSharedLinkByTokenPayload,
  GetReportSharedLinkByTokenResponse,
  GetReportSharedLinkPayload,
  GetReportSharedLinkResponse,
} from "../../../../../../services/apiClient"
import { RemoteActionWithPayload } from "../../../action"

export enum SharedLinkActionType {
  Load = "@@REPORTS/SHARE/LOAD",
  LoadSuccess = "@@REPORTS/SHARE/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/SHARE/LOAD_PENDING",
  LoadFailure = "@@REPORTS/SHARE/LOAD_FAILURE",

  LoadByToken = "@@REPORTS/SHARE_BY_TOKEN/LOAD",
  LoadByTokenSuccess = "@@REPORTS/SHARE_BY_TOKEN/LOAD_SUCCESS",
  LoadByTokenPending = "@@REPORTS/SHARE_BY_TOKEN/LOAD_PENDING",
  LoadByTokenFailure = "@@REPORTS/SHARE_BY_TOKEN/LOAD_FAILURE",

  CurrentClear = "@@REPORTS/SHARE/CLEAR",

  Create = "@@REPORTS/SHARE/CREATE",
  CreateSuccess = "@@REPORTS/SHARE/CREATE_SUCCESS",
  CreatePending = "@@REPORTS/SHARE/CREATE_PENDING",
  CreateFailure = "@@REPORTS/SHARE/CREATE_FAILURE",

  CreatedClear = "@@REPORTS/SHARE/CLEAR_CREATED",

  Delete = "@@REPORTS/SHARE/DELETE",
  DeleteSuccess = "@@REPORTS/SHARE/DELETE_SUCCESS",
  DeletePending = "@@REPORTS/SHARE/DELETE_PENDING",
  DeleteFailure = "@@REPORTS/SHARE/DELETE_FAILURE",
}

export type LoadSharedLinkAction = RemoteActionWithPayload<
  GetReportSharedLinkPayload,
  GetReportSharedLinkResponse,
  SharedLinkActionType.Load,
  SharedLinkActionType.LoadPending,
  SharedLinkActionType.LoadSuccess,
  SharedLinkActionType.LoadFailure
>
export type LoadPendingSharedLinkAction = ReturnType<
  LoadSharedLinkAction["meta"]["pending"]
>
export type LoadSuccessSharedLinkAction = ReturnType<
  LoadSharedLinkAction["meta"]["success"]
>
export type LoadFailureSharedLinkAction = ReturnType<
  LoadSharedLinkAction["meta"]["failure"]
>

export type LoadSharedLinkByTokenAction = RemoteActionWithPayload<
  GetReportSharedLinkByTokenPayload,
  GetReportSharedLinkByTokenResponse,
  SharedLinkActionType.LoadByToken,
  SharedLinkActionType.LoadByTokenPending,
  SharedLinkActionType.LoadByTokenSuccess,
  SharedLinkActionType.LoadByTokenFailure
>
export type LoadPendingSharedLinkByTokenAction = ReturnType<
  LoadSharedLinkByTokenAction["meta"]["pending"]
>
export type LoadSuccessSharedLinkByTokenAction = ReturnType<
  LoadSharedLinkByTokenAction["meta"]["success"]
>
export type LoadFailureSharedLinkByTokenAction = ReturnType<
  LoadSharedLinkByTokenAction["meta"]["failure"]
>

export type CreateSharedLinkAction = RemoteActionWithPayload<
  CreateReportSharedLinkPayload,
  CreateReportSharedLinkResponse,
  SharedLinkActionType.Create,
  SharedLinkActionType.CreatePending,
  SharedLinkActionType.CreateSuccess,
  SharedLinkActionType.CreateFailure
>
export type CreatePendingSharedLinkAction = ReturnType<
  CreateSharedLinkAction["meta"]["pending"]
>
export type CreateSuccessSharedLinkAction = ReturnType<
  CreateSharedLinkAction["meta"]["success"]
>
export type CreateFailureSharedLinkAction = ReturnType<
  CreateSharedLinkAction["meta"]["failure"]
>

export type DeleteSharedLinkAction = RemoteActionWithPayload<
  DeleteReportSharedLinkPayload,
  DeleteReportSharedLinkResponse,
  SharedLinkActionType.Delete,
  SharedLinkActionType.DeletePending,
  SharedLinkActionType.DeleteSuccess,
  SharedLinkActionType.DeleteFailure
>
export type DeletePendingSharedLinkAction = ReturnType<
  DeleteSharedLinkAction["meta"]["pending"]
>
export type DeleteSuccessSharedLinkAction = ReturnType<
  DeleteSharedLinkAction["meta"]["success"]
>
export type DeleteFailureSharedLinkAction = ReturnType<
  DeleteSharedLinkAction["meta"]["failure"]
>

export type ClearSharedLinkAction = Action<SharedLinkActionType.CurrentClear>

export type ClearCreatedSharedLinkAction = Action<
  SharedLinkActionType.CreatedClear
>
