import { AuthTypes } from "../../@types/actions/remote/auth"
import { AuthActions, AuthState } from "../../@types/reducers/remote/auth"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../utils/reducer"

const initState: AuthState = {
  current: {
    isLoading: false,
  },
}

const authReducer = (
  state: AuthState = initState,
  action: AuthActions,
): AuthState => {
  switch (action.type) {
    case AuthTypes.LoadSuccess: {
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload,
          isLoading: false,
        },
      }
    }
    case AuthTypes.LoadFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case AuthTypes.LoadPending: {
      return { ...state, current: { ...state.current, isLoading: true } }
    }

    case AuthTypes.UpdateProfileSuccess: {
      return {
        ...state,
        current: {
          ...state.current,
          isLoading: false,
          data: {
            ...(state.current.data as NonNullable<
              AuthState["current"]["data"]
            >),
            need_register: false,
            user_profile: {
              name: action.payload.name,
              email: action.payload.email,
              phone: action.payload.phone,
              company: action.payload.company,
              position: action.payload.position,
            },
          },
        },
      }
    }
    case AuthTypes.UpdateProfileFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case AuthTypes.UpdateProfilePending:
      return {
        ...state,
        current: updateStateSetPending(state.current),
      }

    case AuthTypes.LogoutSuccess: {
      return JSON.parse(
        JSON.stringify({
          ...initState,
          current: { ...initState.current, data: null },
        }),
      )
    }
    case AuthTypes.LogoutFailure:
      return {
        ...state,
        current: updateStateLoadFailure(state.current, action),
      }

    case AuthTypes.LogoutPending:
      return { ...state, current: updateStateSetPending(state.current) }

    case AuthTypes.Clear: {
      return { ...initState, current: { isLoading: false } }
    }

    default:
      return state
  }
}

export default authReducer
