import React, { lazy, Suspense } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom"
import PageLoader from "../components/PageLoader"
import history from "../lib/history"
import { UnauthorizedError } from "../services/apiClient"
import LocalStorage from "../services/storage"
import { loadSession } from "../state/actions/remote/auth"
import { getAuth } from "../state/selectors/remote/auth"
import LoginPage from "./LoginPage"
import RegisterPage from "./RegisterPage"
import routes from "./routes"
import SRouter from "./s/SRouter"

const AppRouter = lazy(() => import("./AppRouter"))

const RootRouter: React.FC = () => {
  const { url } = useRouteMatch()

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route exact path={routes.login()} component={LoginPage} />
        <Route path={routes.s().index()} component={SRouter} />
        <PrivateRoute path={url} component={AppRouter} />
      </Switch>
    </Suspense>
  )
}

export default RootRouter

type PrivateRouteProps = Omit<RouteProps, "component"> & {
  component: NonNullable<RouteProps["component"]>
}

const PrivateRoute: React.FC<PrivateRouteProps> = React.memo(props => {
  const { component: Component, ...rest } = props

  const location = useLocation()

  const authState = useSelector(getAuth)
  const authStateData = authState.data

  const dispatch = useDispatch()

  React.useEffect(() => {
    if (authStateData === undefined) {
      dispatch(loadSession())
    }
  }, [])

  React.useEffect(() => {
    if (!authState.isLoading && authStateData) {
      if (authStateData.need_register) {
        LocalStorage.removeLastUrl()
      } else {
        const path = LocalStorage.getLastUrl()
        if (path) {
          LocalStorage.removeLastUrl()
          history.push(path)
        }
      }
    }
  }, [authState])

  return (
    <Route
      {...rest}
      render={(routeProps): React.ReactNode => {
        if (
          authState.error instanceof UnauthorizedError ||
          authStateData === null ||
          authState.error
        ) {
          return <Redirect to={routes.login()} />
        }

        if (authStateData === undefined || authState.isLoading) {
          return null
        }

        if (authStateData.need_register) {
          if (location.pathname !== routes.register()) {
            return <Redirect to={routes.register()} />
          }

          return (
            <Route exact path={routes.register()} component={RegisterPage} />
          )
        }

        if (
          !authStateData.need_register &&
          location.pathname === routes.register()
        ) {
          return <Redirect to={routes.index()} />
        }

        return <Component {...routeProps} />
      }}
    />
  )
})
