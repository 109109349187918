import React from "react"
import { ReportSettingAlias } from "../services/reportService"
import IconWithTooltip from "./ui/IconWithTooltip"

type Data = {
  [key in ReportSettingAlias]: {
    label: string
    description?: string
  }
}

const data: Data = {
  [ReportSettingAlias.UseRobotstxt]: {
    label: "Учитывать директивы robots.txt",
    description:
      "Если выбрано, страницы, запрещенные директивами disallow, не будут просканированы",
  },
  [ReportSettingAlias.UseMetarobots]: {
    label: "Учитывать правила метатега robots",
    description:
      "Если выбрано, при сканировании будут учитываться правила загрузки и индексирования страниц сайта из метатега robots",
  },
  [ReportSettingAlias.UseCanonical]: {
    label: "Учитывать атрибут canonical",
    description:
      "Если выбрано, будут просканированы только канонические адреса из rel=”canonical”, а неканонические - проигнорированы",
  },
  [ReportSettingAlias.Sitemaps]: {
    label: "Sitemaps",
  },
  [ReportSettingAlias.FollowSubdomains]: {
    label: "Сканировать поддомены",
    description:
      "Если выбрано, будут просканированы все поддомены при наличии на них внутренних ссылок. Если сканируемый сайт example.com ссылается на свой поддомен sub.example.com, он также будет просканирован",
  },
  [ReportSettingAlias.TreatWwwAsSameHost]: {
    label: "Сканировать зеркала сайта",
    description:
      "Если выбрано, будут просканированы все зеркала сайта при наличии на них внутренних ссылок. Если сканируемый сайт example.com ссылается на свое зеркало www.example.com, оно будет просканировано. Адреса https://example.com и http://example.com также считаются зеркалами",
  },
  [ReportSettingAlias.FetchExternalLinks]: {
    label: "Собирать данные о внешних ссылках",
    description:
      "Если выбрано, при сканировании будут собираться данные об исходящих внешних ссылках",
  },
  [ReportSettingAlias.FollowNofollowLinks]: {
    label: "Переходить по nofollow-ссылкам",
    description:
      "Если выбрано, при сканировании атрибут nofollow для внешних и внутренних ссылок будет проигнорирован",
  },
  [ReportSettingAlias.Budget]: {
    label: "Ограничение количества страниц",
    description:
      "Максимальное количество страниц, которое будет просканировано",
  },
  [ReportSettingAlias.MaxDfi]: {
    label: "Максимальная глубина",
    description:
      "Страницы глубже указанного уровня вложенности не будут просканированы. 0 – без ограничений",
  },
  [ReportSettingAlias.ThreadCount]: {
    label: "Количество потоков",
    description:
      "Как много страниц будут сканироваться параллельно. Чем больше потоков, тем выше скорость сканирования, но возрастает нагрузка на сайт",
  },
  [ReportSettingAlias.ThreadSleepMs]: {
    label: "Задержка между запросами (сек)",
    description:
      "Время ожидания между запросом к очередной странице при сканировании",
  },
  [ReportSettingAlias.TimeoutMs]: {
    label: "Таймаут ожиданий ответа (сек)",
    description:
      "Как долго сканер будет ждать ответа от страницы, перед тем, как решить, что она недоступна",
  },
  [ReportSettingAlias.UserAgent]: {
    label: "HTTP Request User Agent",
  },
  [ReportSettingAlias.RobotsUserAgent]: {
    label: "Robots User Agent",
  },
}

type Props = {
  alias: ReportSettingAlias
  field?: "label" | "description"
  appendDescription?: boolean
}

const ReportSettingInformationByAlias: React.FC<Props> = React.memo(props => {
  const { alias, field = "label", appendDescription } = props

  const information = data[alias]
  if (!information[field]) {
    return null
  }

  const child = information[field]

  if (field === "description") {
    return <>{child}</>
  }

  if (!appendDescription) {
    return <>{child}</>
  }

  const description = information["description"]

  return (
    <>
      {child} {description && <IconWithTooltip description={description} />}
    </>
  )
})

export default ReportSettingInformationByAlias
