import {
  AddFilterMetaRobotsReportAction,
  CancelFilterMetaRobotsReportAction,
  ClearFilterMetaRobotsReportAction,
  ClearSortMetaRobotsReportAction,
  ExportMetaRobotsReportAction,
  LoadMetaRobotsReportAction,
  MetaRobotsReportActionType,
  SetPaginationMetaRobotsReportAction,
  SetSortMetaRobotsReportAction,
} from "../../../../@types/actions/remote/cabinet/report/metaRobots"

const loadMetaRobotsReport = (
  payload: LoadMetaRobotsReportAction["payload"],
): LoadMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: MetaRobotsReportActionType.LoadPending,
    }),
    success: payload => ({
      type: MetaRobotsReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: MetaRobotsReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterMetaRobotsReport = (): ClearFilterMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.ClearFilter,
})

const clearSortMetaRobotsReport = (): ClearSortMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.ClearSort,
})

const exportMetaRobotsReport = (
  payload: ExportMetaRobotsReportAction["payload"],
): ExportMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.Export,
  payload,
})

const addFilterMetaRobotsReport = (
  payload: AddFilterMetaRobotsReportAction["payload"],
): AddFilterMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.AddFilter,
  payload,
})

const cancelFilterMetaRobotsReport = (
  payload: CancelFilterMetaRobotsReportAction["payload"],
): CancelFilterMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.CancelFilter,
  payload,
})

const setPaginationMetaRobotsReport = (
  payload: SetPaginationMetaRobotsReportAction["payload"],
): SetPaginationMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.SetPagination,
  payload,
})

const setSortMetaRobotsReport = (
  payload: SetSortMetaRobotsReportAction["payload"],
): SetSortMetaRobotsReportAction => ({
  type: MetaRobotsReportActionType.SetSort,
  payload,
})

export {
  loadMetaRobotsReport,
  clearFilterMetaRobotsReport,
  clearSortMetaRobotsReport,
  exportMetaRobotsReport,
  addFilterMetaRobotsReport,
  cancelFilterMetaRobotsReport,
  setPaginationMetaRobotsReport,
  setSortMetaRobotsReport,
}
