import { SagaIterator } from "redux-saga"
import { call, put, select } from "redux-saga/effects"
import {
  ApiClientFilter,
  ApiClientPagination,
  ApiClientSort,
  ApiResponseResourceFiltered,
  ResourceFilteredRequest,
  ResourceFilteredRequestParams,
} from "../../../services/apiClient"
import storage from "../../../services/storage"
import persistFilteredResourceParamsToUrl from "../../../services/utils/persistFilteredResourceParamsToUrl"
import { ActionWithPayload } from "../../@types/actions/action"
import { LoadAction } from "../../@types/actions/filteredResourceAction"
import { Selector } from "../../@types/selectors/selectors"
import { StateResourceFiltered } from "../../@types/state"
import prepareFilteredResourceParams from "../../utils/prepareFilteredResourceParams"

type Actions<
  Rec,
  SetSortAction extends ActionWithPayload<ApiClientSort<Rec>, string>,
  AddFilterAction extends ActionWithPayload<ApiClientFilter<Rec>, string>,
  SetPaginationAction extends ActionWithPayload<ApiClientPagination, string>
> = {
  setSort: (payload: SetSortAction["payload"]) => SetSortAction
  addFilter: (payload: AddFilterAction["payload"]) => AddFilterAction
  setPagination: (
    payload: SetPaginationAction["payload"],
  ) => SetPaginationAction
}

type FilteredResourceSagaArgs<
  Rec,
  SetSortAction extends ActionWithPayload<ApiClientSort<Rec>, string>,
  AddFilterAction extends ActionWithPayload<ApiClientFilter<Rec>, string>,
  SetPaginationAction extends ActionWithPayload<ApiClientPagination, string>
> = {
  selector: Selector<StateResourceFiltered<Rec, (keyof Rec)[]>>
  actions?: Actions<Rec, SetSortAction, AddFilterAction, SetPaginationAction>
  action: LoadAction<
    ResourceFilteredRequest<Rec>,
    ApiResponseResourceFiltered<Rec>,
    string,
    string,
    string,
    string
  >
  request(
    params: ResourceFilteredRequest<Rec>,
  ): Promise<ApiResponseResourceFiltered<Rec>>
}

const PREV_ACTION_TYPES: string[] = []

export default function* filteredResourceSaga<
  Rec extends object,
  SetSortAction extends ActionWithPayload<ApiClientSort<Rec>, string>,
  AddFilterAction extends ActionWithPayload<ApiClientFilter<Rec>, string>,
  SetPaginationAction extends ActionWithPayload<ApiClientPagination, string>
>({
  selector,
  actions,
  action,
  request,
}: FilteredResourceSagaArgs<
  Rec,
  SetSortAction,
  AddFilterAction,
  SetPaginationAction
>): SagaIterator<void> {
  let state = yield select(selector)
  if (!PREV_ACTION_TYPES.includes(action.type) && actions) {
    PREV_ACTION_TYPES.push(action.type)

    const params: ResourceFilteredRequestParams<
      Rec
    > | null = getParamsFromUrlSaga<Rec>()
    if (params) {
      if (params.sort) yield put(actions.setSort(params.sort))
      if (params.filter) yield put(actions.addFilter(params.filter))
      if (params.page) yield put(actions.setPagination({ page: params.page }))
      if (params["per-page"])
        yield put(actions.setPagination({ "per-page": params["per-page"] }))
    }

    state = yield select(selector)
  }

  const params: ResourceFilteredRequest<Rec>["params"] = getRequestParams(
    state,
    action.payload.saveParamsToUrl,
  )

  yield put(action.meta.pending())

  try {
    const response = yield call(function* () {
      return yield request({
        reportId: action.payload.reportId,
        shareToken: action.payload.shareToken,
        params,
      })
    })
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

function getRequestParams<Rec>(
  state: StateResourceFiltered<Rec, (keyof Rec)[]>,
  saveParamsToUrl: boolean = true,
) {
  const data: ResourceFilteredRequestParams<Rec> = {}
  if (state.sort) data.sort = state.sort
  if (state.filter) data.filter = state.filter
  if (state.pagination) {
    data.page = state.pagination.page
    data["per-page"] = state.pagination["per-page"]
  }

  // save params before merge params for request
  if (saveParamsToUrl) persistFilteredResourceParamsToUrl(data)

  return prepareStateParamsForLoad(state)
}

function getParamsFromUrlSaga<Rec>(): ResourceFilteredRequestParams<
  Rec
> | null {
  try {
    const search: string = window.location.search
    const urlSearchParams: URLSearchParams = new URLSearchParams(search)
    const value: string | null = urlSearchParams.get("c")
    if (urlSearchParams.has("c") && value) {
      const c: string = window.atob(value)
      return JSON.parse(c)
    }
  } catch (error) {
    // TODO: add logs
  }

  return null
}

type PrepareStateParamsForLoadFunc<Rec> = (
  state: StateResourceFiltered<Rec, (keyof Rec)[]>,
) => ResourceFilteredRequest<Rec>["params"]

export function prepareStateParamsForLoad<Rec = object>(
  state: StateResourceFiltered<Rec, (keyof Rec)[]>,
): ReturnType<PrepareStateParamsForLoadFunc<Rec>> {
  const { pagination }: StateResourceFiltered<Rec, (keyof Rec)[]> = state
  const params: ResourceFilteredRequest<
    Rec
  >["params"] = prepareFilteredResourceParams(state)

  const pageSize: number | null = storage.getPageSize()
  if (
    pagination?.["per-page"] !== undefined &&
    pagination?.["per-page"] !== pageSize
  ) {
    storage.setPageSize(pagination?.["per-page"])
  }

  if (pagination?.["per-page"] === undefined) {
    params["per-page"] = pageSize !== null ? pageSize : 10
  } else {
    params["per-page"] = pagination?.["per-page"]
  }

  params.page = pagination?.page !== undefined ? pagination?.page : 1

  return params
}
