import {
  AddFilterRedirectReportAction,
  CancelFilterRedirectReportAction,
  ClearFilterRedirectReportAction,
  ClearSortRedirectReportAction,
  ExportRedirectReportAction,
  LoadRedirectReportAction,
  RedirectReportActionType,
  SetPaginationRedirectReportAction,
  SetSortRedirectReportAction,
} from "../../../../@types/actions/remote/cabinet/report/redirect"

const loadRedirectReport = (
  payload: LoadRedirectReportAction["payload"],
): LoadRedirectReportAction => ({
  type: RedirectReportActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: RedirectReportActionType.LoadPending,
    }),
    success: payload => ({
      type: RedirectReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: RedirectReportActionType.LoadFailure,
      payload,
    }),
  },
})

const clearFilterRedirectReport = (): ClearFilterRedirectReportAction => ({
  type: RedirectReportActionType.ClearFilter,
})

const clearSortRedirectReport = (): ClearSortRedirectReportAction => ({
  type: RedirectReportActionType.ClearSort,
})

const exportRedirectReport = (
  payload: ExportRedirectReportAction["payload"],
): ExportRedirectReportAction => ({
  type: RedirectReportActionType.Export,
  payload,
})

const addFilterRedirectReport = (
  payload: AddFilterRedirectReportAction["payload"],
): AddFilterRedirectReportAction => ({
  type: RedirectReportActionType.AddFilter,
  payload,
})

const cancelFilterRedirectReport = (
  payload: CancelFilterRedirectReportAction["payload"],
): CancelFilterRedirectReportAction => ({
  type: RedirectReportActionType.CancelFilter,
  payload,
})

const setPaginationRedirectReport = (
  payload: SetPaginationRedirectReportAction["payload"],
): SetPaginationRedirectReportAction => ({
  type: RedirectReportActionType.SetPagination,
  payload,
})

const setSortRedirectReport = (
  payload: SetSortRedirectReportAction["payload"],
): SetSortRedirectReportAction => ({
  type: RedirectReportActionType.SetSort,
  payload,
})

export {
  loadRedirectReport,
  clearFilterRedirectReport,
  clearSortRedirectReport,
  exportRedirectReport,
  addFilterRedirectReport,
  cancelFilterRedirectReport,
  setPaginationRedirectReport,
  setSortRedirectReport,
}
