import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { changeTitlePageHeader } from "../state/actions/ui/pageHeader"
import { getProject } from "../state/selectors/remote/cabinet/project"

export function useTitle(title: string): void {
  const project = useSelector(getProject)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(
      changeTitlePageHeader({
        title,
      }),
    )

    if (project.data && project.data.host) {
      title = `${title} / ${project.data.host}`
    }

    document.title = `${title} - Qrawl.io `
  }, [dispatch, title])
}
