import React from "react"
import Nullable from "../../Nullable"

type Props = {
  value: number | null
}

const Dfi: React.FC<Props> = props => {
  if (props.value === null) {
    return <Nullable />
  }

  return <>{props.value}</>
}

export default Dfi
