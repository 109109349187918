import { SagaIterator } from "redux-saga"
import { call, takeLatest } from "redux-saga/effects"
import apiClient from "../../../../../services/apiClient"
import {
  LoadSitemapUrlReportAction,
  SitemapUrlReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/sitemapUrl"
import {
  addFilterSitemapUrlReport,
  setPaginationSitemapUrlReport,
  setSortSitemapUrlReport,
} from "../../../../actions/remote/cabinet/report/sitemapUrl"
import { getSitemapUrls } from "../../../../selectors/remote/cabinet/report"
import filteredResourceSaga from "../../../common/filteredResourceSaga"

function* fetchGetLoadSitemapUrls(
  action: LoadSitemapUrlReportAction,
): SagaIterator {
  yield call(function* () {
    yield filteredResourceSaga({
      action,
      selector: getSitemapUrls,
      request: apiClient.cabinet.project.reports.getListSitemapUrls,
      actions: {
        addFilter: addFilterSitemapUrlReport,
        setPagination: setPaginationSitemapUrlReport,
        setSort: setSortSitemapUrlReport,
      },
    })
  })
}

export default function* getListSitemapUrlsSaga(): Generator {
  yield takeLatest(SitemapUrlReportActionType.Load, fetchGetLoadSitemapUrls)
}
