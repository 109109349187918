import { ApiClientFilterType } from "../../../../../services/apiClient/@types/filter"
import { ApiClientSortOrder } from "../../../../../services/apiClient/@types/sort"
import { CrawlReportResourceType } from "../../../../../types/reportResource"
import { ImageGroupReportActionType } from "../../../../@types/actions/remote/cabinet/report/imageGroup"
import {
  ImageGroupReportActions,
  ImageGroupReportState,
} from "../../../../@types/reducers/remote/cabinet/report/imageGroupReducer"
import {
  updateStateAddFilter,
  updateStateCancelFilter,
  updateStateClearFilter,
  updateStateClearSort,
  updateStateLoadFailure,
  updateStateLoadListSuccess,
  updateStateSetPagination,
  updateStateSetPending,
  updateStateSetSort,
} from "../../../../utils/reducer"

const initState: ImageGroupReportState = {
  isLoading: false,
  fields: [
    "resource_id",
    "resource_url",
    "resource_url_no_scheme",
    "resource_status_code",
    "resource_type",
    "resource_robotstxt_ignore",
    "resource_robotstxt_line",
    "resource_content_type",
    "resource_size",
    "resource_is_external",
    "page_resource_count",
    "resource_is_broken",
  ],
  defaultFilter: {
    resource_type: [[ApiClientFilterType.Eq, CrawlReportResourceType.Image]],
  },
  alwaysSort: {
    resource_id: ApiClientSortOrder.Asc,
  },
}

const imageGroupsReducer = (
  state: ImageGroupReportState = initState,
  action: ImageGroupReportActions,
): ImageGroupReportState => {
  switch (action.type) {
    case ImageGroupReportActionType.LoadSuccess:
      return updateStateLoadListSuccess(state, action)

    case ImageGroupReportActionType.LoadFailure:
      return updateStateLoadFailure(state, action)

    case ImageGroupReportActionType.LoadPending:
      return updateStateSetPending(state)

    case ImageGroupReportActionType.SetPagination:
      return updateStateSetPagination(state, action)

    case ImageGroupReportActionType.SetSort:
      return updateStateSetSort(state, action)

    case ImageGroupReportActionType.AddFilter:
      return updateStateAddFilter(state, action)

    case ImageGroupReportActionType.CancelFilter:
      return updateStateCancelFilter(state, action)

    case ImageGroupReportActionType.ClearFilter:
      return updateStateClearFilter(state)

    case ImageGroupReportActionType.ClearSort:
      return updateStateClearSort(state)

    default:
      return state
  }
}

export default imageGroupsReducer
