import { PaginationProps, Table as TableAntd } from "antd"
import {
  TablePaginationConfig,
  TableProps as TablePropsAntd,
} from "antd/lib/table"
import {
  SorterResult,
  SortOrder,
  TableCurrentDataSource,
} from "antd/lib/table/interface"
import React, { Key } from "react"
import {
  ApiClientPagination,
  ApiClientSort,
  ApiClientSortOrder,
} from "../services/apiClient"
import formatters from "../services/formatters"

type TableOnChangePaginationAction = {
  type: "pagination"
  value: ApiClientPagination
}

type TableOnChangeSortAction<Rec> = {
  type: "sort"
  value: ApiClientSort<Rec> | null
}

export type TableOnChange<Rec> = (
  action: TableOnChangePaginationAction | TableOnChangeSortAction<Rec>,
) => void

export type TableProps<Rec> = Omit<
  TablePropsAntd<Rec>,
  "onChange" | "pagination"
> & {
  onChangeTable: TableOnChange<Rec>
  pagination?: ApiClientPagination
}

function Table<Rec extends object>(props: TableProps<Rec>): JSX.Element {
  const {
    pagination,
    onChangeTable,
    loading,
    dataSource,
    rowKey,
    columns,
    expandable,
  }: TableProps<Rec> = props

  const refTable: React.MutableRefObject<HTMLElement | null> = React.useRef(
    null,
  )

  // use random id if props.id not set
  const id: string = React.useMemo(() => {
    if (props.id) {
      return props.id
    }

    return `table-${parseInt((Math.random() * 100).toString())}`
  }, [props.id])

  const paginationPropsMemo: PaginationProps = React.useMemo<
    PaginationProps
  >(() => {
    const paginationProps: PaginationProps = {
      showQuickJumper: true,
      showTotal: (total: number): React.ReactNode => (
        <>Всего {formatters.number.format(total)} элементов</>
      ),
    }

    if (pagination) {
      if (typeof pagination.page === "number") {
        paginationProps.current = pagination.page
      }
      if (typeof pagination["per-page"] === "number") {
        paginationProps.pageSize = pagination["per-page"]
      }

      if (typeof pagination.total === "number") {
        paginationProps.total = pagination.total
      }
    }

    return paginationProps
  }, [pagination])

  const onChange: TablePropsAntd<Rec>["onChange"] = React.useCallback(
    (
      _pagination: TablePaginationConfig,
      filters: Record<string, (Key | boolean)[] | null>,
      sorter: SorterResult<Rec> | SorterResult<Rec>[],
      extra: TableCurrentDataSource<Rec>,
    ): void => {
      switch (extra.action) {
        case "paginate":
          onChangeTable({
            type: "pagination",
            value: {
              page: _pagination.current,
              "per-page": _pagination.pageSize,
            },
          })
          break
        case "sort":
          if (Array.isArray(sorter) || Array.isArray(sorter.field)) {
            return
          }

          const order = sorter.order
          const field = sorter.field
          if (!order || !field) {
            onChangeTable({
              type: "sort",
              value: null,
            })
            return
          }

          onChangeTable({
            type: "sort",
            value: {
              // @ts-ignore
              [field]: antSortOrderToSortOrder(order),
            },
          })
      }
    },
    [],
  )

  // useEffect for table resize with expandable rows
  React.useEffect(() => {
    if (!props.expandable) {
      return
    }

    document
      .querySelectorAll(".ant-table-row-expand-icon-cell")
      .forEach(el => el.classList.add("empty"))
  }, [props.dataSource])

  React.useEffect(() => {
    if (refTable.current) {
      const tableOffsetTop: number = refTable.current.getBoundingClientRect()
        .top
      const modal: Element | null = refTable.current?.closest(".ant-modal-wrap")
      // use scrollTo if offset table from top > user scroll
      if (tableOffsetTop < (modal || document.documentElement).scrollTop) {
        ;(modal || window).scrollTo({
          top: refTable.current.getBoundingClientRect().top + window.scrollY,
          behavior: "smooth",
        })
      }
    }
  }, [props.pagination])

  React.useEffect(() => {
    if (refTable.current) {
      return
    }
    // find table by id
    refTable.current = document.getElementById(
      props.id as NonNullable<typeof props.id>,
    )
  }, [props.id])

  if (expandable) {
    // disabled default button with icon
    expandable.expandIcon = (): null => null
  }

  return (
    <TableAntd<Rec>
      id={id}
      tableLayout="fixed"
      showSorterTooltip={false}
      loading={loading}
      columns={columns}
      rowKey={rowKey}
      dataSource={dataSource}
      expandable={expandable}
      onChange={onChange}
      locale={{
        emptyText: "Нет данных для отображения",
      }}
      pagination={{
        position: [],
        ...paginationPropsMemo,
      }}
    />
  )
}

export default Table

export const antSortOrderToSortOrder = (
  order: SortOrder,
): ApiClientSortOrder => {
  if (order === "ascend") {
    return ApiClientSortOrder.Asc
  }

  return ApiClientSortOrder.Desc
}
