import { Action } from "redux"
import {
  ExportListPagesPayload,
  GetListPagesPayload,
  GetListPagesResponse,
} from "../../../../../../services/apiClient"
import { HeadingReportRecord } from "../../../../reducers/remote/cabinet/report/headingReducer"
import {
  AddFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum HeadingReportActionType {
  Load = "@@REPORTS/HEADERS/LOAD",
  LoadSuccess = "@@REPORTS/HEADERS/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/HEADERS/LOAD_PENDING",
  LoadFailure = "@@REPORTS/HEADERS/LOAD_FAILURE",
  SetPagination = "@@REPORTS/HEADERS/SET_PAGINATION",
  SetSort = "@@REPORTS/HEADERS/SET_SORT",
  AddFilter = "@@REPORTS/HEADERS/ADD_FILTER",
  CancelFilter = "@@REPORTS/HEADERS/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/HEADERS/CLEAR_FILTER",
  ClearSort = "@@REPORTS/HEADERS/CLEAR_SORT",
  Export = "@@REPORTS/HEADERS/EXPORT",
}

export type LoadHeadingReportAction = LoadAction<
  GetListPagesPayload,
  GetListPagesResponse<HeadingReportRecord>,
  HeadingReportActionType.Load,
  HeadingReportActionType.LoadPending,
  HeadingReportActionType.LoadSuccess,
  HeadingReportActionType.LoadFailure
>
export type LoadPendingHeadingReportAction = ReturnType<
  LoadHeadingReportAction["meta"]["pending"]
>
export type LoadSuccessHeadingReportAction = ReturnType<
  LoadHeadingReportAction["meta"]["success"]
>
export type LoadFailureHeadingReportAction = ReturnType<
  LoadHeadingReportAction["meta"]["failure"]
>

export type ClearFilterHeadingReportAction = Action<
  HeadingReportActionType.ClearFilter
>

export type ClearSortHeadingReportAction = Action<
  HeadingReportActionType.ClearSort
>

export type ExportHeadingReportAction = ExportAction<
  HeadingReportRecord,
  ExportListPagesPayload<HeadingReportRecord>,
  HeadingReportActionType.Export
>

export type AddFilterHeadingReportAction = AddFilterAction<
  HeadingReportRecord,
  HeadingReportActionType.AddFilter
>

export type CancelFilterHeadingReportAction = AddFilterAction<
  HeadingReportRecord,
  HeadingReportActionType.CancelFilter
>

export type SetSortHeadingReportAction = SetSortAction<
  HeadingReportRecord,
  HeadingReportActionType.SetSort
>
export type SetPaginationHeadingReportAction = SetPaginationAction<
  HeadingReportActionType.SetPagination
>
