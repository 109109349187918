import React from "react"
import { ResourceRedirect } from "../../../types/reportResource"
import Nullable from "../../Nullable"
import RedirectCanonicalItem from "./RedirectCanonicalItem"
import RedirectsItem from "./RedirectsItem"

type Props = {
  items: ResourceRedirect[]
  viewEmpty?: boolean
  canonical?: {
    url: string | null
    isCanonical: boolean | null
  }
  urlStyle?: React.CSSProperties
}

const Redirects: React.FC<Props> = React.memo(props => {
  const { items, canonical, viewEmpty = false } = props

  if (items.length === 0) {
    if (canonical && canonical.isCanonical !== null && !canonical.isCanonical) {
      return <RedirectCanonicalItem url={canonical.url} />
    }

    if (viewEmpty) {
      return <Nullable />
    } else {
      return null
    }
  }

  return (
    <div className="table__url-redirect-container">
      {items.map((item, index) => (
        <RedirectsItem
          key={item.to_url}
          {...item}
          canonical={canonical}
          infinity={items.length >= 9 && items.length - 1 === index}
          isLast={items.length - 1 === index}
        />
      ))}
    </div>
  )
})

export default Redirects
