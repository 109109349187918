import {
  Avatar,
  Button,
  Col,
  Dropdown,
  Layout,
  Menu,
  Row,
  Space,
  Typography,
} from "antd"
import moment from "moment"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { DownOutlined, PlusOutlined, UserOutlined } from "@ant-design/icons"
import logo from "../assets/images/logo/white_logo.svg"
import routes from "../pages/routes"
import { ReportStatus } from "../services/reportService"
import { logout } from "../state/actions/remote/auth"
import { getAuth } from "../state/selectors/remote/auth"
import {
  getProject,
  getProjectList,
} from "../state/selectors/remote/cabinet/project"
import {
  getReport,
  getReportList,
} from "../state/selectors/remote/cabinet/report"
import { Project, ProjectStatus } from "../types/project"
import { UserRole } from "../types/user"
import AdminOnly from "./AdminOnly"
import PageHeader from "./PageHeader"
import env from "../lib/env"

type Props = {
  mainSidebar?: React.ReactNode
  subSidebar?: {
    collapsed: boolean
    onCollapse: (value: boolean) => void
    element: React.ReactNode
  }
}

const CabinetLayout: React.FC<Props> = React.memo(props => {
  const { mainSidebar, subSidebar } = props

  const marginLeft = React.useMemo(() => {
    if (subSidebar) {
      return (!subSidebar.collapsed ? 330 : 160) - (!mainSidebar ? 80 : 0)
    }
    if (mainSidebar) {
      return 80
    }

    return 0
  }, [subSidebar, mainSidebar])

  return (
    <>
      <Navigation />
      <Layout
        hasSider
        style={{ minHeight: "calc(100vh - 54px)", background: "#f0f2f5" }}
      >
        {mainSidebar}
        <Layout
          style={{
            marginLeft,
            marginTop: 54,
          }}
        >
          {subSidebar?.element}
          <Layout.Content style={{ background: "#f0f2f5" }}>
            <PageHeader ghost={false} />
            <div className="content-with-sidebar">
              <Row style={{ height: "100%" }}>
                <Col xl={{ span: 12, offset: 0 }} xxl={{ span: 10, offset: 1 }}>
                  {props.children}
                </Col>
              </Row>
            </div>
          </Layout.Content>
        </Layout>
      </Layout>
    </>
  )
})

export default CabinetLayout

const Navigation: React.FC = React.memo(() => {
  return (
    <Layout.Header
      style={{
        background: "#01719c",
        zIndex: 100,
        position: "fixed",
        width: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Link
        to={routes.index}
        className="header__logo"
        style={{ position: "relative" }}
      >
        <img src={logo} alt="logo" className="header__logo__img" />
      </Link>
      <DropdownResources />
      <DropdownUser />
    </Layout.Header>
  )
})

const DropdownResources: React.FC = () => {
  const project = useSelector(getProject)
  if (!project.data) {
    return null
  }

  return (
    <>
      <DropdownProject project={project.data} />
      <DropdownReport project={project.data} />
    </>
  )
}

const DropdownUser: React.FC = () => {
  const auth = useSelector(getAuth)
  const authData = auth.data

  const dispatch = useDispatch()

  if (!authData) {
    return (
      <Button style={{ marginLeft: "auto" }}>
        <Link to={routes.login()}>Авторизоваться</Link>
      </Button>
    )
  }

  const avatar = auth.data?.user?.avatar_url
    ? { src: auth.data?.user.avatar_url }
    : { icon: <UserOutlined /> }

  const handlerClickMenuItem = (): void => {
    dispatch(logout())
  }

  const overlay = (
    <Menu>
      {auth.data?.user.role === UserRole.Admin && (
        <Menu.Item>
          <a href={env.ADMIN_URL} target="_blank" rel="noopener noreferrer">
            <AdminOnly prefix="Админка" />
          </a>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to={routes.settings().billing().index()}>Подписки</Link>
      </Menu.Item>
      <Menu.Item danger onClick={handlerClickMenuItem}>
        Выход
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={overlay} placement="bottomRight">
      <Space className="header__user-info" style={{ marginLeft: "auto" }}>
        <Avatar size="small" {...avatar} />
        <span className="header__user-info__name">
          {auth.data?.user_profile.name}
        </span>
      </Space>
    </Dropdown>
  )
}

type DropdownProjectProps = {
  project: Project
}

const DropdownProject: React.FC<DropdownProjectProps> = props => {
  const { project } = props

  const [projectCount, setProjectCount] = React.useState(5)
  const projectListState = useSelector(getProjectList)

  // show more
  const handlerClickMenuItem = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    setProjectCount(prevState => (prevState += 5))
  }

  const projectListStateData = projectListState.data

  if (!projectListStateData) {
    return null
  }

  const overlay = (
    <Menu selectedKeys={[project.id]}>
      {projectListStateData.slice(0, projectCount).map(item => (
        <Menu.Item key={item.id}>
          <Link
            to={
              item.id === project.id
                ? "#"
                : routes.site().alias(item.alias).index
            }
          >
            {item.host}
            {item.status === ProjectStatus.Deleted && (
              <Typography.Text type="secondary">
                [Удален] <AdminOnly />
              </Typography.Text>
            )}
          </Link>
        </Menu.Item>
      ))}
      {projectListStateData.length > projectCount && (
        <Menu.Item>
          <Button onClick={handlerClickMenuItem} block type="link">
            Показать еще
          </Button>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to={routes.site().create}>
          <Button block type="link" icon={<PlusOutlined />}>
            Добавить сайт
          </Button>
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <DropDoResource
      overlay={overlay}
      label="Сайт"
      name={
        <>
          {project.host}
          {project.status === ProjectStatus.Deleted && (
            <Typography.Text type="secondary">
              [Удален] <AdminOnly />
            </Typography.Text>
          )}
        </>
      }
    />
  )
}

type DropdownReportProps = {
  project: Project
}

const DropdownReport: React.FC<DropdownReportProps> = props => {
  const { project } = props

  const [reportCount, setReportCount] = React.useState(5)

  const reportState = useSelector(getReport)
  const reportListState = useSelector(getReportList)

  const reportStateData = reportState.data
  const reportListStateData = reportListState.data

  if (!reportStateData || !reportListStateData) {
    return null
  }

  const activeReport = reportListStateData.find(
    report => report.id === reportStateData.id,
  )

  const reports = reportListStateData.filter(report => report.status) // TODO: remove status check after get all info about report

  // show more
  const handlerClickMenuItem = (e: React.MouseEvent): void => {
    e.preventDefault()
    e.stopPropagation()
    setReportCount(prevState => (prevState += 5))
  }

  const reportsMenu = (
    <Menu selectedKeys={activeReport ? [activeReport.id] : []}>
      {reports.slice(0, reportCount).map(report => (
        <Menu.Item key={report.id}>
          <Link
            key={`link_${report.id}`}
            to={
              reportStateData.id === report.id
                ? "#"
                : routes.site().alias(project.alias).reportAlias(report.alias)
                    .index
            }
          >
            {moment(report.created_at).format("D MMMM YYYY, H:mm")}
            {report.status === ReportStatus.Deleted && (
              <Typography.Text type="secondary">
                [Удален] <AdminOnly />
              </Typography.Text>
            )}
          </Link>
        </Menu.Item>
      ))}
      {reports.length > reportCount && (
        <Menu.Item>
          <Button onClick={handlerClickMenuItem} block type="link">
            Показать еще
          </Button>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to={routes.site().alias(project.alias).newReport}>
          <Button block type="link" icon={<PlusOutlined />}>
            Новое сканирование
          </Button>
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <DropDoResource
      overlay={reportsMenu}
      label="Отчет"
      name={moment(reportStateData.created_at).format("D MMMM YYYY, H:mm")}
      style={{
        marginLeft: 15,
      }}
    />
  )
}

type DropDoResourceProps = {
  overlay: JSX.Element
  name: React.ReactNode
  label: string
  style?: React.CSSProperties
}

const DropDoResource: React.FC<DropDoResourceProps> = ({
  overlay,
  name,
  label,
  style,
}) => (
  <Dropdown key="reports-list" overlay={overlay}>
    <Space className="header__projects" style={style}>
      <span className="header__projects__label">{label}:</span>
      <span className="header__projects__name">
        <Space>
          {name}
          <DownOutlined />
        </Space>
      </span>
    </Space>
  </Dropdown>
)
