import React from "react"
import { useDispatch } from "react-redux"
import { useSitemapUrlsGraph } from "../hooks/useSitemapUrlsGraph"
import { loadSitemapUrlsGraph } from "../state/actions/remote/cabinet/report/sitemapUrlsGraph"
import Pie from "./Pie"

type Props = {
  reportId: string
  shareToken?: string
  legend?: boolean
}

const SitemapUrlsGraph: React.FC<Props> = React.memo(
  props => {
    const { reportId, shareToken, legend } = props
    const { state, info } = useSitemapUrlsGraph()

    const dispatch = useDispatch()

    React.useEffect(() => {
      if (!state.data && !state.isLoading) {
        dispatch(loadSitemapUrlsGraph({ reportId, shareToken }))
      }
    }, [])

    return <Pie graph={state} legend={legend} info={info} />
  },
  () => true, // one render
)

export default SitemapUrlsGraph
