import {
  ClearReportAction,
  ClearReportCreatedAction,
  ClearReportListAction,
  CreateReportAction,
  DeleteReportAction,
  LoadReportAction,
  LoadReportByAliasAction,
  LoadReportListAction,
  ReportActionType,
  StopCrawlingAction,
  StopCrawlingResetAction,
} from "../../../../@types/actions/remote/cabinet/report/report"

const clearReport = (): ClearReportAction => ({
  type: ReportActionType.CurrentClear,
})

const clearReportCreated = (): ClearReportCreatedAction => ({
  type: ReportActionType.CreatedClear,
})

const clearReportList = (): ClearReportListAction => ({
  type: ReportActionType.ClearList,
})

const createReport = (
  payload: CreateReportAction["payload"],
): CreateReportAction => ({
  type: ReportActionType.Create,
  payload,
  meta: {
    pending: () => ({ type: ReportActionType.CreatePending }),
    success: payload => ({ type: ReportActionType.CreateSuccess, payload }),
    failure: payload => ({ type: ReportActionType.CreateFailure, payload }),
  },
})

const deleteReport = (
  payload: DeleteReportAction["payload"],
): DeleteReportAction => ({
  type: ReportActionType.Delete,
  payload,
  meta: {
    pending: () => ({ type: ReportActionType.DeletePending }),
    success: payload => ({
      type: ReportActionType.DeleteSuccess,
      payload,
    }),
    failure: payload => ({
      type: ReportActionType.DeleteFailure,
      payload,
    }),
  },
})

const loadReport = (
  payload: LoadReportAction["payload"],
): LoadReportAction => ({
  type: ReportActionType.Load,
  payload,
  meta: {
    pending: () => ({ type: ReportActionType.LoadPending }),
    success: payload => ({
      type: ReportActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: ReportActionType.LoadFailure,
      payload,
    }),
  },
})

const loadReportByAlias = (
  payload: LoadReportByAliasAction["payload"],
): LoadReportByAliasAction => ({
  type: ReportActionType.LoadByAlias,
  payload,
  meta: {
    pending: () => ({ type: ReportActionType.LoadByAliasPending }),
    success: payload => ({
      type: ReportActionType.LoadByAliasSuccess,
      payload,
    }),
    failure: payload => ({
      type: ReportActionType.LoadByAliasFailure,
      payload,
    }),
  },
})

const loadReportList = (
  payload: LoadReportListAction["payload"],
): LoadReportListAction => ({
  type: ReportActionType.LoadList,
  payload,
  meta: {
    pending: () => ({ type: ReportActionType.LoadListPending }),
    success: payload => ({
      type: ReportActionType.LoadListSuccess,
      payload,
    }),
    failure: payload => ({
      type: ReportActionType.LoadListFailure,
      payload,
    }),
  },
})

const stopCrawling = (
  payload: StopCrawlingAction["payload"],
): StopCrawlingAction => ({
  type: ReportActionType.StopCrawling,
  payload,
  meta: {
    pending: () => ({ type: ReportActionType.StopCrawlingPending }),
    success: payload => ({
      type: ReportActionType.StopCrawlingSuccess,
      payload,
    }),
    failure: payload => ({
      type: ReportActionType.StopCrawlingFailure,
      payload,
    }),
  },
})

const resetStopCrawling = (): StopCrawlingResetAction => ({
  type: ReportActionType.StopCrawlingReset,
})

export {
  clearReport,
  clearReportCreated,
  clearReportList,
  createReport,
  deleteReport,
  loadReport,
  loadReportByAlias,
  loadReportList,
  stopCrawling,
  resetStopCrawling,
}
