import { SagaIterator } from "redux-saga"
import { call, put, takeEvery } from "redux-saga/effects"
import apiClient, {
  GetReportListResponse,
} from "../../../../../services/apiClient"
import {
  LoadReportByAliasAction,
  LoadReportListAction,
  ReportActionType,
} from "../../../../@types/actions/remote/cabinet/report/report"

type Actions = LoadReportListAction | LoadReportByAliasAction

function* fetchGetReportList(action: Actions): SagaIterator {
  yield put(action.meta.pending())
  try {
    const response: GetReportListResponse = yield call(
      apiClient.cabinet.project.projects.getReportList,
      action.payload,
    )
    yield put(action.meta.success(response))
  } catch (error) {
    yield put(action.meta.failure(error))
  }
}

export default function* getReportListSaga(): Generator {
  yield takeEvery(ReportActionType.LoadList, fetchGetReportList)
  yield takeEvery(ReportActionType.LoadByAlias, fetchGetReportList)
}
