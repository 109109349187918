import {
  LoadStatusCodesGraphAction,
  StatusCodesGraphActionType,
} from "../../../../@types/actions/remote/cabinet/report/statusCodesGraph"

const loadStatusCodesGraph = (
  payload: LoadStatusCodesGraphAction["payload"],
): LoadStatusCodesGraphAction => ({
  type: StatusCodesGraphActionType.Load,
  payload,
  meta: {
    pending: () => ({
      type: StatusCodesGraphActionType.LoadPending,
    }),
    success: payload => ({
      type: StatusCodesGraphActionType.LoadSuccess,
      payload,
    }),
    failure: payload => ({
      type: StatusCodesGraphActionType.LoadFailure,
      payload,
    }),
  },
})

export { loadStatusCodesGraph }
