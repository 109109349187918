import decodeHostnameInUrl from "../../../../services/url/decodeHostnameInUrl"
import { HostTypes } from "../../../@types/actions/remote/cabinet/host"
import {
  HostActions,
  HostState,
} from "../../../@types/reducers/remote/cabinet/host"
import {
  updateStateLoadFailure,
  updateStateSetPending,
} from "../../../utils/reducer"

const initState: HostState = {
  entryPoints: {
    isLoading: false,
  },
  robotstxtAndSitemaps: {
    isLoading: false,
  },
  validSitemap: {
    isLoading: false,
  },
}

const hostReducer = (
  state: HostState = initState,
  action: HostActions,
): HostState => {
  switch (action.type) {
    case HostTypes.LoadEntryPointsSuccess:
      return {
        ...state,
        entryPoints: {
          data: action.payload.entry_points
            .sort(a => (a.status === 200 ? -1 : 1))
            .map(element => {
              if (element.redirect_url) {
                element.redirect_url = decodeHostnameInUrl(element.redirect_url)
              }

              element.url = decodeHostnameInUrl(element.url)

              return element
            }),
          isLoading: false,
        },
      }

    case HostTypes.LoadEntryPointsFailure:
      return {
        ...state,
        entryPoints: { isLoading: false, error: action.payload },
      }

    case HostTypes.LoadEntryPointsPending:
      const elementState = { ...state.entryPoints, isLoading: true }

      delete elementState.error
      elementState.data = []

      return { ...state, entryPoints: elementState }

    case HostTypes.ClearEntryPoints:
      return { ...state, entryPoints: { isLoading: false } }

    case HostTypes.LoadRobotsAndSitemapsSuccess:
      return {
        ...state,
        robotstxtAndSitemaps: {
          data: action.payload.data,
          isLoading: false,
        },
      }

    case HostTypes.LoadRobotsAndSitemapsFailure:
      return {
        ...state,
        robotstxtAndSitemaps: updateStateLoadFailure(
          state.robotstxtAndSitemaps,
          action,
        ),
      }

    case HostTypes.LoadRobotsAndSitemapsPending:
      return {
        ...state,
        robotstxtAndSitemaps: updateStateSetPending(state.robotstxtAndSitemaps),
      }

    case HostTypes.ClearRobotsAndSitemaps:
      return {
        ...state,
        robotstxtAndSitemaps: JSON.parse(
          JSON.stringify(initState.robotstxtAndSitemaps),
        ),
      }

    case HostTypes.ValidateSitemapSuccess:
      return {
        ...state,
        validSitemap: {
          data: action.payload.valid,
          isLoading: false,
        },
      }

    case HostTypes.ValidateSitemapFailure:
      return {
        ...state,
        validSitemap: updateStateLoadFailure(state.validSitemap, action),
      }

    case HostTypes.ValidateSitemapPending:
      return {
        ...state,
        validSitemap: updateStateSetPending(state.validSitemap),
      }

    case HostTypes.ClearIsValidSitemap:
      return {
        ...state,
        validSitemap: { ...initState.validSitemap },
      }

    default:
      return state
  }
}

export default hostReducer
