import React from "react"
import { useDispatch } from "react-redux"
import { FiltersOnChange, FiltersOnClear } from "../components/Filters"
import { ApiClientFilter, ApiClientSort } from "../services/apiClient"
import { FilterActionType } from "../services/filters"

type UseFilters<Rec> = {
  onChange: FiltersOnChange<Rec>
  onClear: FiltersOnClear
}

const useFilters = <Rec>(
  afterOnChange: () => void,
  afterOnClear: () => void,
  addFilterAction: (filter: ApiClientFilter<Rec>) => void,
  cancelFilterAction: (filter: ApiClientFilter<Rec>) => void,
  clearFilterAction: () => void,
  setSortAction: (sort: ApiClientSort<Rec>) => void,
): UseFilters<Rec> => {
  const dispatch = useDispatch()

  const onChange: FiltersOnChange<Rec> = React.useCallback(
    (filterActions, prevFilterActions) => {
      if (prevFilterActions) {
        for (const filterAction of prevFilterActions) {
          if (filterAction.type === FilterActionType.Filter) {
            dispatch(cancelFilterAction({ ...filterAction.action.payload }))
          }
        }
      }
      for (const filterAction of filterActions) {
        if (filterAction.type === FilterActionType.Filter) {
          dispatch(addFilterAction({ ...filterAction.action.payload }))
          continue
        }

        if (filterAction.type === FilterActionType.Sort) {
          dispatch(setSortAction({ ...filterAction.action.payload }))
          continue
        }
      }

      afterOnChange()
    },
    [cancelFilterAction, addFilterAction, setSortAction],
  )

  const onClear = React.useCallback(() => {
    dispatch(clearFilterAction())
    afterOnClear()
  }, [clearFilterAction])

  return {
    onChange,
    onClear,
  }
}

export default useFilters
