import { ExternalReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/externalReducer"
import { addFilterExternalReport } from "../../../state/actions/remote/cabinet/report/external"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<ExternalReportRecord>[] = [
  {
    name: "Битые ссылки",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterExternalReport({
          page_resource_is_broken: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "С редиректом",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterExternalReport({
          target_resource_status_code: [
            [ApiClientFilterType.Gte, 300],
            [ApiClientFilterType.Lte, 399],
          ],
        }),
      },
    ],
  },
  {
    name: "nofollow",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterExternalReport({
          page_resource_anchor_is_nofollow: [[ApiClientFilterType.Eq, true]],
        }),
      },
    ],
  },
  {
    name: "follow",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterExternalReport({
          page_resource_anchor_is_nofollow: [[ApiClientFilterType.Ne, true]],
        }),
      },
    ],
  },
  {
    name: "UGC",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterExternalReport({
          page_resource_attrs_rel: [[ApiClientFilterType.In, ["ugc"]]],
        }),
      },
    ],
  },
  {
    name: "Sponsored",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterExternalReport({
          page_resource_attrs_rel: [[ApiClientFilterType.In, ["sponsored"]]],
        }),
      },
    ],
  },
]

export default filters
