import { Button, Card, Form, Input } from "antd"
import { Store } from "rc-field-form/lib/interface"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { updateProfile } from "../state/actions/remote/auth"
import { getAuth } from "../state/selectors/remote/auth"

interface FormStore extends Store {
  name: string
  email: string
  phone?: string
  company?: string
  position?: string
}

const RegisterPage: React.FC = (): JSX.Element | null => {
  const auth = useSelector(getAuth)

  const dispatch = useDispatch()

  if (!auth.data) {
    return null
  }

  const onFinish = (body: FormStore): void => {
    dispatch(
      updateProfile({
        ...body,
      }),
    )
  }

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Card title="Регистрация" style={{ minWidth: 450 }}>
        <Form
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            name: auth.data.user_profile.name,
            email: auth.data.user_profile.email,
          }}
        >
          <Form.Item
            name="name"
            label="Имя"
            rules={[{ required: true, max: 128, type: "string" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[{ required: true, max: 128, type: "email" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="phone"
            label="Телефон"
            rules={[{ required: true, max: 20, type: "string" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="company"
            label="Организация"
            rules={[{ max: 128, type: "string" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="position"
            label="Должность"
            rules={[{ max: 128, type: "string" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button style={{ float: "right" }} type="primary" htmlType="submit">
              Продолжить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}

export default RegisterPage
