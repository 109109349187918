import { Action } from "redux"
import {
  ExportListPageResourcesPayload,
  GetListPageResourcesPayload,
  GetListPageResourcesResponse,
} from "../../../../../../services/apiClient"
import { ImageReportRecord } from "../../../../reducers/remote/cabinet/report/imageReducer"
import {
  AddFilterAction,
  CancelFilterAction,
  ExportAction,
  LoadAction,
  SetPaginationAction,
  SetSortAction,
} from "../../../filteredResourceAction"

export enum ImageReportActionType {
  Load = "@@REPORTS/IMAGES/LOAD",
  LoadSuccess = "@@REPORTS/IMAGES/LOAD_SUCCESS",
  LoadPending = "@@REPORTS/IMAGES/LOAD_PENDING",
  LoadFailure = "@@REPORTS/IMAGES/LOAD_FAILURE",
  SetPagination = "@@REPORTS/IMAGES/SET_PAGINATION",
  SetSort = "@@REPORTS/IMAGES/SET_SORT",
  AddFilter = "@@REPORTS/IMAGES/ADD_FILTER",
  CancelFilter = "@@REPORTS/IMAGES/CANCEL_FILTER",
  ClearFilter = "@@REPORTS/IMAGES/CLEAR_FILTER",
  ClearSort = "@@REPORTS/IMAGES/CLEAR_SORT",
  Export = "@@REPORTS/IMAGES/EXPORT",
}

export type LoadImageReportAction = LoadAction<
  GetListPageResourcesPayload<ImageReportRecord>,
  GetListPageResourcesResponse<ImageReportRecord>,
  ImageReportActionType.Load,
  ImageReportActionType.LoadPending,
  ImageReportActionType.LoadSuccess,
  ImageReportActionType.LoadFailure
>
export type LoadPendingImageReportAction = ReturnType<
  LoadImageReportAction["meta"]["pending"]
>
export type LoadSuccessImageReportAction = ReturnType<
  LoadImageReportAction["meta"]["success"]
>
export type LoadFailureImageReportAction = ReturnType<
  LoadImageReportAction["meta"]["failure"]
>

export type ClearFilterImageReportAction = Action<
  ImageReportActionType.ClearFilter
>
export type ClearSortImageReportAction = Action<ImageReportActionType.ClearSort>

export type ExportImageReportAction = ExportAction<
  ImageReportRecord,
  ExportListPageResourcesPayload<ImageReportRecord>,
  ImageReportActionType.Export
>

export type AddFilterImageReportAction = AddFilterAction<
  ImageReportRecord,
  ImageReportActionType.AddFilter
>

export type CancelFilterImageReportAction = CancelFilterAction<
  ImageReportRecord,
  ImageReportActionType.CancelFilter
>

export type SetSortImageReportAction = SetSortAction<
  ImageReportRecord,
  ImageReportActionType.SetSort
>

export type SetPaginationImageReportAction = SetPaginationAction<
  ImageReportActionType.SetPagination
>
