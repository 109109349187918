import { DescriptionReportRecord } from "../../../state/@types/reducers/remote/cabinet/report/descriptionReducer"
import {
  addFilterDescriptionReport,
  setSortDescriptionReport,
} from "../../../state/actions/remote/cabinet/report/description"
import { ApiClientFilterType } from "../../apiClient/@types/filter"
import { ApiClientSortOrder } from "../../apiClient/@types/sort"
import { Filter, FilterActionType } from "../types/filter"

const filters: Filter<DescriptionReportRecord>[] = [
  {
    name: "дубликат",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterDescriptionReport({
          page_description_not_unique: [[ApiClientFilterType.Eq, true]],
        }),
      },
      {
        type: FilterActionType.Sort,
        action: setSortDescriptionReport({
          page_description: ApiClientSortOrder.Desc,
        }),
      },
    ],
  },
  {
    name: "пустые",
    actions: [
      {
        type: FilterActionType.Filter,
        action: addFilterDescriptionReport({
          page_description: [[ApiClientFilterType.Eq, ""]],
        }),
      },
    ],
  },
]

export default filters
